import { useRef, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { MdOutlineAddCircleOutline } from "react-icons/md";

import Modal from "../Modal";
import ManagementTable from "../ManagementTable";
import ConfirmationModal from "../ConfirmationModal";
import { useThrottle, useToggle } from "../../helpers/hooks";

export default function AddRowsModal({
    title = "User",
    tableTitle = "Users",
    primaryKey = "userGuid",
    show,
    onHide,
    fields,
    apis,
    initialSearchForm,
    searchFields,
    onAddRows,
    confirmationMessage = `Are you sure you want to add the selected ${title}(s) ?`,
}) {
    const [showConfirmation, toggleShowConfirmation, setShowConfirmation] = useToggle(false);
    const selectedRows = useRef([]);

    const onConfirm = useThrottle(
        () => {
            onAddRows(selectedRows.current);
            setShowConfirmation(false);
        },
        [onAddRows, setShowConfirmation],
        1000
    );

    const onChangeSelection = useCallback((selected) => (selectedRows.current = selected), []);

    const handleAddRows = useThrottle(
        () => {
            if (!selectedRows.current?.length) return toast.warning(`Please select at least one ${title}.`);

            setShowConfirmation(true);
        },
        [setShowConfirmation],
        1000
    );

    useEffect(() => {
        selectedRows.current = [];
    }, [show]);

    return (
        <>
            <Modal show={show} onHide={onHide} size="xl" title={""}>
                <div className="add-rows-modal">
                    <ManagementTable
                        title={title}
                        tableTitle={tableTitle}
                        primaryKey={primaryKey}
                        fields={fields}
                        apis={apis}
                        initialSearchForm={initialSearchForm}
                        searchFields={searchFields}
                        onChangeSelection={onChangeSelection}
                        initialFilterCount={6}
                        searchCols={{ open: 12, close: 6 }}
                        withCheckboxes
                        withSearch
                        hideActionsColumn
                        hideAddButton
                    />
                </div>
                <div className="p-3 my-2 filterAdduserBtns d-flex justify-content-end">
                    <div className="mx-2">
                        <button className="btnPrimaryOutline btn btn-outline-primary" type="button" onClick={onHide}>
                            CANCEL
                        </button>
                    </div>
                    <div className="mx-2">
                        <button className="addNewUser btn" type="button" onClick={handleAddRows}>
                            <MdOutlineAddCircleOutline />
                            {`Add Selected ${title}s`}
                        </button>
                    </div>
                </div>
            </Modal>
            <ConfirmationModal show={showConfirmation} onCancel={toggleShowConfirmation} confirmationMessage={confirmationMessage} onConfirm={onConfirm} />
        </>
    );
}
