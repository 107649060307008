import "./LoadingSpinner.css"

export default function LoadingSpinner() {
    return <div className="loading-spinner">
        <div className="d-flex justify-content-center" >
            <div className="spinner-border" role="status">
                <span className="sr-only" />
            </div>
        </div>
    </div>
}