import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { toast } from "react-toastify";
import BodyBackground from "../../components/BodyBackground";
import LoadingModal from "../../components/LoadingModal";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { logInUserAction } from "../../redux/userReducer/userActions";

import "./SignIn.css";

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address format").required("Required Field."),
    password: Yup.string().min(3, "Password must be 3 characters at minimum").required("Required Field."),
});

const SignIn = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const verified = useRef(false);
    const processing = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [logo, setLogo] = useState(null);
    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        const funder = searchParams.get("funder");
        if (funder) {
            callApi.get(buildURL(`${Urls.GetFundersLogo}?funderCode=${funder}`)).then((response) => {
                if (response.responseCode === 200) {
                    setLogo(response?.responseData?.logo1);
                }
            });
        }
    }, []);
    const handleSubmit = async (values) => {
        if (!values.email || !values.password) return setError("Please enter an email address and Password.");
        if (!verified.current) return setError("Please verify the captcha.");
        if (processing.current) return;

        processing.current = true;
        setError("");
        setLoading(true);

        // const mdEncrypt = md5(values.password); commented as Vinod has not implemented this conversion
        const postData = values.email + ":" + values.password;
        const encrypt = btoa(postData);
        const url = buildURL(Urls.UserLogin);
        const headers = {
            Authorization: `Basic ${encrypt}`,
            X_SPARK_AUTH: process.env.REACT_APP_X_SPARK_AUTH,
            device_type: 1,
            device_id: 1,
            captchaCode: verified.current,
        };

        const response = await callApi.post(url, {}, { headers });

        processing.current = false;
        setLoading(false);

        if (response.responseCode !== 200) return toast.error(response.responseData);
        response.responseData.email = response.responseData.emailId;
        delete response.responseData.emailId;

        dispatch(logInUserAction(response.responseData));
        if (location.state)
            navigate(location.state, {
                replace: true,
            });
    };

    const onCaptchaChange = (value) => (verified.current = value);
    const onCaptchaExpire = () => (verified.current = false);

    // useEffect(() => {
    //   const timeout = setTimeout(() => navigate("/sign-in", { replace: true }), 100);

    //   return () => clearTimeout(timeout);
    // }, [navigate]);

    return (
        <BodyBackground logo={logo}>
            <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={LoginSchema}
                onSubmit={({ setSubmitting }) => {
                    setSubmitting(true);
                }}
            >
                {({ touched, errors, isSubmitting, values }) => (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(values);
                        }}
                    >
                        <div className="login-form-wrapper">
                            <div className="login-form-wrapperInner">
                                <h1 className="SignInTxt mt-3">Sign In</h1>
                                <div className="form-group">
                                    <label htmlFor="email">EMAIL ADDRESS</label>
                                    <Field
                                        type="email"
                                        maxLength={100}
                                        name="email"
                                        placeholder="Enter Email Address"
                                        className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Field
                                        type="password"
                                        maxLength={25}
                                        name="password"
                                        placeholder="Enter Password"
                                        className={`form-control ${touched.password && errors.password ? "is-invalid" : ""}`}
                                    />
                                    <ErrorMessage component="div" name="password" className="invalid-feedback" />
                                </div>
                                <ReCAPTCHA className="captcha-container" sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={onCaptchaChange} onExpired={onCaptchaExpire} />
                                <div className="text-center p-2">
                                    <span className="text-danger">{error}</span>
                                </div>
                                <div className="checkboxWrap">
                                    {/* <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" name="remember-me" id="remember-me" />
                    <label className="custom-control-label" htmlFor="remember-me">
                      Remember Me
                    </label>
                  </div> */}
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" name="remember-me" id="remember-me" />
                                        <label className="form-check-label" htmlFor="remember-me">
                                            Remember Me
                                        </label>
                                    </div>
                                    <Link to="/forgot-password">Set Your Password</Link>
                                </div>
                                <div className="mb-3 text-center">
                                    <button type="submit" className="btn purpleBtn btn-block">
                                        {isSubmitting ? "Please wait..." : "Sign In"}
                                    </button>
                                </div>
                                <div className="text-center account-txt">
                                    <p>
                                        {"Don't have an account? ".replace("'", "'")}
                                        <a href="https://entrepreneursforever.org/join-us/" target="_blank" rel="noreferrer noopener ">
                                            Find Out More
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
            <LoadingModal show={loading} />
        </BodyBackground>
    );
};

export default SignIn;
