import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// import GrowthGraph from "../../assets/images/Growth_graph.png";
// import CalenderIcon from "../../assets/images/calendar_with_bg.svg";
import GreenStar from "../../assets/images/greenStar.svg";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import ManagementTable from "../../components/ManagementTable";
import { eventPrimaryKey } from "../../services/events/eventServices";
import {
    userDashboardUpcomingEventFields,
    userDashboardUpcomingEventApis,
    userDashboardUpcomingMilestoneFields,
    userDashboardUpcomingMilestoneApis,
    userDashboardForCommunityDirectorAndFacilitatorApis,
    userDashboardForCommunityDirectorAndFacilitatorPeerGroupsFields,
    userDashboardPendingAttendanceFields,
    userDashboardPendingAttendanceApis,
} from "./DATA";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
    selectUserId,
    selectUserIsCommunityDirector,
    selectUserIsFacilitator,
    selectUserIsProspectNotScheduled,
    selectUserIsProspectQualified,
    selectUserIsProspectWaitlist,
} from "../../redux/userReducer/userSelectors";
import { formatDateOnly, formatTimeOnly } from "../../helpers";
import { getUserDashboardUpcomingEvents } from "../../services/users/userEventServices";

const UserDashboard = ({ userId, isProspectOrMember, isStaff }) => {
    const rescheduleEventSchema = Yup.object().shape({
        eventId: Yup.string().required("Required Field"),
    });
    const [data, setData] = useState({
        isBusinessDetail: true,
        isResultForm: true,
        isBusinessAddress: true,
        isDemographic: true,
        isMemberAgreement: true,
        isPeerGroupMeeting: true,
        isYearlyResult: true,
    });
    const [discoverySessions, setDiscoverySessions] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [showConfirmationPopup2, setShowConfirmationPopup2] = useState(false);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetSignMemberAgreement}${userId}`));

            if (!response.responseData) return;

            setData(response.responseData);
        })();
    }, [userId]);
    useEffect(() => {
        (async () => {
            const response = await getUserDashboardUpcomingEvents({ userId });
            if (!response.data) return;
            setUpcomingEvents(response?.data);
        })();
    }, [userId, refresh]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetDiscoverySessions}`));

            if (!response.responseData) return;

            upcomingEvents.length > 0
                ? setDiscoverySessions([
                      ...response?.responseData?.searchResult,
                      {
                          eventDate: "",
                          eventEndTime: "",
                          eventId: "none",
                          eventStartTime: "",
                          eventTimeZone: "",
                      },
                  ])
                : setDiscoverySessions(response?.responseData?.searchResult);
        })();
    }, [refresh, upcomingEvents.length]);

    const { isFacilitator, myUserId, isCommunityDirectory, isProspectQualified, isProspectWaitlist, isProspectNotScheduled } = useSelector(
        createStructuredSelector({
            isFacilitator: selectUserIsFacilitator,
            myUserId: selectUserId,
            isCommunityDirectory: selectUserIsCommunityDirector,
            isProspectQualified: selectUserIsProspectQualified,
            isProspectWaitlist: selectUserIsProspectWaitlist,
            isProspectNotScheduled: selectUserIsProspectNotScheduled,
        })
    );

    const upcomingEventApis = useMemo(() => ({ ...userDashboardUpcomingEventApis, getAll: { ...userDashboardUpcomingEventApis.getAll, body: { userId } } }), [userId]);
    const upcomingMilestoneWidgetApis = useMemo(() => ({ ...userDashboardUpcomingMilestoneApis, getAll: { ...userDashboardUpcomingMilestoneApis.getAll, body: { userId } } }), [userId]);
    const userDashboardPeeraGroupApis = useMemo(
        () => ({ ...userDashboardForCommunityDirectorAndFacilitatorApis, getAll: { ...userDashboardForCommunityDirectorAndFacilitatorApis.getAll, body: { userId } } }),
        [userId]
    );
    const userDashboardPendingAttendanceFinalApis = useMemo(() => ({ ...userDashboardPendingAttendanceApis, getAll: { ...userDashboardPendingAttendanceApis.getAll, body: { userId } } }), [userId]);
    return (
        <div className="dashboardWrapper">
            <div className="row">
                {isProspectOrMember && !isProspectQualified && !isProspectWaitlist && !isProspectNotScheduled && (
                    <div className="col-12 col-lg-6">
                        <div className="todolistCard customCard">
                            <div className="dashboardTitle">
                                <p>
                                    <img src={GreenStar} alt="Required to become a member" /> Required to become a member
                                </p>
                                <h3>Your To-Do List</h3>
                            </div>
                            <div className="todoLists">
                                <ul>
                                    {!data.isBusinessDetail && (
                                        <li className="stepPending star">
                                            <Link to={`/users/${userId}/user/business`}>Update Business Details</Link>
                                        </li>
                                    )}
                                    {!data.isYearlyResult && (
                                        <li className="stepPending star">
                                            <Link to={`/users/${userId}/user/annual-member-results`}>Add Annual Member Results</Link>
                                        </li>
                                    )}
                                    {!data.isBusinessAddress && (
                                        <li className="stepPending">
                                            <Link to={`/users/${userId}/user/contact-info`}>Add Business Address</Link>
                                        </li>
                                    )}
                                    {!data.isDemographic && (
                                        <li className="stepPending star">
                                            <Link to={`/users/${userId}/user/demographics`}>Add Demographic Information</Link>
                                        </li>
                                    )}
                                    {!data.isMemberAgreement && (
                                        <li className="stepPending star">
                                            <Link to={`/users/${userId}/forms/member-agreement`}>Sign Member Agreement</Link>
                                        </li>
                                    )}
                                    {!data.isPeerGroupMeeting && (
                                        <li className="stepPending star">
                                            <Link to={`/users/${userId}/events`}>Attend Peer Group Meeting</Link>
                                        </li>
                                    )}
                                    {!data.isResultForm && (
                                        <li className="stepPending">
                                            <Link to={`/users/${userId}/forms/month-list`}>Submit Monthly Results Form</Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
                {isFacilitator && isStaff && (
                    <div className="col-12">
                        <div className="my-3">
                            <ManagementTable
                                title="Pending Attendance"
                                tableTitle={"Pending Attendance"}
                                primaryKey={eventPrimaryKey}
                                apis={userDashboardPendingAttendanceFinalApis}
                                fields={userDashboardPendingAttendanceFields}
                                hideActionsColumn
                                hideAddButton
                                noPagination
                                noSorting
                            />
                        </div>
                    </div>
                )}
                {(isFacilitator || isCommunityDirectory || isProspectOrMember) && myUserId === userId && !isProspectQualified && !isProspectWaitlist && !isProspectNotScheduled && (
                    <div className="col-12">
                        <div className="my-3">
                            <ManagementTable
                                title="My Peer Groups"
                                tableTitle={"My Peer Groups"}
                                primaryKey={eventPrimaryKey}
                                apis={userDashboardPeeraGroupApis}
                                fields={userDashboardForCommunityDirectorAndFacilitatorPeerGroupsFields}
                                hideActionsColumn
                                hideAddButton
                                noPagination
                                noSorting
                            />
                        </div>
                    </div>
                )}
                <div className="col-12">
                    <div className="my-3">
                        <ManagementTable
                            title="Event"
                            tableTitle={"Upcoming Events For Next 90 Days"}
                            primaryKey={eventPrimaryKey}
                            apis={upcomingEventApis}
                            fields={userDashboardUpcomingEventFields}
                            localSort
                            hideActionsColumn
                            refreshTable={refresh}
                            hideAddButton
                            noPagination
                        />
                    </div>
                </div>
                {isFacilitator && myUserId === userId && (
                    <div className="col-12">
                        <div className="my-3">
                            <ManagementTable
                                title="Milestone"
                                tableTitle={"Upcoming Milestones For Next 30 Days"}
                                primaryKey={eventPrimaryKey}
                                apis={upcomingMilestoneWidgetApis}
                                fields={userDashboardUpcomingMilestoneFields}
                                hideActionsColumn
                                hideAddButton
                                noPagination
                                noSorting
                            />
                        </div>
                    </div>
                )}

                {(isProspectQualified || isProspectWaitlist || isProspectNotScheduled) && (
                    <Formik
                        enableReinitialize={true}
                        validationSchema={rescheduleEventSchema}
                        initialValues={{
                            eventId: "",
                            convenientEventNotFound: "",
                        }}
                        onSubmit={async (values, actions) => {
                            const response = await callApi.post(buildURL(`${Urls.RescheduleEvent}`), {
                                ...values,
                                eventId: values.eventId === "none" ? "" : values.eventId,
                                convenientEventNotFound: values.eventId === "none",
                            });
                            if (!response.responseData) return toast.error("Something Went Wrong!");

                            if (response.responseCode === 200 && values.eventId === "none") {
                                setShowConfirmationPopup(true);
                                setRefresh((prev) => prev + 1);
                            } else if (response.responseCode === 200) {
                                // toast.success(`Discovery Session has been ${upcomingEvents?.length ? "rescheduled" : "scheduled"} successfully!`);
                                setShowConfirmationPopup2(true);
                                setRefresh((prev) => prev + 1);
                            } else toast.error("Something Went Wrong!");
                        }}
                    >
                        {(formik) => (
                            <Form>
                                <div className="customCard">
                                    <h3>{upcomingEvents?.length ? "Reschedule" : "Schedule"} Discovery Session</h3>
                                    <div className="pt-4 pb-4 reschedule-session-email" style={{ fontSize: 16 }}>
                                        {!upcomingEvents?.length
                                            ? "If you are ready to become a Member of Entrepreneurs Forever, the next step is to SCHEDULE an upcoming Discovery Session, can't wait to see you there!"
                                            : "As business owners ourselves, we understand things come up, if you need to RESCHEDULE your discovery session you're in the right place!!!"}
                                    </div>
                                    {!discoverySessions.length ? (
                                        <span>No Discovery Sessions available at this moment!</span>
                                    ) : (
                                        <>
                                            {discoverySessions?.map((d) => {
                                                return (
                                                    <div class="customRadioButton form-check-inline pb-2">
                                                        <Field type="radio" name="eventId" value={d?.eventId} id="actionRadio4" className="form-check-input" />
                                                        <label htmlFor="actionRadio1" className="form-check-label">
                                                            {d.eventId === "none"
                                                                ? "None of these times work for me"
                                                                : `${formatDateOnly(d?.eventDate)} ${formatTimeOnly(d?.eventStartTime)} ${d?.eventEndTime && "-"} ${formatTimeOnly(d?.eventEndTime)}`}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                    <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"eventId"} />{" "}
                                    {discoverySessions?.length > 0 ? (
                                        <Row>
                                            <Col lg="12" className="text-end pt-3">
                                                <button type="submit" className="customPrimaryBtn btn" variant="primary">
                                                    {upcomingEvents?.length ? "RESCHEDULE" : "SCHEDULE"} SESSION
                                                </button>
                                            </Col>
                                        </Row>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
                <Modal className="manageUserPopup" show={showConfirmationPopup} onHide={() => setShowConfirmationPopup(false)} backdrop="static" size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Discovery Session Not Scheduled</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="reschedule-session-email">
                            We can’t wait for you to explore our program. Please check back in a few days for updated dates to select a Discovery Session date that works best for you.”
                            <p className="pt-2 pb-2">
                                Please feel free to use our “Chat” feature located on your profile for any further questions or reach out to us at{" "}
                                <a style={{ paddingLeft: 5 }} href="mailto:info@e4ever.org">
                                    info@e4ever.org
                                </a>
                                .
                            </p>{" "}
                            Best, <p>Entrepreneurs Forever Team!</p>
                        </p>
                        <Row>
                            <Col lg="12" className="text-end pt-3">
                                <button onClick={() => setShowConfirmationPopup(false)} className="customPrimaryBtn btn" variant="primary">
                                    Continue
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Modal className="manageUserPopup" show={showConfirmationPopup2} onHide={() => setShowConfirmationPopup2(false)} backdrop="static" size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Discovery Session Scheduled</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="reschedule-session-email">We have updated your Discovery Session!</p>
                        <Row>
                            <Col lg="12" className="text-end pt-3">
                                <button onClick={() => setShowConfirmationPopup2(false)} className="customPrimaryBtn btn" variant="primary">
                                    Continue
                                </button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                {/* <div className="col-12 col-lg-6">
          <div className="todolistCard customCard">
            <div className="dashboardTitle">
              <h3>Upcoming Peer Group</h3>
            </div>
            <div className="peerDateTimeSec">
              <img src={CalenderIcon} alt="Calendar" />
              <div>
                <h5>Tuesday, November 23, 2021</h5>
                <p>10:00am - 12:00pm EST</p>
              </div>
            </div>
            <div className="addressFocusSec">
              <div className="addressDetails">
                <h6>ADDRESS DETAILS</h6>
                <p>597 5th Ave<br />
                  New York, NY <br />
                  10017<br /><br />
                  Take elevator to 3rd floor.</p>
              </div>
              <div className="focusSec">
                <h6>FOCUS</h6>
                <button className="salesBtn btn" type="button">
                  <img src={GrowthGraph} alt="Graph" />  Sales</button>
              </div>
            </div>
          </div>
        </div> */}
            </div>
        </div>
    );
};

export default UserDashboard;
