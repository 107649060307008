import { formToFormData } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const countiesPrimaryKey = "countyId";
export const communityCountiesPrimaryKey = "countyCummunityId";

export const getCounites = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchCounty), {
        headers: filters,
    });
    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const createUpdateCounites = async ({ ...form }) => {
    const { name, isActive } = form;
    const payload = {
        isActive,
        name,
    };
    if (form[countiesPrimaryKey]) {
        payload.countyId = form.countyId;
    }
    const response = await callApi.post(buildURL(form[countiesPrimaryKey] ? Urls.UpdateCounty : Urls.AddCounty), formToFormData(payload));
    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.countyId } } : response;
};

export const getCountiesById = async (countyId) => {
    const response = await callApi.get(buildURL(Urls.GetCountyById), { headers: { countyId } });
    const data = response.responseData;
    return {
        data:
            response.responseCode !== 200 || !data
                ? null
                : {
                      ...data,
                  },
    };
};

export const getCounitesCommunity = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchCountiesCommunity), {
        headers: filters,
    });
    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const createUpdateCounitesCommunity = async ({ ...form }) => {
    const { communityCode, startDate, endDate, countyId } = form;
    const payload = {
        communityCode,
        startDate,
    };
    if (endDate) {
        payload.endDate = endDate;
    }
    if (form[communityCountiesPrimaryKey]) {
        payload.communityCountyId = form.countyCummunityId;
    } else {
        payload.countyId = countyId;
    }

    const response = await callApi.post(buildURL(form[communityCountiesPrimaryKey] ? Urls.UpdateCountiesCommunity : Urls.AddCountiesCommunity), formToFormData(payload));
    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.funderId } } : response;
};

export const getCountiesCommunityById = async (countiesCommunityId) => {
    const response = await callApi.get(buildURL(Urls.getCountiesommunityById), { headers: { countiesCommunityId } });
    const data = response.responseData;
    return {
        data:
            response.responseCode !== 200 || !data
                ? null
                : {
                      ...data,
                  },
    };
};

export const deleteCountiesCommunity = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteCountiesCommunity), {
        communityCountyId: formData.countyCummunityId,
    });
    return response;
};

export const getCountyCommunityCode = async (code = null) => {
    let response = null;
    if (code === null) {
        response = await callApi.get(buildURL(Urls.GetCountyByCommunity));
    } else {
        response = await callApi.get(buildURL(Urls.GetCountyByCommunity), { headers: { communityCode: code } });
    }
    const options = {
        data: [],
        _fetchedAt: Date.now(),
        _hasError: false,
    };
    if (response.responseData && response.responseData.length) {
        options.data = response.responseData.map((c) => ({ label: c.name, value: c.id?.trim() || "", roleCode: c.id?.trim() }));
    }
    return options;
};
