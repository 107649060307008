import { createGetOptionsApi } from "..";
import { renameObjectKey } from "../../helpers";
import { buildURL, callApi } from "../../helpers/api-urls";

export const peerGroupScorecardPrimaryKey = "userPeerGroupScoreCardId";

// const peerGroupScorecardDetailsKeyMaps = {
//     queOne: "que1",
//     queTwo: "que2",
//     queThree: "que3",
//     queFour: "que4",
//     queFive: "que5",
//     queSix: "que6",
//     queSeven: "que7",
//     queEight: "que8",
//     queNine: "que9",
//     noteOne: "que1Note",
//     noteTwo: "que2Note",
//     noteThree: "que3Note",
//     noteFour: "que4Note",
//     noteFive: "que5Note",
//     noteSix: "que6Note",
//     noteSeven: "que7Note",
//     noteEight: "que8Note",
//     noteNine: "que9Note",
// };

export const getPeerGroupScorecard = async ({ peerGroupId, communityName, communityCode, peerGroupName }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL("/PeerGroup/ScoreCard"), {
        headers: {
            peerGroup: peerGroupId,
        },
    });

    if (Array.isArray(responseData?.records)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.records.map((row) => {
            return {
                year: row.year,
                quarter: row.scoreCardQuarter,
                membersCount: row.members,
                statusName: row.status,
                statusCode: row.statusCode,
                peerGroupId,
                communityName,
                communityCode,
                peerGroupName,
            };
        });
    }

    return response;
};

export const getPeerGroupScorecardDetails = async ({
    // pageSize,
    // pageNumber,
    // sortOrder,
    // sortField,
    communityCode,
    peerGroupId,
    communityName,
    peerGroupName,
    statusCode,
    year,
    quarter,
}) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
        peerGroupScoreCardStatusName: "",
    };

    const { responseData } = await callApi.get(buildURL("/PeerGroup/ScoreCard/Details"), {
        headers: {
            // pageSize,
            // pageNumber,
            // sortOrder,
            // sortField: peerGroupScorecardDetailsKeyMaps[sortField] || sortField,
            community: communityCode,
            peerGroup: peerGroupId,
            year,
            quarter: `${quarter || "Q1"}QAR `,
            scoreCardStatus: "" ?? statusCode,
        },
    });

    if (Array.isArray(responseData?.result?.searchResult)) {
        response.totalRecords = responseData.result.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.peerGroupScoreCardStatusName = responseData.peerGroupScoreCardStatus;
        response.data = responseData.result.searchResult.map((row) => {
            const queOne = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE1");
            row.queOnePercentage = (queOne.noCount * 100) / queOne.totalCount;
            const queTwo = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE2");
            row.queTwoPercentage = (queTwo.noCount * 100) / queTwo.totalCount;
            const queThree = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE3");
            row.queThreePercentage = (queThree.noCount * 100) / queThree.totalCount;
            const queFour = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE4");
            row.queFourPercentage = (queFour.noCount * 100) / queFour.totalCount;
            const queFive = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE5");
            row.queFivePercentage = (queFive.noCount * 100) / queFive.totalCount;
            const queSix = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE6");
            row.queSixPercentage = (queSix.noCount * 100) / queSix.totalCount;
            const queSeven = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE7");
            row.queSevenPercentage = (queSeven.noCount * 100) / queSeven.totalCount;
            const queEight = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE8");
            row.queEightPercentage = (queEight.noCount * 100) / queEight.totalCount;
            const queNine = responseData.scoreCardHeader.find((q) => q.columnCode === "QUE9");
            row.queNinePercentage = (queNine.noCount * 100) / queNine.totalCount;

            const qSetter = (val) => (val === null ? "" : val.toString());
            const noteSetter = (val) => val || "";

            renameObjectKey(row, "que1", "queOne", qSetter);
            renameObjectKey(row, "que2", "queTwo", qSetter);
            renameObjectKey(row, "que3", "queThree", qSetter);
            renameObjectKey(row, "que4", "queFour", qSetter);
            renameObjectKey(row, "que5", "queFive", qSetter);
            renameObjectKey(row, "que6", "queSix", qSetter);
            renameObjectKey(row, "que7", "queSeven", qSetter);
            renameObjectKey(row, "que8", "queEight", qSetter);
            renameObjectKey(row, "que9", "queNine", qSetter);
            renameObjectKey(row, "que1Note", "noteOne", noteSetter);
            renameObjectKey(row, "que2Note", "noteTwo", noteSetter);
            renameObjectKey(row, "que3Note", "noteThree", noteSetter);
            renameObjectKey(row, "que4Note", "noteFour", noteSetter);
            renameObjectKey(row, "que5Note", "noteFive", noteSetter);
            renameObjectKey(row, "que6Note", "noteSix", noteSetter);
            renameObjectKey(row, "que7Note", "noteSeven", noteSetter);
            renameObjectKey(row, "que8Note", "noteEight", noteSetter);
            renameObjectKey(row, "que9Note", "noteNine", noteSetter);

            row.queOneView = !row.queOne ? "" : row.queOne === "1" ? "Yes" : row.queOne === "0" ? "No" : "Maybe";
            row.queTwoView = !row.queTwo ? "" : row.queTwo === "1" ? "Yes" : row.queTwo === "0" ? "No" : "Maybe";
            row.queThreeView = !row.queThree ? "" : row.queThree === "1" ? "Yes" : row.queThree === "0" ? "No" : "Maybe";
            row.queFourView = !row.queFour ? "" : row.queFour === "1" ? "Yes" : row.queFour === "0" ? "No" : "Maybe";
            row.queFiveView = !row.queFive ? "" : row.queFive === "1" ? "Yes" : row.queFive === "0" ? "No" : "Maybe";
            row.queSixView = !row.queSix ? "" : row.queSix === "1" ? "Yes" : row.queSix === "0" ? "No" : "Maybe";
            row.queSevenView = !row.queSeven ? "" : row.queSeven === "1" ? "Yes" : row.queSeven === "0" ? "No" : "Maybe";
            row.queEightView = !row.queEight ? "" : row.queEight === "1" ? "Yes" : row.queEight === "0" ? "No" : "Maybe";
            row.queNineView = !row.queNine ? "" : row.queNine === "1" ? "Yes" : row.queNine === "0" ? "No" : "Maybe";

            return { ...row, communityName, peerGroupName, quarter, year };
        });
    }

    return response;
};

export const updatePeerGroupScorecardDetails = async ({ peerGroupId, rows }) => {
    const response = await callApi.post(buildURL("/PeerGroup/ScoreCard/Update"), {
        peerGroup: peerGroupId,
        scoreCards: rows.map((row) => ({
            userPeerGroupScoreCardId: row.userPeerGroupScoreCardId,
            peerGroupScoreCardId: row.peerGroupScoreCardId,
            userGuid: row.userGuid,
            que1: !row.queOne ? null : +row.queOne,
            que1Note: row.noteOne,
            que2: !row.queTwo ? null : +row.queTwo,
            que2Note: row.noteTwo,
            que3: !row.queThree ? null : +row.queThree,
            que3Note: row.noteThree,
            que4: !row.queFour ? null : +row.queFour,
            que4Note: row.noteFour,
            que5: !row.queFive ? null : +row.queFive,
            que5Note: row.noteFive,
            que6: !row.queSix ? null : +row.queSix,
            que6Note: row.noteSix,
            que7: !row.queSeven ? null : +row.queSeven,
            que7Note: row.noteSeven,
            que8: !row.queEight ? null : +row.queEight,
            que8Note: row.noteEight,
            que9: !row.queNine ? null : +row.queNine,
            que9Note: row.noteNine,
        })),
    });

    return response;
};

export const getScorecardAnswerOptions = createGetOptionsApi("/Lookup/ScoreCardAnswers", "scorecardAnswerOptions", 10);
