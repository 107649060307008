import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useParams, Link, NavLink, Navigate } from "react-router-dom";
import { Nav, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import DetailsPageContainer from "../../components/DetailsPageContainer";
import PageLoader from "../../components/PageLoader";
import ErrorBoundary from "../../components/ErrorBoundary";
import UserDashboard from "../UserDashboard";
import NotFoundPage from "../NotFoundPage";
import UnAuthorisedPage from "../UnAuthorisedPage";
import { userInitialForm } from "../Users/DATA";
import { formattedPhoneNumber } from "../../helpers";
import { selectUserId, selectUserIsMember, selectUserIsProspectApproved } from "../../redux/userReducer/userSelectors";
import { getUserById } from "../../services/users/userServices";
import {
    selectPermissionsUserBusinessMenu,
    selectPermissionsUserChecklistMenu,
    selectPermissionsUserCommunitiesMenu,
    selectPermissionsUserContactInfoMenu,
    selectPermissionsUserDemographicsMenu,
    selectPermissionsUserDetailsMenu,
    selectPermissionsUserAnnualResultsMenu,
    selectPermissionsUserLOAMenu,
    selectPermissionsUserMilestonesMenu,
    selectPermissionsUserPeerGroupsMenu,
    selectPermissionsUserRolesMenu,
    selectPermissionsUserDashboardMenu,
    selectPermissionsUserFormsMenu,
    selectPermissionsUserAgreementFormMenu,
    selectPermissionsUserInitialFormMenu,
    selectPermissionsUserMonthlyFormMenu,
    selectPermissionsUserEventsMenu,
    selectPermissionsUserNotesMenu,
    selectPermissionsUserDocumentsMenu,
    selectPermissionsFetched,
    selectPermissionsSidebarUsersMenu,
    selectUserMenuPermission,
} from "../../redux/permissionReducer/permissionSelectors";
import "./UserDetails.css";

// NO LAZY IMPORTS ANYMORE...
import Details from "./Details.component";
import UserDocuments from "../UserDocuments";
import UserCommunities from "../UserCommunities";
import UserPeerGroups from "../UserPeerGroups";
import UserDemographics from "../UserDemographics";
import UserRoles from "../UserRoles";
import UserEvents from "../UserEvents";
import UserNotes from "../UserNotes";
import UserBusiness from "../UserBusiness";
import UserContactInfo from "../UserContactInfo";
import UserMonthlyList from "../UserMonthlyDetails/UserMonthlyList";
import KPIForm from "../KPI_Form";
import MemberAgreement from "../MemberAgreement";
import UserMilestonePage from "../UserMilestones";
import Referrals from "../Referrals";
import UserLOA from "../LOA";

const UserDetails = () => {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(() => ({
        userId: userId,
        canEdit: false,
        canAddCommunities: false,
        canEditCommunities: false,
        canDeleteCommunities: false,
        canAddPeerGroups: false,
        canEditPeerGroups: false,
        canDeletePeerGroups: false,
        canAddRoles: false,
        canEditRoles: false,
        canDeleteRoles: false,
        isProspectOrMember: false,
        ...userInitialForm,
        businessName: "",
        accountStatusId: "",
        lastUpdatedBy: "",
        lastUpdatedDate: "",
        createdBy: "",
        lastLoginDate: "",
        createdDate: "",
        accountStatusName: "",
        roleNames: "",
        canAddUserMilestone: false,
        canDeleteUserMilestone: true,
    }));
    const location = useLocation();
    const {
        myUserId,
        isMember,
        isProspectApproved,
        permissionsFetched,
        userPeerGroupsMenuPermission,
        userChecklistMenuPermission,
        userLOAMenuPermission,
        userDemographicsMenuPermission,
        userCommunitiesMenuPermission,
        userBusinessMenuPermission,
        userAnnualResultsMenuPermission,
        userContactInfoMenuPermission,
        userMilestonesMenuPermission,
        userDetailsMenuPermission,
        userRolesMenuPermission,
        userDashboardMenuPermission,
        userFormsMenuPermission,
        userAgreementFormMenuPermission,
        userInitialFormMenuPermission,
        userMonthlyFormMenuPermission,
        userDocumentsMenuPermission,
        userEventsMenuPermission,
        userNotesMenuPermission,
        sidebarUsersMenuPermission,
        userMenuPermission,
    } = useSelector(
        createStructuredSelector({
            myUserId: selectUserId,
            isMember: selectUserIsMember,
            isProspectApproved: selectUserIsProspectApproved,
            permissionsFetched: selectPermissionsFetched,
            sidebarUsersMenuPermission: selectPermissionsSidebarUsersMenu,
            userDashboardMenuPermission: selectPermissionsUserDashboardMenu,
            userPeerGroupsMenuPermission: selectPermissionsUserPeerGroupsMenu,
            userFormsMenuPermission: selectPermissionsUserFormsMenu,
            userCommunitiesMenuPermission: selectPermissionsUserCommunitiesMenu,
            userBusinessMenuPermission: selectPermissionsUserBusinessMenu,
            userContactInfoMenuPermission: selectPermissionsUserContactInfoMenu,
            userMilestonesMenuPermission: selectPermissionsUserMilestonesMenu,
            userDetailsMenuPermission: selectPermissionsUserDetailsMenu,
            userAnnualResultsMenuPermission: selectPermissionsUserAnnualResultsMenu,
            userRolesMenuPermission: selectPermissionsUserRolesMenu,
            userDemographicsMenuPermission: selectPermissionsUserDemographicsMenu,
            userLOAMenuPermission: selectPermissionsUserLOAMenu,
            userChecklistMenuPermission: selectPermissionsUserChecklistMenu,
            userAgreementFormMenuPermission: selectPermissionsUserAgreementFormMenu,
            userInitialFormMenuPermission: selectPermissionsUserInitialFormMenu,
            userMonthlyFormMenuPermission: selectPermissionsUserMonthlyFormMenu,
            userEventsMenuPermission: selectPermissionsUserEventsMenu,
            userNotesMenuPermission: selectPermissionsUserNotesMenu,
            userDocumentsMenuPermission: selectPermissionsUserDocumentsMenu,
            userMenuPermission: selectUserMenuPermission,
        })
    );

    useEffect(() => {
        (async () => {
            const response = await getUserById({ userId });

            setLoading(false);

            if (!response.data) return;

            setUserDetails((prev) => ({ ...prev, ...response.data }));
        })();
    }, [userId]);

    const expandedDetails = useMemo(() => location.pathname === `/users/${userId}/dashboard` || location.pathname === `/users/${userId}`, [location.pathname, userId]);

    return (
        <DetailsPageContainer
            breadCrumbTitle={"User Management"}
            breadCrumbLink={"/users"}
            expandedDetails={expandedDetails}
            hideBreadcrumb={!sidebarUsersMenuPermission}
            image={userDetails.image}
            nameLetters={`${userDetails.firstName?.slice(0, 1)} ${userDetails.lastName?.slice(0, 1)}`}
            detailsChildren={
                <>
                    {expandedDetails && (
                        <h6>
                            {(userDetails.lastUpdatedDate || userDetails.createdDate) &&
                                `${userDetails.accountStatusName || "Active"} since ${moment(userDetails.lastUpdatedDate || userDetails.createdDate).format("MMMM YYYY")}`}
                        </h6>
                    )}
                    <h4
                        className="overflow-text"
                        style={expandedDetails ? undefined : { maxWidth: 320 }}
                        title={`${userDetails.firstName} ${userDetails.middleName || ""} ${userDetails.lastName}`}
                    >{`${userDetails.firstName} ${userDetails.middleName || ""} ${userDetails.lastName}`}</h4>
                    <p style={expandedDetails ? undefined : { maxWidth: 320 }}>{userDetails.businessName}</p>
                </>
            }
            expandedDetailsChildren={
                <ul>
                    <li className="contactEmail" title={userDetails.email}>
                        <a href={`mailto:${userDetails.email}`}>{userDetails.email}</a>
                    </li>
                    {/* {userDetails.mobilePhoneNumber && (
                        <li className="contactPhone">
                            <a href={`tel:${userDetails.mobilePhoneNumber} `}>{formattedPhoneNumber(userDetails.mobilePhoneNumber)}</a>
                        </li>
                    )} */}
                    {userDetails.businessName && <li className="contactPublic">{userDetails.businessName}</li>}
                </ul>
            }
            navLinkChildren={
                permissionsFetched && (
                    <>
                        {userDashboardMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`dashboard`}>
                                    Dashboard
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {userMenuPermission && (
                            <NavDropdown title="User" active={location.pathname.startsWith(`/users/${userId}/user`)}>
                                {userPeerGroupsMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/peer-groups`}>
                                        Peer Groups
                                    </NavDropdown.Item>
                                )}
                                {userCommunitiesMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/communities`}>
                                        Communities
                                    </NavDropdown.Item>
                                )}
                                {userBusinessMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/business`}>
                                        Business
                                    </NavDropdown.Item>
                                )}
                                {userDetails.isProspectOrMember && userAnnualResultsMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/annual-member-results`}>
                                        Annual Member Results
                                    </NavDropdown.Item>
                                )}
                                {userContactInfoMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/contact-info`}>
                                        Contact Info
                                    </NavDropdown.Item>
                                )}
                                {userDetails.isProspectOrMember && userMilestonesMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/milestones`}>
                                        Milestones
                                    </NavDropdown.Item>
                                )}
                                {userDetailsMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/details`}>
                                        User Details
                                    </NavDropdown.Item>
                                )}
                                {userRolesMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/roles`}>
                                        Roles
                                    </NavDropdown.Item>
                                )}
                                {userDetails.isProspectOrMember && userDemographicsMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/demographics`}>
                                        Demographics
                                    </NavDropdown.Item>
                                )}
                                {/* {userDetails.isProspectOrMember && userDemographicsMenuPermission && ( */}
                                <NavDropdown.Item as={Link} to={`user/referrals`}>
                                    Refer A Friend
                                </NavDropdown.Item>
                                {/* )} */}
                                {userDetails.isProspectOrMember && userLOAMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`user/loa`}>
                                        LOA
                                    </NavDropdown.Item>
                                )}
                                {/* {userDetails.isProspectOrMember && userChecklistMenuPermission && (
                                <NavDropdown.Item as={Link} to={`user/checklist`}>
                                    Checklist
                                </NavDropdown.Item>
                            )} */}
                            </NavDropdown>
                        )}
                        {userFormsMenuPermission && userDetails.isProspectOrMember && (
                            <NavDropdown title="Forms" active={location.pathname.startsWith(`/users/${userId}/forms`)}>
                                {userMonthlyFormMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`forms/month-list`}>
                                        Monthly Results Form
                                    </NavDropdown.Item>
                                )}
                                {userAgreementFormMenuPermission && (
                                    <NavDropdown.Item as={Link} to={`forms/member-agreement`}>
                                        Member Agreement
                                    </NavDropdown.Item>
                                )}
                                {userInitialFormMenuPermission && (
                                    <NavDropdown.Item as={"a"} target="_blank" href={`/initial-form/${userId}`}>
                                        Initial Form
                                    </NavDropdown.Item>
                                )}
                            </NavDropdown>
                        )}
                        {userEventsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`events`}>
                                    Events
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {userId !== myUserId && userDetails.isProspectOrMember && userNotesMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`notes`}>
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {/* {userDocumentsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`documents`}>
                                    Documents
                                </Nav.Link>
                            </Nav.Item>
                        )} */}
                    </>
                )
            }
        >
            <ErrorBoundary>
                {loading || !permissionsFetched ? (
                    <PageLoader />
                ) : (
                    <Suspense fallback={<PageLoader />}>
                        <Routes>
                            <Route path="/dashboard" element={!userDashboardMenuPermission ? <UnAuthorisedPage /> : <UserDashboard {...userDetails} />} />
                            <Route path="/user/communities" element={!userCommunitiesMenuPermission ? <UnAuthorisedPage /> : <UserCommunities {...userDetails} setUserDetails={setUserDetails} />} />
                            <Route path="/user/peer-groups" element={!userPeerGroupsMenuPermission ? <UnAuthorisedPage /> : <UserPeerGroups {...userDetails} setUserDetails={setUserDetails} />} />
                            <Route path="/user/roles" element={!userRolesMenuPermission ? <UnAuthorisedPage /> : <UserRoles {...userDetails} setUserDetails={setUserDetails} />} />
                            <Route path="/user/demographics" element={!userDemographicsMenuPermission ? <UnAuthorisedPage /> : <UserDemographics {...userDetails} />} />
                            <Route path="/user/business" element={!userBusinessMenuPermission ? <UnAuthorisedPage /> : <UserBusiness setUserDetails={setUserDetails} />} />
                            <Route path="/user/annual-member-results" element={!userAnnualResultsMenuPermission ? <UnAuthorisedPage /> : <KPIForm {...userDetails} />} />
                            <Route path="/user/details" element={!userDetailsMenuPermission ? <UnAuthorisedPage /> : <Details {...userDetails} setUserDetails={setUserDetails} />} />
                            <Route path="/user/contact-info" element={!userContactInfoMenuPermission ? <UnAuthorisedPage /> : <UserContactInfo {...userDetails} />} />
                            <Route path="/user/milestones" element={!userMilestonesMenuPermission ? <UnAuthorisedPage /> : <UserMilestonePage {...userDetails} setUserDetails={setUserDetails} />} />
                            <Route path="/forms/month-list" element={!userMonthlyFormMenuPermission ? <UnAuthorisedPage /> : <UserMonthlyList {...userDetails} />} />
                            <Route path="/user/loa" element={!userLOAMenuPermission ? <UnAuthorisedPage /> : <UserLOA {...userDetails} />} />
                            <Route path="/user/referrals" element={<Referrals {...userDetails} />} />
                            <Route
                                path="/forms/member-agreement"
                                element={!(myUserId === userId ? isMember || isProspectApproved : userAgreementFormMenuPermission) ? <UnAuthorisedPage /> : <MemberAgreement {...userDetails} />}
                            />
                            <Route path="/documents" element={!userDocumentsMenuPermission ? <UnAuthorisedPage /> : <UserDocuments {...userDetails} />} />
                            <Route path="/events" element={!userEventsMenuPermission ? <UnAuthorisedPage /> : <UserEvents {...userDetails} />} />
                            <Route path="/notes" element={!userNotesMenuPermission ? <UnAuthorisedPage /> : <UserNotes {...userDetails} />} />
                            <Route path="/" element={<Navigate to={"dashboard"} replace />} />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                )}
            </ErrorBoundary>
        </DetailsPageContainer>
    );
};

export default UserDetails;
