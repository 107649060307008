import { createGetOptionsApi } from "..";
import { formToFormData } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const userPrimaryKey = "userId";

export const getUsers = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchUsers), {
        headers: filters,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
                  communityNames: `${u.communities?.length || 0}`,
                  peerGroupNames: `${u.peerGroups?.length || 0}`,
                  roleNames: u.roles?.map((d) => d.name).join(", "),
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const getUserStatusOptions = createGetOptionsApi(Urls.GetAccountStatusOptions, "userStatusOptions", 10);
export const getUserCommunityOptions = createGetOptionsApi("/User/Community", "userStatusOptions", 0);

export const getNewAuthToken = async () => {
    const issuedAt = localStorage.getItem("token_issued_at");

    if (!issuedAt || Date.now() < +issuedAt + 18 * 60000 || Date.now() > +issuedAt + 30 * 60000) return;

    const response = await callApi.post(buildURL("/Login/refresh/token"));

    if (!response?.responseData?.token) return;

    localStorage.setItem("token", response.responseData.token);
    localStorage.setItem("token_issued_at", Date.now());

    return response;
};

export const getUserPermissions = async () => {
    const response = await callApi.get(buildURL("/Permission"));

    return response;
};

export const getUserById = async ({ userId }) => {
    const response = await callApi.get(buildURL(Urls.UserView) + userId);

    if (response.responseCode !== 200) return { data: null };
    const { firstName, middleName, lastName, email, mobilePhoneNumber, accountStatus: accountStatusId, accountStatusName, rolesName: roleNames } = response.responseData;

    return {
        data:
            response.responseCode !== 200
                ? null
                : {
                      ...response.responseData,
                      userId,
                      firstName: firstName || "",
                      middleName: middleName || "",
                      lastName: lastName || "",
                      email: email || "",
                      roleNames: roleNames || "",
                      mobilePhoneNumber: mobilePhoneNumber || "",
                      accountStatusName: accountStatusName || "",
                      accountStatusId: accountStatusId?.trim() || "",
                  },
    };
};

export const createUser = async ({ ...form }) => {
    const response = await callApi.post(buildURL(Urls.AddUser), formToFormData({ ...form, password: btoa(form.password) }));

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.userId } } : response;
};
export const updateUser = async ({ ...form }) => {
    delete form.accountStatusName;
    if (!form.password) delete form.password;
    else form.password = btoa(form.password);
    const response = await callApi.post(buildURL(Urls.EditUser), formToFormData(form));

    return response;
};
