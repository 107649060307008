/* eslint-disable jsx-a11y/alt-text */
import { Suspense, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import BodyContainer from "./components/BodyContainer";
import ErrorBoundary from "./components/ErrorBoundary";
import { clearPermissionsAction, getPermissionsObj, setPermissionsAction } from "./redux/permissionReducer/permissionActions";
import {
    selectPermissionsFetched,
    selectPermissionsSidebarChecklistsMenu,
    selectPermissionsSidebarCommunitiesMenu,
    selectPermissionsSidebarCountiesMenu,
    selectPermissionsSidebarCurriculumsMenu,
    selectPermissionsSidebarDashboard,
    selectPermissionsSidebarEventsMenu,
    selectPermissionsSidebarFunderMenu,
    selectPermissionsSidebarMemberDirectoryMenu,
    selectPermissionsSidebarMembersMenu,
    selectPermissionsSidebarPeerGroupsMenu,
    selectPermissionsSidebarReferralsMenu,
    selectPermissionsSidebarReportsMenu,
    selectPermissionsSidebarUsersMenu,
} from "./redux/permissionReducer/permissionSelectors";
import { signOutUserAction } from "./redux/userReducer/userActions";
import { selectUserId } from "./redux/userReducer/userSelectors";
import { getNewAuthToken, getUserPermissions } from "./services/users/userServices";

import PageLoader from "./components/PageLoader";
import Dashboard from "./pages/Dashboard";
import NotFoundPage from "./pages/NotFoundPage";
import SignIn from "./pages/SignIn";
import UnAuthorisedPage from "./pages/UnAuthorisedPage";

// THESE WERE LAZY ROUTES, Converting to normal imports as there were some issues for some PCs...
import Draggable from "react-draggable";
import isotype from "./assets/images/isotype.jpg";
import Checklists from "./pages/Checklists";
import Communities from "./pages/Communities";
import CommunityDetails from "./pages/CommunityDetails";
import Counties from "./pages/Counties";
import CountiesDetails from "./pages/CountiesDetails";
import CurriculumView from "./pages/CurriculamView";
import Curriculum from "./pages/Curriculum";
import EventDetails from "./pages/EventDetails";
import Events from "./pages/Events";
import ForgotPassword from "./pages/ForgotPassword";
import Funder from "./pages/Funder";
import FunderDetails from "./pages/FunderDetails";
import MemberDirectory from "./pages/MemberDirectory";
import MemberDirectoryViewPage from "./pages/MemberDirectoryView";
import Members from "./pages/Members";
import PeerGroupDetails from "./pages/PeerGroupDetails";
import PeerGroups from "./pages/PeerGroups";
import PeerGroupScorecardDetails from "./pages/PeerGroupScorecardDetails";
import Referrals from "./pages/Referrals";
import ProspectForm from "./pages/Register";
import SignUpBusinessDetails from "./pages/Register/SignUpBusinessDetails";
import SignUpConfirmDiscovery from "./pages/Register/SignUpConfirmDiscovery";
import SignUpContactInfo from "./pages/Register/SignUpContactInfo";
import SignUpDiscoverySession from "./pages/Register/SignUpDiscoverySession";
import SignUpFinalizeApplication from "./pages/Register/SignUpFinalizeApplication";
import SignUpInitial from "./pages/Register/SignUpInitial";
import Reports from "./pages/Reports";
import ResetPassword from "./pages/ResetPassword";
import UserDetails from "./pages/UserDetails";
import UserInitialForm from "./pages/UserInitialForm";
import UserMonthlyView from "./pages/UserMonthlyDetails/UserMonthlyView";
import Users from "./pages/Users";

export const AppRoutes = () => {
    const {
        userId,
        permissionsFetched,
        sidebarChecklistsMenuPermission,
        sidebarCommunitiesMenuPermission,
        sidebarCurriculumsMenuPermission,
        sidebarDashboardMenuPermission,
        sidebarEventsMenuPermission,
        sidebarMemberDirectoryMenuPermission,
        sidebarMembersMenuPermission,
        sidebarPeerGroupsMenuPermission,
        sidebarReferralsMenuPermission,
        sidebarReportsMenuPermission,
        sidebarUsersMenuPermission,
        sidebarFunderMenuPermission,
        sidebarCountiesMenuPermission,
    } = useSelector(
        createStructuredSelector({
            userId: selectUserId,
            permissionsFetched: selectPermissionsFetched,
            sidebarDashboardMenuPermission: selectPermissionsSidebarDashboard,
            sidebarUsersMenuPermission: selectPermissionsSidebarUsersMenu,
            sidebarMembersMenuPermission: selectPermissionsSidebarMembersMenu,
            sidebarEventsMenuPermission: selectPermissionsSidebarEventsMenu,
            sidebarCommunitiesMenuPermission: selectPermissionsSidebarCommunitiesMenu,
            sidebarPeerGroupsMenuPermission: selectPermissionsSidebarPeerGroupsMenu,
            sidebarCurriculumsMenuPermission: selectPermissionsSidebarCurriculumsMenu,
            sidebarChecklistsMenuPermission: selectPermissionsSidebarChecklistsMenu,
            sidebarMemberDirectoryMenuPermission: selectPermissionsSidebarMemberDirectoryMenu,
            sidebarReferralsMenuPermission: selectPermissionsSidebarReferralsMenu,
            sidebarReportsMenuPermission: selectPermissionsSidebarReportsMenu,
            sidebarFunderMenuPermission: selectPermissionsSidebarFunderMenu,
            sidebarCountiesMenuPermission: selectPermissionsSidebarCountiesMenu,
        })
    );
    const dispatch = useDispatch();
    const { state: locationState, pathname } = useLocation();
    const [imageLoaded, setImageLoaded] = useState(false);
    const signOut = useCallback(() => dispatch(signOutUserAction()), [dispatch]);
    const resetSessionTimer = useCallback(() => localStorage.setItem("session", Date.now()), []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (userId && !localStorage.getItem("token")) return signOut();
            if (!userId && localStorage.getItem("token")) {
                try {
                    if (JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user).userId) setTimeout(() => window.location.reload(), 500);
                    // eslint-disable-next-line no-empty
                } catch (e) {}
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [userId, signOut]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, [pathname]);

    useEffect(() => {
        document.body.addEventListener("keydown", resetSessionTimer);
        document.body.addEventListener("click", resetSessionTimer);

        const interval = setInterval(() => {
            const timer = localStorage.getItem("session");

            if (!localStorage.getItem("token") || !timer) return resetSessionTimer();

            if (timer > Date.now() - 150 * 60000) return;

            // eslint-disable-next-line no-alert
            resetSessionTimer();
            // eslint-disable-next-line no-alert
            window.alert("Session Expired.");
            signOut();
        }, 1000);

        return () => {
            document.body.removeEventListener("keydown", resetSessionTimer);
            document.body.removeEventListener("click", resetSessionTimer);
            clearInterval(interval);
        };
    }, [signOut, resetSessionTimer]);

    useEffect(() => {
        const interval = setInterval(getNewAuthToken, 1000 * 10);

        getNewAuthToken();

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (!userId) return dispatch(clearPermissionsAction());

        getUserPermissions().then((response) => {
            const permissions = getPermissionsObj(response);

            dispatch(setPermissionsAction(permissions));
        });
    }, [userId, dispatch]);

    if (localStorage.getItem("token_issued_at") && +localStorage.getItem("token_issued_at") < Date.now() - 30 * 60000) {
        resetSessionTimer();
        if (userId) signOut();
    }
    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route path="/sign-in" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignIn />} />
                <Route path="/reset-password/:key" element={userId ? <Navigate to={locationState || "/"} replace /> : <ResetPassword />} />
                <Route path="/sign-up/initial" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpInitial />} />
                <Route path="/sign-up/contact-information" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpContactInfo />} />
                <Route path="/sign-up/business-details" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpBusinessDetails />} />
                <Route path="/sign-up/finalize-application" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpFinalizeApplication />} />
                <Route path="/sign-up/discovery-session" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpDiscoverySession />} />
                <Route path="/sign-up/confirm-discovery" element={userId ? <Navigate to={locationState || "/"} replace /> : <SignUpConfirmDiscovery />} />
                <Route path="/prospect-form" element={userId ? <Navigate to={locationState || "/"} replace /> : <ProspectForm />} />
                <Route path="/forgot-password" element={userId ? <Navigate to={locationState || "/"} replace /> : <ForgotPassword />} />
                <Route
                    path={"/*"}
                    element={
                        !userId ? (
                            <Navigate to={"/sign-in"} state={window.location.pathname + window.location.search} replace />
                        ) : (
                            <BodyContainer>
                                <ErrorBoundary>
                                    <Suspense fallback={<PageLoader />}>
                                        <Routes>
                                            <Route path="/dashboard" element={!permissionsFetched ? <PageLoader /> : sidebarDashboardMenuPermission ? <Dashboard /> : <UnAuthorisedPage />} />
                                            <Route path="/checklist" element={!permissionsFetched ? <PageLoader /> : sidebarChecklistsMenuPermission ? <Checklists /> : <UnAuthorisedPage />} />
                                            <Route path="/curriculum" element={!permissionsFetched ? <PageLoader /> : sidebarCurriculumsMenuPermission ? <Curriculum /> : <UnAuthorisedPage />} />
                                            <Route path="/curriculum/:curriculumId" element={<CurriculumView />} />
                                            <Route path="/users" element={!permissionsFetched ? <PageLoader /> : sidebarUsersMenuPermission ? <Users /> : <UnAuthorisedPage />} />
                                            <Route path="/events" element={!permissionsFetched ? <PageLoader /> : sidebarEventsMenuPermission ? <Events /> : <UnAuthorisedPage />} />
                                            <Route path="/communities" element={!permissionsFetched ? <PageLoader /> : sidebarCommunitiesMenuPermission ? <Communities /> : <UnAuthorisedPage />} />
                                            <Route path="/peer-groups" element={!permissionsFetched ? <PageLoader /> : sidebarPeerGroupsMenuPermission ? <PeerGroups /> : <UnAuthorisedPage />} />
                                            <Route path="/members" element={!permissionsFetched ? <PageLoader /> : sidebarMembersMenuPermission ? <Members /> : <UnAuthorisedPage />} />
                                            <Route path="/funder" element={!permissionsFetched ? <PageLoader /> : sidebarFunderMenuPermission ? <Funder /> : <UnAuthorisedPage />} />
                                            <Route path="/county" element={!permissionsFetched ? <PageLoader /> : sidebarCountiesMenuPermission ? <Counties /> : <UnAuthorisedPage />} />
                                            <Route path="/reports" element={!permissionsFetched ? <PageLoader /> : sidebarReportsMenuPermission ? <Reports /> : <UnAuthorisedPage />} />
                                            <Route
                                                path="/member-directory"
                                                element={!permissionsFetched ? <PageLoader /> : sidebarMemberDirectoryMenuPermission ? <MemberDirectory /> : <UnAuthorisedPage />}
                                            />
                                            <Route
                                                path="/member-directory/member-directory-view/:userId"
                                                element={!permissionsFetched ? <PageLoader /> : sidebarMemberDirectoryMenuPermission ? <MemberDirectoryViewPage /> : <UnAuthorisedPage />}
                                            />
                                            <Route path="/referrals" element={!permissionsFetched ? <PageLoader /> : sidebarReferralsMenuPermission ? <Referrals /> : <UnAuthorisedPage />} />
                                            <Route path="/month-view/:userId/:eventId/:monthlyFormId" element={<UserMonthlyView />} />
                                            <Route path="/users/:userId/*" element={<UserDetails />} />
                                            <Route path="/funder/:funderId/*" element={<FunderDetails />} />
                                            <Route path="/county/:countyId/*" element={<CountiesDetails />} />
                                            <Route path="/events/:eventId/*" element={<EventDetails />} />
                                            <Route path="/communities/:communityId/*" element={<CommunityDetails />} />
                                            <Route path="/peer-group-scorecard/:peerGroupId" element={<PeerGroupScorecardDetails />} />
                                            <Route path="/peer-groups/:peerGroupId/*" element={<PeerGroupDetails />} />
                                            <Route path="/initial-form/:userId" element={<UserInitialForm />} />
                                            <Route path="/" element={<Navigate to={`/users/${userId}`} replace />} />
                                            <Route path="/*" element={<NotFoundPage />} />
                                        </Routes>
                                    </Suspense>
                                </ErrorBoundary>
                            </BodyContainer>
                        )
                    }
                />
            </Routes>
            {/* Code Comment Dialpad Chat Integration Ends 8/7/24 */}
            {/* <Draggable>
                <div className="dialpad-wrapper">
                    {imageLoaded && (
                        <div class="click-here-dialpad">
                            <span>Need Help?</span>
                            <span className="click-here-text">Click Here!</span>{" "}
                        </div>
                    )}
                    <span
                        className="help-container"
                        id="kpd_koopidtag"
                        data-kpdprovemail="5860371734495232@e4ever.org"
                        data-kpdguest="true"
                        data-kpdembedded="true"
                        data-kpdtag="E4ever Knowledge AI_102571"
                    >
                        <img src={isotype} class="dialpad-icon" onLoad={() => setImageLoaded(true)} loading={"eager"} />
                    </span>
                </div>
            </Draggable> */}
        </Suspense>
    );
};

export default AppRoutes;
