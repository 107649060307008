import { Link } from "react-router-dom";
import { formatDateOnly, formatTimeOnly } from "../../helpers";
import { getCommunityPastEvents, getCommunityUpcomingEvents } from "../../services/communities/communityEventServices";
import { createUpdateEvent, getEventTypeOptions } from "../../services/events/eventServices";

export const communityUpcomingEventFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventEndTime",
    columnWidth: 100,
    title: "Time To",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventTimeZoneName",
    title: "Time Zone",
    columnWidth: 120,
  },
  {
    name: "eventTypeName",
    title: "Event Type",
    columnWidth: 150,
  },
  {
    name: "eventFormatName",
    title: "Format",
    columnWidth: 110,
  },
  {
    name: "eventRsvp",
    title: "RSVP",
    columnWidth: 90,
  },
];

export const communityEventHistorFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    filterable: true,
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    filterable: true,
  },
  {
    name: "eventEndTime",
    columnWidth: 100,
    title: "Time To",
    filterable: true,
  },
  {
    name: "eventTimeZoneName",
    title: "Time Zone",
    filterable: true,
    columnWidth: 120,
  },
  {
    name: "eventTypeName",
    title: "Event Type",
    filterable: true,
    columnWidth: 150,
  },
  {
    name: "eventFormatName",
    title: "Format",
    filterable: true,
    columnWidth: 110,
  },
  {
    name: "eventWentCount",
    title: "Went",
    filterable: true,
    columnWidth: 90,
  },
];

export const getCommunityEventTypeOptions = async () => {
  const response = await getEventTypeOptions();
  return {
    data: response.data.filter((o) => o.label === "Peer Group" || o.label === "One on One"),
  };
};

export const getCommunityInitialEventTypeOptions = (response) => response.data.find((opt) => opt.label === "Peer Group");

export const communityUpcomingEventApis = {
  getAll: {
    api: getCommunityUpcomingEvents,
    body: {
      communityId: "",
    },
  },
  create: {
    api: createUpdateEvent,
  },
};

export const communityPastEventApis = {
  getAll: {
    api: getCommunityPastEvents,
    body: {
      communityId: "",
    },
  },
};
