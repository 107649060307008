import { downloadFile } from "../../helpers";
import { getEventCurriculumDetailsOverviewPage, getEventCurriculumDetailsPage } from "../../services/events/eventCurriculumDetailsServices";

export const curriculumEventAttachmentFields = [
    {
        name: "focus",
        title: "Focus",
    },
    {
        name: "for",
        title: "For",
    },
    {
        name: "title",
        title: "Title",
    },
    {
        name: "documentName",
        title: "Document",
        cell: (value, data) => {
            const names = (data.documentFilePath || "").split("?")[0].split(".");
            const fileName = (value || "") + "." + names[names.length - 1];

            return (
                <span className="anchor-tag" onClick={() => downloadFile(data.documentFilePath, fileName)}>
                    {value}
                </span>
            );
        },
    },
];
export const curriculumEventOverviewFields = [
    {
        name: "focus",
        title: "Focus",
    },
    {
        name: "title",
        title: "Title",
    },
    {
        name: "documentName",
        title: "Document",
        cell: (value, data) => {
            const names = (data.documentFilePath || "").split("?")[0].split(".");
            const fileName = (value || "") + "." + names[names.length - 1];

            return (
                <span className="anchor-tag" onClick={() => downloadFile(data.documentFilePath, fileName)}>
                    {value}
                </span>
            );
        },
    },
];
export const eventDetailsCurriculumApis = {
    // create: {
    //     api: createUpdateCurriculum,
    // },
    getAll: {
        api: getEventCurriculumDetailsPage,
    },
};
export const eventDetailsCurriculumOverviewApis = {
    // create: {
    //     api: createUpdateCurriculum,
    // },
    getAll: {
        api: getEventCurriculumDetailsOverviewPage,
    },
};
