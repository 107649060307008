import { useCallback, useState, useMemo } from "react";

import { debounce, throttle } from ".";

export const updateFieldsOptions = (form, field, setFields) => {
    if (!field?.dependencies) return;

    for (const f of field.dependencies) {
        if (f.resetOnCondition ? f.resetOnCondition(form) : true) {
            if (f.nameForm) form[f.nameForm] = "initialValue" in f ? f.initialValue : "";
            form[f.name] = "initialValue" in f ? f.initialValue : "";
        }

        if (!f.getOptions || !setFields) continue;

        setFields((prev) => prev.map((fld) => (fld["nameForm" in fld ? "nameForm" : "name"] === f["nameForm" in fld ? "nameForm" : "name"] ? { ...fld, options: [] } : fld)));

        f.getOptions(form)?.then((response) => {
            setFields((prev) => prev.map((fld) => (fld["nameForm" in fld ? "nameForm" : "name"] === f["nameForm" in fld ? "nameForm" : "name"] ? { ...fld, options: response.data } : fld)));
        });
    }
};

export function useForm(initialForm, fields, setFields) {
    const [form, setForm] = useState(() => initialForm || {});

    const onChange = useCallback(
        (event, extraToChange = {}) =>
            setForm((prev) => {
                const { name, value, type, checked, files } = event.target;

                const field = fields?.find((f) => f.nameForm === name || f.name === name);

                const newForm = {
                    ...prev,
                    ...extraToChange,
                    [name]:
                        field?.inputType === "checkboxes"
                            ? checked
                                ? [...(prev[name] || []), value]
                                : (prev[name] || []).filter((v) => v !== value)
                            : type === "checkbox"
                            ? checked
                            : type === "file"
                            ? files[0] || ""
                            : value,
                };

                if (type === "file" && field?.name && field.name !== name) newForm[field.name] = value;

                updateFieldsOptions(newForm, field, setFields);

                return newForm;
            }),
        [fields, setFields]
    );

    return [form, onChange, setForm];
}
/*
You may first use 'useCallback' then change it to 'useDebounce' to validate the correct dependencies. Note: You must pass the component state in it's arguments and component props in the dependency array.
*/
export function useDebounce(func = () => {}, dependencies = [], delay = 500) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const funcToRun = useCallback(func, [...dependencies]);
    const debouncedFunction = useMemo(() => debounce(funcToRun, delay), [funcToRun, delay]);

    return debouncedFunction;
}
/*
You may first use 'useCallback' then change it to 'useDebounce' to validate the correct dependencies. Note: You must pass the component state in it's arguments and component props in the dependency array.
*/
export function useThrottle(func = () => {}, dependencies = [], delay = 500) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const funcToRun = useCallback(func, [...dependencies]);
    const throttledFunction = useMemo(() => throttle(funcToRun, delay), [funcToRun, delay]);

    return throttledFunction;
}

export function useToggle(initialValue = true, delay = 500) {
    const [value, setValue] = useState(() => initialValue);
    const togglePure = useCallback(() => setValue((prev) => !prev), []);
    const toggleThrottle = useThrottle((newValue) => setValue((prev) => (typeof newValue === "boolean" ? newValue : !prev)), [], delay);

    return [value, delay ? toggleThrottle : togglePure, setValue];
}
