import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const getUserDashboardMilestoneWidgets = async (filters) => {
    const { responseData } = await callApi.get(buildURL(Urls.GetUserMilestoneWidgets + filters.userId), {
        headers: { ...filters, noOfDays: 90 },
    });

    return {
        data: Array.isArray(responseData?.searchResult)
            ? responseData?.searchResult.map((u) => ({
                  date: u.milestoneDate?.slice(0, 10) || "",
                  peerGroupName: u.peerGroupShortName || "",
                  memberName: u.memberName || "",
                  milestoneName: u.milestone || "",
                  eventId: u.eventId || "",
                  peerGroupCode: u.peerGroupCode || "",
                  memberId: u.memberId || "",
              }))
            : [],
        totalRecords: responseData?.totalRecords || responseData?.searchResult?.length || 0,
    };
};
