export const EUserActionTypes = {
  logIn: "LOG_IN",
  signOut: "LOG_OUT",
  update: "UPDATE",
};

export const logInUserAction = (userData) => ({
  type: EUserActionTypes.logIn,
  payload: userData,
});

export const signOutUserAction = () => ({
  type: EUserActionTypes.signOut,
});
export const updateUserAction = (userData) => ({
  type: EUserActionTypes.update,
  payload: userData,
});
