import { useMemo, useState, useCallback } from "react";

// import { getPeerGroupOptionsByCommunity } from "../../services/peer-groups/peerGroupServices";
import ManagementTable from "../../components/ManagementTable";
import { addEventPermission } from "../../services/events/eventPermissionServices";
import { EEventTypes, eventPrimaryKey } from "../../services/events/eventServices";
import { eventAddFormFields, eventInitialForm } from "../Events/DATA";
import {
    communityEventHistorFields,
    communityPastEventApis,
    communityUpcomingEventApis,
    communityUpcomingEventFields,
    // getCommunityEventTypeOptions,
    // getCommunityInitialEventTypeOptions,
} from "./DATA";

export default function CommunityEvents({ communityId, communityDetails }) {
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });

    // const getCommunityPeerGroupOptions = useCallback(() => getPeerGroupOptionsByCommunity({ communityCode: communityId }), [communityId]);

    const runAfterGetAll = useCallback(({ canAdd, canEdit, canDelete }) => setPermissions((prev) => ({ ...prev, canAdd, canEdit, canDelete })), []);
    const runAfterSubmit = useCallback(
        async (formData, response) => {
            // if (formData.eventTypeName !== EEventTypes.peerGroup) return;
            await addEventPermission({
                communityCode: communityId,
                eventId: response.responseData.id,
            });
        },
        [communityId]
    );
    const upcomingEventApis = useMemo(() => ({ ...communityUpcomingEventApis, getAll: { ...communityUpcomingEventApis.getAll, body: { communityId } } }), [communityId]);
    const pastEventApis = useMemo(() => ({ ...communityPastEventApis, getAll: { ...communityPastEventApis.getAll, body: { communityId } } }), [communityId]);
    // const formFields = useMemo(
    //     () =>
    //         eventAddFormFields.map((f) =>
    //             f.nameForm === "eventTypeCode"
    //                 ? {
    //                     ...f,
    //                     dependencies: undefined,
    //                     getOptions: getCommunityEventTypeOptions,
    //                     getInitialOption: getCommunityInitialEventTypeOptions,
    //                     disableEmptyOption: true,
    //                 }
    //                 : f.nameForm === "communityCode"
    //                     ? { ...f, readOnly: true, excludeInFormOnCondition: undefined, getInitialOption: undefined, getOptions: undefined, options: [{ label: communityDetails.communityName, value: communityId }] }
    //                     : f.nameForm === "peerGroupCode"
    //                         ? { ...f, excludeInFormOnCondition: undefined, getInitialOption: undefined, options: undefined, getOptions: getCommunityPeerGroupOptions }
    //                         : { ...f }
    //         ),
    //     [getCommunityPeerGroupOptions, communityId, communityDetails.communityName]
    // );
    const initialForm = useMemo(
        () => ({ ...eventInitialForm, eventTypeName: "Peer Group", communityCode: communityId, communityNames: communityDetails.communityName }),
        [communityId, communityDetails.communityName]
    );

    return (
        <div className="row mx-0">
            <div className="col-lg-12 col-md-12 col-12 my-3">
                <ManagementTable
                    title="Event"
                    tableTitle={"Upcoming Events"}
                    primaryKey={eventPrimaryKey}
                    initialForm={initialForm}
                    formFields={eventAddFormFields}
                    customAddButtonTitle="Add Event"
                    apis={upcomingEventApis}
                    fields={communityUpcomingEventFields}
                    hideAddButton={!permissions.canAdd}
                    runAfterGetAll={runAfterGetAll}
                    runAfterSubmit={runAfterSubmit}
                    refreshAfterSubmit
                    hideActionsColumn
                    localSort
                />
            </div>
            <div className="col-lg-12 col-md-12 col-12 my-3">
                <ManagementTable
                    title="Event History"
                    tableTitle={"Event History"}
                    primaryKey={eventPrimaryKey}
                    apis={pastEventApis}
                    fields={communityEventHistorFields}
                    hideActionsColumn
                    hideAddButton
                    localSort
                />
            </div>
        </div>
    );
}
