import moment from "moment";
import { Link } from "react-router-dom";
import { EFieldInputTypes } from "../../components/FormField";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createUpdateCounitesCommunity, deleteCountiesCommunity, getCounitesCommunity } from "../../services/counties/countiesService";

export const initialCountiesDetails = {
    canEdit: true,
    canAdd: true,
    canDelete: true,
    countyId: "",
    name: "",
    isActive: "true",
};
export const communityFields = [
    {
        name: "communityCode",
        col: 12,
        title: "Community",
        getOptions: getCommunityOptions,
        getInitialOption: undefined,
        readOnly: false,
        disableActiveField: false,
        noEmptyOption: false,
        required: true,
        readOnlyInEdit: true,
        inputType: EFieldInputTypes.select,
        filterable: true,
        filter: {
            maxLength: 20,
        },
        notSortable: false,
        cell: (communityCode, data) => (
            <Link style={{ color: "#00adba" }} to={`/communities/${communityCode}`}>
                {data.community}
            </Link>
        ),
    },
    {
        name: "startDate",
        required: true,
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        placeholder: "Start Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
        cell: (value) => (value ? <span>{moment(value).format("MM/DD/YYYY")}</span> : ""),
    },
    {
        name: "endDate",
        title: "End Date",
        inputType: EFieldInputTypes.date,
        placeholder: "End Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
        cell: (value) => (value ? <span>{moment(value).format("MM/DD/YYYY")}</span> : ""),
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
];

export const communityApis = {
    getAll: {
        api: getCounitesCommunity,
    },
    create: {
        api: createUpdateCounitesCommunity,
    },
    delete: {
        api: deleteCountiesCommunity,
    },
};
