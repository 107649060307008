import { useCallback } from "react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";

// import { ReactComponent as SortIcon } from "../../assets/svgs/icon-sort.svg";
// import { ReactComponent as SortIconUp } from "../../assets/svgs/icon-sort-up-arrow.svg";
// import { ReactComponent as SortIconDown } from "../../assets/svgs/icon-sort-down-arrow.svg";

export default function HeaderCell({ name, title, sort, onSortChange, className, notSortable = false }) {
    const isSorted = sort[0]?.field === name;

    const onClick = useCallback(() => {
        if (notSortable) return;
        onSortChange(sort[0]?.dir === "desc" && isSorted ? { sort: [] } : { sort: [{ field: name, dir: sort[0]?.dir === "asc" && isSorted ? "desc" : "asc" }] });
    }, [isSorted, name, notSortable, onSortChange, sort]);

    return (
        <div className={`k-link header-cell${className ? ` ${className}` : ""}${notSortable ? " cursor-default" : ""}`} title={title} onClick={onClick}>
            <span>{title}</span>
            {!notSortable && isSorted && <span className="sort-icon">{sort[0].dir === "asc" ? <FaSortUp /> : <FaSortDown />}</span>}
            {!notSortable && (
                <span className={"sort-icon"}>
                    <FaSort className="sort-icon-arrows" />
                </span>
            )}
        </div>
    );
}
