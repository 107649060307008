import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import EditIcon from "../../components/EditIcon";
import FormModal from "../../components/FormModal";
import Status from "../../components/Status";
import ConfirmationModal from "../../components/ConfirmationModal";
import messages from "../../helpers/messages";
import { formatDateOnly, formatDateTime, formatTimeOnly, showResponseMessage } from "../../helpers";
import { useForm, useToggle } from "../../helpers/hooks";
import { peerGroupPrimaryKey, updatePeerGroup, updatePeerGroupMonthlyDetails } from "../../services/peer-groups/peerGroupServices";
import { peerGroupFormFields } from "../PeerGroups/DATA";
import { displayWeekNumber, peerGroupMonthlyFormFields } from "./DATA";
import { selectUserFirstName, selectUserLastName } from "../../redux/userReducer/userSelectors";

export default function Details({ asDashboard = false, peerGroupDetails, setPeerGroupDetails }) {
  const [form, onChange, setForm] = useForm({ ...peerGroupDetails }, peerGroupFormFields);
  const [formTwo, onChangeTwo, setFormTwo] = useForm({ ...peerGroupDetails }, peerGroupMonthlyFormFields);
  const [showProcessing, setShowProcessing] = useState(false);
  const [showWarning, toggleShowWarning] = useToggle(false);
  const [showOne, toggleShowOne] = useToggle(false);
  const [showTwo, toggleShowTwo] = useToggle(false);

  const { firstName, lastName } = useSelector(createStructuredSelector({ firstName: selectUserFirstName, lastName: selectUserLastName }));

  const onCancel = useCallback(() => {
    setTimeout(() => setForm({ ...peerGroupDetails }), 500);
    toggleShowOne(false);
  }, [peerGroupDetails, setForm, toggleShowOne]);

  const updatePeerGroupDetails = useCallback(async () => {
    toggleShowWarning(false);
    setShowProcessing(true);

    const response = await updatePeerGroup(form);

    setShowProcessing(false);

    showResponseMessage(response, {
      success: messages.updatePeerGroup,
    });

    if (response.responseCode !== 200) return;
    toggleShowOne(false);
    setPeerGroupDetails((prev) => ({
      ...prev,
      ...form,
      modifiedBy: `${firstName} ${lastName}`,
      modifiedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      statusName: new Date(form.startDate).getTime() < Date.now() && (form.endDate ? new Date(form.endDate).getTime() > Date.now() : true) ? "Active" : "Inactive",
    }));
  }, [firstName, form, lastName, setPeerGroupDetails, toggleShowOne, toggleShowWarning]);

  const onSubmit = useCallback(() => toggleShowWarning(true), [toggleShowWarning]);
  const onCancelTwo = useCallback(() => {
    setTimeout(() => setFormTwo({ ...peerGroupDetails }), 500);
    toggleShowTwo(false);
  }, [peerGroupDetails, setFormTwo, toggleShowTwo]);

  const updateMonthlyInfo = useCallback(
    async (newForm) => {
      setShowProcessing(true);

      const response = await updatePeerGroupMonthlyDetails(newForm);

      setShowProcessing(false);

      showResponseMessage(response, {
        success: messages.updatePeerGroup,
      });

      if (response.responseCode !== 200) return;
      toggleShowTwo(false);
      setPeerGroupDetails((prev) => ({ ...prev, ...newForm, modifiedBy: `${firstName} ${lastName}`, modifiedDate: moment().format("YYYY-MM-DD HH:mm:ss") }));
    },
    [firstName, lastName, setPeerGroupDetails, toggleShowTwo]
  );
  const onConfirm = useCallback(() => {
    toggleShowWarning(false);
    updateMonthlyInfo(formTwo);
  }, [formTwo, toggleShowWarning, updateMonthlyInfo]);
  const onSubmitTwo = useCallback(
    (form) => {
      if (!peerGroupDetails.startTime) return updateMonthlyInfo(form);

      toggleShowWarning(true);
    },
    [peerGroupDetails.startTime, toggleShowWarning, updateMonthlyInfo]
  );

  return (
    <>
      <div className="viewUserMainData card">
        <div className="cardHeader">
          <h4>Peer Group</h4>
          {!asDashboard && (
            <div className="right-sec">
              <p>{`Created: ${formatDateTime(peerGroupDetails.createdDate)} by ${peerGroupDetails.createdBy || " - "} / Last Updated: ${formatDateTime(peerGroupDetails.modifiedDate)} by ${peerGroupDetails.modifiedBy || " - "
                }`}</p>
              {peerGroupDetails.canEdit && <EditIcon onClick={toggleShowOne} />}
            </div>
          )}
        </div>
        <div className="personalDetails">
          {!peerGroupDetails.peerGroupPictureUrl ? (
            <div className="user-initial">{peerGroupDetails.peerGroupName?.slice(0, 2)}</div>
          ) : (
            <div>
              <div className="personalImage">
                <img src={peerGroupDetails.peerGroupPictureUrl} alt={peerGroupDetails.peerGroupName} />
              </div>
            </div>
          )}
          <div className="personalDetailsRightSec d-flex justify-content-between">
            <ul className="userContactInfo personal-info">
              <h4 className="overflow-text" title={`${peerGroupDetails.peerGroupName} ${peerGroupDetails.communityName && "-"} ${peerGroupDetails.communityName}`}>
                {peerGroupDetails.peerGroupName === peerGroupDetails.peerGroupCustomName ? "" : peerGroupDetails.peerGroupName}{" "}
                {peerGroupDetails.peerGroupName !== peerGroupDetails.peerGroupCustomName && peerGroupDetails.communityName && "-"} {peerGroupDetails.communityName}
              </h4>
              <p className="overflow-text">{peerGroupDetails.peerGroupCustomName}</p>
            </ul>
            <div>
              <h3 className="contentHeading">Status</h3>
              <p className="overflow-text">
                {formatDateOnly(peerGroupDetails.startDate)} - {formatDateOnly(peerGroupDetails.endDate) || "Active"}
              </p>
              <div className="p-3">
                <div className="d-flex justiy-content-end">
                  <Status text={peerGroupDetails.statusName} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="descriptionContet col-md-6">
            <h3 className="contentHeading">Peer Group Monthly Date</h3>
            <p style={{ marginLeft: 10 }} className="content-txt mt-3">
              {displayWeekNumber(peerGroupDetails.monthEventWeek)} {peerGroupDetails.dayOfWeekName} {formatTimeOnly(peerGroupDetails.startTime)} {peerGroupDetails.endTime && "to"}{" "}
              {formatTimeOnly(peerGroupDetails.endTime)} {peerGroupDetails.timeZoneName}
            </p>
            <h3 className="contentHeading mt-4">Format</h3>
            <p style={{ marginLeft: 10 }} className="content-txt mt-3">
              {peerGroupDetails.eventFormatName}{" "}
              {peerGroupDetails.eventFormatName === "Virtual" ? (
                <>
                  <span> : </span>
                  <a target={"_blank"} href={peerGroupDetails.monthEventLink} rel="noreferrer">
                    {peerGroupDetails.monthEventLink}
                  </a>
                </>
              ) : (
                <span>
                  {peerGroupDetails.address1} {peerGroupDetails.address2}
                </span>
              )}
            </p>
          </div>
          <div className="FormatDetails col-md-6">
            {peerGroupDetails.canEdit && (
              <span style={{ float: "right" }}>
                <EditIcon onClick={toggleShowTwo} />
              </span>
            )}
            <h3 className="contentHeading">Format Note</h3>
            <p style={{ marginLeft: 10 }} className="content-txt mt-3">
              {peerGroupDetails.formatNote}
            </p>
          </div>
        </div>
      </div>
      <FormModal
        title="Peer Group"
        primaryKey={peerGroupPrimaryKey}
        show={showOne}
        showProcessing={showProcessing}
        fields={peerGroupFormFields}
        form={form}
        onCancel={onCancel}
        onChange={onChange}
        onSubmit={onSubmit}
      />
      <FormModal
        title="Peer Group"
        primaryKey={peerGroupPrimaryKey}
        show={showTwo}
        showProcessing={showProcessing}
        fields={peerGroupMonthlyFormFields}
        form={formTwo}
        onCancel={onCancelTwo}
        onChange={onChangeTwo}
        onSubmit={onSubmitTwo}
      />
      <ConfirmationModal
        modalTitle={"Warning"}
        confirmButtonTitle="Ok"
        cancelButtonTitle="Cancel"
        show={showWarning}
        onConfirm={showOne ? updatePeerGroupDetails : onConfirm}
        onCancel={toggleShowWarning}
        confirmationMessage={"Are you sure you want to update? This will delete the existing upcoming event(s) as well as ad-hoc event(s) and create the new monthly events."}
      />
    </>
  );
}
