
// import loginIcon from "../../assets/images/login-logo.png";
import loginIcon from "../../assets/images/logo-green-white.svg";
import LogoIcon from "../../assets/images/white-logo-icon.png";

export default function BodyBackground({ children, logo }) {
    return <div className="login-body-wrap">
        <div className="login-inner-wrap">
            <div className="parent-background">
                {logo ? (<img src={logo} className="background-logo" alt="logo" />) :
                    (<><img src={loginIcon} className="background-logo" alt="logo" />
                        <img src={LogoIcon} className="logoIconLrg" alt="LogoIcon" /> </>)
                }
            </div>
            {children}
        </div>
    </div>
}