import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ManagementTable from "../../components/ManagementTable";
import { UserLoaPrimaryKey } from "../../services/loa/userLOAServices";
import { userLOAApis, userLOAFields } from "./DATA";

const UserLOA = () => {
    const { userId } = useParams();
    const [permissionsLOA, setPermissionsLOA] = useState({ canAdd: false, canEdit: false, canDelete: false, _fetched: false });

    const userLOAApisFinal = useMemo(
        () => ({
            ...userLOAApis,
            create: { ...userLOAApis.create, body: { userId } },
            update: { ...userLOAApis.update, body: { userId } },
            getAll: { ...userLOAApis.getAll, body: { userId } },
            delete: { ...userLOAApis.delete, body: { userId } },
        }),
        [userId]
    );
    const runAfterGetAllLOA = (response) => {
        // eslint-disable-next-line dot-notation
        setPermissionsLOA((prev) => (prev["_fetched"] ? prev : { ...prev, canAdd: response.canAdd, canDelete: response.canDelete, canEdit: response.canEdit, _fetched: true }));
    };

    return (
        <div>
            {" "}
            <ManagementTable
                title="Leave of Absence"
                tableTitle={"Leave of Absence History"}
                primaryKey={UserLoaPrimaryKey}
                apis={userLOAApisFinal}
                fields={userLOAFields}
                hideActionsColumn={!permissionsLOA.canEdit && !permissionsLOA.canDelete}
                hideEditButton={!permissionsLOA.canEdit}
                hideDeleteButton={!permissionsLOA.canDelete}
                noPagination
                hideAddButton={!permissionsLOA.canAdd}
                customDeleteConfirmationMessage={"Are you sure you would like to delete the record?"}
                noSorting
                refreshAfterSubmit
                runAfterGetAll={runAfterGetAllLOA}
            />
        </div>
    );
};

export default UserLOA;
