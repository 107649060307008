import { v4 as uuid } from "uuid";

import { createGetOptionsApi } from "..";
import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const userRolePrimaryKey = "userRoleId";

export const getRoleOptions = createGetOptionsApi(Urls.GetRoleOptions, "roleOptions", 5);

export const getRoleOptionsForCommunity = createGetOptionsApi(Urls.GetRoleOptionsForCommunity, "communityRoleOptions", 5);

export const getRoleOptionsForPeerGroup = createGetOptionsApi(Urls.GetRoleOptionsForPeerGroup, "peerGroupRoleOptions", 5);

export const getUserRoles = async ({ userId, ...filters }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetUserRoles) + userId, { headers: filters });
    if (Array.isArray(responseData?.searchResult)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.searchResult.map((role) => {
            return {
                ...role,
                roleCode: role.roleCode?.trim(),
                startDate: normalizeDateOnly(role.startDate),
                endDate: normalizeDateOnly(role.endDate),
                [userRolePrimaryKey]: role?.userRoleId,
                roleId: role.roleId,
                reasonCode: role.exitReasonId,
                reason: role.exitReason,
            };
        });
    }

    return response;
};

export const getExitReasonOptionsByRole = createGetOptionsApi(Urls.GetExitReasonOptionsByRole, "exitReasonRoleOptions", 0);
export const createUserRole = async ({ userId, roleCode, startDate, endDate, reasonCode }) => {
    const response = await callApi.post(buildURL(Urls.CreateUserRole), { userId, roleCode, startDate: startDate || null, endDate: endDate || null, exitReasonId: reasonCode });

    return response.responseCode === 200 ? { ...response, responseData: { id: uuid() } } : response;
};

export const updateUserRole = async ({ userId, roleCode, startDate, endDate, userRoleId, reasonCode }) => {
    const response = await callApi.post(buildURL(Urls.UpdateUserRole), { userId, roleCode, startDate: startDate || null, endDate: endDate || null, userRoleId, exitReasonId: reasonCode });

    return response.responseCode === 200 && response.responseData === false ? { responseCode: 400, responseData: `User cannot have same role with blank End Date.` } : response;
};

export const deleteUserRole = async ({ userId, roleCode, startDate, endDate, userRoleId }) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserRole), { userId, roleCode, startDate: startDate || null, endDate: endDate || null, userRoleId });

    return response;
};
