import { Link } from "react-router-dom";

import { formatDateOnly, formatTimeOnly } from "../../helpers";
import { createUpdateEvent } from "../../services/events/eventServices";
import { getPeerGroupPastEvents, getPeerGroupUpcomingEvents } from "../../services/peer-groups/peerGroupEventServices";

export const peerGroupUpcomingEventFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventEndTime",
    columnWidth: 100,
    title: "Time To",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventTimeZoneName",
    title: "Time Zone",
    columnWidth: 120,
  },
  {
    name: "eventTypeName",
    title: "Event Type",
    columnWidth: 150,
  },
  {
    name: "eventFormatName",
    title: "Format",
    columnWidth: 110,
  },
  {
    name: "eventRsvp",
    title: "RSVP",
    columnWidth: 90,
  },
];

export const peerGroupEventHistorFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    filterable: true,
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    filterable: true,
  },
  {
    name: "eventEndTime",
    columnWidth: 100,
    title: "Time To",
    filterable: true,
  },
  {
    name: "eventTimeZoneName",
    title: "Time Zone",
    filterable: true,
    columnWidth: 120,
  },
  {
    name: "eventTypeName",
    title: "Event Type",
    filterable: true,
    columnWidth: 150,
  },
  {
    name: "eventFormatName",
    title: "Format",
    filterable: true,
    columnWidth: 110,
  },
  {
    name: "eventRsvp",
    title: "RSVP",
    filterable: true,
    columnWidth: 90,
  },
  {
    name: "eventWentCount",
    title: "Went",
    filterable: true,
    columnWidth: 90,
  },
];

export const peerGroupUpcomingEventInitialSort = {
  field: "eventDate",
  dir: "asc",
};

export const peerGroupUpcomingEventApis = {
  getAll: {
    api: getPeerGroupUpcomingEvents,
    body: { peerGroupId: null },
  },
  create: {
    api: createUpdateEvent,
  },
};

export const peerGroupPastEventApis = {
  getAll: {
    api: getPeerGroupPastEvents,
    body: { peerGroupId: null },
  },
};
