import { buildURL, callApi } from "../../helpers/api-urls";

export const getUserAggreementForm = async ({ userId }) => {
  const response = await callApi.get(buildURL("/UserForms/ViewAgreementForm/" + userId));

  return {
    data: response.responseData,
  };
};

export const submitUserAggreementForm = async ({ userId, masterAgreementId, ipAddress }) => {
  const response = await callApi.post(buildURL("/UserForms/SaveAgreementForm"), { masterAgreementId, ipAddress, userId });

  return response;
};
