import { BsInfoCircle } from "react-icons/bs";

import FormField from "../FormField";
import Tooltip from "../Tooltip";

export default function FormGroup({ field, onChange, form, primaryKey, formErrors, hideLabel = false, viewOnly = false }) {
    const info = field.showInfo?.(form);
    const error = formErrors[field.nameForm || field.name];
    const readOnly = (primaryKey && field.readOnlyInEdit && form[primaryKey]) || field.readOnly;

    return (
        <div className="form-group customInput">
            {!hideLabel && <label>{field.title}</label>}
            {field.info && (
                <Tooltip text={field.info(form)} placement="top">
                    <span className="mx-2 pointer">
                        <BsInfoCircle />
                    </span>
                </Tooltip>
            )}
            {viewOnly ? (
                <div>{form[field.name]}</div>
            ) : (
                <FormField
                    {...field}
                    options={field.optionsFilterer ? field.optionsFilterer(field.options, form) : field.options}
                    // eslint-disable-next-line no-undef
                    disabled={typeof field?.getDisabled === 'function' ? field?.getDisabled(form) : false}
                    readOnly={readOnly}
                    value={form[field.nameForm || field.name]}
                    error={error}
                    viewValue={form[field.name]}
                    onChange={onChange}
                />
            )}
            {error && (
                <div className="small text-danger p-1 my-1" title={error}>
                    {error}
                </div>
            )}
            {info && (
                <div className="small text-primary p-1 my-1" title={info}>
                    {info}
                </div>
            )}
        </div>
    );
}
