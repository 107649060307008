import { MdKeyboardArrowLeft } from "react-icons/md";
import { Link } from "react-router-dom";

import "./BreadCrumb.css"

export default function BreadCrumb({ title = "", link, hidden }) {
    return (
        <div className="bread-crumb">
            {hidden ? null : link ? (
                <Link to={link}>
                    <h1>
                        <MdKeyboardArrowLeft /> {`Back${title ? ` to ${title}` : ""}`}
                    </h1>
                </Link>
            ) : (
                <h1>{title}</h1>
            )}
        </div>
    );
}
