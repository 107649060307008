import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextFieldInput = ({ label, hideErrorMessage, dollarClass, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            {/* <label htmlFor={field.name}>{label}</label> */}
            <input style={{ paddingLeft: dollarClass }} className={`form-control ${meta.touched && meta.error && "is-invalid"}`} {...field} {...props} autoComplete="off" />
            {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={field.name} className="error" />}
        </>
    );
};
