import { useMemo } from "react";

import DateTimePicker from "../DateTimePicker";
import ImageInput from "../ImageInput";
import SelectInput from "../SelectInput";

import TagsInput from "../TagsInput";
import "./FormField.css";

export const EFieldInputTypes = {
    text: "text",
    textarea: "textarea",
    email: "email",
    password: "password",
    number: "number",
    date: "date",
    time: "time",
    select: "select",
    radio: "radio",
    checkbox: "checkbox",
    checkboxes: "checkboxes",
    custom: "custom",
    image: "image",
    file: "file",
    tags: "tags",
    multiple: "multiple",
};

export default function FormField({
    name,
    nameForm = name,
    title,
    maxLength,
    options,
    getOptions,
    getInitialOption,
    inputType = EFieldInputTypes.text,
    error,
    value,
    viewValue,
    onChange,
    noEmptyOption = false,
    asSearchField = false,
    placeholder,
    readOnly,
    withCheckbox,
    disableEmptyOption,
    autoComplete = "off",
    accept,
    isValidDate,
    disabled,
    textAreaMaxRow = 3,
    isRectangular = false,
}) {
    const id = useMemo(() => `${name}-${Math.random()}`, [name]);
    switch (inputType) {
        case EFieldInputTypes.image:
            return (
                <ImageInput
                    disabled={disabled === true}
                    name={nameForm}
                    viewName={name}
                    title={title}
                    value={value}
                    viewValue={viewValue}
                    onChange={onChange}
                    readOnly={readOnly}
                    isRectangular={isRectangular}
                />
            );
        case EFieldInputTypes.select:
            return (
                <SelectInput
                    name={name}
                    nameForm={nameForm}
                    title={title}
                    placeholder={placeholder}
                    options={options}
                    getOptions={getOptions}
                    getInitialOption={getInitialOption}
                    value={value}
                    viewValue={viewValue}
                    onChange={onChange}
                    noEmptyOption={noEmptyOption}
                    asSearchField={asSearchField}
                    withCheckbox={withCheckbox}
                    readOnly={readOnly}
                    error={error}
                    disabled={disabled === true}
                    disableEmptyOption={disableEmptyOption}
                />
            );
        case EFieldInputTypes.time:
            return (
                <DateTimePicker
                    name={nameForm}
                    type={EFieldInputTypes.time}
                    title={title}
                    placeholder={placeholder ?? "--:-- --"}
                    value={value}
                    onChange={onChange}
                    disabled={disabled === true}
                    asSearchField={asSearchField}
                    readOnly={readOnly}
                    error={error}
                />
            );
        case EFieldInputTypes.date:
            return (
                <DateTimePicker
                    name={nameForm}
                    type={EFieldInputTypes.date}
                    title={title}
                    placeholder={placeholder ?? "mm/dd/yyyy"}
                    value={value}
                    onChange={onChange}
                    asSearchField={asSearchField}
                    readOnly={readOnly}
                    disabled={disabled === true}
                    error={error}
                    isValidDate={isValidDate}
                />
            );
        case EFieldInputTypes.textarea:
            return (
                <textarea
                    name={nameForm}
                    readOnly={readOnly}
                    disabled={disabled === true}
                    maxLength={maxLength}
                    rows={textAreaMaxRow}
                    value={value ?? ""}
                    onChange={onChange}
                    placeholder={`${asSearchField ? "Search by" : "Enter"} ${title}`}
                    className={`form-control${asSearchField ? " searchBox" : error ? " is-invalid" : ""}`}
                />
            );
        case EFieldInputTypes.tags:
            return <TagsInput name={nameForm} readOnly={readOnly} value={value} onChange={onChange} />;
        case EFieldInputTypes.radio:
            return (
                <div>
                    {options?.map((o, i) => (
                        <span className="mx-2">
                            <input disabled={disabled === true} type="radio" key={i} name={nameForm} id={`${nameForm}-${i + 1}`} checked={value === o.value} value={o.value} onChange={onChange} />
                            <label className="mx-2" htmlFor={`${nameForm}-${i + 1}`}>
                                {o.label}
                            </label>
                        </span>
                    ))}
                </div>
            );
        case EFieldInputTypes.checkboxes:
            return (
                <div className="py-2">
                    {options?.map((o, i) => (
                        <span className="mx-2">
                            <input
                                disabled={disabled === true}
                                className="form-check-input"
                                type="checkbox"
                                key={i}
                                name={nameForm}
                                id={`${nameForm}-${i + 1}`}
                                checked={value?.includes(o.value || o.label)}
                                value={o.value}
                                onChange={onChange}
                            />
                            <label className="mx-2" htmlFor={`${nameForm}-${i + 1}`}>
                                {o.label}
                            </label>
                        </span>
                    ))}
                </div>
            );
        case EFieldInputTypes.checkbox:
            return (
                <div className="checkbox-field">
                    <input disabled={disabled === true} name={nameForm} id={id} type={inputType} readOnly={readOnly} checked={!!value} onChange={onChange} className={`form-check-input mx-3`} />
                    <label htmlFor={id}>{title}</label>
                </div>
            );
        default:
            return (
                <input
                    name={nameForm}
                    type={inputType === EFieldInputTypes.email ? EFieldInputTypes.text : inputType}
                    readOnly={readOnly}
                    maxLength={maxLength}
                    value={inputType === EFieldInputTypes.file ? viewValue || undefined : value ?? ""}
                    onChange={onChange}
                    autoComplete={autoComplete}
                    accept={accept}
                    placeholder={placeholder || `${asSearchField ? "Search by" : "Enter"} ${title}`}
                    className={`form-control${asSearchField ? " searchBox" : error ? " is-invalid" : ""}`}
                    disabled={disabled === true}
                />
            );
    }
}
