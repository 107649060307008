import BreadCrumb from "../../components/BreadCrumb";
import Form from "../../components/Form";
import { memberDirectorySearchFields, memberDirectoryInitialSearchForm } from "./DATA";
import "./MemberDirectory.css";
import { FaUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useForm } from "../../helpers/hooks";
import { getMemberDirectories } from "../../services/memberDirectory/memberDirectoryServices";
import LoadingSpinner from "../../components/LoadingSpinner";
import InitialTableLayout from "../../components/ManagementTable/InitialTableLayout.component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import bruceMars from "../../assets/images/bruce-mars-2.png";

export default function MemberDirectory() {
    const navigate = useNavigate();

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState({ size: 9, number: 1 });
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showTable, setShowTable] = useState(false);

    const [searchFormData, setSearchFormData] = useState({ memberDirectoryInitialSearchForm });
    const [searchForm, onChangeSearchForm, setSearchForm] = useForm({ memberDirectoryInitialSearchForm });

    useEffect(() => {
        setLoading(true);
        getMemberDirectories({ ...searchFormData, pageSize: page.size, pageNumber: page.number }).then((res) => {
            setRows(res[0]);
            setTotal(res[1]);
            setLoading(false);
        });
    }, [page.number, page.size, searchFormData]);
    const onSubmit = (formData) => {
        setShowTable(true);
        setSearchFormData(formData);
    };

    const onReset = () => {
        setSearchForm(memberDirectoryInitialSearchForm);
    };
    function goToNextPage() {
        setPage((page) => ({ ...page, number: page.number + 1 }));
    }
    function goToPreviousPage() {
        setPage((page) => ({ ...page, number: page.number - 1 }));
    }
    function goToLastPage() {
        const lastPage = Math.ceil(total / page.size);
        setPage((page) => ({ ...page, number: lastPage }));
    }
    function goToFirstPage() {
        setPage((page) => ({ ...page, number: 1 }));
    }
    const totalPages = Math.ceil(total / page.size) || 1;
    const lastPageInArray = Math.ceil(total / page.size);

    const initialPageNumber = page.number - (page.number % 5) + (page.number % 5 === 0 ? -page.number + 1 : 1);
    return (
        <>
            <BreadCrumb title="Member Directory" />
            <div className="member-directory-wrapper mb-2">
                <div className="card p-0">
                    <div className="viewUserDetailsInfo card-header">
                        <span className="user-ic">
                            <FaUserCircle />
                        </span>
                        <h4>Member Directory</h4>
                    </div>
                    <div className="card-body">
                        <div className="filterSec">
                            <Form
                                form={searchForm}
                                onChange={onChangeSearchForm}
                                title="Directory"
                                fields={memberDirectorySearchFields}
                                searchCols={{ open: 3, close: 3, openMd: 6 }}
                                hideMoreFiltersButton
                                asSearchForm
                                onSubmit={onSubmit}
                                onReset={onReset}
                            />
                        </div>

                        <div className="row">
                            {loading && (
                                <div className="w-100" style={{ height: 70, position: "absolute", zIndex: 9 }}>
                                    <LoadingSpinner />
                                </div>
                            )}
                            {!showTable && <InitialTableLayout hideAddButton />}
                            {rows.length === 0 && !loading && <div className="text-center">No Records found, please refine search</div>}
                            {showTable && (
                                <>
                                    {rows.map((r) => (
                                        <div onClick={() => navigate(`/member-directory/member-directory-view/${r.userGuid}`)} className="col-md-4 mb-3">
                                            <div className="card">
                                                <div className="cardHead">
                                                    <div className="small-logo">
                                                        {r.businessLogo ? <img src={r.businessLogo} className="" alt="logo" /> : <span>{r?.businessName?.slice(0, 1)}</span>}
                                                    </div>
                                                    <div className="company-details">
                                                        <h5>{r.businessName}</h5>
                                                        <p>
                                                            {r.firstName} {r.lastName}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="cardFooter">
                                                    <div className={`block${r.establishedDate && "-info"}`}>
                                                        <span>
                                                            <svg className="info-ic" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 25" fill="none">
                                                                <path
                                                                    d="M12.5001 0.416626C10.1102 0.416626 7.77404 1.1253 5.78695 2.45303C3.79985 3.78077 2.2511 5.66793 1.33654 7.87587C0.421984 10.0838 0.182693 12.5134 0.648931 14.8573C1.11517 17.2012 2.266 19.3543 3.95588 21.0442C5.64576 22.7341 7.79881 23.8849 10.1427 24.3511C12.4867 24.8174 14.9162 24.5781 17.1242 23.6635C19.3321 22.7489 21.2193 21.2002 22.547 19.2131C23.8747 17.226 24.5834 14.8898 24.5834 12.5C24.5834 10.9132 24.2709 9.34189 23.6636 7.87587C23.0564 6.40985 22.1663 5.07779 21.0443 3.95575C19.9223 2.83371 18.5902 1.94366 17.1242 1.33642C15.6582 0.729171 14.0869 0.416626 12.5001 0.416626ZM12.5001 22.1666C10.5882 22.1666 8.71925 21.5997 7.12957 20.5375C5.5399 19.4753 4.3009 17.9656 3.56925 16.1992C2.8376 14.4329 2.64617 12.4892 3.01916 10.6141C3.39215 8.73894 4.31281 7.0165 5.66472 5.66459C7.01663 4.31269 8.73907 3.39203 10.6142 3.01904C12.4894 2.64604 14.433 2.83748 16.1994 3.56912C17.9657 4.30077 19.4754 5.53977 20.5376 7.12945C21.5998 8.71912 22.1668 10.5881 22.1668 12.5C22.1668 15.0637 21.1483 17.5225 19.3355 19.3353C17.5226 21.1482 15.0638 22.1666 12.5001 22.1666Z"
                                                                    fill="#99CC66"
                                                                />
                                                                <path
                                                                    d="M12.5001 8.87492C13.1674 8.87492 13.7084 8.33393 13.7084 7.66659C13.7084 6.99924 13.1674 6.45825 12.5001 6.45825C11.8327 6.45825 11.2917 6.99924 11.2917 7.66659C11.2917 8.33393 11.8327 8.87492 12.5001 8.87492Z"
                                                                    fill="#99CC66"
                                                                />
                                                                <path
                                                                    d="M12.5001 10.0833C12.1796 10.0833 11.8723 10.2106 11.6457 10.4372C11.4191 10.6638 11.2917 10.9711 11.2917 11.2916V17.3333C11.2917 17.6537 11.4191 17.9611 11.6457 18.1877C11.8723 18.4143 12.1796 18.5416 12.5001 18.5416C12.8206 18.5416 13.1279 18.4143 13.3545 18.1877C13.5811 17.9611 13.7084 17.6537 13.7084 17.3333V11.2916C13.7084 10.9711 13.5811 10.6638 13.3545 10.4372C13.1279 10.2106 12.8206 10.0833 12.5001 10.0833Z"
                                                                    fill="#99CC66"
                                                                />
                                                            </svg>
                                                            {r.industry}
                                                            {r.community && r.industry && " / "}
                                                            {r.community}
                                                        </span>
                                                        <span>
                                                            {r.establishedDate && "Established"} {!r.establishedDate ? null : moment(r.establishedDate).format("MMMM YYYY")}
                                                        </span>
                                                    </div>
                                                    {/* <div>
                                                <a href="#">SouthWester MA</a>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>

                        {showTable && (
                            <div className="k-pager-wrap k-pager k-widget k-grid-pager" role="navigation">
                                <span href="#" className={`k-link k-pager-nav k-pager-first k-state${page.number === 1 && "-disabled"}`} title="Go to the first page">
                                    <span onClick={goToFirstPage} className="k-icon k-i-arrow-end-left" />
                                </span>
                                <span href="#" className={`k-link k-pager-nav k-state${page.number === 1 && "-disabled"}`} title="Go to the previous page">
                                    <span onClick={goToPreviousPage} className="k-icon k-i-arrow-60-left" />
                                </span>
                                <div className="k-pager-numbers-wrap">
                                    <select className="k-dropdown">
                                        <option className="k-link">1</option>
                                        <option className="k-link">2</option>
                                        <option className="k-link">3</option>
                                        <option className="k-link">4</option>
                                        <option className="k-link">5</option>
                                        <option className="k-link" value={6}>
                                            ...
                                        </option>
                                    </select>

                                    <ul className="k-pager-numbers k-reset">
                                        {new Array(totalPages > 5 ? 5 : totalPages).fill(initialPageNumber).map((_zero, index) => {
                                            const number = index + initialPageNumber;
                                            return number > totalPages ? null : (
                                                <li key={index} onClick={number === page.number ? undefined : () => setPage((prev) => ({ ...prev, number: number }))}>
                                                    <span href="#" className={`k-link${page.number === number ? " k-state-selected" : ""}`}>
                                                        {number}
                                                    </span>
                                                </li>
                                            );
                                        })}

                                        {totalPages > 5 && page.number !== totalPages && (
                                            <li onClick={() => setPage((prev) => ({ ...prev, number: totalPages >= page.number ? totalPages : initialPageNumber + 5 }))}>
                                                <span className="k-link" href="#">
                                                    ...
                                                </span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <span href="#" className="k-link k-pager-nav" title="Go to the next page">
                                    <span onClick={lastPageInArray === page.number ? undefined : goToNextPage} className="k-icon k-i-arrow-60-right" />
                                </span>
                                <span href="#" className="k-link k-pager-nav k-pager-last" title="Go to the last page">
                                    <span onClick={goToLastPage} className="k-icon k-i-arrow-end-right" />
                                </span>
                                <span className="k-pager-sizes k-label">{page.size} items per page</span>
                                <div className="k-pager-info k-label">
                                    {(page.number - 1) * page.size + 1} - {page.number * page.size < total ? page.number * page.size : total} of {total} items
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
