import { EHeaderActionTypes } from "./headerActions";

const initialState = {
  show: true,
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case EHeaderActionTypes.hideHeader:
      return {
        ...state,
        show: false,
      };
    case EHeaderActionTypes.showHeader:
      return {
        ...state,
        show: true,
      };
    case EHeaderActionTypes.toggleHeader:
      return {
        ...state,
        show: !state.show,
      };
    default:
      return state;
  }
};

export default headerReducer;
