import { useCallback, useEffect, useRef } from "react";

import ManagementTable from "../../components/ManagementTable";
import AddRowsModal from "../../components/AddRowsModal";
import { eventCurriculumApis, eventCurriculumFields } from "./DATA";
import { showResponseMessage } from "../../helpers";
import { useToggle } from "../../helpers/hooks";
import { addEventStaffMembers, eventStaffPrimaryKey } from "../../services/events/eventStaffServices";
import { curriculumApis, curriculumFields, curriculumInitialSearchForm, curriculumSearchFields } from "../Curriculum/DATA";
import { curriculumPrimaryKey } from "../../services/curriculum/curriculum.services";
import { addEventCurriculum, eventCurriculumPrimaryKey } from "../../services/events/eventCurriculumServices";

export default function EventCurriculum({ eventId, dataRows, eventDetails, selectUserSearchFields, userInitialSearchForm, setDataRows, setRefresh }) {
    const [show, toggleShow, setShow] = useToggle(false);
    const processing = useRef(false);

    const onAddEventCurriculum = useCallback(
        async (selectedUsers) => {
            if (processing.current) return;
            processing.current = true;

            const response = await addEventCurriculum({
                eventId,
                curriculumIds: selectedUsers.map((user) => user.curriculumId),
            });

            setTimeout(() => {
                processing.current = false;
            }, 2000);
            showResponseMessage(response, { success: "Event(s) have been successfully added to the curriculum." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            setShow(false);
        },
        [eventId, setRefresh, setShow]
    );

    useEffect(() => {
        if (!Array.isArray(dataRows)) setRefresh((prev) => prev + 1);
    }, [dataRows, setRefresh]);
    return (
        <>
            <ManagementTable
                title={"Event Curriculum"}
                tableTitle="Curriculum"
                primaryKey={eventCurriculumPrimaryKey}
                fields={eventCurriculumFields}
                onClickAdd={toggleShow}
                data={dataRows}
                // runAfterDelete={runAfterDelete}
                // customAddButtonTitle={"Add Staff Member"}
                hideAddButton={!eventDetails.canAddEventCurriculum}
                hideActionsColumn={!eventDetails.canDeleteEventCurriculum}
                apis={eventCurriculumApis}
                customDeleteSuccessMessage={"Event has been removed successfully from this curriculum."}
                customDeleteConfirmationMessage="Are you sure you want to delete the record?"
                hideEditButton
            />
            <AddRowsModal
                show={show}
                primaryKey={curriculumPrimaryKey}
                onHide={toggleShow}
                fields={curriculumFields}
                title={"Curriculum"}
                apis={curriculumApis}
                initialSearchForm={curriculumInitialSearchForm}
                searchFields={curriculumSearchFields}
                onAddRows={onAddEventCurriculum}
                confirmationMessage={`Are you sure you want to add the curriculum(s) to the event?`}
            />
        </>
    );
}
