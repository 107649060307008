import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const eventPermissionPrimaryKey = "eventPermissionId";

export const eventPermissionInitialForm = {
  eventId: "",
  communityCode: "",
  peerGroupCode: "",
};

export const getEventPermissions = async ({ eventId }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };
  const { responseData } = await callApi.get(buildURL(Urls.GetEventPermissions) + eventId);

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((u) => ({
      communityName: u.communityName,
      peerGroupName: u.peerGroupName,
      communityCode: u.communityCode,
      peerGroupCode: u.peerGroupCode,
      [eventPermissionPrimaryKey]: u.eventPermissionId,
      eventId,
    }));
  }

  return response;
};

export const addEventPermission = async (formData) => {
  const response = await callApi.post(buildURL(Urls.AddEventPermission), {
    eventId: formData.eventId,
    communityCode: formData.communityCode || "-1",
    peerGroupCode: formData.peerGroupCode || "-1",
  });

  return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventPermissionId } } : response;
};

export const deleteEventPermission = async (formData) => {
  const response = await callApi.post(buildURL(Urls.DeleteEventPermission), {
    eventPermissionId: formData.eventPermissionId,
    eventId: formData.eventId,
  });

  return response;
};
