import { React, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import EditIcon from "../../components/EditIcon";
import ManagementTable from "../../components/ManagementTable";
import Tooltip from "../../components/Tooltip";
import { formatDateTime } from "../../helpers";
import { curriculumEventAttachmentFields, curriculumEventOverviewFields, eventDetailsCurriculumApis, eventDetailsCurriculumOverviewApis } from "./DATA";

const EventCurriculumDetails = () => {
    const { eventId } = useParams();
    const eventDetailsCurriculumOverviewFinalApis = useMemo(() => ({ ...eventDetailsCurriculumOverviewApis, getAll: { ...eventDetailsCurriculumOverviewApis.getAll, body: { eventId } } }), [eventId]);

    return <div className="card mb-4"></div>;
};

export default EventCurriculumDetails;
