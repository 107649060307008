import { EFieldInputTypes } from "../../components/FormField";
import { isStringUrl } from "../../helpers";
import messages from "../../helpers/messages";
import { getEventFormatOptions } from "../../services/events/eventServices";
import { getDayOfWeekOptions } from "../../services/masters/masterServices";
import { getTimeZoneOptions } from "../../services/time-zones/timeZoneServices";

export const displayWeekNumber = (week) => {
  switch (week?.toString() || "") {
    case "1":
      return "1st";
    case "2":
      return "2nd";
    case "3":
      return "3rd";
    case "4":
      return "4th";
    case "5":
      return "5th";
    default:
      return "";
  }
};
export const displayDayNumber = (day) => {
  switch (day?.toString() || "") {
    case "1":
      return "Monday";
    case "2":
      return "Tuesday";
    case "3":
      return "Wednesday";
    case "4":
      return "Thursday";
    case "5":
      return "Friday";
    case "6":
      return "Saturday";
    case "7":
      return "Sunday";
    default:
      return "";
  }
};

export const initialPeerGroupMonthlyForm = {
  peerGroupId: "",
  monthEventWeek: "",
  monthEventDayId: "",
  startTime: "",
  endTime: "",
  timeZoneCode: "",
  eventFormatCode: "",
  monthEventLink: "",
  monthEventNote: "",
};

export const peerGroupMonthlyFormFields = [
  {
    name: "dayOfWeekName",
    nameForm: "monthEventDayId",
    required: true,
    title: "Day of Week",
    inputType: EFieldInputTypes.select,
    getOptions: getDayOfWeekOptions,
  },
  {
    name: "monthEventWeek",
    required: true,
    title: "Week",
    inputType: EFieldInputTypes.select,
    options: [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
    ],
  },
  {
    name: "startTime",
    required: true,
    title: "Time From",
    inputType: EFieldInputTypes.time,
    col: 3,
    colMd: 4,
  },
  {
    name: "endTime",
    required: true,
    title: "Time To",
    inputType: EFieldInputTypes.time,
    validate: (form) => (form.endTime <= form.startTime ? messages.endTimeBeforeStartTime : ""),
    col: 3,
    colMd: 4,
  },
  {
    name: "timeZoneName",
    nameForm: "timeZoneCode",
    required: true,
    title: "Time Zone",
    inputType: EFieldInputTypes.select,
    getOptions: getTimeZoneOptions,
    colMd: 4,
  },
  {
    name: "eventFormatName",
    nameForm: "eventFormatCode",
    required: true,
    title: "Format",
    inputType: EFieldInputTypes.select,
    getOptions: getEventFormatOptions,
    dependencies: [
      {
        name: "monthEventLink",
      },
    ],
  },
  {
    name: "monthEventLink",
    title: "Meeting Link",
    inputType: EFieldInputTypes.text,
    maxLength: 320,
    col: 12,
    required: (form) => form.eventFormatName === "Virtual",
    excludeInFormOnCondition: (form) => form.eventFormatName !== "Virtual",
    validate: (form) => (form.monthEventLink && !isStringUrl(form.monthEventLink) ? messages.invalidUrl : ""),
  },
  {
    name: "formatNote",
    required: false,
    title: "Format Note",
    inputType: EFieldInputTypes.textarea,
    col: 12,
  },
];
