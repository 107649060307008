import { combineReducers } from "redux";

import userReducer from "./userReducer";
import permissionReducer from "./permissionReducer";
import sidebarReducer from "./sidebarReducer";
import headerReducer from "./headerReducer";

const rootReducer = combineReducers({
  user: userReducer,
  permissions: permissionReducer,
  sidebar: sidebarReducer,
  header: headerReducer,
});

export default rootReducer;
