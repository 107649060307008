import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";

const Footer = () => {
    return (
        <footer>
            <Container fluid>
                <Row>
                    <Col>
                        <Nav className="justify-content-end">
                            <p>© {new Date().getFullYear()} Entrepreneurs Forever. All Rights Reserved.</p>
                            <Nav.Item>
                                <Nav.Link href="/terms-and-conditions">Terms & Conditions</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="link-1">Privacy Policy</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="https://www.entrepreneursforever.org/" target={"_blank"} eventKey="link-2">
                                    Entrepreneurs Forever Home
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
