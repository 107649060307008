export const ESidebarActionTypes = {
  hideSidebar: "HIDE_SIDEBAR",
  showSidebar: "SHOW_SIDEBAR",
  toggleSidebar: "TOGGLE_SIDEBAR",
};

export const hideSidebarAction = () => ({
  type: ESidebarActionTypes.hideSidebar,
});
export const showSidebarAction = () => ({
  type: ESidebarActionTypes.showSidebar,
});
export const toggleSidebarAction = () => ({
  type: ESidebarActionTypes.toggleSidebar,
});
