import axios from "axios";
import { throttle } from "lodash";
import { toast } from "react-toastify";

const showUnauthorisedMessage = throttle(() => toast.warning("You are not authorized."), 4000);

// const redirect = throttle(() => {
//   // eslint-disable-next-line no-alert
//   alert("Session Expired.");
//   window.location.href = "/log-out";
// }, 2000);

axios.interceptors.response.use((response) => {
    if (response.data.responseCode === 401 && response.data.responseData !== "Login Failed.") showUnauthorisedMessage();

    return response;
});

export const Urls = {
    GetAccountStatusOptions: "/Lookup/AccountStatus",
    GetEventTypeMaxDefault: "/Lookup/EventTypeMaxDefault",
    GetSocialTypeLookup: "/lookup/SocialLinkType",
    GetCountryOptions: "/Lookup/Country",
    GetStateOptions: "/Lookup/State/",
    GetPeerGroupOptions: "/Lookup/PeerGroup",
    GetPeerGroupOptionsByCommunity: "/PeerGroup/GetByCommunity",
    GetExitReasonOptionsByRole: "/Lookup/ExitReasons",
    GetRoleOptions: "/Lookup/Role",
    GetTimeZoneOptions: "/Lookup/TimeZone",
    GetRoleOptionsForCommunity: "/Lookup/RolesForCommunity",
    GetRoleOptionsForPeerGroup: "/Lookup/RolesForPeerGroup",
    GetEventTypeOptions: "/Lookup/EventType",
    GetEventFormatOptions: "/Lookup/EventFormat",
    GetEventStatusOptions: "/Lookup/EventStatus",
    GetCommunityOptions: "/Lookup/Community",
    GetCommunityStatusOptions: "/Lookup/CommunityStatus",
    GetRevenueSource: "/Lookup/RevenueSource",
    GetAnnualMmeberTooltips: "/Lookup/Labels",
    AddEditUser: "/user/addedit",
    UserLogin: "/Login",
    ForgotPasswordApi: "/Login/forgotPassword",
    IsLinkValidApi: "/User/keyStatus/",
    VerifyLinkApi: "/User/resetpassword",
    UpdatePassword: "/User/updatePassword",
    SearchUsers: "/Search/User",
    SearchPeerGroups: "/Search/PeerGroup",
    SearchCommunities: "/Search/Community",
    SearchEvents: "/Search/Event",
    GetEventStaff: "/Event/GetStaff/",
    AddEventStaff: "/Event/AddStaff",
    DeleteEventStaff: "/Event/DeleteStaff",
    GetPeerGroupStaff: "/PeerGroup/Facilitators",
    AddPeerGroupStaff: "/PeerGroup/AddFacilitator",
    UpdatePeerGroupStaff: "/PeerGroup/UpdateFacilitator",
    DeletePeerGroupStaff: "/PeerGroup/DeleteFacilitator",
    GetCommunityById: "/Community/Get",
    GetCommunityStaff: "/Community/Directors",
    AddCommunityStaff: "/Community/AddDirector",
    UpdateCommunityStaff: "/Community/UpdateDirector",
    DeleteCommunityStaff: "/Community/DeleteDirector",
    GetEventAttendees: "/Event/GetAttendee/",
    AddEventAttendee: "/Event/AddAttendee",
    DeleteEventAttendee: "/Event/DeleteAttendee",
    GetEventPermissions: "/Event/GetPermission/",
    AddEventPermission: "/Event/AddPermission",
    DeleteEventPermission: "/Event/DeletePermission",
    ResetPassword: "/Login/resetpassword",
    UserView: "/User/View/",
    GetUserCommunities: "/User/GetCommunity/",
    CreateUserCommunity: "/User/AddCommunity",
    UpdateUserCommunity: "/User/UpdateCommunity",
    DeleteUserCommunity: "/User/DeleteCommunity",
    GetUserPeerGroups: "/User/GetPeerGroup/",
    CreateUserPeerGroup: "/User/AddPeerGroup",
    UpdateUserPeerGroup: "/User/UpdatePeerGroup",
    DeleteUserPeerGroup: "/User/DeletePeerGroup",
    GetUserRoles: "/User/GetUserRole/",
    CreateUserRole: "/User/AddUserRole",
    UpdateUserRole: "/User/UpdateUserRole",
    DeleteUserRole: "/User/DeleteUserRole",
    AddUser: "/User/add",
    EditUser: "/User/update",
    GetEventById: "/Event/",
    AddEvent: "/Event/Add",
    EditEvent: "/Event/Update",
    GeneratePasswrod: "/User/generatePassword",
    // Prospect form
    GetHearAboutUsOptions: "/Pub/Register/HearAboutUs",
    GetCommunityOptionsProspectForm: "/Pub/Register/Community",
    SubmitProspectForm: "/Pub/Register/Add",
    SubmitAddFirstStepForm: "/Pub/Register/AddFirstStep",
    AssignCommunity: "/Pub/Register/AssignCommunity",
    GetCommunityEvents: "/Pub/Register/GetCommunityEvents/",
    AssignEvent: "/Pub/Register/AssignSession",
    GetDemographicsUserInfo: "/User/GetDemographicInfo/",
    Ethnicity: "/Lookup/Ethnicity",
    Languages: "/Lookup/Language",
    EditUserDemographics: "/User/UpdateDemographicInfo",
    GetIp: "/Pub/Register/Init",
    GetUserContactInfo: "/UserContact/View/",
    UpdateContactAddress: "/UserContact/UpdateAddress",
    UpdatePhoneNumber: "/UserContact/UpdatePhone",
    UpdateEmail: "/UserContact/UpdateEmail",
    UpdatePreferredContact: "/UserContact/UpdatePreferredContact",
    CheckExistingEmail: "/Pub/Register/CheckEmailExists",

    // User Business
    GetUserBusinessDetails: "/UserBusiness/View/",
    GetUsserMemberDirectory: "/User/DirectorySetting/",
    SetUsserMemberDirectory: "/User/SetDirectorySetting",
    RescheduleEvent: "/User/RescheduleEvent",
    GetMemberDirectoryView: "/MemberDirectory/Detail/",
    UserBusinessEdit: "/UserBusiness/Update",
    GetMonthlyResultForms: "/UserForms/GetResultForms/",
    GetMonthlyViewResultForm: "/UserForms/ViewResultForm",
    GetFocusAreaOptions: "/Lookup/FormFocusArea",
    EditMonthlyForm: "/UserForms/EditResultForm",
    GetPeerGroupDetailsPage: "/PeerGroup/Get",
    // get user initial form
    GetUserInitialForm: "/UserForms/ViewInitForm/",
    EditIntitialForm: "/UserForms/EditInitForm",
    // KPI Forms
    GetKPIForm: "/UserForms/ViewYearlyResult/",
    SaveEmployeeForm: "/UserForms/SaveYearlyResultEmployee",
    SaveAnnualGrossRevenue: "/UserForms/SaveYearlyResultRevenue",
    SaveCompensation: "/UserForms/SaveYearlyResultOwnerCompensation",
    SaveOwnerCommercialSpace: "/UserForms/SaveYearlyResultCommercialSpace",
    SaveFinancialStatement: "/UserForms/SaveYearlyResultFinancialStatement",
    SaveHouseholdIncome: "/UserForms/SaveYearlyResultHouseholdIncome",
    GetCommercialSpaceType: "/Lookup/CommercialSpaceType",

    // Sign member Agreement
    GetSignMemberAgreement: "/User/Dashboard/",
    GetDiscoverySessions: "/User/DiscoverySessions",

    // Curriculum
    CreateCurriculum: "/Curriculum/Add",
    UpdateCurriculum: "/Curriculum/Update",
    SearchCurriculum: "/Search/Curriculum",
    GetCurriculum: "/Curriculum/Get",
    GetCurriculumAttachments: "/Curriculum/Attachments",
    CurriculumAttachmentAdd: "/Curriculum/Attachment/Add",
    DeleteCurriculumAttachment: "/Curriculum/Attachment/Delete",
    GetCurriculumFeedbacks: "/Curriculum/Feedbacks",
    AddCurriculumFeedback: "/Curriculum/Feedback/Add",
    DeleteCurriculumFeedback: "/Curriculum/Feedback/Delete",
    // eslint-disable-next-line spaced-comment
    //Event Curriculum

    GetEventCurriculum: "/Event/GetCurriculum/",
    AddEventCurriculum: "/Event/AddCurriculum",
    DeleteEventCurriculum: "/Event/DeleteCurriculum",
    GetEventCurriculumOverviewDetails: "/Event/Overview/Curriculum/",

    // eslint-disable-next-line spaced-comment
    //User Milestones
    GetUserMilestones: "/User/Milestone/",
    AddUserMilestone: "/User/AddMilestone",
    DeleteUserMilestone: "/User/DeleteMilestone",
    GetUserMilestoneWidgets: "/User/Widget/Milestones/",
    GetEventCurriculumDetails: "/Event/Detail/Curriculum/",

    PeerGroupMemberAdd: "/PeerGroup/Member/Add",

    // User Referrals
    GetUserReferral: "/User/Referral/",
    CreateUserReferral: "/User/AddReferral",
    // User Dashboard for Community Director and Facilitator Peer Groups
    GetUserDashboardPeerGroups: "/UserDashboard/PeerGroups/",
    GetUserDashboardPendingAttendance: "/User/Widget/PendingEventAttendance/",

    // USer Social Links
    GetUserSocialLinks: "/UserBusiness/SocialLink/",
    AddUserSocialLink: "/userbusiness/AddSocialLink",
    DeleteUserSocialLink: "/userbusiness/deletesociallink",
    // Member Directory
    GetMemberDirectories: "/Search/MemberDirectory",

    // USer LOA
    AddUserLOA: "/User/AddLOA",
    GetUserLOA: "/User/LOA/",
    UpdateUserLOA: "/User/UpdateLOA",
    DeleteUserLOA: "/User/DeleteLOA",

    // Funders
    AddFunders: "/Funder/Add",
    UpdateFunders: "/Funder/Update",
    GetFundersById: "/Funder/Get",
    SearchFunder: "/Search/Funder",
    AddFundersCommunity: "/Funder/AddCommunityFunder",
    UpdateFundersCommunity: "/Funder/UpdateCommunityFunder",
    getFundersCommunityById: "/Funder/GetCommunityFunderById",
    SearchFunderCommunity: "/Funder/FunderCommunities",
    DeleteFunderCommunity: "/Funder/DeleteCommunity",
    GetFundersByCommunity: "/Funder/CommunityFunders",
    GetFundersLogo: "/Funder/GetLogo",
    // County
    AddCounty: "/County/Add",
    UpdateCounty: "/County/Update",
    GetCountyById: "/County/Get",
    SearchCounty: "/Search/County",
    AddCountiesCommunity: "/County/AddCommunityCounty",
    UpdateCountiesCommunity: "/County/UpdateCommunityCounty",
    getCountiesCommunityById: "/County/GetCommunityCountyById",
    SearchCountiesCommunity: "/County/CountyCommunities",
    DeleteCountiesCommunity: "/County/DeleteCommunity",
    GetCountyByCommunity: "/County/CommunityCounties",
};

const axiosConfig = {
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },
};
export const buildURL = (url, queryParam, paramReplacements) => {
    const API_URL = process.env.REACT_APP_API_URL || "https://e3ever-dev.azurewebsites.net/api/v1";
    if (queryParam) {
        url = addQueryParameters(url, queryParam);
    }

    if (paramReplacements) {
        url = replaceParams(url, paramReplacements);
    }

    return API_URL + url;
};
export const buildImgURL = (url) => {
    if (url) {
        const IMG_URL = process.env.REACT_APP_IMAGE_PATH;
        return IMG_URL + url;
    }
    return "";
};
const addQueryParameters = (url, params) => {
    const queryList = [];
    Object.keys(params).forEach((k) => {
        queryList.push(`${k}=${encodeURIComponent(params[k])}`);
    });
    const prefixCharacter = url.indexOf("?") === -1 ? "?" : "&";

    return `${url + prefixCharacter + queryList.join("&")}`;
};

const replaceParams = (url, params) => {
    Object.keys(params).forEach((k) => {
        url = url.replace(`:${k}`, encodeURIComponent(params[k]));
    });
    return url;
};

export const callApi = {
    get: async (url, config = {}) => {
        try {
            const response = await axios.get(url, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...axiosConfig.headers,
                    ...(config.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    post: async (url, data, config = {}) => {
        try {
            const response = await axios.post(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    ...(config?.headers || {}),
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    put: async (url, data, config = {}) => {
        try {
            const response = await axios.put(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    patch: async (url, data, config = {}) => {
        try {
            const response = await axios.patch(url, data, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    delete: async (url, config = {}) => {
        try {
            const response = await axios.delete(url, {
                ...axiosConfig,
                ...config,
                headers: {
                    ...(config.headers || axiosConfig.headers || {}),
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
    otherMethod: async (url, method = "get", ...args) => {
        try {
            const response = await axios[method](url, ...args);

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            if (process.env.NODE_ENV !== "production") console.log(error);
            if (error.response?.status === 403 || error.response?.status === 401) showUnauthorisedMessage();
            return error.response?.data || {};
        }
    },
};

export const downloadFile = (url, fileName, method, postData) => {
    axios
        .request({
            url,
            method: method || "get",
            data: postData,
            responseType: "blob",
        })
        .then(({ data }) => {
            let a = document.createElement("a");
            let file = new Blob([data]);
            let fileURL = window.URL.createObjectURL(file);
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = fileURL;
            a.download = `${fileName}`;
            a.click();
        });
};
