import { useMemo } from "react";

import ManagementTable from "../../components/ManagementTable";
import { eventPrimaryKey } from "../../services/events/eventServices";
import { eventHistorFields, pastEventApis, upcomingEventApis, upcomingEventFields } from "./DATA";

export default function UserEvents({ userId }) {
    const upcomingEventApisFinal = useMemo(() => ({ ...upcomingEventApis, getAll: { ...upcomingEventApis.getAll, body: { userId } } }), [userId]);
    const pastEventApisFinal = useMemo(() => ({ ...pastEventApis, getAll: { ...pastEventApis.getAll, body: { userId } } }), [userId]);

    return <div className="row mx-0">
        <div className="col-lg-12 col-md-12 col-12 my-3">
            <ManagementTable title="Event" tableTitle={"Upcoming Events"} primaryKey={eventPrimaryKey} apis={upcomingEventApisFinal} customAddButtonTitle="Add Event" fields={upcomingEventFields} hideActionsColumn hideAddButton localSort />
        </div>
        <div className="col-lg-12 col-md-12 col-12 my-3">
            <ManagementTable title="Event History" tableTitle={"Event History"} primaryKey={eventPrimaryKey} apis={pastEventApisFinal} fields={eventHistorFields} hideActionsColumn hideAddButton localSort />
        </div>
    </div>;
}
