import { Link } from "react-router-dom";

import messages from "../../helpers/messages";
import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly } from "../../helpers";
import { createCommunityPeerGroup, updateCommunityPeerGroup } from "../../services/communities/communityPeerGroupServices";

export const communityPeerGroupFields = [
  {
    name: "peerGroupName",
    nameForm: "peerGroupId",
    columnWidth: 160,
    title: "Peer Group Name",
    filterable: true,
    required: true,
    col: 12,
    cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupId}`}>{value}</Link>,
  },
  {
    name: "startDate",
    columnWidth: 120,
    title: "Start Date",
    filterable: true,
    required: true,
    inputType: EFieldInputTypes.date,
    cell: (value) => formatDateOnly(value),
    col: 6,
  },
  {
    name: "endDate",
    columnWidth: 120,
    title: "End Date",
    filterable: true,
    inputType: EFieldInputTypes.date,
    cell: (value) => formatDateOnly(value),
    col: 6,
    validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? messages.endDateLater : ""),
  },
];

export const communityPeerGroupApis = {
  create: {
    api: createCommunityPeerGroup,
    body: {
      communityId: null,
    },
  },
  update: {
    api: updateCommunityPeerGroup,
  },
};
