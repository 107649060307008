import React, { useCallback, useRef } from "react";
// import { confirmAlert } from "react-confirm-alert";

import Form from "../Form";
import Modal from "../Modal";
import ConfirmationModal from "../ConfirmationModal";
import LoadingModal from "../LoadingModal";
import { useToggle } from "../../helpers/hooks";

export default function FormModal({
  show,
  showProcessing,
  size,
  title = "Title",
  customModalTitle,
  form,
  fields,
  primaryKey,
  onSubmit,
  onChange,
  onCancel,
  onReset,
  customCreateConfirmationMessage,
  customUpdateConfirmationMessage,
  viewOnly = false,
  confirmOnSubmit = false,
}) {
  const formData = useRef({});
  const [showConfirmation, toggleShowConfirmation] = useToggle(false);

  const handleClose = useCallback(() => onCancel(), [onCancel]);
  const handleSubmit = useCallback(
    (form) => {
      if (!confirmOnSubmit) return onSubmit(form);

      formData.current = form;
      toggleShowConfirmation();
    },
    [onSubmit, confirmOnSubmit, toggleShowConfirmation]
  );

  const handleConfirmSubmit = useCallback(() => {
    toggleShowConfirmation();
    onSubmit(formData.current);
  }, [onSubmit, toggleShowConfirmation]);

  const confirmationMessage =
    !form[primaryKey] && customCreateConfirmationMessage
      ? customCreateConfirmationMessage
      : form[primaryKey] && customUpdateConfirmationMessage
        ? customUpdateConfirmationMessage
        : `Are you sure you want to ${form[primaryKey] ? "update" : "add"} the ${title} ?`;

  return (
    <>
      <LoadingModal show={showProcessing} />
      <ConfirmationModal modalTitle={"Confirmation"} show={showConfirmation} confirmationMessage={confirmationMessage} onCancel={toggleShowConfirmation} onConfirm={handleConfirmSubmit} />
      <Modal className="manageUserPopup" show={show} onHide={handleClose} title={customModalTitle || `${viewOnly ? "View" : form[primaryKey] ? "Edit" : "Add"} ${title}`} size={size}>
        <Form title={title} fields={fields} form={form} onSubmit={handleSubmit} onReset={onReset} onChange={onChange} primaryKey={primaryKey} onCancel={handleClose} viewOnly={viewOnly} />
      </Modal>
    </>
  );
}
