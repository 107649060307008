import React from "react";

import BodyBackground from "../../components/BodyBackground";
import WavingHand from "../../assets/images/waving-hand.png";

const Dashboard = () => {

  return (
    <BodyBackground>
      <div className="login-form-wrapper reset-link-box">
        <div className="m-2">
          <img alt="Welcome." className="mx-auto d-block" src={WavingHand} width={40} height={40} />
        </div>
        <h1 className="SignInTxt reset-link-txt">
          <p>Welcome to</p>
          <p>Entrepreneurs Forever!</p>
        </h1>
        <div className="text-center">Your account is still in process and we will send you a confirmation email to initiate your profile set up. If any concern please email us at : xxxxxxx@email.com</div>
      </div>
    </BodyBackground>
  );
};

export default Dashboard;
