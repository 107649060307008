import { useMemo } from "react";

import ManagementTable from "../../components/ManagementTable";
import { peerGroupScorecardPrimaryKey } from "../../services/peer-groups/peerGroupScorecardServices";
import { peerGroupScorecardApis, peerGroupScorecardFields } from "./DATA";

export default function PeerGroupScorecard({ peerGroupId, peerGroupDetails: { communityName, communityCode, peerGroupName } }) {
    const apis = useMemo(() => ({ ...peerGroupScorecardApis, getAll: { ...peerGroupScorecardApis.getAll, body: { peerGroupId, communityName, communityCode, peerGroupName } } }), [peerGroupId, communityName, communityCode, peerGroupName]);

    return <ManagementTable title="Scorecard" tableTitle="Scorecard" primaryKey={peerGroupScorecardPrimaryKey} apis={apis} fields={peerGroupScorecardFields} noPagination hideActionsColumn hideAddButton localSort />;
}
