import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, throttle } from "../../helpers";
import { addUserMilestone, deleteUserMilestone, getUserMilestones } from "../../services/userMilestones";
export const userMilestoneInitialForm = {
    userId: "",
    date: "",
    milestone: "",
};
export const userMilestoneFields = [
    {
        name: "date",
        title: "Date",
        inputType: EFieldInputTypes.date,
        required: true,
        col: 6,
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "milestone",
        nameForm: "milestone",
        title: "Milestone",
        required: true,
        col: 6,
        // readOnlyInEdit: true,
        maxLength: 30,
    },
];

export const userMilestoneApis = {
    create: {
        api: addUserMilestone,
        body: {
            userId: "",
        },
    },
    delete: {
        api: deleteUserMilestone,
    },
    getAll: {
        api: getUserMilestones,
        body: {
            userId: "",
        },
    },
};
