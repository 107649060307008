export default function NotFoundPage() {
    return (
        <div className="py-5 px-3 text-center">
            <div className="py-5 card">
                <div className="h1 my-2">404 | Not Found</div>
                <div className="h2 my-2">Either you're lost or this page is to be developed soon...</div>
            </div>
        </div>
    );
}
