import ComingSoonPage from "../ComingSoon";
// import ManagementTable from "../../components/ManagementTable";
// import BreadCrumb from "../../components/BreadCrumb";
// import { memberApis, memberFields, memberInitialSearchForm, memberSearchFields } from "./DATA";
// import { memberPrimaryKey } from "../../services/members/memberServices";

export default function Members() {
    return (
        <ComingSoonPage title={"Members"} />
        // <>
        //     <BreadCrumb title="Members" />
        //     <ManagementTable
        //         title="Member"
        //         tableTitle={"Members"}
        //         primaryKey={memberPrimaryKey}
        //         apis={memberApis}
        //         fields={memberFields}
        //         searchFields={memberSearchFields}
        //         initialSearchForm={memberInitialSearchForm}
        //         searchCols={{ open: 12, close: 6 }}
        //         initialFilterCount={6}
        //         hideActionsColumn
        //         hideAddButton
        //     />
        // </>
    );
}
