import React, { useCallback } from "react";

const FilterCell = ({ field, value, onChange, filterType: { filterType, options, maxLength } }) => {
  const handleOnChange = useCallback(
    ({ target: { value, name }, nativeEvent, syntheticEvent = nativeEvent }) => {
      onChange({ value, operator: "contains", syntheticEvent });
    },
    [onChange]
  );

  return filterType === "select" ? (
    <select onChange={handleOnChange} name={field} value={value ?? ""} className="form-control form-select tableInputSearch">
      <option value={""}>All</option>
      {options?.map((option, idx) => (
        <option key={idx} value={option.label}>
          {option.label}
        </option>
      ))}
    </select>
  ) : (
    <input onChange={handleOnChange} name={field} value={value ?? ""} className="form-control tableInputSearch" maxLength={maxLength} placeholder={`Search...`} />
  );
};

export default FilterCell;
