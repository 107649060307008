import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi } from "../../helpers/api-urls";

export const getUserUpcomingEvents = async (filters) => {
  const { responseData } = await callApi.get(buildURL("/User/UpcomingEvents/" + filters.userId), {
    headers: filters,
  });

  return {
    data: Array.isArray(responseData?.searchResult)
      ? responseData?.searchResult.map((u) => ({
          eventDate: u.eventDate?.slice(0, 10) || "",
          eventStartTime: u.startTime || "",
          eventEndTime: u.endTime || "",
          eventTimeZoneName: u.timezoneName || "",
          eventTypeName: u.eventTypeName || "",
          eventFormatName: u.formatTypeName || "",
          eventStatusName: u.eventStatusName || "",
          attendeeStatusName: u.attendeeStatusName || "",
          eventId: u.eventId || "",
        }))
      : [],
    totalRecords: responseData?.totalRecords || responseData?.searchResult?.length || 0,
  };
};

export const getUserDashboardUpcomingEvents = async (filters) => {
  const { responseData } = await callApi.get(buildURL("/User/Widget/UpcomingEvents/" + filters.userId), {
    headers: { ...filters, noOfDays: 90 },
  });

  return {
    data: Array.isArray(responseData?.searchResult)
      ? responseData?.searchResult.map((u) => ({
          eventDate: u.eventDate?.slice(0, 10) || "",
          eventStartTime: formatTimeOnly(u.startTime),
          eventEndTime: u.endTime || "",
          timeZoneName: u.timeZoneName || "",
          eventTypeName: u.eventTypeName || "",
          eventFormatName: u.formatTypeName || "",
          eventStatusName: u.eventStatusName || "",
          userStatusName: u.userStatusName || "",
          eventId: u.eventId || "",
        }))
      : [],
    totalRecords: responseData?.totalRecords || responseData?.searchResult?.length || 0,
  };
};

export const getUserPastEvents = async (filters) => {
  const { responseData } = await callApi.get(buildURL("/User/PastEvents/" + filters.userId), {
    headers: filters,
  });

  return {
    data: Array.isArray(responseData?.searchResult)
      ? responseData.searchResult.map((u) => ({
          eventDate: u.eventDate?.slice(0, 10) || "",
          eventStartTime: formatTimeOnly(u.startTime),
          eventEndTime: formatTimeOnly(u.endTime),
          eventTimeZoneName: u.timezoneName || "",
          eventTypeName: u.eventTypeName || "",
          eventFormatName: u.formatTypeName || "",
          eventStatusName: u.eventStatusName || "",
          attendeeStatusName: u.attendeeStatusName || "",
          eventId: u.eventId || "",
        }))
      : [],
    totalRecords: responseData?.totalRecords || responseData?.searchResult?.length || 0,
  };
};
