import { useCallback, useEffect, useMemo } from "react";

import ManagementTable from "../../components/ManagementTable";
import { eventPermissionInitialForm, eventPermissionPrimaryKey } from "../../services/events/eventPermissionServices";
import { eventPermissionApis, eventPermissionFields } from "./DATA";

export default function EventPermissions({ eventId, eventDetails, dataRows, setDataRows, setRefresh }) {
    const apis = useMemo(() => ({ ...eventPermissionApis, getAll: { ...eventPermissionApis.getAll, body: { eventId } }, create: { ...eventPermissionApis.create, body: { eventId } } }), [eventId]);
    const canAddDelete = eventDetails.eventTypeName !== "Peer Group" && eventDetails.eventTypeName !== "One on One";
    const hideActionsColumn = !eventDetails.canDeletePermissions || !canAddDelete;
    const hideAddButton = !eventDetails.canAddPermissions || !canAddDelete;

    const runAfterDelete = useCallback(
        (data) => {
            setDataRows((prev) => prev.filter((user) => user.eventPermissionId !== data.eventPermissionId));
        },
        [setDataRows]
    );
    const runAfterSubmit = useCallback(() => setRefresh((prev) => prev + 1), [setRefresh]);

    useEffect(() => {
        if (!Array.isArray(dataRows)) setRefresh((prev) => prev + 1);
    }, [dataRows, setRefresh]);

    return (
        <ManagementTable
            title="Permission"
            tableTitle={"Permissions"}
            customAddButtonTitle={"Add Permission"}
            primaryKey={eventPermissionPrimaryKey}
            data={dataRows}
            initialForm={eventPermissionInitialForm}
            fields={eventPermissionFields}
            runAfterDelete={runAfterDelete}
            runAfterSubmit={runAfterSubmit}
            customCreateConfirmationMessage={"Are you sure you want to update the permissions?"}
            customDeleteConfirmationMessage={"Are you sure you want to delete?"}
            customCreateSuccessMessage="Permissions have been successfully updated."
            apis={apis}
            hideActionsColumn={hideActionsColumn}
            hideAddButton={hideAddButton}
            confirmOnSubmit
            hideEditButton
            refreshAfterSubmit
        />
    );
}
