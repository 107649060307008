import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import * as Yup from "yup";

import Footer from "../../components/BodyContainer/Footer.component";
import "./SignUp.css";

import axios from "axios";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { useToggle } from "../../helpers/hooks";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import { TextFieldInput } from "./TextFieldInput";

const validationSchema = Yup.object({
    firstName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
    lastName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
    email: Yup.string().trim().email("Email is invalid").max(320, "Must be 320 characters or less").required("Required Field."),
    preferredContactMethod: Yup.string().required("Required Field."),
    hearAboutUsCode: Yup.string().required("Required Field."),

    phoneNumber: Yup.string()
        .matches(/^[\d \-()]*$/, "Not Valid Phone Number!")
        .min(6, "Not Valid Phone Number!")
        .max(15, "Not Valid Phone Number!")
        .required("Required Field."),
});

const SignUpContactInfo = ({ label, hideErrorMessage, form, setForm, setTab, prospectInitId, setActiveClass, setProspectInitId, sourceURL, isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/contact-information" + window.location.search);
    }, [gaEventTracker]);
    const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);
    const [timer, setTimer] = useState(0);
    const [phoneType, setPhoneType] = useState("Mobile");
    const [show, toggleShow] = useToggle(false);
    const [showVerification, toggleShowVerification] = useToggle(false);
    const [emailId, setEmailId] = useState("");
    const [userAlreadyExists, setUserAlreadyExists] = useState(false);
    const [userVerified, setUserVerified] = useState(false);
    const [userId, setUserId] = useState("");
    const processingRef = useRef(false);
    const businessProcessingRef = useRef(false);
    const [ip, setIP] = useState("");
    const [referrerURL, setReferrerURL] = useState("");
    useEffect(() => {
        let i = 0;
        async function geoLocation() {
            i++;
            try {
                const res = await axios.get("https://checkip.amazonaws.com/");
                if (!res && i <= 10) return geoLocation();
                setIP(res?.trim());
            } catch (error) {
                if (i <= 10) {
                    geoLocation();
                    if (i == 10) {
                        setIP("0.0.0.0");
                    }
                }
            }
        }
        geoLocation();
    }, []);
    useEffect(() => {
        (() => {
            const referrer = document.referrer;
            setReferrerURL(referrer);
        })();
    }, [ip, referrerURL]);
    useEffect(() => {
        (async () => {
            if (!ip) return;
            const response = await callApi.post(buildURL(`${Urls.GetIp}`), {
                ipAddress: ip,
                referrerAddress: referrerURL,
            });

            if (!response.responseData) return;
            setProspectInitId(response.responseData.prospectInitId);
        })();
    }, [ip, referrerURL, setProspectInitId]);
    const checkExistingEmail = (email, cb = () => {}) => {
        if (email) {
            (async () => {
                const response = await callApi.get(buildURL(`${Urls.CheckExistingEmail}`), {
                    headers: {
                        emailId: email || "",
                    },
                });
                if (!response.responseData) return;
                if (response.responseData.isAlreadyExists) {
                    setEmailId(email);
                    setUserId(response.responseData.userId);
                    setUserAlreadyExists(true);
                    toggleShow();
                    cb(true);
                } else {
                    setUserAlreadyExists(false);
                    setUserId(response?.responseData?.userId || 0);
                    cb(false);
                }
            })();
        }
    };

    const handleSendVerificationCodeAgain = async () => {
        const response = await callApi.post(buildURL(`/Pub/Register/SendCode/${emailId}`));

        if (response.responseCode === 200) {
            toast.success("Verification code has been sent on your email address. This is valid for 10 minutes“");
            setTimer(10);
            const interval = setInterval(() => setTimer((prev) => (prev <= 0 ? 0 : prev - 1)), 60000);
            setTimeout(() => clearInterval(interval), 10 * 60 * 1000 + 100);
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleConfirmVerification = async () => {
        const response = await callApi.post(buildURL(`/Pub/Register/SendCode/${emailId}`));

        if (response.responseCode === 200) {
            toast.success("Verification code has been sent on your email address. This is valid for 10 minutes“");
            toggleShowVerification();
            setTimer(10);
            const interval = setInterval(() => setTimer((prev) => (prev <= 0 ? 0 : prev - 1)), 60000);
            setTimeout(() => clearInterval(interval), 10 * 60 * 1000 + 100);
        } else {
            toast.error("Something went wrong!");
        }
    };
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetHearAboutUsOptions}`));

            if (!response.responseData) return;

            setHearAboutUsOptions(response.responseData);
        })();
    }, []);

    const handleSubmit = (values) => {
        if (businessProcessingRef.current) return;
        businessProcessingRef.current = true;
        const queryParams = new URLSearchParams(sourceURL);
        if ((userAlreadyExists && userVerified) || !userAlreadyExists) {
            setForm((prev) => {
                const payloads = {
                    ...prev,
                    ...values,
                    // hearAboutUsCode: hearAboutUsOptions,
                    phoneType: phoneType,
                    prospectInitId: prospectInitId,
                    // phoneNumber: "" + values.phoneNumber,
                    phoneNumber: "" + values.phoneNumber?.replace(/[^0-9]/g, ""),
                    userId: userId,
                };
                checkExistingEmail(values?.email, (isEmailExist) => {
                    if (!isEmailExist) {
                        callApi
                            .post(buildURL(`${Urls.SubmitAddFirstStepForm}`), {
                                prospectInitId: prospectInitId,
                                firstName: values?.firstName,
                                lastName: values?.lastName,
                                middleName: values?.middleName,
                                email: values?.email,
                                phoneNumber: values?.phoneNumber,
                                phoneType: phoneType,
                                preferredContactMethod: values?.preferredContactMethod,
                                hearAboutUsCode: values?.hearAboutUsCode.trim(),
                                hearAboutUsDetail: "",
                                channel: queryParams.get("ch"),
                                funder: queryParams.get("funder"),
                                source: sourceURL,
                            })
                            .then((response) => {
                                if (response.responseCode !== 200) return toast.error("Something Went Wrong!");
                                payloads.prospectUserFirstStepId = response?.responseData?.prospectUserFirstStepId;
                                payloads.funder = queryParams.get("ch") ?? undefined;
                                payloads.channel = queryParams.get("funder") ?? undefined;
                                setActiveClass(1);
                                setTab(2);
                            });
                    }
                });

                return payloads;
            });
        } else {
            toast.error("This email already exists");
            toggleShow();
        }
        setTimeout(() => (businessProcessingRef.current = false), 3000);
    };
    const handleSubmitVerificationCode = async (values, actions) => {
        if (processingRef.current) return;

        processingRef.current = true;
        const response = await callApi.post(buildURL(`/Pub/Register/Verify`), {
            verificationCode: "" + values.verificationCode,
            email: emailId,
        });
        setTimeout(() => (processingRef.current = false), 2000);

        if (response?.responseCode === 200) {
            toast.success(response?.responseMessage);
            toggleShow();
            toggleShowVerification();
            setUserVerified(true);
        } else {
            toast.error("Entered verification code is not correct" || response?.responseMessage);
        }
    };
    // const handleChange = (e) => {
    //   setHearAboutUsValue(e.target.value);
    // };
    const handleChangePhoneType = (e) => {
        setPhoneType(e.target.value);
    };
    return (
        <>
            <div className="signUpRightFormSec align-items-start">
                <Formik enableReinitialize={true} validationSchema={validationSchema} initialValues={form} onSubmit={handleSubmit}>
                    {(formik) => (
                        <Form>
                            <div className="rightSecForm prospect-form-container">
                                <h3>Contact Info</h3>
                                <h6>Welcome! A few quick steps can change the path of your small business.</h6>
                                <form>
                                    <Row>
                                        <Col md="4" xs="7">
                                            <div className="form-group customInput">
                                                <label>First Name*</label>
                                                <TextFieldInput name="firstName" type="text" placeholder="First Name" />
                                            </div>
                                        </Col>
                                        <Col md="4" xs="5">
                                            <div className="form-group customInput">
                                                <label>MIDDLE INITIAL</label>
                                                <TextFieldInput maxLength="1" name="middleName" type="text" placeholder="Middle Initial" />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="form-group customInput">
                                                <label>last NAME*</label>
                                                <TextFieldInput name="lastName" type="text" placeholder="Last Name" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" xs="7">
                                            <div className="form-group customInput">
                                                <label>PREFERRED PHONE #*</label>
                                                <TextFieldInput
                                                    name="phoneNumber"
                                                    inputmode="numeric"
                                                    pattern="[0-9]*"
                                                    onKeyDown={(evt) => {
                                                        const array = ["(", ")", "-", "Backspace", "Delete", "ArrowRight", "ArrowLeft", "Control", "Tab"];
                                                        !array.includes(evt.key) && isNaN(evt.key) && evt.preventDefault();
                                                    }}
                                                    type="text"
                                                    placeholder="Phone Number"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="6" xs="5">
                                            <div className="form-group customInput">
                                                <label>phone # Type*</label>
                                                <div className="select-box-wrapper">
                                                    {" "}
                                                    <select className="form-select" value={phoneType} onChange={handleChangePhoneType}>
                                                        <option value="select">Select</option>
                                                        <option value="Mobile">Mobile</option>
                                                        <option value="Landline">Landline</option>
                                                    </select>
                                                    <span className="select-arrow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                            <path
                                                                d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                fill="#B4AFC6"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>

                                                {/* <Field name="phoneType" className="form-select" as="select">
                        <option value="Mobile">Mobile</option>
                        <option value="Phone">Phone</option>
                      </Field> */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="form-group customInput">
                                                <label>PREFERRED EMAIL ADDRESS*</label>
                                                <TextFieldInput
                                                    readOnly={userVerified}
                                                    name="email"
                                                    type="email"
                                                    placeholder="Enter Email Address"
                                                    onBlur={(e) => checkExistingEmail(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="contactMethod justify-content-start">
                                                <h6>How do you want us to reach you?</h6>
                                                <div className="selectMethod">
                                                    {/* name must match the Formik value key, and must
              be set on all radio buttons in the group  */}
                                                    <div class="form-check form-check-inline">
                                                        <Field type="radio" name="preferredContactMethod" value="Email" id="emailRadio" class="form-check-input" checked />
                                                        <label title="" for="emailRadio" class="form-check-label">
                                                            Email
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <Field type="radio" name="preferredContactMethod" value="Phone" id="phoneRadio" class="form-check-input" />
                                                        <label title="" for="phoneRadio" class="form-check-label">
                                                            Phone
                                                        </label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <Field type="radio" name="preferredContactMethod" value="Text" id="textRadio" class="form-check-input" />
                                                        <label title="" for="textRadio" class="form-check-label">
                                                            Text
                                                        </label>
                                                    </div>

                                                    {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"preferredContactMethod"} className="error" />}
                                                    {/* <Form.Check inline type="radio" aria-label="radio 1" name="radio" label="Email" />
                      <Form.Check inline type="radio" aria-label="radio 2" name="radio" label="Phone" />
                      <Form.Check inline type="radio" aria-label="radio 3" name="radio" label="Text" /> */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group customInput">
                                                <label>How did you hear about us?*</label>
                                                <div className="select-box-wrapper">
                                                    <Field name="hearAboutUsCode" className="form-select" as="select">
                                                        <option value="">Please Select</option>
                                                        {hearAboutUsOptions.map((option) => (
                                                            <option value={option.code}>{option.name}</option>
                                                        ))}
                                                    </Field>
                                                    <span className="select-arrow">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                            <path
                                                                d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                fill="#B4AFC6"
                                                            />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <ErrorMessage style={{ color: "red" }} component="div" name={"hearAboutUsCode"} className="error" />
                                                {/* <select onChange={handleChange} value={hearAboutUsValue} className="form-select" as="select" name="hearAboutUsCode">

                        {hearAboutUsOptions.map((option) => (
                          <option value={option.code}>{option.name}</option>
                        ))}

                        {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"hearAboutUsCode"} className="error" />}
                      </select> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                                <div className="bottomBtnSec">
                                    <p className="nextScreenInfo">That was easy! Next, tell us a little about your business.</p>
                                    <button type="submit" className="customPrimaryBtn mt-4">
                                        YOUR BUSINESS <HiOutlineChevronRight />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                {show && (
                    <Modal className="manageUserPopup" backdrop="static" show={show} onHide={toggleShow} size="lg" centered>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        This email address already exists with form data. If you would like to resubmit your application then we will send you a verification code over email to reset the form and
                        start the application process once again.
                        <br />
                        Note - Code will be of 6 digit code (alphanumeric). System shall send the verification code over email address
                        <Modal.Body>
                            <Row>
                                <div className="d-flex justify-content-center my-3">
                                    <div className="mx-2">
                                        <button className="btnPrimaryOutline btn btn-outline-primary" type="button" onClick={toggleShow}>
                                            CANCEL
                                        </button>
                                    </div>
                                    <div className="mx-2">
                                        <button className="customPrimaryBtn btn" type="button" onClick={handleConfirmVerification}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </Modal.Body>
                    </Modal>
                )}
                <Modal className="manageUserPopup" backdrop="static" show={showVerification} onHide={toggleShowVerification} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    Verification code has been sent on your email address. This is valid for 10 minutes
                    <Modal.Body>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                verificationCode: "",
                                email: emailId,
                            }}
                            onSubmit={handleSubmitVerificationCode}
                        >
                            {(formik) => (
                                <Form>
                                    <Col md="12">
                                        <div>
                                            <h3>
                                                {timer || "Expired"} {timer < 1 ? "minute" : !timer ? "" : "minutes"}
                                            </h3>
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="form-group customInput">
                                            <label>Verification Code</label>
                                            <TextFieldInput name="verificationCode" type="text" placeholder="Enter Verification Code" />
                                        </div>
                                    </Col>
                                    <Row>
                                        <div className="d-flex justify-content-center my-3">
                                            {!timer && (
                                                <div className="mx-2">
                                                    <button className="customPrimaryBtn btn" type="button" onClick={handleSendVerificationCodeAgain}>
                                                        Resend Code
                                                    </button>
                                                </div>
                                            )}

                                            <div className="mx-2">
                                                <button className="customPrimaryBtn btn" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            </div>
            {!isFunderLogo && <div className="signupFooter">
                <Footer />
            </div>}
        </>
    );
};

export default SignUpContactInfo;
