import { useState, useEffect, useMemo, useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ReactTagInput from "@pathofdev/react-tag-input";

import EditSquare from "../../assets/images/edit_square_icon.png";
import EvaInfoIcon from "../../assets/images/eva_info-outline.png";
import World from "../../assets/images/tabler_world.png";
import ImageInput from "../../components/ImageInput";
import { TextField } from "../Users/TextField";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { formatDateOnly, formatDateTime } from "../../helpers";
import { useToggle } from "../../helpers/hooks";
import { getUserBusinessType, getUserIndustryType, getUserServiceOfferingType } from "../../services/userBusiness/userBusiness";
import GreenCheckmark from "../../../src/assets/images/checkmark-green.png";
import PinkCross from "../../../src/assets/images/eva_close-pink.png";
import "./UserBusiness.css";
import ManagementTable from "../../components/ManagementTable";
import { userSocialLinksApis, userSocialLinksFields } from "./DATA";
import { SocialLinkPrimaryKey } from "../../services/userBusiness/userSocialLinksServices";
import { createStructuredSelector } from "reselect";
import { selectUserId, selectUserIsAdmin, selectUserIsCommunityDirector, selectUserIsFacilitator } from "../../redux/userReducer/userSelectors";
import { useSelector } from "react-redux";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required Field"),
    startYear: Yup.string().required("Required Field"),
    startMonth: Yup.string().required("Required Field"),
    industryCode: Yup.string().required("Required Field"),
    businessTypeCode: Yup.string().required("Required Field"),
    serviceTypeCode: Yup.string().required("Required Field"),
    background: Yup.string().required("Required Field"),
    // briefDescription: Yup.string().required("Required Field"),
    website: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Enter correct url!"),
});
const memberDirectoryValidationSchema = Yup.object().shape({
    isDirectoryEnabled: Yup.string().required("Required Field"),
    isBusinessPhone: Yup.string().required("Required Field"),
    isBusinessAdd: Yup.string().required("Required Field"),
    isBusinessEmail: Yup.string().required("Required Field"),
    featureLink1: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Please enter valid URL"),
    featureLink2: Yup.string().matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "Please enter valid URL"),
});

export default function UserBusiness({ setUserDetails }) {
    const { userId } = useParams();

    const [show, toggleShow] = useToggle(false);
    const [showDirectory, toggleShowDirectory] = useToggle(false);
    const [industryType, setIndustryType] = useState([]);
    const [serviceOfferingType, setServiceOfferingType] = useState([]);
    const [businessType, setBusinessType] = useState([]);
    // Member Directory
    const [memberDirectory, setMemberDirectory] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [tagsButton, setTagsButton] = useState([]);
    const [userBusinessDetails, setUserBusinessDetails] = useState({
        background: "",
        canEdit: false,
        briefDescription: "",
        businessTypeCode: "",
        businessTypeName: "",
        industryName: "",
        industryTypeCode: "",
        logo: null,
        modifiedOn: "",
        name: "",
        serviceTypeCode: "",
        serviceOfferingName: "",
        startMonth: "",
        startYear: "",
        tags: [],
        userGuid: "",
        gainByJoiningProgram: "",
        website: "",
    });
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    const runAfterGetAll = useCallback(({ canAdd, canEdit, canDelete }) => setPermissions((prev) => ({ ...prev, canAdd, canEdit, canDelete })), []);
    const { isFacilitator, myUserId, isCommunityDirectory, isAdmin } = useSelector(
        createStructuredSelector({ isFacilitator: selectUserIsFacilitator, myUserId: selectUserId, isCommunityDirectory: selectUserIsCommunityDirector, isAdmin: selectUserIsAdmin })
    );
    const userSocialLinksApisFinal = useMemo(
        () => ({
            ...userSocialLinksApis,
            getAll: { ...userSocialLinksApis.getAll, body: { userId } },
            create: { ...userSocialLinksApis.create, body: { userId } },
            delete: { ...userSocialLinksApis.delete, body: { userId } },
        }),
        [userId]
    );
    const bussinessModelClose = () => {
        toggleShow(false);
        setTagsButton(userBusinessDetails.tags);
    };
    const onChangeTags = (tags) => {
        tags = tags?.filter((tags) => {
            return tags?.trim() !== "";
        });
        setTagsButton(tags);
    };
    useEffect(() => {
        (async () => {
            const response = await getUserIndustryType();
            if (!response.responseData) return;

            setIndustryType(response.responseData);
        })();
        (async () => {
            const response = await getUserServiceOfferingType();
            if (!response.responseData) return;

            setServiceOfferingType(response.responseData);
        })();
        (async () => {
            const response = await getUserBusinessType();
            if (!response.responseData) return;

            setBusinessType(response.responseData);
        })();
    }, []);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetUserBusinessDetails}${userId}`));
            if (!response.responseData) return;
            const tagsResponse = response.responseData.tags?.length ? response.responseData.tags.split(",") : [];
            setUserBusinessDetails({ ...response.responseData, tags: tagsResponse });
            setTagsButton(tagsResponse);
        })();
    }, [userId, refresh]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetUsserMemberDirectory}${userId}`));
            if (!response.responseData) return;
            setMemberDirectory(response.responseData);
        })();
    }, [userId, refresh]);

    const {
        background,
        briefDescription,
        businessTypeCode,
        businessTypeName,
        industryName,
        industryTypeCode,
        logo,
        modifiedOn,
        modifiedBy,
        name,
        serviceTypeCode,
        startMonth,
        startYear,
        tags,
        gainByJoiningProgram,
        website,
    } = userBusinessDetails;

    return (
        <>
            <div>
                <div className="eventDetails mb-3">
                    <div className="eventHeader">
                        <h1>Business Details</h1>
                        <span className="pointer">
                            {modifiedOn && (
                                <span>
                                    Last Updated:{formatDateOnly(modifiedOn)} by {modifiedBy}
                                </span>
                            )}
                            {userBusinessDetails.canEdit && <img onClick={toggleShow} src={EditSquare} alt="Edit Event" />}
                        </span>
                    </div>
                    <div className="eventContainer">
                        {!logo ? (
                            <div className="business-initial">{name?.slice(0, 1)}</div>
                        ) : (
                            <div>
                                <div className="personalImage">
                                    <img src={logo} className="" alt="" />
                                </div>
                            </div>
                        )}
                        {/* <div className="image-box">
                            <img src={ClosetFactory} className="" alt="unknown" />
                        </div> */}
                        <div className="eventContentBusiness">
                            <div className="eventContentBusinessHeading">
                                <h3>{name}</h3>
                            </div>
                            <span>
                                <ul>
                                    <li className="event-items-business mt-3">
                                        {startMonth && (
                                            <span>
                                                <img src={EvaInfoIcon} className="" alt="MessageIc" />
                                            </span>
                                        )}
                                        <span className="event-items-txt">
                                            {" "}
                                            {startMonth && "Est."} {startMonth ? moment(startMonth, "MM").format("MMMM") : ""} {startYear} {startYear && "|"} {industryName} {industryName && "|"}{" "}
                                            {businessTypeName}
                                        </span>
                                    </li>
                                    <li className="event-items-business mt-3">
                                        {website && (
                                            <span>
                                                <img src={World} className="" alt="MessageIc" />
                                            </span>
                                        )}
                                        <span className="event-items-link"> {website}</span>
                                    </li>
                                </ul>
                            </span>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="descriptionContet col-md-12 mb-2">
                            {/* <h3 className="contentHeading">Brief DESCRIPTION</h3>
              <p style={{ marginLeft: 10 }} className="content-txt mt-3">
                {briefDescription}
              </p> */}
                            <h3 className="contentHeading">TAGS</h3>
                            <div className="TagsContainer">
                                {tags?.map((t) => (
                                    <button className="businessTags" type="button">
                                        {t}
                                    </button>
                                ))}
                            </div>
                            <div className="businees-descp-panel">
                                <h3 className="bussinessDecriptionheading mt-4">{`Business DESCRIPTION`}</h3>
                                <p style={{ marginTop: 22, marginLeft: 5 }}> {` (This description will appear on your Member Profile and in our Member Directory.)`}</p>
                            </div>
                            <p style={{ marginLeft: 10 }} className="content-txt mt-3">
                                {background}
                            </p>
                        </div>

                        <div className="FormatDetails col-md-6"></div>
                    </div>
                </div>
                <ManagementTable
                    title="Social Link"
                    tableTitle={"Social Links"}
                    primaryKey={SocialLinkPrimaryKey}
                    apis={userSocialLinksApisFinal}
                    fields={userSocialLinksFields}
                    hideEditButton
                    hideActionsColumn={!permissions.canAdd}
                    noPagination
                    hideAddButton={!permissions.canAdd}
                    customDeleteConfirmationMessage={"Are you sure you would like to delete the record?"}
                    noSorting
                    refreshAfterSubmit
                    runAfterGetAll={runAfterGetAll}
                />

                <div className="descriptionContainer mt-3">
                    <span className="edit-ic pointer">
                        <span>{`Last Updated: ${formatDateTime(memberDirectory.lastUpdatedDate)} by ${memberDirectory.lastUpdatedBy}`}</span>
                        {memberDirectory?.canEdit && !isFacilitator && <img onClick={toggleShowDirectory} src={EditSquare} className="" alt="MessageIc" />}
                    </span>
                    <h3 className="contentHeading mb-2">MEMBER DIRECTORY SETTINGS</h3>
                    <div className="row">
                        <div className="col-md-6 mt-3">
                            <div className="member-directory-wrap graybg">
                                <div className="member-directory-list statusBox">
                                    {" "}
                                    <span className="statusBox-txt">Member Directory : </span>
                                    {memberDirectory?.isDirectoryEnabled === true ? (
                                        <span className="Status-chk">
                                            <img src={GreenCheckmark} className="" alt="MessageIc" />
                                            <div className="Status-txt">{"On"}</div>
                                        </span>
                                    ) : (
                                        <span className="Status-cross">
                                            <img src={PinkCross} className="" alt="MessageIc" />
                                            <div className="Status-txt">{"Off"}</div>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4 mt-4 statusBox">
                            <span className="statusBox-txt"> Display On Member Directory</span>
                            <span className="Status-chk">
                                <img src={GreenCheckmark} className="" alt="MessageIc" />
                                <div className="Status-txt">Yes</div>
                            </span>
                        </div> */}
                        {memberDirectory?.isDirectoryEnabled && (
                            <>
                                <div className="col-md-6 mt-3">
                                    <div className="member-directory-wrap graybg">
                                        <div className="member-directory-list statusBox">
                                            <span className="statusBox-txt">Business Phone</span>
                                            <span className={`Status-${memberDirectory?.isBusinessPhone === true ? "chk" : "cross"}`}>
                                                <img src={memberDirectory?.isBusinessPhone === true ? GreenCheckmark : PinkCross} className="" alt="MessageIc" />
                                                <div className="Status-txt">{memberDirectory?.isBusinessPhone === true ? "On" : "Off"}</div>
                                            </span>
                                        </div>
                                        <div className="member-directory-list statusBox">
                                            <span className="statusBox-txt">Business Address</span>
                                            <span className={`Status-${memberDirectory?.isBusinessAdd === true ? "chk" : "cross"}`}>
                                                <img src={memberDirectory?.isBusinessAdd === true ? GreenCheckmark : PinkCross} className="" alt="MessageIc" />
                                                <div className="Status-txt">{memberDirectory?.isBusinessAdd === true ? "On" : "Off"}</div>
                                            </span>
                                        </div>
                                        <div className="member-directory-list statusBox">
                                            <span className="statusBox-txt">Business Email</span>
                                            <span className={`Status-${memberDirectory?.isBusinessEmail === true ? "chk" : "cross"}`}>
                                                <img src={memberDirectory?.isBusinessEmail === true ? GreenCheckmark : PinkCross} className="" alt="MessageIc" />
                                                <div className="Status-txt">{memberDirectory?.isBusinessEmail === true ? "On" : "Off"}</div>
                                            </span>
                                        </div>
                                        {(isAdmin || isFacilitator || isCommunityDirectory) && (
                                            <>
                                                <div className="f-link">
                                                    <span className="statusBox-txt">Featured Link1:</span>
                                                    <a href={`/${memberDirectory?.featureLink1}`} target="_blank" rel="noreferrer">
                                                        <span>{memberDirectory?.featureLink1}</span>
                                                    </a>
                                                </div>
                                                <div className="f-link">
                                                    <span className="statusBox-txt">Featured Link2:</span>
                                                    <a href={`/${memberDirectory?.featureLink2}`} target="_blank" rel="noreferrer">
                                                        <span>{memberDirectory?.featureLink2}</span>
                                                    </a>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="p-4 text-center">
                        <Modal className="manageUserPopup" show={showDirectory} onHide={toggleShowDirectory} backdrop="static" size="lg" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Member Directory Settings</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Formik
                                    enableReinitialize={true}
                                    validationSchema={memberDirectoryValidationSchema}
                                    initialValues={{
                                        isDirectoryEnabled: memberDirectory?.isDirectoryEnabled === true ? "true" : memberDirectory?.isDirectoryEnabled === false ? "false" : null || "",
                                        isBusinessPhone: memberDirectory?.isBusinessPhone === true ? "true" : memberDirectory?.isBusinessPhone === false ? "false" : null || "",
                                        isBusinessAdd: memberDirectory?.isBusinessAdd === true ? "true" : memberDirectory?.isBusinessAdd === false ? "false" : null || "",
                                        isBusinessEmail: memberDirectory?.isBusinessEmail === true ? "true" : memberDirectory?.isBusinessEmail === false ? "false" : null || "",
                                        featureLink1: memberDirectory?.featureLink1 || "",
                                        featureLink2: memberDirectory?.featureLink2 || "",
                                    }}
                                    onSubmit={async (values, actions) => {
                                        const response = await callApi.post(buildURL(`${Urls.SetUsserMemberDirectory}`), {
                                            ...values,
                                            userId: userId,
                                            isDirectoryEnabled: values.isDirectoryEnabled === "true",
                                            isBusinessPhone: values.isBusinessPhone === "true",
                                            isBusinessEmail: values.isBusinessEmail === "true",
                                            isBusinessAdd: values.isBusinessAdd === "true",
                                        });

                                        if (!response.responseData) return toast.error("Something Went Wrong!");

                                        if (response.responseCode === 200) {
                                            toast.success("Member Directory Settings Updated Successfully!");
                                            toggleShowDirectory();
                                            setRefresh((prev) => prev + 1);
                                        } else toast.error("Something Went Wrong!");
                                    }}
                                >
                                    {(formik) => (
                                        <Form>
                                            <div className="contactMethod member-directory-popup">
                                                <h6>Member Directory:</h6>
                                                <div class="customRadioButton form-check-inline">
                                                    <Field type="radio" name="isDirectoryEnabled" value={"true"} id="actionRadio4" className="form-check-input" />
                                                    <label htmlFor="actionRadio1" className="form-check-label">
                                                        On
                                                    </label>
                                                </div>
                                                <div class="customRadioButton form-check-inline">
                                                    <Field type="radio" name="isDirectoryEnabled" value={"false"} id="DecisionRadio4" className="form-check-input" />
                                                    <label htmlFor="DecisionRadio1" className="form-check-label">
                                                        Off
                                                    </label>
                                                </div>
                                                <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"isDirectoryEnabled"} />{" "}
                                            </div>
                                            {formik.values.isDirectoryEnabled === "true" && (
                                                <>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="contactMethod member-directory-popup">
                                                                <h6>Business Phone:</h6>
                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessPhone" value={"true"} id="actionRadio1" className="form-check-input" />
                                                                    <label htmlFor="actionRadio1" className="form-check-label">
                                                                        On
                                                                    </label>
                                                                </div>
                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessPhone" value={"false"} id="DecisionRadio1" className="form-check-input" />
                                                                    <label htmlFor="DecisionRadio1" className="form-check-label">
                                                                        Off
                                                                    </label>
                                                                </div>
                                                                <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"isBusinessPhone"} />{" "}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="contactMethod member-directory-popup">
                                                                <h6>Business Address:</h6>

                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessAdd" value={"true"} id="actionRadio2" className="form-check-input" />
                                                                    <label htmlFor="actionRadio1" className="form-check-label">
                                                                        On
                                                                    </label>
                                                                </div>
                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessAdd" value={"false"} id="DecisionRadio2" className="form-check-input" />
                                                                    <label htmlFor="DecisionRadio1" className="form-check-label">
                                                                        Off
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12">
                                                            <div className="contactMethod member-directory-popup">
                                                                <h6>Business Email:</h6>

                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessEmail" value={"true"} id="actionRadio3" className="form-check-input" />
                                                                    <label htmlFor="actionRadio1" className="form-check-label">
                                                                        On
                                                                    </label>
                                                                </div>
                                                                <div class="customRadioButton form-check-inline">
                                                                    <Field type="radio" name="isBusinessEmail" value={"false"} id="DecisionRadio3" className="form-check-input" />
                                                                    <label htmlFor="DecisionRadio1" className="form-check-label">
                                                                        Off
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {(isAdmin || isFacilitator || isCommunityDirectory) && (
                                                        <>
                                                            <Row>
                                                                <Col md="12">
                                                                    <div className="contactMethod featured-link-panel">
                                                                        <h6>Featured Link 1:</h6>

                                                                        <div class="">
                                                                            <TextField type="text" name="featureLink1" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <div className="contactMethod featured-link-panel">
                                                                        <h6>Featured Link 2:</h6>

                                                                        <div class="">
                                                                            <TextField type="text" name="featureLink2" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            <Row>
                                                <Col lg="12" className="text-end pt-3">
                                                    <button className="btnPrimaryLink btn mx-3" type="button" onClick={toggleShowDirectory}>
                                                        CANCEL
                                                    </button>
                                                    <button type="submit" className="customPrimaryBtn btn" variant="primary">
                                                        SAVE & UPDATE
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                </Formik>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <div className="p-4 text-center">
                    <Modal className="manageUserPopup" show={show} onHide={bussinessModelClose} backdrop="static" size="lg" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Business Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                initialValues={{
                                    userGuid: userId,
                                    name: name || "",
                                    startYear: startYear || "",
                                    startMonth: startMonth || "",
                                    industryCode: industryTypeCode || "",
                                    businessTypeCode: businessTypeCode || "",
                                    website: website || "",
                                    serviceTypeCode: serviceTypeCode || "",
                                    logo: logo,
                                    briefDescription: briefDescription || "",
                                    background: background || "",
                                    gainByJoiningProgram: gainByJoiningProgram || "",
                                    tags: tags,
                                }}
                                onSubmit={async (values, actions) => {
                                    const formData = new FormData();
                                    formData.append("UserGuid", userId);
                                    formData.append("Name", values.name);
                                    formData.append("Logo", values.logo);
                                    formData.append("IndustryCode", values.industryCode);
                                    formData.append("ServiceTypeCode", values.serviceTypeCode);
                                    formData.append("BusinessTypeCode", values.businessTypeCode);
                                    formData.append("StartMonth", values.startMonth);
                                    formData.append("StartYear", values.startYear);
                                    formData.append("Website", values.website);
                                    formData.append("BriefDescription", values.briefDescription);
                                    formData.append("Background", values.background);
                                    formData.append("GainByJoiningProgram", values.gainByJoiningProgram);
                                    formData.append("Tags", tagsButton);
                                    const response = await callApi.post(buildURL(`${Urls.UserBusinessEdit}`), formData);

                                    if (!response.responseData) return;

                                    if (response.responseCode === 200) {
                                        toast.success("Success");
                                        bussinessModelClose();
                                        setRefresh((prev) => prev + 1);
                                        setUserDetails((prev) => ({ ...prev, businessName: values.name }));
                                    } else toast.error("Error");
                                }}
                            >
                                {(formik) => (
                                    <Form>
                                        <Row>
                                            <Col lg="3" md="4">
                                                <ImageInput value={logo} name="logo" onChange={formik.handleChange} />
                                            </Col>
                                            <Col lg="9" md="8">
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>BUSINESS Name</label>
                                                            <TextField maxLength={50} className="form-control" name="name" type="text" placeholder="Enter Business Name" />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>Business Established</label>
                                                            <Row>
                                                                <Col md="6">
                                                                    <Field className="form-select" as="select" name="startMonth">
                                                                        <option value="">Month</option>
                                                                        <option value="1">January</option>
                                                                        <option value="2">February</option>
                                                                        <option value="3">March</option>
                                                                        <option value="4">April</option>
                                                                        <option value="5">May</option>
                                                                        <option value="6">June</option>
                                                                        <option value="7">July</option>
                                                                        <option value="8">August</option>
                                                                        <option value="9">September</option>
                                                                        <option value="10">October</option>
                                                                        <option value="11">November</option>
                                                                        <option value="12">December</option>
                                                                    </Field>
                                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"startMonth"} />
                                                                </Col>
                                                                <Col md="6">
                                                                    <Field className="form-select" as="select" name="startYear">
                                                                        <option value="">Year</option>
                                                                        <option value="2022">2022</option>
                                                                        <option value="2021">2021</option>
                                                                        <option value="2020">2020</option>
                                                                        <option value="2019">2019</option>
                                                                        <option value="2018">2018</option>
                                                                        <option value="2017">2017</option>
                                                                        <option value="2016">2016</option>
                                                                        <option value="2015">2015</option>
                                                                        <option value="2014">2014</option>
                                                                        <option value="2013">2013</option>
                                                                        <option value="2012">2012</option>
                                                                        <option value="2011">2011</option>
                                                                        <option value="2010">2010</option>
                                                                        <option value="2009">2009</option>
                                                                        <option value="2008">2008</option>
                                                                        <option value="2007">2007</option>
                                                                        <option value="2006">2006</option>
                                                                        <option value="2005">2005</option>
                                                                        <option value="2004">2004</option>
                                                                        <option value="2003">2003</option>
                                                                        <option value="2002">2002</option>
                                                                        <option value="2001">2001</option>
                                                                        <option value="2000">2000</option>
                                                                        <option value="1999">1999</option>
                                                                        <option value="1998">1998</option>
                                                                        <option value="1997">1997</option>
                                                                        <option value="1996">1996</option>
                                                                        <option value="1995">1995</option>
                                                                        <option value="1994">1994</option>
                                                                        <option value="1993">1993</option>
                                                                        <option value="1992">1992</option>
                                                                        <option value="1991">1991</option>
                                                                        <option value="1990">1990</option>
                                                                        <option value="1989">1989</option>
                                                                        <option value="1988">1988</option>
                                                                        <option value="1987">1987</option>
                                                                        <option value="1986">1986</option>
                                                                        <option value="1985">1985</option>
                                                                        <option value="1984">1984</option>
                                                                        <option value="1983">1983</option>
                                                                        <option value="1982">1982</option>
                                                                        <option value="1981">1981</option>
                                                                        <option value="1980">1980</option>
                                                                    </Field>
                                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"startYear"} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>INDUSTRY</label>
                                                            <Field className="form-select" as="select" name="industryCode">
                                                                <option value="">Please select</option>
                                                                {industryType?.map((i) => (
                                                                    <option value={i.code}>{i.name}</option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage style={{ color: "red" }} component={"div"} name={"industryCode"} />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>Business Type</label>
                                                            <Field className="form-select" as="select" name="businessTypeCode">
                                                                <option value="">Please select</option>
                                                                {businessType?.map((i) => (
                                                                    <option value={i.code}>{i.name}</option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage style={{ color: "red" }} component={"div"} name={"businessTypeCode"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>Business Website</label>
                                                            <Field type="text" className="form-control" name="website" placeholder="Enter Business Website Url" />
                                                            <ErrorMessage style={{ color: "red" }} component={"div"} name={"website"} />
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>Offering</label>
                                                            <Field className="form-select" as="select" name="serviceTypeCode">
                                                                <option value="">Please select</option>
                                                                {serviceOfferingType?.map((i) => (
                                                                    <option value={i.code}>{i.name}</option>
                                                                ))}
                                                            </Field>
                                                            <ErrorMessage style={{ color: "red" }} component={"div"} name={"serviceTypeCode"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col md="12">
                                                <div className="form-group customInput">
                                                    <label>Brief Description</label>

                                                    <Field className="form-control" placeholder="Brief Description" maxLength={150} as="textarea" style={{ height: "100px" }} name="briefDescription" />
                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"briefDescription"} />
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group customInput">
                                                    <label>Business Description</label>
                                                    <span> {`(This description will appear on your Member Profile and in our Member Directory.)`}</span>
                                                    <Field className="form-control" placeholder="Business Description" maxLength={200} as="textarea" style={{ height: "100px" }} name="background" />
                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"background"} />
                                                </div>
                                            </Col>
                                            {userBusinessDetails.gainByJoiningProgram ? (
                                                ""
                                            ) : (
                                                <Col md="12">
                                                    <div className="form-group customInput">
                                                        <label>What do you hope to gain by joining the peer-to -peer program?</label>
                                                        <Field
                                                            className="form-control"
                                                            placeholder="What do you hope to gain by joining the peer-to -peer program?"
                                                            as="textarea"
                                                            style={{ height: "100px" }}
                                                            name="gainByJoiningProgram"
                                                        />
                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"gainByJoiningProgram"} />
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group customInput">
                                                    <label>BUSINESS TAGS</label>
                                                    <ReactTagInput tags={tagsButton} onChange={onChangeTags} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" className="text-end pt-3">
                                                <button className="btnPrimaryLink btn mx-3" type="button" onClick={bussinessModelClose}>
                                                    CANCEL
                                                </button>
                                                <button type="submit" className="customPrimaryBtn btn" variant="primary">
                                                    SAVE & UPDATE
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </>
    );
}
