import { useMemo, useState, useCallback } from "react";
import ManagementTable from "../../components/ManagementTable";
import { eventAddFormFields, eventInitialForm } from "../Events/DATA";
import { peerGroupEventHistorFields, peerGroupPastEventApis, peerGroupUpcomingEventApis, peerGroupUpcomingEventFields, peerGroupUpcomingEventInitialSort } from "./DATA";

export default function PeerGroupEvents({ peerGroupDetails }) {
    const { peerGroupId, communityCode, communityName, peerGroupName } = peerGroupDetails;
    const [state, setState] = useState({
        canAdd: false,
    });

    const runAfterGetAll = useCallback((response) => {
        setState((prev) => ({ ...prev, canAdd: !!response.canAdd }));
    }, []);
    const upcomingEventApis = useMemo(
        () => ({ ...peerGroupUpcomingEventApis, getAll: { ...peerGroupUpcomingEventApis.getAll, body: { ...peerGroupUpcomingEventApis.getAll.body, peerGroupId } } }),
        [peerGroupId]
    );
    const pastEventApis = useMemo(() => ({ ...peerGroupPastEventApis, getAll: { ...peerGroupPastEventApis.getAll, body: { peerGroupId } } }), [peerGroupId]);
    const formFields = useMemo(
        () =>
            eventAddFormFields.map((f) =>
                f.nameForm === "eventTypeCode"
                    ? {
                        ...f,
                        dependencies: undefined,
                        getOptions: async () => {
                            const response = await f.getOptions();
                            return {
                                data: response.data.filter((o) => o.label === "Peer Group" || o.label === "One on One"),
                            };
                        },
                        getInitialOption: (response) => response.data.find((opt) => opt.label === "Peer Group"),
                        disableEmptyOption: true,
                    }
                    : f.nameForm === "communityCode" || f.nameForm === "peerGroupCode"
                        ? { ...f, readOnly: true, getInitialOption: undefined, getOptions: undefined }
                        : { ...f }
            ),
        []
    );
    const initialForm = useMemo(
        () => ({ ...eventInitialForm, peerGroupCode: peerGroupId, peerGroupNames: peerGroupName, communityCode: communityCode, communityNames: communityName }),
        [communityCode, communityName, peerGroupId, peerGroupName]
    );

    return (
        <div className="row mx-0">
            <div className="col-lg-12 col-md-12 col-12 my-3">
                <ManagementTable
                    title="Event"
                    tableTitle={"Upcoming Events"}
                    customAddButtonTitle="Add Event"
                    initialSort={peerGroupUpcomingEventInitialSort}
                    initialForm={initialForm}
                    formFields={formFields}
                    apis={upcomingEventApis}
                    fields={peerGroupUpcomingEventFields}
                    hideAddButton={!state.canAdd}
                    runAfterGetAll={runAfterGetAll}
                    refreshAfterSubmit
                    hideActionsColumn
                    localSort
                />
            </div>
            <div className="col-lg-12 col-md-12 col-12 my-3">
                <ManagementTable title="Event History" tableTitle={"Event History"} apis={pastEventApis} fields={peerGroupEventHistorFields} hideActionsColumn hideAddButton localSort />
            </div>
        </div>
    );
}
