import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const getUserDashboardPendingAttendance = async (filters) => {
    const { responseData } = await callApi.get(buildURL(Urls.GetUserDashboardPendingAttendance + filters.userId), {
        headers: { ...filters, noOfDays: 90 },
    });

    return {
        data: Array.isArray(responseData?.searchResult)
            ? responseData?.searchResult.map((u) => ({
                  date: u.eventDate?.slice(0, 10) || "",
                  eventType: u.eventType || "",
                  totalMembers: u.totalMembers || "",
                  communityName: u.communityName || "",
                  eventId: u.eventId || "",
                  peerGroupCode: u.peerGroupCode || "",
                  dayOfWeekName: u.dayOfWeekName || "",
                  weekChoiceText: u.weekChoiceText || "",
                  startTime: u.startTime || "",
                  timeZoneName: u.timeZoneName || "",
                  endTime: u.endTime || "",
                  userId: u.userId || "",
              }))
            : [],
        totalRecords: responseData?.totalRecords || responseData?.searchResult?.length || 0,
    };
};
