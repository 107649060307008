import { useCallback } from "react";
import PropTypes from "prop-types";
import ReactTagInput from "@pathofdev/react-tag-input";

const defaultTags = [];

export default function TagsInput({ name, value, onChange, readOnly, maxLength, editable = true }) {
    const handleChange = useCallback((tags) => onChange({ target: { name, value: tags.map((tag) => tag.trim()) } }), [name, onChange]);
    const validator = useCallback((tag) => !!tag.trim() && !value?.find((t) => t.toLowerCase() === tag.trim().toLowerCase()), [value]);

    return <ReactTagInput tags={value || defaultTags} onChange={handleChange} validator={validator} maxTags={maxLength} readOnly={readOnly} editable={editable} removeOnBackspace />;
}

TagsInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    editable: PropTypes.bool,
    maxLength: PropTypes.number,
};
