import { Modal } from "react-bootstrap";

import PageLoader from "../PageLoader";

import "./LoadingModal.css";

export default function LoadingModal({ show }) {
    return (
        <Modal className="loading-modal" show={show} centered>
            <PageLoader />
        </Modal>
    );
}
