import Modal from "../Modal";

export default function ConfirmationModal({ show, modalTitle, confirmButtonTitle = "Yes", cancelButtonTitle = "No", confirmationMessage = "Are you sure?", onCancel, onConfirm }) {
    return (
        <Modal title={modalTitle} show={show} onHide={onCancel} size="md">
            <div className="text-center p-3 my-3">
                <h4>{confirmationMessage}</h4>
            </div>
            <div className="d-flex justify-content-center my-3">
                <div className="mx-2">
                    <button className="btnPrimaryOutline btn btn-outline-primary" type="button" onClick={onCancel}>
                        {cancelButtonTitle}
                    </button>
                </div>
                <div className="mx-2">
                    <button className="customPrimaryBtn btn" type="button" onClick={onConfirm}>
                        {confirmButtonTitle}
                    </button>
                </div>
            </div>
        </Modal>
    );
}
