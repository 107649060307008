import React, { useState } from "react";

import RequestLink from "./components/RequestLink";
import SentLink from "./components/SentLink";

const ForgotPassword = () => {
  const [resetLink, setResetLink] = useState(false);
  const linkStatus = (status) => {
    if (status) setResetLink(true);
  };
  return !resetLink ? <RequestLink linkStatus={linkStatus} /> : <SentLink />;
};

export default ForgotPassword;
