import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { getPeerGroupOptionsByCommunity } from "../../services/peer-groups/peerGroupServices";
import { addEventPermission, deleteEventPermission } from "../../services/events/eventPermissionServices";
import { throttle } from "../../helpers";

export const eventPermissionFields = [
  {
    name: "communityName",
    nameForm: "communityCode",
    title: "Community",
    inputType: EFieldInputTypes.select,
    dependencies: [
      {
        name: "peerGroupName",
        nameForm: "peerGroupCode",
        initialValue: "",
        getOptions: throttle(async (form) => {
          if (!form.communityCode) return { data: [] };

          const response = await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode });

          return response;
        }, 200),
      },
    ],
    getOptions: getCommunityOptions,
    col: 6,
    placeholder: "ALL",
    cell: (name, data) => <Link to={`/communities/${data.communityCode}`}>{name}</Link>,
  },
  {
    name: "peerGroupName",
    nameForm: "peerGroupCode",
    title: "Peer Group",
    inputType: EFieldInputTypes.select,
    options: [],
    col: 6,
    placeholder: "ALL",
    cell: (name, data) => <Link to={`/peer-groups/${data.peerGroupCode}`}>{name}</Link>,
  },
];

export const eventPermissionApis = {
  delete: {
    api: deleteEventPermission,
  },
  create: {
    api: addEventPermission,
  },
};
