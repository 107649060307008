import { formToFormData } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const funderPrimaryKey = "funderId";
export const communityFunderPrimaryKey = "funderCummunityId";

export const getFunders = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchFunder), {
        headers: filters,
    });
    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const createUpdateFunders = async ({ ...form }) => {
    const { name, code, email, notes, startDate, endDate, isWhiteLabel, logo1, logo2, emailSignature1, emailSignature2 } = form;
    const payload = {
        name,
        code,
        email,
        notes,
        startDate,
        isWhiteLabel: isWhiteLabel === "ON",
        logo1,
        logo2,
        emailSignature1,
        emailSignature2,
    };
    if (endDate) {
        payload.endDate = endDate;
    }
    if (form[funderPrimaryKey]) {
        payload.funderId = form.funderId;
    }
    const response = await callApi.post(buildURL(form[funderPrimaryKey] ? Urls.UpdateFunders : Urls.AddFunders), formToFormData(payload));
    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.funderId } } : response;
};

export const getFunderById = async (funderId) => {
    const response = await callApi.get(buildURL(Urls.GetFundersById), { headers: { funderId } });
    const data = response.responseData;
    return {
        data:
            response.responseCode !== 200 || !data
                ? null
                : {
                      ...data,
                  },
    };
};

export const getFunderCommunity = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchFunderCommunity), {
        headers: filters,
    });
    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const createUpdateFundersCommunity = async ({ ...form }) => {
    const { communityCode, startDate, endDate, funderId } = form;
    const payload = {
        communityCode,
        startDate,
    };
    if (endDate) {
        payload.endDate = endDate;
    }
    if (form[communityFunderPrimaryKey]) {
        payload.communityFunderId = form.funderCummunityId;
    } else {
        payload.funderId = funderId;
    }

    const response = await callApi.post(buildURL(form[communityFunderPrimaryKey] ? Urls.UpdateFundersCommunity : Urls.AddFundersCommunity), formToFormData(payload));
    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.funderId } } : response;
};

export const getFunderCommunityById = async (funderCommunityId) => {
    const response = await callApi.get(buildURL(Urls.getFundersCommunityById), { headers: { funderCommunityId } });
    const data = response.responseData;
    return {
        data:
            response.responseCode !== 200 || !data
                ? null
                : {
                      ...data,
                  },
    };
};

export const deleteFunderCommunity = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteFunderCommunity), {
        communityFunderId: formData.funderCummunityId,
    });
    return response;
};

export const getFunderByCommunityCode = async (code = null) => {
    let response = null;
    if (code === null) {
        response = await callApi.get(buildURL(Urls.GetFundersByCommunity));
    } else {
        response = await callApi.get(buildURL(Urls.GetFundersByCommunity), { headers: { communityCode: code } });
    }
    const options = {
        data: [],
        _fetchedAt: Date.now(),
        _hasError: false,
    };
    if (response.responseData && response.responseData.length) {
        options.data = response.responseData.map((c) => ({ label: c.name, value: c.id?.trim() || "", roleCode: c.id?.trim() }));
    }
    return options ?? [];
};
