import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi } from "../../helpers/api-urls";

export const getCommunityUpcomingEvents = async ({ communityId, ...filters }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };

  const { responseData } = await callApi.get(buildURL("/Community/UpcomingEvents"), {
    headers: { ...filters, communityCode: communityId },
  });

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((u) => ({
      eventDate: u.eventDate?.slice(0, 10) || "",
      eventStartTime: u.startTime || "",
      eventEndTime: u.endTime || "",
      eventTimeZoneName: u.timezoneName || "",
      eventTypeName: u.eventTypeName || "",
      eventFormatName: u.formatTypeName || "",
      eventStatusName: u.eventStatusName || "",
      eventFocusName: u.focusName || "",
      eventRsvp: u.rsvpData || "",
      eventId: u.eventId || "",
      communityId,
    }));
  }

  return response;
};
export const getCommunityPastEvents = async ({ communityId, ...filters }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };

  const { responseData } = await callApi.get(buildURL("/Community/PastEvents"), {
    headers: { ...filters, communityCode: communityId },
  });

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((u) => ({
      eventDate: u.eventDate?.slice(0, 10) || "",
      eventStartTime: formatTimeOnly(u.startTime),
      eventEndTime: formatTimeOnly(u.endTime),
      eventTimeZoneName: u.timezoneName || "",
      eventTypeName: u.eventTypeName || "",
      eventFormatName: u.formatTypeName || "",
      eventStatusName: u.eventStatusName || "",
      eventFocusName: u.focusName || "",
      eventRsvp: u.rsvpData || "",
      eventWentCount: u.wentCount || "",
      eventId: u.eventId || "",
      communityId,
    }));
  }

  return response;
};
