import moment from "moment";
import { Link } from "react-router-dom";
import { EFieldInputTypes } from "../../components/FormField";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createUpdateFundersCommunity, deleteFunderCommunity, getFunderCommunity } from "../../services/funder/funderService";

export const initialFunderDetails = {
    canEdit: true,
    canAdd: true,
    canDelete: true,
    funderId: "",
    name: "",
    code: "",
    notes: "",
    email: "",
    startDate: "",
    endDate: "",
    isWhiteLabel: false,
    logo1: "",
    logo2: "",
    emailSignature1: "",
    emailSignature2: "",
    createdDate: "",
    createdBy: "",
    modifiedDate: "",
    modifiedBy: "",
};
export const communityFields = [
    {
        name: "communityCode",
        col: 12,
        title: "Community",
        getOptions: getCommunityOptions,
        getInitialOption: undefined,
        readOnly: false,
        disableActiveField: false,
        noEmptyOption: false,
        readOnlyInEdit: true,
        required: true,
        inputType: EFieldInputTypes.select,
        cell: (communityCode, data) => (
            <Link style={{ color: "#00adba" }} to={`/communities/${communityCode}`}>
                {data.community}
            </Link>
        ),
    },
    {
        name: "startDate",
        required: true,
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        placeholder: "Start Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
        cell: (value) => (value ? <span>{moment(value).format("MM/DD/YYYY")}</span> : ""),
    },
    {
        name: "endDate",
        title: "End Date",
        inputType: EFieldInputTypes.date,
        placeholder: "End Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
        cell: (value) => (value ? <span>{moment(value).format("MM/DD/YYYY")}</span> : ""),
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
];

export const communityApis = {
    getAll: {
        api: getFunderCommunity,
    },
    create: {
        api: createUpdateFundersCommunity,
    },
    delete: {
        api: deleteFunderCommunity,
    },
};
