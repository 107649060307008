import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Modal, Row } from "react-bootstrap";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import EditIcon from "../../components/EditIcon";
import ManagementTable from "../../components/ManagementTable";
import Tooltip from "../../components/Tooltip";
import { formatDateTime } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { useToggle } from "../../helpers/hooks";
import {
    curriculumAttachmentFields,
    curriculumAttachmentFormFields,
    curriculumAttachmentInitialForm,
    curriculumFeedbackFields,
    curriculumFeedbackFormFields,
    curriculumViewApis,
    curriculumViewFeedbackApis,
} from "./DATA";
import { TextField } from "../Users/TextField";

import "./CurriculumView.css";
import { curriculumAttachmentPrimaryKey, curriculumFeedbackPrimaryKey } from "../../services/curriculum/curriculum.view.services";

const curriculumValidationSchema = Yup.object().shape({
    isActive: Yup.string().required("Required Field"),
    title: Yup.string().required("Required Field"),
    order: Yup.string().required("Required Field"),
    monthlyFormFocusCode: Yup.string().required("Required Field"),
});
const CurriculumView = () => {
    const [show, toggleShow] = useToggle(false);
    const [formFocusAreaOptions, setFormFocusAreaOptions] = useState([]);
    const [data, setData] = useState({});
    const [permissionsAttachment, setPermissionsAttachment] = useState({ canAdd: false, canEdit: false, canDelete: false, _fetched: false });
    const [permissionsFeedback, setPermissionsFeedback] = useState({ canAdd: false, canEdit: false, canDelete: false, _fetched: false });
    const { curriculumId } = useParams();
    const [refresh, setRefresh] = useState(0);

    const curriculumAttachmentsApis = useMemo(
        () => ({ ...curriculumViewApis, getAll: { ...curriculumViewApis.getAll, body: { curriculumId } }, create: { ...curriculumViewApis.create, body: { curriculumId } } }),
        [curriculumId]
    );
    const curriculumFeedbackApis = useMemo(
        () => ({ ...curriculumViewFeedbackApis, getAll: { ...curriculumViewFeedbackApis.getAll, body: { curriculumId } }, create: { ...curriculumViewFeedbackApis.create, body: { curriculumId } } }),
        [curriculumId]
    );
    const runAfterGetAllAttachment = (response) => {
        // eslint-disable-next-line dot-notation
        setPermissionsAttachment((prev) => (prev["_fetched"] ? prev : { ...prev, canAdd: response.canAdd, canDelete: response.canDelete, _fetched: true }));
    };
    const runAfterGetAllFeedback = (response) => {
        // eslint-disable-next-line dot-notation
        setPermissionsFeedback((prev) => (prev["_fetched"] ? prev : { ...prev, canAdd: response.canAdd, canDelete: response.canDelete, _fetched: true }));
    };

    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetCurriculum}`), { headers: { curriculumId } });

            if (!response.responseData) return;

            setData(response.responseData);
        })();
    }, [curriculumId, refresh]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetFocusAreaOptions}`));

            if (!response.responseData) return;
            setFormFocusAreaOptions(response.responseData);
        })();
    }, []);

    return (
        <div className="py-5">
            <div className="card mb-4">
                <div className="section-header mb-5">
                    <h1>Curriculum</h1>
                    <div className="right-sec">
                        <p>
                            {`Created:
                             ${formatDateTime(data?.createdDate)}
                              by ${data?.createdBy || " - "}
                              ${
                                  !data?.modifiedBy?.trim()
                                      ? ""
                                      : ` |
                                 Last Updated: 
                                 ${formatDateTime(data.modifiedDate)} by ${data.modifiedBy}`
                              }`}
                        </p>
                        {data?.canEdit && (
                            <Tooltip text="Edit">
                                <EditIcon onClick={toggleShow} />
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">Title</h3>
                            <p className="content-txt mt-3 ml-10">{data?.title}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">Focus</h3>
                            <p className="content-txt mt-3 ml-10">{data?.monthlyFormFocus}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">Satus</h3>
                            <p className="content-txt mt-3 ml-10">{data?.isActive === true ? "Active" : "Inactive"}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">Type</h3>
                            <div className="type-check-box">
                                <p className="content-txt mt-3">
                                    <span className="mx-2">
                                        <input type="checkbox" className="form-check-input" checked={data?.awareness} />
                                        <label className="mx-2">Awareness</label>
                                    </span>
                                </p>
                                <p className="content-txt mt-3">
                                    <span className="mx-2">
                                        <input type="checkbox" className="form-check-input" checked={data?.decision} />
                                        <label className="mx-2">Decision</label>
                                    </span>
                                </p>
                                <p className="content-txt mt-3">
                                    <span className="mx-2">
                                        <input type="checkbox" className="form-check-input" checked={data?.action} />
                                        <label className="mx-2">Action</label>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">brief description</h3>
                            <p className="content-txt mt-3 ml-10">{data?.description}</p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div class="descriptionContet">
                            <h3 className="contentHeading">order</h3>
                            <p className="content-txt mt-3 ml-10">{data?.order}</p>
                        </div>
                    </div>
                </div>
                {show && (
                    <Modal className="manageUserPopup" show={show} onHide={toggleShow} size="lg" backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Formik
                                enableReinitialize={true}
                                validationSchema={curriculumValidationSchema}
                                initialValues={{
                                    curriculumId: curriculumId,
                                    title: data?.title || "",
                                    monthlyFormFocusCode: data?.monthlyFormFocusCode || "",
                                    isActive: data?.isActive === true ? "active" : "inactive" || "",
                                    awareness: data?.awareness === true,
                                    decision: data?.decision === true,
                                    action: data?.action === true,
                                    description: data?.description || "",
                                    order: data?.order || "0",
                                }}
                                onSubmit={async (values) => {
                                    const response = await callApi.post(buildURL(`${Urls.UpdateCurriculum}`), { ...values, isActive: values.isActive === "active" });

                                    if (!response.responseData) return;
                                    if (response.responseCode === 200) {
                                        toast.success("Curriculum Details Updated Successfully!");
                                        toggleShow();
                                        setRefresh((prev) => prev + 1);
                                    } else toast.error("Something Went Wrong");
                                }}
                            >
                                {({ errors, handleChange, isValid, isSubmitting, values, formik }) => (
                                    <Form>
                                        <Col lg="12" md="12">
                                            <Row>
                                                <Col md="6">
                                                    <div className="form-group customInput">
                                                        <label>Status</label>
                                                        <Field className="form-select" as="select" name="isActive">
                                                            <option value="">Select Status</option>
                                                            <option value="active">Active</option>
                                                            <option value="inactive">Inactive</option>
                                                        </Field>{" "}
                                                        <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"isActive"} />{" "}
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="form-group customInput">
                                                        <label>Focus</label>
                                                        <Field className="form-select" as="select" name="monthlyFormFocusCode">
                                                            <option value="">Select Focus</option>
                                                            {formFocusAreaOptions.map((option) => (
                                                                <option value={option.code}>{option.name}</option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"monthlyFormFocusCode"} />{" "}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="9">
                                                    <div className="form-group customInput">
                                                        <label>Title</label>
                                                        <TextField className="form-control" min="0" name="title" type="text" placeholder="Enter Title" />
                                                    </div>
                                                </Col>
                                                <Col md="3">
                                                    <div className="form-group customInput">
                                                        <label>Order</label>
                                                        <TextField className="form-control" min="0" name="order" type="text" placeholder="Enter Order" />
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="form-group customInput">
                                                        <label>Type</label>
                                                        <p className="content-txt mt-3 ml-10">
                                                            <span className="mx-2">
                                                                <Field
                                                                    type="checkbox"
                                                                    name="awareness"
                                                                    className="form-check-input"
                                                                    // className={"form-check-input " + (errors.acceptTerms && touched.acceptTerms ? " is-invalid" : "")}
                                                                />
                                                                <label htmlFor="awareness" className="form-check-label">
                                                                    Awareness
                                                                </label>
                                                                <ErrorMessage name="awareness" component="div" className="invalid-feedback" />
                                                            </span>
                                                            <span className="mx-2">
                                                                <Field
                                                                    type="checkbox"
                                                                    name="decision"
                                                                    className="form-check-input"
                                                                    // className={"form-check-input " + (errors.acceptTerms && touched.acceptTerms ? " is-invalid" : "")}
                                                                />
                                                                <label htmlFor="decision" className="form-check-label">
                                                                    Decision
                                                                </label>
                                                                <ErrorMessage name="decision" component="div" className="invalid-feedback" />
                                                            </span>
                                                            <span className="mx-2">
                                                                <Field
                                                                    type="checkbox"
                                                                    name="action"
                                                                    className="form-check-input"
                                                                    // className={"form-check-input " + (errors.acceptTerms && touched.acceptTerms ? " is-invalid" : "")}
                                                                />
                                                                <label htmlFor="action" className="form-check-label">
                                                                    Action
                                                                </label>
                                                                <ErrorMessage name="action" component="div" className="invalid-feedback" />
                                                            </span>
                                                        </p>{" "}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div className="form-group customInput">
                                                        <label>Brief Description</label>
                                                        <TextField className="form-control" min="0" name="description" type="text" placeholder="Enter Brief Description" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Row>
                                            <Col lg="12" className="text-end pt-3">
                                                <button className="btnPrimaryLink btn mx-3" type="button" onClick={toggleShow}>
                                                    CANCEL
                                                </button>
                                                <button disabled={isSubmitting} type="submit" className="customPrimaryBtn btn" variant="primary">
                                                    SAVE & UPDATE
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
            <ManagementTable
                title="Attachment"
                tableTitle="Attachments"
                fields={curriculumAttachmentFields}
                initialForm={curriculumAttachmentInitialForm}
                formFields={curriculumAttachmentFormFields}
                apis={curriculumAttachmentsApis}
                primaryKey={curriculumAttachmentPrimaryKey}
                runAfterGetAll={runAfterGetAllAttachment}
                hideAddButton={!permissionsAttachment.canAdd}
                hideActionsColumn={!permissionsAttachment.canDelete}
                formSize="md"
                hideViewButton
                hideEditButton
                refreshAfterSubmit
                localSort
            />
            <div className="my-4">
                <ManagementTable
                    title="Feedback"
                    tableTitle="Feedback"
                    fields={curriculumFeedbackFields}
                    formFields={curriculumFeedbackFormFields}
                    apis={curriculumFeedbackApis}
                    primaryKey={curriculumFeedbackPrimaryKey}
                    runAfterGetAll={runAfterGetAllFeedback}
                    hideAddButton={!permissionsFeedback.canAdd}
                    hideActionsColumn={!permissionsFeedback.canDelete}
                    formSize="md"
                    hideViewButton
                    hideEditButton
                    refreshAfterSubmit
                    localSort
                />
            </div>
        </div>
    );
};

export default CurriculumView;
