/* eslint-disable no-underscore-dangle */
import { Suspense, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Navigate, NavLink, Route, Routes, useParams } from "react-router-dom";

import { createStructuredSelector } from "reselect";
import DetailsPageContainer from "../../components/DetailsPageContainer";
import ErrorBoundary from "../../components/ErrorBoundary";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
    selectPermissionsEventAttendeesMenu,
    selectPermissionsEventCurriculumMenu,
    selectPermissionsEventDetailsMenu,
    selectPermissionsEventDocumentsMenu,
    selectPermissionsEventNotesMenu,
    selectPermissionsEventPermissionsMenu,
    selectPermissionsEventStaffMenu,
    selectPermissionsFetched,
    selectPermissionsSidebarEventsMenu,
    selectPermissionsSidebarFunderMenu
} from "../../redux/permissionReducer/permissionSelectors";
import { selectUserId } from "../../redux/userReducer/userSelectors";
import { communityPrimaryKey } from "../../services/communities/communityServices";
import { getCountiesById } from "../../services/counties/countiesService";
import NotFoundPage from "../NotFoundPage";
import { initialCountiesDetails } from "./DATA";
import Details from "./Details.component";

const CountiesDetails = () => {
    const { countyId } = useParams();
    const [countiesDetails, setCountiesDetails] = useState(() => ({
        ...initialCountiesDetails,
        _loading: true,
        countyId,
    }));
    const {
        permissionsFetched,
        sidebarFunderMenuPermission
    } = useSelector(
        createStructuredSelector({
            sidebarEventsMenuPermission: selectPermissionsSidebarEventsMenu,
            eventDetailsMenuPermission: selectPermissionsEventDetailsMenu,
            eventAttendeesMenuPermission: selectPermissionsEventAttendeesMenu,
            eventCurriculumMenuPermission: selectPermissionsEventCurriculumMenu,
            eventNotesMenuPermission: selectPermissionsEventNotesMenu,
            eventDocumentsMenuPermission: selectPermissionsEventDocumentsMenu,
            eventStaffMenuPermission: selectPermissionsEventStaffMenu,
            eventPermissionsMenuPermission: selectPermissionsEventPermissionsMenu,
            permissionsFetched: selectPermissionsFetched,
            myUserId: selectUserId,
            sidebarFunderMenuPermission: selectPermissionsSidebarFunderMenu,
        })
    );
    const expandedDetails = false;
    useEffect(() => {
        (async () => {
            const response = await getCountiesById(countyId);
            setCountiesDetails((prev) => ({
                ...prev,
                ...(response.data || {}),
                isActive: String(response.data.isActive),
                [communityPrimaryKey]: countyId,
                _loading: false,
            }));
        })();
    }, [countyId]);
    return (
        <DetailsPageContainer
            breadCrumbTitle={"County"}
            breadCrumbLink={"/county"}
            expandedDetails={expandedDetails}
            image={null}
            hideBreadcrumb={!sidebarFunderMenuPermission}
            nameLetters={countiesDetails.name[0] || " "}
            detailsChildren={
                <>
                    <h4
                        className="overflow-text"
                        style={expandedDetails ? undefined : { maxWidth: 320 }}
                        title={`${countiesDetails.name | ""}`}
                    >{`${countiesDetails.name || ""}`}</h4>

                </>
            }
            navLinkChildren={
                countiesDetails._loading ? null : (
                    <>
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`details`}>
                                Details
                            </Nav.Link>
                        </Nav.Item>
                    </>
                )
            }
        >
            <ErrorBoundary>
                {countiesDetails._loading || !permissionsFetched ? (
                    <div className="h-100 pt-5">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <Suspense
                        fallback={
                            <div className="h-100 pt-5">
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Routes>
                            <Route
                                path="/details"
                                element={<Details countiesDetails={countiesDetails} setCountiesDetails={setCountiesDetails} />}
                            />
                            <Route path="/" element={<Navigate to={"details"} replace />} />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                )}
            </ErrorBoundary>
        </DetailsPageContainer>
    );
};

export default CountiesDetails;
