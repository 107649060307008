import LogoIcon from "../../assets/images/logo-icon-large.svg";
import Logo from "../../assets/images/logo-white-green.svg";
const LeftBar = ({ tab, setTab, activeClass, logo, isFunderLogo }) => {
    return (
        <>
            {tab === 0 ? (
                ""
            ) : (
                <div>
                    <div className="signUpLeftMenuSec">
                        <div className="logoSec">
                            <a href={logo ?? "https://www.entrepreneursforever.org/"} target={"_blank"} rel="noreferrer">
                                <img src={logo ?? Logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="leftSecMenu">
                            <ul>
                                <li className={tab === 1 ? "active" : activeClass === 1 || activeClass === 2 ? "stepDone" : undefined}>
                                    <span>Contact Info</span>
                                    {/* <span onClick={() => setTab(1)}>Contact Details</span> */}
                                </li>
                                <li className={tab === 2 ? "active" : activeClass === 2 ? "stepDone" : undefined}>
                                    {/* <span onClick={() => setTab(2)}>Business Details</span> */}
                                    <span>Your Business</span>
                                </li>
                                <li className={tab === 3 || tab === 5 ? "active" : tab === 6 || tab === 7 || tab === 8 || tab === 4 ? "stepDone" : undefined}>
                                    <span>Last Step</span>
                                    {/* <span onClick={() => setTab(1)}>Finalize Application</span> */}
                                </li>
                            </ul>
                        </div>
                        <div className="leftInfoBtmSec">
                            {tab === 2 ? (
                                <h4>
                                    <span className="text-green">You’re so close!</span> Only one more quick step to go.
                                </h4>
                            ) : tab === 3 ? (
                                <h4>
                                    <span className="text-green">Last step for you.</span> And a big step forward!
                                </h4>
                            ) : tab === 5 ? (
                                <h4>
                                    <span className="text-green">You did it!</span> <br /> We knew you could!
                                </h4>
                            ) : (
                                <h4>
                                    <span className="text-green">Success is a journey of many steps.</span> This application is just three!
                                </h4>
                            )}
                        </div>
                        {!isFunderLogo && (
                            <div className="logoBtmSec">
                                <img src={LogoIcon} alt="LogoIcon" />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default LeftBar;
