import { useEffect, useMemo, useState, useCallback, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import moment from "moment";
import ReactDateTimePicker from "react-datetime";

import "react-datetime/css/react-datetime.css";
import "./DateTimePicker.css";

import { dateTimeFormat, formatDateOnly, formatTimeOnly, isOnMobileDevice } from "../../helpers";
import { useToggle } from "../../helpers/hooks";

const initialTime = "-- : --  --";
const initialDate = "mm/dd/yyyy";

export default function DateTimePicker({ value, name, onChange, isValidDate, asSearchField, type = "date", required = false, readOnly, error }) {
    const ref = useRef(null);
    const initialValue = useMemo(() => (type === "date-time" ? `${initialDate} ${initialTime}` : type === "date" ? initialDate : ""), [type]);
    const [inputValue, setInputValue] = useState(() => initialValue);
    const [open, toggleOpen, setOpen] = useToggle(false);

    const getInputValue = useCallback(
        (value) => (value ? (type === "date-time" ? moment(value).format(dateTimeFormat) : type === "date" ? formatDateOnly(value) : value) : initialValue),
        [type, initialValue]
    );
    const onOpen = useCallback(() => !readOnly && setOpen(true), [setOpen, readOnly]);
    const onClose = useCallback(() => setOpen(false), [setOpen]);

    const onClear = () => {
        if (!onChange) return setInputValue(initialValue);

        onChange({ target: { name, value: "" } });
    };

    const onChangeDateTime = (moment) => {
        const value = typeof moment === "string" ? moment : moment.format(type === "date-time" ? "YYYY-MM-DD HH:mm:ss" : type === "date" ? "YYYY-MM-DD" : "HH:mm");

        // if (!onChange) return
        setInputValue(getInputValue(value));

        onChange({ target: { name, value } });
    };

    const inputProps = useMemo(() => {
        const onMobile = isOnMobileDevice();
        return {
            value: !(type === "time" && onMobile) ? inputValue : inputValue ? formatTimeOnly(inputValue) : initialTime,
            readOnly: type !== "time" || onMobile,
            style: type === "time" && onMobile ? { paddingLeft: 8 } : undefined,
            className: `form-control cursor-default${asSearchField ? " searchBox no-background px-3" : ""}${readOnly || asSearchField ? "" : " bg-transparent"}`,
            type: type !== "time" ? "text" : onMobile ? "text" : "time",
            onChange,
        };
    }, [asSearchField, inputValue, onChange, readOnly, type]);

    useEffect(() => {
        setInputValue(getInputValue(value));
    }, [value, getInputValue]);

    useEffect(() => {
        const picker = ref.current?.querySelector(".rdtPicker");

        if (type !== "time" || !picker) return;

        picker.setAttribute("style", `min-width: 135px;`);
        const handleClose = (event) => !picker.contains(event.target) && !ref.current.contains(event.target) && onClose();

        document.body.addEventListener("click", handleClose);
        return () => document.body.removeEventListener("click", handleClose);
    }, [type, onClose]);

    const left = useMemo(() => {
        let num = value && !readOnly ? 79 : 45;

        return num;
    }, [readOnly, value]);

    return (
        <div className={`date-time-picker ${type === "time" ? "time-picker" : "date-picker"}`} ref={ref}>
            <ReactDateTimePicker
                timeFormat={type === "date" ? false : "hh:mm A"}
                dateFormat={type === "time" ? false : "MM-DD-YYYY"}
                value={value ? moment(type === "time" ? `${new Date().getFullYear()}-01-01 ${value}` : value) : undefined}
                onChange={onChangeDateTime}
                inputProps={inputProps}
                open={type === "time" ? open : readOnly ? false : undefined}
                onOpen={type === "time" ? onOpen : undefined}
                onClose={type === "time" ? onClose : undefined}
                isValidDate={isValidDate}
                closeOnClickOutside
                closeOnSelect
            />
            {!required && !readOnly && value && (
                <span className="date-picker-icon pointer" style={{ left: `calc(100% - ${left - (type === "time" ? 12 : asSearchField ? 18 : 11)}px)` }} onClick={onClear}>
                    <IoMdClose />
                </span>
            )}
            {type === "time" && (
                <span className="date-picker-icon" style={{ left: `calc(100% - ${left}px)` }} onClick={toggleOpen}>
                    <FiClock />
                </span>
            )}
        </div>
    );
}
