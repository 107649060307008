import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Suspense, useEffect, useState } from "react";
import { Navigate, NavLink, Route, Routes, useParams } from "react-router-dom";

import DetailsPageContainer from "../../components/DetailsPageContainer";
import ErrorBoundary from "../../components/ErrorBoundary";
import LoadingSpinner from "../../components/LoadingSpinner";
import NotFoundPage from "../NotFoundPage";
import UnAuthorisedPage from "../UnAuthorisedPage";
import Details from "./Details.component";
import { initialCommunityDetails } from "./DATA";
import { getCommunityStaff } from "../../services/communities/communityStaffServices";
import { getCommunityById } from "../../services/communities/communityServices";
import { selectUserIsAdmin, selectUserIsCommunityDirector } from "../../redux/userReducer/userSelectors";
import { getCommunityPeerGroups } from "../../services/communities/communityPeerGroupServices";
import {
    // selectPermissionsCommunityDashboardMenu,
    selectPermissionsCommunityDetailsMenu,
    selectPermissionsCommunityDirectorsMenu,
    selectPermissionsCommunityDocumentsMenu,
    selectPermissionsCommunityEventsMenu,
    // selectPermissionsCommunityMembersMenu,
    selectPermissionsCommunityNotesMenu,
    selectPermissionsCommunityPeerGroupsMenu,
    selectPermissionsSidebarCommunitiesMenu,
} from "../../redux/permissionReducer/permissionSelectors";

import "./CommunityDetails.css";

// NO LAZY IMPORTS ANYMORE...
import CommunityEvents from "../CommunityEvents";
import CommunityStaff from "../CommunityStaff";
import CommunityPeerGroups from "../CommunityPeerGroups";

const CommunityDetails = () => {
    const { communityId } = useParams();
    const [communityDetails, setCommunityDetails] = useState(() => ({
        ...initialCommunityDetails,
        communityId,
    }));
    const [staff, setStaff] = useState(undefined);
    const [refreshStaff, setRefreshStaff] = useState(0);
    const [peerGroups, setPeerGroups] = useState(undefined);
    const [refreshPeerGroups, setRefreshPeerGroups] = useState(0);
    const {
        isAdmin,
        isCommunityDirector,
        sidebarCommunitiesMenuPermission,
        // communityDashboardMenuPermission,
        communityDetailsMenuPermission,
        // communityMembersMenuPermission,
        communityEventsMenuPermission,
        communityPeerGroupsMenuPermission,
        communityNotesMenuPermission,
        communityDocumentsMenuPermission,
        communityDirectorsMenuPermission,
    } = useSelector(
        createStructuredSelector({
            isAdmin: selectUserIsAdmin,
            isCommunityDirector: selectUserIsCommunityDirector,
            sidebarCommunitiesMenuPermission: selectPermissionsSidebarCommunitiesMenu,
            // communityDashboardMenuPermission: selectPermissionsCommunityDashboardMenu,
            communityDetailsMenuPermission: selectPermissionsCommunityDetailsMenu,
            // communityMembersMenuPermission: selectPermissionsCommunityMembersMenu,
            communityEventsMenuPermission: selectPermissionsCommunityEventsMenu,
            communityPeerGroupsMenuPermission: selectPermissionsCommunityPeerGroupsMenu,
            communityNotesMenuPermission: selectPermissionsCommunityNotesMenu,
            communityDocumentsMenuPermission: selectPermissionsCommunityDocumentsMenu,
            communityDirectorsMenuPermission: selectPermissionsCommunityDirectorsMenu,
        })
    );

  const expandedDetails = false;

  useEffect(() => {
    getCommunityById({ communityId }).then((response) => {
      if (response.data) setCommunityDetails((prev) => ({ ...prev, communityId, ...response.data }));
    });
  }, [communityId]);

  useEffect(() => {
    if (refreshStaff === 0) return;

    getCommunityStaff({ communityId }).then(({ data, canAdd, canEdit, canDelete }) => {
      setStaff(data);
      setCommunityDetails((prev) => ({ ...prev, canAddStaff: canAdd, canEditStaff: canEdit, canDeleteStaff: canDelete }));
    });
  }, [communityId, refreshStaff]);
  useEffect(() => {
    if (refreshPeerGroups === 0) return;

    getCommunityPeerGroups({ communityId }).then(({ data, canAdd, canEdit, canDelete }) => {
      setPeerGroups(data);
      setCommunityDetails((prev) => ({ ...prev, canAddPeerGroups: canAdd, canEditPeerGroups: canEdit, canDeletePeerGroups: canDelete }));
    });
  }, [communityId, refreshPeerGroups]);

    return !(isAdmin || isCommunityDirector) ? (
        <UnAuthorisedPage />
    ) : (
        <DetailsPageContainer
            breadCrumbTitle={"Communities"}
            breadCrumbLink={"/communities"}
            expandedDetails={expandedDetails}
            hideBreadcrumb={!sidebarCommunitiesMenuPermission}
            image={communityDetails.communityPictureUrl}
            fontSize={23}
            nameLetters={communityDetails.communityPictureInitials}
            detailsChildren={
                <>
                    <h4 className="overflow-text" style={expandedDetails ? undefined : { width: 320 }} title={communityDetails.communityName}>
                        {communityDetails.communityName}
                    </h4>
                    <p className="overflow-text">{communityDetails.stateName}</p>
                </>
            }
            navLinkChildren={
                <>
                    {/* {communityDashboardMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`dashboard`}>
                                Dashboard
                            </Nav.Link>
                        </Nav.Item>
                    )} */}
                    {communityDetailsMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`details`}>
                                Details
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {/* {communityMembersMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`members`}>
                                Members
                            </Nav.Link>
                        </Nav.Item>
                    )} */}
                    {communityEventsMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`events`}>
                                Events
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {communityPeerGroupsMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`peer-groups`}>
                                Peer Groups
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {communityNotesMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`notes`}>
                                Notes
                            </Nav.Link>
                        </Nav.Item>
                    )}
                    {/* {communityDocumentsMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`documents`}>
                                Documents
                            </Nav.Link>
                        </Nav.Item>
                    )} */}
                    {communityDirectorsMenuPermission && (
                        <Nav.Item as="li">
                            <Nav.Link as={NavLink} to={`directors`}>
                                Directors
                            </Nav.Link>
                        </Nav.Item>
                    )}
                </>
            }
        >
            <ErrorBoundary>
                <Suspense
                    fallback={
                        <div className="h-100 pt-5">
                            <LoadingSpinner />
                        </div>
                    }
                >
                    <Routes>
                        {/* <Route path="/dashboard" element={<Details communityDetails={communityDetails} communityId={communityId} asDashboard />} /> */}
                        <Route path="/details" element={<Details communityDetails={communityDetails} communityId={communityId} setCommunityDetails={setCommunityDetails} />} />
                        <Route path="/events" element={<CommunityEvents communityDetails={communityDetails} communityId={communityId} />} />
                        <Route
                            path="/directors"
                            element={<CommunityStaff communityDetails={communityDetails} communityId={communityId} staff={staff} setStaff={setStaff} setRefresh={setRefreshStaff} />}
                        />
                        <Route
                            path="/peer-groups"
                            element={
                                <CommunityPeerGroups
                                    communityDetails={communityDetails}
                                    communityId={communityId}
                                    peerGroups={peerGroups}
                                    setPeerGroups={setPeerGroups}
                                    setRefresh={setRefreshPeerGroups}
                                />
                            }
                        />
                        <Route path="/" element={<Navigate to="details" replace />} />
                        <Route path="/*" element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </ErrorBoundary>
        </DetailsPageContainer>
    );
};

export default CommunityDetails;
