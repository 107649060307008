import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import "./SignUp.css";

import ReactGA from "react-ga";
import Footer from "../../components/BodyContainer/Footer.component";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

const SignUpFinalizeApplication = ({ setTab, userId, setGlobalCommunity, communtiesURL, decodedURL, isFunderLogo, sourceURL }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/community-select" + window.location.search);
    }, [gaEventTracker]);
    const [communityOptions, setCommunityOptions] = useState([]);
    const [checkedOption, setCheckedOption] = useState("");
    const [deafultChecked, setDefaultChecked] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleSubmit = async () => {
        const queryParams = new URLSearchParams(sourceURL);
        let funderCode = {};
        if (queryParams.get('funder')) {
            funderCode = { funderCode: queryParams.get('funder') }
        }
        if ((checkedOption && checkedOption !== "none") || deafultChecked) {
            const response = await callApi.post(buildURL(`${Urls.AssignCommunity}`), {
                prospectUserId: userId,
                communityCode: deafultChecked ? communityOptions?.[0].code : checkedOption,
                ...funderCode
            });
            if (!response.responseData) return;
            if (response.responseCode === 200 && response.responseData.communityFormStatus === "CFSFLL") {
                setTab(7);
            }
            if (response.responseCode === 200 && response.responseData.communityFormStatus !== "CFSFLL") {
                setGlobalCommunity(deafultChecked ? communityOptions?.[0].code : checkedOption);
                setTab(5);
            }
        }

        if (checkedOption === "none") {
            await callApi.post(buildURL(`${Urls.AssignCommunity}`), {
                prospectUserId: userId,
                communityCode: "",
                convenientCommunityNotFound: true,
            });
            setTab(8);
        }
        // if (!response.responseData) return;
        // setCommunityOptions(response.responseData)
        // console.log(response.responseData, "response")
    };
    useEffect(() => {
        (async () => {
            const queryParams = new URLSearchParams(sourceURL);
            let funderCode = "";
            if (queryParams.get('funder')) {
                funderCode = `?funderCode=${queryParams.get('funder')}`;
            }
            const response = await callApi.get(buildURL(`${Urls.GetCommunityOptionsProspectForm}${funderCode}`), { headers: { communities: communtiesURL } });
            if (!response.responseData) return;
            if (decodedURL.includes("comm" || "ch") && response?.responseData?.length === 1) setDefaultChecked(true);
            setCommunityOptions(
                [...response.responseData, { code: "none", name: "None of the communities are convenient for me" }]
            );
        })();
    }, [communtiesURL, decodedURL, sourceURL]);

    return (
        <>
            <div className="signUpRightFormSec align-items-start">
                <div className="rightSecForm">
                    <h3>Last Step</h3>
                    <h6>Select the community closest to you.</h6>

                    <Form>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Community*</label>
                                    {/* <select disabled={checkedOption} name="businessStartMonth" className="form-select" as="select" onChange={(e) => setCommunity(e.target.value)}>
                                        <option selected={checkedOption}>Select a community...</option>
                                    </select> */}
                                    <div className="select-box-wrapper">
                                        <select
                                            className="form-select"
                                            onChange={(e) => {
                                                setCheckedOption(e.target.value);
                                            }}
                                        >
                                            <option>Select</option>
                                            {communityOptions.map((option) => (
                                                <option value={option.code}>{option.name}</option>
                                            ))}
                                        </select>
                                        <span className="select-arrow">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                <path
                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                    fill="#B4AFC6"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                </div>

                                {/* <h6>{moment(e.eventDate).format("LLLL")}</h6>
                  <div className="date_time">{formatTimeOnly(e.eventStartTime)} -  {formatTimeOnly(e.eventEndTime)} {e.eventTimeZone}</div> */}
                            </Col>
                        </Row>
                        <div className="bottomBtnSec">
                            <button type="button" onClick={handleSubmit} className="customPrimaryBtn mt-4">
                                SUBMIT <HiOutlineChevronRight />
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
            {!isFunderLogo && <div className="signupFooter">
                <Footer />
            </div>}
        </>
    );
};

export default SignUpFinalizeApplication;
