import { useCallback, useMemo } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { toast } from "react-toastify";

import FormModal from "../../components/FormModal";
import { showResponseMessage } from "../../helpers";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import messages from "../../helpers/messages";
import { EEventTypes } from "../../services/events/eventServices";
import { createUserNote } from "../../services/users/userNoteServices";
import { userNoteFormFields, userNoteInitialForm } from "../UserNotes/DATA";

const noteFields = userNoteFormFields.map((f) => (f.name === "noteDate" ? { ...f, readOnly: true } : f));

export default function UpdateStatusButtons({
    eventTypeName,
    statusOptions,
    roleOptions,
    selectedStatusOption,
    selectedRoleOption,
    setSelectedStatusOption,
    setSelectedRoleOption,
    onSubmitUpdateStatus,
    noteId,
    eventId,
    eventDate,
    selectedAttendees,
}) {
    const initialForm = useMemo(() => ({ ...userNoteInitialForm, noteDate: eventDate }), [eventDate]);
    const [showOptions, toggleShowOptions, setShowOptions] = useToggle(false);
    const [showOptionsTwo, toggleShowOptionsTwo, setShowOptionsTwo] = useToggle(false);
    const [showForm, toggleShowForm] = useToggle(false);
    const [form, onChange, setForm] = useForm(initialForm);

  const hideOptions = useCallback(() => setTimeout(() => setShowOptions(false), 150), [setShowOptions]);
  const hideOptionsTwo = useCallback(() => setTimeout(() => setShowOptionsTwo(false), 150), [setShowOptionsTwo]);

    const handleSubmitStatus = useThrottle(
        () => {
            if (!selectedRoleOption?.label?.includes("Not Approved")) return onSubmitUpdateStatus();
            if (!selectedAttendees.current.length) return toast.warn("Please select at least one attendee to update status.");
            toggleShowForm();
        },
        [onSubmitUpdateStatus, selectedAttendees, selectedRoleOption?.label, toggleShowForm],
        2000
    );

    const onSubmitNote = useCallback(
        async (formData) => {
            const response = await createUserNote({ ...formData, entityTypeId: eventId, noteType: `Event: ${EEventTypes.discoverySession}`, userIds: selectedAttendees.current.map((u) => u.userId) });

            showResponseMessage(response, {
                success: messages.createUserNote,
            });

            if (response.responseCode !== 200) return;

            noteId.current = response.responseData?.id || "";

            setForm({ ...initialForm });
            onSubmitUpdateStatus();
            toggleShowForm(false);
        },
        [eventId, initialForm, noteId, onSubmitUpdateStatus, selectedAttendees, setForm, toggleShowForm]
    );
    const onCancelNote = useCallback(() => {
        toggleShowForm(false);
        setTimeout(() => setForm({ ...initialForm }), 500);
    }, [initialForm, setForm, toggleShowForm]);

    return (
        <>
            <div className="no-change-btn">
                <div>
                    <button className={`status-dropdown-toggle status-dropdown left-btn left${showOptions ? " active" : ""}`} type="button" onClick={toggleShowOptions} onBlur={hideOptions}>
                        {selectedStatusOption?.label || "No Change"}
                        <span className="caret"></span>
                        {showOptions ? <IoIosArrowUp color="#65317e" /> : <IoIosArrowDown color="#65317e" />}
                    </button>
                    <ul className={`status-dropdown-menu left right left-btn ${showOptions ? "show" : "hide"}`}>
                        {statusOptions.map((opt) => (
                            <li className="pointer" key={opt.value} onClick={() => setSelectedStatusOption(opt)}>{`${opt.label}`}</li>
                        ))}
                        {(eventTypeName === "Social" || eventTypeName === "Other" || eventTypeName === "Discovery Session") && (
                            <li className="pointer" onClick={() => setSelectedStatusOption(null)}>
                                No Change
                            </li>
                        )}
                    </ul>
                </div>{" "}
                {eventTypeName === "Discovery Session" && (
                    <div>
                        <button className="status-dropdown-toggle status-dropdown right-btn" type="button" onClick={toggleShowOptionsTwo} onBlur={hideOptionsTwo}>
                            {selectedRoleOption?.label || "No Change"}
                            <span className="caret-new"></span>
                            {showOptionsTwo ? <IoIosArrowUp color="#65317e" /> : <IoIosArrowDown color="#65317e" />}
                        </button>
                        <ul className={`status-dropdown-menu left right right-btn ${showOptionsTwo ? "show" : "hide"}`}>
                            {roleOptions.map((opt) => (
                                <li className="pointer" key={opt.value} onClick={() => setSelectedRoleOption(opt)}>
                                    {opt.label}
                                </li>
                            ))}
                            <li className="pointer" onClick={() => setSelectedRoleOption(null)}>
                                No Change
                            </li>
                        </ul>
                    </div>
                )}
                <button className="apply-btn" type="button" onClick={handleSubmitStatus}>
                    APPLY
                </button>
            </div>

            <FormModal title="Note" show={showForm} fields={noteFields} form={form} onChange={onChange} onSubmit={onSubmitNote} onCancel={onCancelNote} />
        </>
    );
}
