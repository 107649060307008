import { EFieldInputTypes } from "../../components/FormField";
import { createEventNote, deleteEventNote, getEventNotes } from "../../services/events/eventNotesServices";
import { downloadFile, formatDateOnly, formatDateTime } from "../../helpers/index";

export const userNoteInitialForm = {
    noteDate: "",
    documentName: "",
    documentFile: "",
    tags: [],
    noteDescription: "",
    eventId: "",
    // entityTypeId: "",
    createdDate: "",
};
export const userNoteInitialSearchForm = {
    noteTypeCode: "",
    noteDate: "",
    documentExists: false,
    noteDescription: "",
};

export const userNoteFields = [
    // {
    //     name: "noteTypeName",
    //     title: "Note Type",
    // },
    {
        name: "noteSelectedDate",
        title: "Date",
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "noteDescription",
        title: "Note",
        cell: (value, data) => (
            <>
                <span>{value}</span>
                <span> </span>
                {data.tags.map((tag, i) => (
                    <strong key={i} title={tag} className="d-inline-block mx-1 cursor-default">
                        {tag.length > 0 ? "#" + tag : tag}
                    </strong>
                ))}
            </>
        ),
        columnWidth: 220,
    },
    {
        name: "documentName",
        title: "Document",
        cell: (value, data) => {
            const names = (data.documentFile || "").split("?")[0].split(".");
            const fileName = (value || "") + "." + names[names.length - 1];

            return (
                <span className="anchor-tag" onClick={() => downloadFile(data.documentFile, fileName)}>
                    {value}
                </span>
            );
        },
    },
    {
        name: "noteByName",
        title: "Note By",
    },
    {
        name: "noteDate",
        title: "Note Date",
        cell: (value, data) => `${formatDateTime(value)} ${data.timeZoneName || "EST"}`,
        columnWidth: 175,
    },
];

export const userNoteSearchFields = [
    // {
    //     name: "noteTypeCode",
    //     col: 3,
    //     title: "Type",
    //     colMd: 4,
    //     colSm: 6,
    //     inputType: EFieldInputTypes.select,
    //     getOptions: getUserNoteTypeOptions,
    // },
    {
        name: "noteDate",
        col: 3,
        title: "Date",
        colMd: 4,
        colSm: 6,
        inputType: EFieldInputTypes.date,
    },
    {
        name: "noteDescription",
        col: 3,
        title: "Note",
        colMd: 4,
        colSm: 6,
        inputType: EFieldInputTypes.text,
    },
    {
        name: "documentExists",
        col: 3,
        colMd: 4,
        colSm: 6,
        title: "Document",
        inputType: EFieldInputTypes.checkbox,
    },
];

export const userNoteFormFields = [
    {
        name: "noteDate",
        title: "Date",
        inputType: EFieldInputTypes.date,
        required: true,
    },
    {
        name: "documentName",
        title: "Document Name",
        required: (form) => !!form.documentFile,
    },
    {
        name: "documentFileName",
        nameForm: "documentFile",
        title: "Document File",
        inputType: EFieldInputTypes.file,
        accept: ".pdf, .doc, .docx, .xlsx, .png, .svg, .jpg, .jpeg, .gif, .txt",
        col: 12,
        colMd: 12,
        required: (form) => !!form.documentName,
    },
    {
        name: "tags",
        title: "Tags",
        inputType: EFieldInputTypes.tags,
        col: 12,
        colMd: 12,
    },
    {
        name: "noteDescription",
        title: "Note",
        inputType: EFieldInputTypes.textarea,
        required: true,
        col: 12,
        colMd: 12,
    },
];

export const userNoteApis = {
    getAll: {
        api: getEventNotes,
    },
    delete: {
        api: deleteEventNote,
    },
    create: {
        api: createEventNote,
    },
};
