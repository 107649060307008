import { useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";

export default function UnAuthorisedPage({ pageTitle = "Dashboard", pageLink = "/dashboard" }) {
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const goBack = useCallback(() => navigate(-1), []);

    return (
        <div className="card text-center py-5 px-2 m-5">
            <h2 className="my-3">You are not authorized to view this page.</h2>
            <h4 className="my-3">
                Please{" "}
                <span className="anchor-tag" onClick={goBack}>
                    Go Back
                </span>{" "}
                or go to <Link to={pageLink}>{pageTitle}</Link>.
            </h4>
        </div>
    );
}
