import { Link } from "react-router-dom";

import messages from "../../helpers/messages";
import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, throttle } from "../../helpers";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createUserPeerGroup, deleteUserPeerGroup, getPeerGroupOptionsByCommunity, getUserPeerGroups, updateUserPeerGroup } from "../../services/peer-groups/peerGroupServices";
import { getRoleOptionsForPeerGroup } from "../../services/roles/roleServices";

export const userPeerGroupFields = [
  {
    name: "communityName",
    nameForm: "communityCode",
    title: "Community Name",
    inputType: EFieldInputTypes.select,
    dependencies: [
      {
        name: "peerGroupName",
        nameForm: "peerGroupCode",
        initialValue: "",
        getOptions: throttle(async (form) => {
          if (!form.communityCode) return { data: [] };

          const response = await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode });

          return response;
        }, 200),
      },
    ],
    getOptions: getCommunityOptions,
    required: true,
    col: 6,
    readOnlyInEdit: true,
    cell: (name, data) => <Link to={`/communities/${data.communityCode}`}>{name}</Link>,
  },
  {
    name: "peerGroupName",
    nameForm: "peerGroupCode",
    title: "Peer Group",
    inputType: EFieldInputTypes.select,
    options: [],
    required: true,
    col: 6,
    readOnlyInEdit: true,
    cell: (name, data) => <Link to={`/peer-groups/${data.peerGroupCode}`}>{name}</Link>,
  },
  {
    name: "roleName",
    nameForm: "roleCode",
    title: "Responsibility",
    inputType: EFieldInputTypes.select,
    getOptions: getRoleOptionsForPeerGroup,
    required: true,
    readOnlyInEdit: true,
    col: 6,
  },
  {
    name: "startDate",
    title: "Start Date",
    inputType: EFieldInputTypes.date,
    required: true,
    col: 6,
    cell: (value) => formatDateOnly(value),
    // validate: (form) => (new Date(form.startDate).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime() ? "Start Date can not be in the past." : ""),
  },
  {
    name: "endDate",
    title: "End Date",
    inputType: EFieldInputTypes.date,
    required: false,
    initialValue: null,
    col: 6,
    cell: (value) => formatDateOnly(value),
    validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? messages.endDateLater : ""),
  },
];

export const userPeerGroupInitialForm = {
  communityCode: "",
  roleCode: "",
  startDate: "",
  endDate: "",
  userId: "",
};

export const userPeerGroupApis = {
  create: {
    api: createUserPeerGroup,
  },
  update: {
    api: updateUserPeerGroup,
  },
  delete: {
    api: deleteUserPeerGroup,
  },
  getAll: {
    api: getUserPeerGroups,
    body: {
      userId: "",
    },
  },
};
