import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import SelectInput from "../../components/SelectInput";
import Status from "../../components/Status";
import Tooltip from "../../components/Tooltip";
import { formatDateOnly, throttle } from "../../helpers";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { getPeerGroupOptions, getPeerGroupOptionsByCommunity } from "../../services/peer-groups/peerGroupServices";
import { getRoleOptions } from "../../services/roles/roleServices";
import { createUser, getUsers, getUserStatusOptions } from "../../services/users/userServices";

const tooltipMap = (d, i) => (
    <p key={i} className="p-1">{`${d.communityName ? `${d.communityName}, ` : ""}${d.name}: ${formatDateOnly(d.startDate) || "Active"} - ${formatDateOnly(d.endDate) || "Active"}`}</p>
);

export const userSearchFields = [
    {
        name: "lastName",
        col: 3,
        title: "Last Name",
        maxLength: 50,
    },
    {
        name: "firstName",
        col: 3,
        title: "First Name",
        maxLength: 50,
    },
    {
        name: "email",
        col: 3,
        title: "Email",
        maxLength: 320,
    },
    {
        name: "status",
        col: 3,
        title: "Account Status",
        inputType: EFieldInputTypes.select,
        getOptions: getUserStatusOptions,
    },
    {
        name: "role",
        col: 3,
        title: "Role",
        inputType: EFieldInputTypes.custom,
        disableActiveRoles: false,
        noEmptyOption: false,
        component({ form, onChange }) {
            return (
                <div className="my-2">
                    <SelectInput
                        name={this.name}
                        title={this.title}
                        getOptions={this.getOptions}
                        getInitialOption={this.getInitialOption}
                        onChange={onChange}
                        value={form[this.name]}
                        readOnly={this.readOnly}
                        noEmptyOption={this.noEmptyOption}
                        asSearchField
                    />
                    <div className="my-2">
                        <input
                            type={"checkbox"}
                            className="form-check-input"
                            name="isActiveRoles"
                            id="isActiveRoles"
                            onChange={this.disableActiveRoles ? undefined : onChange}
                            checked={form.isActiveRoles}
                            readOnly={this.disableActiveRoles}
                        />
                        <label htmlFor="isActiveRoles" className="mx-1" style={{ fontSize: "16px" }}>
                            Active Roles
                        </label>
                    </div>
                </div>
            );
        },
        getOptions: getRoleOptions,
        getInitialOption: undefined,
        readOnly: false,
    },
    {
        name: "community",
        col: 3,
        title: "Community",
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityOptions,
        dependencies: [
            {
                name: "peerGroup",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.community ? await getPeerGroupOptionsByCommunity({ communityCode: form.community }) : await getPeerGroupOptions();

                    return response;
                }, 200),
            },
        ],
    },
    {
        name: "peerGroup",
        col: 3,
        title: "Peer Group",
        inputType: EFieldInputTypes.select,
        getOptions: getPeerGroupOptions,
    },
    {
        name: "businessName",
        col: 3,
        title: "Business",
        maxLength: 150,
    },
];

export const userFields = [
    {
        name: "lastName",
        title: "Last Name",
        filterable: true,
        cell: (lastName, data) => (
            <Link style={{ color: "#00adba" }} to={`/users/${data.userGuid}`}>
                {lastName}
            </Link>
        ),
        filter: {
            maxLength: 50,
        },
        columnWidth: 130,
    },
    {
        name: "firstName",
        title: "First Name",
        filterable: true,
        cell: (firstName, data) => (
            <Link style={{ color: "#00adba" }} to={`/users/${data.userGuid}`}>
                {firstName}
            </Link>
        ),
        filter: {
            maxLength: 50,
        },
        columnWidth: 130,
    },
    {
        name: "email",
        title: "Email",
        filterable: true,
        col: 12,
        filter: {
            maxLength: 320,
        },
        columnWidth: 250,
    },
    {
        name: "password",
        title: "Password",
        filterable: true,
        hideTableColumn: true,
    },
    {
        name: "roleNames",
        title: "Roles",
        filterable: true,
        cell: (value, data) => (
            <div>
                {!data.roles?.length ? (
                    <span className="anchor-tag cursor-default">{value}</span>
                ) : (
                    <Tooltip placement="top" text={data.roles.map(tooltipMap)}>
                        <span className="anchor-tag">{value}</span>
                    </Tooltip>
                )}
            </div>
        ),
        filter: {
            maxLength: 20,
        },
    },
    {
        name: "communityNames",
        title: "Community",
        columnWidth: 100,
        filterable: true,
        cell: (value, data) => (
            <div className="text-center font-weight-bold">
                {!data.communities?.length ? (
                    <span className="anchor-tag cursor-default">{value}</span>
                ) : (
                    <Tooltip placement="top" text={data.communities.map(tooltipMap)}>
                        <span className="anchor-tag">{value}</span>
                    </Tooltip>
                )}
            </div>
        ),
        notSortable: true,
    },
    {
        name: "peerGroupNames",
        title: "Peer Group",
        columnWidth: 90,
        filterable: true,
        cell: (value, data) => (
            <div className="text-center font-weight-bold">
                {!data.peerGroups?.length ? (
                    <span className="anchor-tag cursor-default">{value}</span>
                ) : (
                    <Tooltip placement="top" text={data.peerGroups.map(tooltipMap)}>
                        <span className="anchor-tag">{value}</span>
                    </Tooltip>
                )}
            </div>
        ),
        notSortable: true,
    },
    {
        name: "userStatus",
        title: "Account Status",
        columnWidth: 140,
        filterable: true,
        cell: (value) => <Status text={value} />,
        filter: {
            maxLength: 20,
        },
    },
];

export const userFormFields = [
    {
        name: "imagePath",
        nameForm: "image",
        title: "",
        col: 3,
        colMd: 3,
        inputType: EFieldInputTypes.image,
    },
    {
        name: "formSection",
        title: "Form Section",
        col: 9,
        colMd: 9,
        inputType: "multiple",
        fields: [
            {
                name: "firstName",
                required: true,
                title: "First Name",
                placeholder: "First Name",
                col: 4,
                colMd: 4,
                maxLength: 50,
            },
            {
                name: "middleName",
                required: false,
                title: "Middle Initial",
                placeholder: "Middle Initial",
                col: 4,
                colMd: 4,
                maxLength: 1,
            },
            {
                name: "lastName",
                required: true,
                title: "Last Name",
                placeholder: "Last Name",
                col: 4,
                colMd: 4,
                maxLength: 50,
            },
            {
                name: "email",
                required: true,
                title: "Email",
                placeholder: "Email Address",
                inputType: EFieldInputTypes.email,
                col: 12,
                colMd: 12,
                maxLength: 320,
            },
            {
                name: "accountStatusName",
                nameForm: "accountStatus",
                required: true,
                title: "Status",
                inputType: EFieldInputTypes.select,
                getOptions: getUserStatusOptions,
                getInitialOption: (response) => response.data.find((o) => o.label === "Active"),
                col: 6,
            },
            {
                name: "password",
                required: (form) => !form.userId,
                title: "Password",
                placeholder: "**********",
                inputType: EFieldInputTypes.password,
                col: 6,
                minLength: 8,
                maxLength: 20,
                info: (form) => (
                    <div className="px-1">
                        <p className="my-1">Password Criteria:</p>
                        <p className="my-1">Passwords are Case Sensitive.</p>
                        <p className="my-1">
                            Min <strong>1</strong> Special Character.
                        </p>
                        <p className="my-1">
                            Min <strong>2</strong> Letters.
                        </p>
                        <p className="my-1">
                            Min <strong>1</strong> Number.
                        </p>
                        <p className="my-1">
                            Min <strong>8</strong> Characters Length.
                        </p>
                        <p className="my-1">Min Mix of Case.</p>
                        <p className="my-1">
                            Max Length <strong>20</strong> Characters.
                        </p>
                    </div>
                ),
                validate: (form) => (form.password && !form.password?.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])/) ? "Password do not match the specified criteria." : ""),
            },
            {
                name: "roleName",
                nameForm: "assignedRoleCode",
                title: "Role",
                inputType: EFieldInputTypes.select,
                options: [],
                hideInEditForm: true,
                col: 6,
            },
        ],
    },
];

export const userInitialForm = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    accountStatus: "",
    password: "",
    mobilePhoneNumber: "",
    image: null,
    assignedRoleCode: "",
};

export const userInitialSort = {
    dir: "asc",
    field: "lastName",
};

export const userInitialSearchForm = {
    pageSize: 25,
    pageNumber: 1,
    sortOrder: "asc",
    sortField: "lastName",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    community: "",
    peerGroup: "",
    status: "",
    isActiveRoles: true,
    businessName: "",
};

export const userApis = {
    getAll: {
        api: getUsers,
    },
    create: {
        api: createUser,
    },
};
