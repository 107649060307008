import React from "react";
import "./KPI.css";
import { Col, Modal, Row } from "react-bootstrap";
import { Formik, Form } from "formik";

const KPIModal = ({ validationSchema, show, onHide, initialValues, modalTitle, onSubmit, children }) => {
  return (
    <Modal className="manageUserPopup" show={show} onHide={onHide} size="lg" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={
            onSubmit
            //     async (values, actions) => {
            //   const response = await callApi.post(buildURL(`${Urls.UserBusinessEdit}`));

            //   if (!response.responseData) return;

            //   if (response.responseCode === 200) {
            //     toast.success("Success");
            //     // bussinessModelClose();
            //     // setRefresh((prev) => prev + 1);
            //   } else toast.error("Error");
            // }
          }
        >
          {({ errors, handleChange, isValid, isSubmitting, values, formik }) => (
            <Form>
              {children}
              <Row>
                <Col lg="12" className="text-end pt-3">
                  <button className="btnPrimaryLink btn mx-3" type="button" onClick={onHide}>
                    CANCEL
                  </button>
                  <button disabled={isSubmitting} type="submit" className="customPrimaryBtn btn" variant="primary">
                    SAVE & UPDATE
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default KPIModal;
