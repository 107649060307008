import { getCommunityOptions, getIndustryOptions } from "../../services/communities/communityServices";

export const memberDirectoryInitialSearchForm = {
    pageSize: 0,
    pageNumber: 0,
    sortOrder: "",
    sortField: "",
    communityCode: "",
    searchKey: "",
    industrySearchCode: "",
    communitySearchCode: "",
};

export const memberDirectorySearchFields = [
    {
        name: "searchKey",
        title: "Keyword/Name Search",
        col: 3,
    },
    {
        name: "industrySearchCode",
        title: "Industry",
        col: 3,
        inputType: "select",
        getOptions: getIndustryOptions,
    },
    {
        name: "communitySearchCode",
        title: "Community",
        col: 3,
        inputType: "select",
        getOptions: getCommunityOptions,
    },
];
