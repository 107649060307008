import { useCallback, useEffect, useRef } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import {
    // FaAccusoft,
    FaAddressBook,
    FaAddressCard,
    FaAdversal,
    FaAlignCenter, FaLandmark, FaUser, FaUserAlt, FaUsers, FaWeight
} from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import "react-pro-sidebar/dist/css/styles.css";
import "./MenuBar.css";

import BigLogo from "../../assets/images/logo-big.svg";
import SmallLogo from "../../assets/images/logo-small.svg";
import {
    selectPermissionsSidebarChecklistsMenu,
    selectPermissionsSidebarCommunitiesMenu,
    selectPermissionsSidebarCountiesMenu, selectPermissionsSidebarCurriculumsMenu,
    selectPermissionsSidebarEventsMenu,
    selectPermissionsSidebarFunderMenu,
    selectPermissionsSidebarMemberDirectoryMenu,
    // selectPermissionsSidebarMembersMenu,
    selectPermissionsSidebarPeerGroupsMenu,
    selectPermissionsSidebarReferralsMenu,
    selectPermissionsSidebarReportsMenu,
    selectPermissionsSidebarUsersMenu
} from "../../redux/permissionReducer/permissionSelectors";
import { selectfunderNavLogo, selectUserId } from "../../redux/userReducer/userSelectors";

const MenuBar = ({ menuCollapse, toggleMenuCollapse, setMenuCollapse }) => {
    const ref = useRef(null);
    const {
        userId,
        sidebarChecklistsMenuPermission,
        sidebarCommunitiesMenuPermission,
        sidebarCurriculumsMenuPermission,
        sidebarEventsMenuPermission,
        sidebarMemberDirectoryMenuPermission,
        // sidebarMembersMenuPermission,
        sidebarPeerGroupsMenuPermission,
        sidebarReferralsMenuPermission,
        sidebarReportsMenuPermission,
        sidebarUsersMenuPermission,
        sidebarFunderMenuPermission,
        sidebarCountiesMenuPermission,
        funderNavLogo
    } = useSelector(
        createStructuredSelector({
            sidebarEventsMenuPermission: selectPermissionsSidebarEventsMenu,
            sidebarUsersMenuPermission: selectPermissionsSidebarUsersMenu,
            sidebarPeerGroupsMenuPermission: selectPermissionsSidebarPeerGroupsMenu,
            sidebarMemberDirectoryMenuPermission: selectPermissionsSidebarMemberDirectoryMenu,
            sidebarReferralsMenuPermission: selectPermissionsSidebarReferralsMenu,
            sidebarCommunitiesMenuPermission: selectPermissionsSidebarCommunitiesMenu,
            // sidebarMembersMenuPermission: selectPermissionsSidebarMembersMenu,
            sidebarCurriculumsMenuPermission: selectPermissionsSidebarCurriculumsMenu,
            sidebarChecklistsMenuPermission: selectPermissionsSidebarChecklistsMenu,
            sidebarReportsMenuPermission: selectPermissionsSidebarReportsMenu,
            userId: selectUserId,
            sidebarFunderMenuPermission: selectPermissionsSidebarFunderMenu,
            sidebarCountiesMenuPermission: selectPermissionsSidebarCountiesMenu,
            funderNavLogo: selectfunderNavLogo
        })
    );
    const location = useLocation();

    const handleHideSidebar = useCallback(() => window.innerWidth < 768 && setMenuCollapse(true), [setMenuCollapse]);

    useEffect(() => {
        document.body.classList[menuCollapse ? "add" : "remove"]("collapsed");
    }, [menuCollapse]);

    useEffect(() => {
        if (menuCollapse) return;
        const listener = (event) => window.innerWidth < 768 && !ref.current?.contains(event.target) && setMenuCollapse(true);

        document.body.addEventListener("click", listener);

        return () => document.body.removeEventListener("click", listener);
    }, [setMenuCollapse, menuCollapse]);
    return (
        <div id="sidebar" ref={ref}>
            <ProSidebar>
                <SidebarHeader>
                    <div className="logoSec">
                        {menuCollapse ? <img alt="Logo" src={SmallLogo} className="smallLogo" /> : <img alt="Logo" src={funderNavLogo ?? BigLogo} className="largeLogo" />}
                        <div className="menuDivider"></div>
                    </div>
                    <div className="closemenu hideMob" onClick={toggleMenuCollapse}>
                        {!menuCollapse ? <AiOutlineDoubleLeft className="close-menu-ic" /> : <AiOutlineDoubleRight className="close-menu-ic" />}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem
                            active={(location.pathname === "/dashboard" || location.pathname.startsWith(`/users/${userId}`)) && !location.pathname.endsWith("referrals")}
                            icon={<IoHome />}
                            title={menuCollapse ? "Dashboard" : undefined}
                        >
                            <Link onClick={handleHideSidebar} to={`/users/${userId}/dashboard`}>
                                Dashboard
                            </Link>
                        </MenuItem>
                        {sidebarUsersMenuPermission && (
                            <MenuItem
                                active={location.pathname.startsWith("/user") && !location.pathname.startsWith(`/users/${userId}`)}
                                icon={<FaUsers />}
                                title={menuCollapse ? "User Management" : undefined}
                            >
                                <Link onClick={handleHideSidebar} to="/users">
                                    User Management
                                </Link>
                            </MenuItem>
                        )}
                        {/* {sidebarMembersMenuPermission && (
              <MenuItem active={location.pathname.startsWith("/members")} icon={<FaAccusoft />} title={menuCollapse ? "Members" : undefined}>
                <Link onClick={handleHideSidebar} to="/members">
                  Members
                </Link>
              </MenuItem>
            )} */}
                        {sidebarEventsMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/event")} icon={<FaLandmark />} title={menuCollapse ? "Events" : undefined}>
                                <Link onClick={handleHideSidebar} to="/events">
                                    Events
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarFunderMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/funder")} icon={<FaUser />} title={menuCollapse ? "Funder" : undefined}>
                                <Link onClick={handleHideSidebar} to="/funder">
                                    Funder
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarCountiesMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/county")} icon={<FaUser />} title={menuCollapse ? "County" : undefined}>
                                <Link onClick={handleHideSidebar} to="/county">
                                    County
                                </Link>
                            </MenuItem>
                        )}

                        {sidebarCommunitiesMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/communities")} icon={<FaAddressBook />} title={menuCollapse ? "Communities" : undefined}>
                                <Link onClick={handleHideSidebar} to="/communities">
                                    Communities
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarPeerGroupsMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/peer-group")} icon={<FaWeight />} title={menuCollapse ? "Peer Groups" : undefined}>
                                <Link onClick={handleHideSidebar} to="/peer-groups">
                                    Peer Groups
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarCurriculumsMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/curriculum")} icon={<FaAddressCard />} title={menuCollapse ? "Curriculum" : undefined}>
                                <Link onClick={handleHideSidebar} to="/curriculum">
                                    Curriculum
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarChecklistsMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/checklist")} icon={<FaUserAlt />} title={menuCollapse ? "Checklists" : undefined}>
                                <Link onClick={handleHideSidebar} to="/checklist">
                                    Checklists
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarMemberDirectoryMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/member-directory")} icon={<FaAlignCenter />} title={menuCollapse ? "Member Directory" : undefined}>
                                <Link onClick={handleHideSidebar} to="/member-directory">
                                    Member Directory
                                </Link>
                            </MenuItem>
                        )}
                        {sidebarReferralsMenuPermission && (
                            <MenuItem active={location.pathname.endsWith("/referrals")} icon={<FaAdversal />} title={menuCollapse ? "Referrals" : undefined}>
                                <Link onClick={handleHideSidebar} to={`users/${userId}/user/referrals`}>
                                    Refer A Friend
                                </Link>
                            </MenuItem>
                        )}
                        {/* {sidebarReportsMenuPermission && (
                            <MenuItem active={location.pathname.startsWith("/reports")} icon={<FaCalendarDay />} title={menuCollapse ? "Reports" : undefined}>
                                <Link onClick={handleHideSidebar} to="/reports">
                                    Reports
                                </Link>
                            </MenuItem>
                        )} */}
                    </Menu>
                </SidebarContent>
            </ProSidebar>
        </div>
    );
};

export default MenuBar;
