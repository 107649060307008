import moment from "moment";
import { useEffect } from "react";
import ReactGA from "react-ga";
import GreenTick from "../../assets/images/green-tick.svg";
import GreenCalender from "../../assets/images/greenCalender.svg";
import Footer from "../../components/BodyContainer/Footer.component";
import { formatTimeOnly } from "../../helpers";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import "./SignUp.css";

const SignUpConfirmDiscovery = ({ eventData, isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/discovery-session-scheduled" + window.location.search);
    }, [gaEventTracker]);
    return (
        <>
            <div className="signUpRightFormSec">
                <div className="rightSecForm">
                    <div className="thankYouPage">
                        <div className="thanks-header">
                            <img className="greentick" src={GreenTick} alt="Tick" />
                            <h3>Thank you!</h3>
                        </div>
                        <p>Congratulations! You’re registered for an Entrepreneurs Forever Discovery Session on:</p>
                        <div className="submitDate">
                            <div className="calIcon">
                                <img src={GreenCalender} alt="Calendar Icon" />
                            </div>
                            <div className="dateTimeSec">
                                <h5>{moment(eventData.eventDate).format("LLLL")?.slice(0, -8)}</h5>
                                <p>
                                    {formatTimeOnly(eventData.eventStartTime)} - {formatTimeOnly(eventData.eventEndTime)} {eventData.eventTimeZone}
                                </p>
                            </div>
                        </div>
                        <p>
                            We know you can’t wait to get started and neither can we. So be sure to check your email for a link to the Discovery Session. And don’t forget to place it on your calendar.
                            Then, get ready for your business to move in a whole new direction.
                        </p>
                        <p className="mt-3">See you there!</p>
                    </div>
                </div>
            </div>
            {!isFunderLogo && <div className="signupFooter">
                <Footer />
            </div>}
        </>
    );
};

export default SignUpConfirmDiscovery;
