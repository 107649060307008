import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";

import FormModal from "../../components/FormModal";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { selectPermissionsCountiesEdit } from "../../redux/permissionReducer/permissionSelectors";

import { useParams } from "react-router-dom";
import EditIcon from "../../components/EditIcon";
import ManagementTable from "../../components/ManagementTable";
import { selectUserFirstName, selectUserIsAdmin, selectUserLastName, selectUserMiddleName } from "../../redux/userReducer/userSelectors";
import { communityPrimaryKey } from "../../services/communities/communityServices";
import { communityCountiesPrimaryKey, createUpdateCounites } from "../../services/counties/countiesService";
import { countiesFormFields, getCountiesStatusOptions } from "../Counties/DATA";
import "./CountiesDetails.css";
import { communityApis, communityFields } from "./DATA";

export default function Details({ countiesDetails, setCountiesDetails }) {
    const { countyId } = useParams();
    const { isAdmin } = useSelector(createStructuredSelector({ isAdmin: selectUserIsAdmin }));
    const [fieldsFinal, setFieldsFinal] = useState(countiesFormFields);
    const [form, onChangeForm, setForm] = useForm({}, countiesFormFields, setFieldsFinal);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showOne, toggleShowOne, setShowOne] = useToggle(false);
    const { firstName, middleName, lastName, countiesEditPermission } = useSelector(
        createStructuredSelector({
            firstName: selectUserFirstName,
            middleName: selectUserMiddleName,
            lastName: selectUserLastName,
            countiesEditPermission: selectPermissionsCountiesEdit,
        })
    );
    useEffect(() => {
        setForm({ ...countiesDetails });
    }, [countiesDetails, setForm]);
    const onSubmit = useThrottle(
        async (formData) => {
            setShowProcessing(true);
            const response = await createUpdateCounites(formData);
            setShowProcessing(false);
            if (response.responseCode !== 200) return toast.error(response.responseMessage || "Something went wrong.");
            toast.success("County details have been updated successfully.");
            setShowOne(false);
            setCountiesDetails((prev) => ({ ...prev, ...formData, modifiedBy: `${firstName} ${middleName} ${lastName}`, modifiedDate: moment().format("YYYY-MM-DD") }));
        },
        [setCountiesDetails, setShowOne]
    );
    const onCancelOne = useCallback(() => {
        setShowOne(false);
        setTimeout(() => setForm({ ...countiesDetails }), 500);
    }, [setShowOne, setForm, countiesDetails]);
    const createdByDateTimeInfo = `Created ${countiesDetails.createdDate ? moment(countiesDetails.createdDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${countiesDetails.createdBy?.trim() || "-"}`;
    const updatedByDateTimeInfo = !countiesDetails.modifiedBy?.trim()
        ? ""
        : `Last Updated ${countiesDetails.modifiedDate ? moment(countiesDetails.modifiedDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${countiesDetails.modifiedBy?.trim()}`;
    const apis = useMemo(() => ({ ...communityApis, update: { ...communityApis.create }, create: { ...communityApis.create, body: { countyId } }, getAll: { ...communityApis.getAll, body: { countyId } } }), [countyId]);
    return (
        <>
            <div className="eventDetails">
                <div className="eventHeader">
                    <div style={{ minWidth: 200 }}>
                        <h1>{`County Details`}</h1>
                    </div>
                    <div className="date-time-info">
                        <span title={createdByDateTimeInfo} className="cursor-default text-right overflow-text">
                            {createdByDateTimeInfo}
                        </span>
                        {updatedByDateTimeInfo && (
                            <>
                                <span className="mx-2">|</span>
                                <span title={updatedByDateTimeInfo} className="cursor-default text-right overflow-text">
                                    {updatedByDateTimeInfo}
                                </span>
                            </>
                        )}
                    </div>
                    {countiesDetails.canEdit && countiesEditPermission && <EditIcon onClick={toggleShowOne} />}
                </div>
                <div className="descriptionContainer mt-4">
                    <div className="row">
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">County</h3>
                            <p className="content-txt mt-2">{countiesDetails.name}</p>
                        </div>
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">Account Status</h3>
                            <p className="content-txt mt-2">{getCountiesStatusOptions().find(t => t.value === String(countiesDetails.isActive))?.label}</p>
                        </div>
                    </div>
                </div>
                <div className="descriptionContainer mt-4">
                    <ManagementTable
                        title="County Community"
                        customAddButtonTitle="Add New Community"
                        fields={communityFields}
                        tableTitle={"Associated Communities"}
                        primaryKey={communityCountiesPrimaryKey}
                        customFormModalTitle={"Associate Community"}
                        apis={apis}
                        refreshAfterSubmit
                        hideAddButton={!isAdmin}
                        hideDeleteButtonRow={(row) => {
                            return row.memberCount > 0
                        }}
                        /*   noSorting */
                        customDeleteConfirmationMessage={"Are you sure you want to delete the Community from the County?"}
                        hideActionsColumn={!isAdmin}
                    />
                </div>
            </div>
            <FormModal
                show={showOne}
                showProcessing={showProcessing}
                title={"County"}
                primaryKey={communityPrimaryKey}
                fields={fieldsFinal}
                form={form}
                onChange={onChangeForm}
                onSubmit={onSubmit}
                onCancel={onCancelOne}
                customUpdateConfirmationMessage={"Are you sure want to update the County details?"}
                confirmOnSubmit
            />
        </>
    );
}
