import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly } from "../../helpers";
import { addLOA, deleteUserLOA, editUserLOA, getUserLOA } from "../../services/loa/userLOAServices";
import moment from "moment";

const yesterday = moment().subtract(1, "day");
const isValidDate = function (current) {
    return current.isAfter(yesterday);
};
export const userLOAFields = [
    {
        name: "startDate",
        title: "Start Date",
        filterable: true,
        inputType: EFieldInputTypes.date,
        filter: {
            maxLength: 25,
        },
        cell: (value) => formatDateOnly(value),
        required: true,
        // isValidDate: isValidDate,
    },
    {
        name: "endDate",
        title: "End Date",
        filterable: true,
        inputType: EFieldInputTypes.date,
        filter: {
            maxLength: 25,
        },
        cell: (value) => formatDateOnly(value),
        // isValidDate: isValidDate,

        required: true,
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
    {
        name: "days",
        title: "Days",
        hideInEditForm: true,
        excludeInForm: true,
    },
    {
        name: "note",
        title: "Note",
        inputType: EFieldInputTypes.textarea,
        maxLength: 500,
    },
];
export const userLOAApis = {
    getAll: {
        api: getUserLOA,
        body: {
            userId: "",
        },
    },
    create: {
        api: addLOA,
        body: {
            userId: "",
        },
    },
    delete: {
        api: deleteUserLOA,
        body: {
            userId: "",
        },
    },
    update: {
        api: editUserLOA,
        body: {
            userId: "",
        },
    },
};
