import { MdOutlineAddCircleOutline } from "react-icons/md";

import SearchIcon from "../../assets/images/search-icon.png";

export default function InitialTableLayout({ hideAddButton, title = "Title", customAddButtonTitle = `Add New ${title}`, onClickAdd }) {
    return (
        <div className="text-center initial-table-layout border bg-white">
            <img src={SearchIcon} alt="Search" className="search-icon my-3" />
            <h5 className="mx-auto">Begin your search by entering a search term or applying filters.</h5>
            {!hideAddButton && (
                <div className="filterAdduserBtns justify-content-center my-3">
                    <button className="addNewUser btn" onClick={onClickAdd}>
                        <MdOutlineAddCircleOutline /> {customAddButtonTitle}
                    </button>
                </div>
            )}
        </div>
    );
}
