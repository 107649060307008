import { useCallback, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import BreadCrumb from "../../components/BreadCrumb";
import ManagementTable from "../../components/ManagementTable";
import { generatePassword } from "../../helpers";
import { getRoleOptions } from "../../services/roles/roleServices";
import { selectUserIsAdmin, selectUserIsCommunityDirector } from "../../redux/userReducer/userSelectors";
import { userApis, userFields, userFormFields, userInitialForm, userInitialSearchForm, userInitialSort, userSearchFields } from "./DATA";

export default function Users() {
    const [roleOptions, setRoleOptions] = useState([]);
    const { isAdmin, isCommunityDirector } = useSelector(createStructuredSelector({ isAdmin: selectUserIsAdmin, isCommunityDirector: selectUserIsCommunityDirector }));

    const navigate = useNavigate();
    const runAfterSubmit = useCallback(
        (_formData, response) => {
            const userId = response.responseData?.id;

            navigate(`/users/${userId}`);
        },
        [navigate]
    );

    const initialForm = useMemo(() => ({ ...userInitialForm, password: generatePassword() }), []);
    const formFields = useMemo(
        () =>
            !isAdmin && !isCommunityDirector
                ? userFormFields
                : userFormFields.map((field) =>
                      field.name !== "formSection"
                          ? field
                          : {
                                ...field,
                                fields: field.fields.map((f) =>
                                    f.name !== "roleName"
                                        ? f
                                        : {
                                              ...f,
                                              required: true,
                                              options: roleOptions,
                                          }
                                ),
                            }
                  ),
        [isAdmin, isCommunityDirector, roleOptions]
    );

    useEffect(() => {
        getRoleOptions().then((options) => {
            setRoleOptions(isAdmin ? options.data : options.data.filter((option) => option.label !== "Admin"));
        });
    }, [isAdmin]);

    return (
        <>
            <BreadCrumb title="User Management" />
            <ManagementTable
                title="User"
                tableTitle={"Users"}
                primaryKey={"userId"}
                customFormModalTitle={"Manage User"}
                fields={userFields}
                formFields={formFields}
                apis={userApis}
                initialForm={initialForm}
                runAfterSubmit={runAfterSubmit}
                initialSearchForm={userInitialSearchForm}
                searchFields={userSearchFields}
                initialSort={userInitialSort}
                initialFilterCount={6}
                searchCols={{ open: 12, close: 6 }}
                withSearch
                hideActionsColumn
            />
        </>
    );
}
