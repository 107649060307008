import { buildURL, callApi, Urls } from "../../helpers/api-urls";
export const UserLoaPrimaryKey = "loaId";
export const addLOA = async (formData) => {
    const response = await callApi.post(buildURL(Urls.AddUserLOA), {
        userGuid: formData.userId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        note: formData.note,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId } } : response;
};
export const getUserLOA = async ({ userId, ...filters }) => {
    const headers = {
        userId,
    };

    const response = await callApi.get(buildURL(Urls.GetUserLOA + userId), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData.searchResult)
            ? response.responseData.searchResult.map((row) => {
                  return {
                      //   [UserMilestonePrimaryKey]: row.milestoneId,
                      loaId: row.loaId,
                      userId: row.userId,
                      startDate: row.startDate,
                      endDate: row.endDate,
                      days: row.days,
                      note: row.note,
                  };
              })
            : [],
        canAdd: response.responseData?.canAdd,
        canDelete: response.responseData?.canDelete,
        canEdit: response.responseData?.canEdit,
        totalRecords: response.responseData?.totalRecords || 0,
    };
};
export const deleteUserLOA = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserLOA), {
        loaId: formData.loaId,
        userId: formData.userId,
    });

    return response;
};
export const editUserLOA = async (formData) => {
    const response = await callApi.post(buildURL(Urls.UpdateUserLOA), {
        startDate: formData.startDate,
        endDate: formData.endDate,
        note: formData.note,
        loaId: formData.loaId,
        userId: formData.userId,
    });

    return response.responseCode === 200 ? { ...response } : response;
};
