import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { Col, Modal, Row } from "react-bootstrap";
// import Tooltip from "../../components/Tooltip";
import ManagementTable from "../../components/ManagementTable";
import AddRowsModal from "../../components/AddRowsModal";
import UpdateStatusButtons from "./UpdateStatusButtons.component";
import ExtraButtons from "./ExtraButtons.component";
import FormModal from "../../components/FormModal";
import messages from "../../helpers/messages";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { selectPermissionsUserNoteAdd } from "../../redux/permissionReducer/permissionSelectors";
import { downloadFile, showResponseMessage } from "../../helpers";
import { eventAttendeesFields, eventAttendeeApis, searchEventAttendeeUserApis } from "./DATA";
import { userFields } from "../Users/DATA";
import { EEventTypes } from "../../services/events/eventServices";
import { userNoteFormFields, userNoteInitialForm } from "../UserNotes/DATA";
import { createUserNote } from "../../services/users/userNoteServices";
import {
    addEventAttendee,
    eventAttendeePrimaryKey,
    getEventAttendeeStatusOptionsByType,
    getEventAttendeeRoleOptions,
    updateAttendeesStatus,
    EEventAttendeeFieldNames,
} from "../../services/events/eventAttendeeServices";

import "./EventAttendees.css";

export default function EventAttendees({ eventId, dataRows, selectUserSearchFields, setEventDetails, myUserId, eventDetails, setRefresh, refresh, userInitialSearchForm, setDataRows }) {
    const initialNoteForm = useMemo(() => ({ ...userNoteInitialForm, noteDate: eventDetails.eventDate }), [eventDetails.eventDate]);

    const [show, toggleShow, setShow] = useToggle(false);
    const [showMassNote, toggleShowMassNote] = useToggle(false);
    const [formMassNote, onChangeMassNote, setFormMassNote] = useForm(initialNoteForm);
    const [statusOptions, setStatusOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedStatusOption, setSelectedStatusOption] = useState(null);
    const [selectedRoleOption, setSelectedRoleOption] = useState(null);
    const selectedAttendees = useRef([]);
    const processing = useRef(false);
    const noteId = useRef("");

    const userNoteAddPermission = useSelector(selectPermissionsUserNoteAdd);

    const onChangeAttendeeSelection = useCallback((selected) => {
        selectedAttendees.current = selected;
    }, []);

    const runAfterDelete = useCallback(
        (data) => {
            const filterCallback = (user) => user.eventAttendeeId !== data.eventAttendeeId;
            selectedAttendees.current = selectedAttendees.current.filter(filterCallback);
            setDataRows((prev) => prev.filter(filterCallback));
            if (data.userId === myUserId) setEventDetails((prev) => ({ ...prev, isAttendee: false }));
        },
        [myUserId, setDataRows, setEventDetails]
    );

    const warningRef = useRef(false);

    const handleMassNote = useThrottle(
        () => {
            if (!selectedAttendees.current.length) {
                if (!warningRef.current) {
                    toast.warn("Please select at least one attendee.");
                    warningRef.current = true;
                    setTimeout(() => {
                        warningRef.current = false;
                    }, 5000);
                }
                return;
            }
            toggleShowMassNote(true);
        },
        [toggleShowMassNote],
        1000
    );
    const onSubmitNote = useCallback(
        async (formData) => {
            const response = await createUserNote({ ...formData, entityTypeId: eventId, noteType: `Event: ${eventDetails.eventTypeName}`, userIds: selectedAttendees.current.map((u) => u.userId) });

            showResponseMessage(response, {
                success: messages.createUserNote,
            });

            if (response.responseCode !== 200) return;

            setFormMassNote({ ...initialNoteForm });
            toggleShowMassNote(false);
        },
        [eventDetails.eventTypeName, eventId, initialNoteForm, setFormMassNote, toggleShowMassNote]
    );
    const onCancelNote = useCallback(() => {
        toggleShowMassNote(false);
        setTimeout(() => setFormMassNote({ ...initialNoteForm }), 500);
    }, [initialNoteForm, setFormMassNote, toggleShowMassNote]);

    const onAddEventAttendees = useCallback(
        async (selectedUsers) => {
            if (processing.current) return;
            processing.current = true;
            const response = await addEventAttendee({
                eventId,
                userIds: selectedUsers.map((user) => user.userGuid),
            });
            setTimeout(() => {
                processing.current = false;
            }, 2000);
            showResponseMessage(response, { success: "User(s) have been successfully added to the event." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            setShow(false);
        },
        [eventId, setRefresh, setShow]
    );

    const extraActionButtons = useCallback(
        ({ data }) => <ExtraButtons data={data} eventDate={eventDetails.eventDate} eventId={eventId} eventTypeName={eventDetails.eventTypeName} />,
        [eventDetails.eventDate, eventDetails.eventTypeName, eventId]
    );

    const handleCreateFile = useThrottle(
        () => {
            const fileName = `Attendees_${eventDetails.eventTypeName}_${moment().format("MM_DD_YYYY")}.txt`;
            const text =
                (selectedAttendees.current.length ? selectedAttendees.current : dataRows)
                    .map((d) => `${d[EEventAttendeeFieldNames.firstName]} ${d[EEventAttendeeFieldNames.lastName]} <${d[EEventAttendeeFieldNames.email] || "---"}>`)
                    .join("; ") + ";";
            const url = "data:text/plain;charset=utf-8," + encodeURIComponent(text);

            downloadFile(url, fileName);
        },
        [dataRows, eventDetails.eventTypeName],
        2000
    );

    const onSubmitUpdateStatus = useThrottle(
        async () => {
            if (processing.current) return;
            if (!selectedStatusOption && (eventDetails.eventTypeName === "Discovery Session" ? !selectedRoleOption : true)) return toast.warning("Please select an status option to update status.");
            if (!selectedAttendees.current.length) return toast.warning("Please select at least one attendee to update status.");

            processing.current = true;

            const response = await updateAttendeesStatus({
                eventId,
                eventAttendeeIds: selectedAttendees.current.map((user) => user.eventAttendeeId),
                statusCode: selectedStatusOption?.value || "",
                roleCode: eventDetails.eventTypeName === "Discovery Session" ? selectedRoleOption?.value ?? "" : undefined,
                noteId: noteId.current,
            });

            showResponseMessage(response);
            setTimeout(() => {
                processing.current = false;
            }, 2000);

            if (response.responseCode !== 200) return;

            selectedAttendees.current = [];
            noteId.current = "";
            setRefresh((prev) => prev + 1);
            setSelectedStatusOption(null);
            setSelectedRoleOption(null);
        },
        [eventDetails.eventTypeName, eventId, selectedRoleOption, selectedStatusOption, setRefresh],
        2000
    );

    const fields = useMemo(
        () =>
            eventAttendeesFields.filter((field) => {
                if (eventDetails.eventTypeName !== EEventTypes.discoverySession && field.name === "roleName") return false;
                if (eventDetails.eventTypeName !== EEventTypes.peerGroup && field.name === "monthlyFormStatusName") return false;
                return true;
            }),
        [eventDetails.eventTypeName]
    );

    useEffect(() => {
        if (eventDetails.eventTypeCode) getEventAttendeeStatusOptionsByType({ eventType: eventDetails.eventTypeCode }).then((response) => setStatusOptions(response.data));
    }, [eventDetails.eventTypeCode]);
    useEffect(() => {
        if (eventDetails.eventTypeName === "Discovery Session") getEventAttendeeRoleOptions().then((response) => setRoleOptions(response.data));
    }, [eventDetails.eventTypeName]);

    useEffect(() => {
        if (!Array.isArray(dataRows)) setRefresh((prev) => prev + 1);
    }, [dataRows, setRefresh]);

    const childrenBeforeAddButton = useMemo(
        () => (
            <div className="d-inline-flex ml-auto attend-buttons">
                {userNoteAddPermission && (
                    <button className="btnPrimaryOutline btn mx-2" style={{ minWidth: 140 }} type="button" onClick={handleMassNote}>
                        ADD MASS NOTE
                    </button>
                )}
                <button className="btnPrimaryOutline btn mx-2" style={{ minWidth: 230 }} type="button" onClick={handleCreateFile}>
                    CREATE DISTRIBUTION LIST
                </button>
                {eventDetails.canEditAttendees && (
                    <UpdateStatusButtons
                        eventTypeName={eventDetails.eventTypeName}
                        statusOptions={statusOptions}
                        roleOptions={roleOptions}
                        selectedStatusOption={selectedStatusOption}
                        selectedRoleOption={selectedRoleOption}
                        noteId={noteId}
                        eventDate={eventDetails.eventDate}
                        eventId={eventId}
                        selectedAttendees={selectedAttendees}
                        setSelectedStatusOption={setSelectedStatusOption}
                        setSelectedRoleOption={setSelectedRoleOption}
                        onSubmitUpdateStatus={onSubmitUpdateStatus}
                    />
                )}
            </div>
        ),
        [
            eventDetails.canEditAttendees,
            eventDetails.eventDate,
            eventDetails.eventTypeName,
            eventId,
            handleCreateFile,
            handleMassNote,
            onSubmitUpdateStatus,
            roleOptions,
            selectedRoleOption,
            selectedStatusOption,
            statusOptions,
            userNoteAddPermission,
        ]
    );

    return (
        <div className="attendees-table">
            {!dataRows?.length ? (
                <div className="card">
                    <div className="attendeesActionSec d-flex justify-content-between">
                        <div className="py-2">
                            <h1 className="mb-3">Attendees</h1>
                        </div>
                        {eventDetails.canAddAttendees && (
                            <div className="filterAdduserBtns align-items-center">
                                <button className="addNewUser btn ml-auto" onClick={toggleShow}>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" d="M0 0h24v24H0V0z" />
                                        <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                    </svg>{" "}
                                    Add RSVP
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <ManagementTable
                    title="Attendee"
                    tableTitle={"Attendees"}
                    fields={fields}
                    onClickAdd={toggleShow}
                    data={dataRows}
                    customAddButtonTitle="Add RSVP"
                    apis={eventAttendeeApis}
                    primaryKey={eventAttendeePrimaryKey}
                    extraActionButtons={extraActionButtons}
                    initialActionsColumnWidth={180}
                    onChangeSelection={onChangeAttendeeSelection}
                    runAfterDelete={runAfterDelete}
                    withCheckboxes={eventDetails.canEditAttendees || userNoteAddPermission}
                    hideAddButton={!eventDetails.canAddAttendees}
                    hideDeleteButton={!eventDetails.canDeleteAttendees}
                    clearSelection={refresh}
                    childrenBeforeAddButton={childrenBeforeAddButton}
                    hideEditButton
                />
            )}
            <AddRowsModal
                show={show}
                onHide={toggleShow}
                fields={userFields}
                apis={searchEventAttendeeUserApis}
                initialSearchForm={userInitialSearchForm}
                searchFields={selectUserSearchFields}
                onAddRows={onAddEventAttendees}
                confirmationMessage={`Are you sure you want to add the user(s) to the event?`}
            />
            <FormModal title="Mass Notes" show={showMassNote} fields={userNoteFormFields} form={formMassNote} onChange={onChangeMassNote} onSubmit={onSubmitNote} onCancel={onCancelNote} />
        </div>
    );
}
