import { buildURL, callApi, Urls } from "../../helpers/api-urls";

const userMonthlyReportsKeyMaps = {
  date: "EventDate",
  emotions: "Emotion",
  focusAreas: "FocusAreas",
  status: "Status",
};

export const getMonthlyData = async ({ userId, ...filters }) => {
  const response = await callApi.get(buildURL(Urls.GetMonthlyResultForms) + userId, {
    headers: { ...filters, sortField: userMonthlyReportsKeyMaps[filters.sortField] || filters.userMonthlyReportsKeyMaps },
  });

  return {
    data: Array.isArray(response.responseData)
      ? response.responseData.map((u) => {
          return {
            status: u.status,
            focusAreas: u.focusAreas,
            emotions: u.emotionIndex,
            date: u.eventDate,
            eventId: u.eventId,
            monthlyFormId: u.monthlyFormId,
          };
        })
      : [],
    totalRecords: response.totalRecords || response.responseData?.length || 0,
  };
};
