import { buildURL, callApi } from "../../helpers/api-urls";

export const getUserIndustryType = async () => {
  const response = await callApi.get(buildURL("/Lookup/IndustryType"));

  return response;
};
export const getUserServiceOfferingType = async () => {
  const response = await callApi.get(buildURL("/Lookup/ServiceType"));

  return response;
};
export const getUserBusinessType = async () => {
  const response = await callApi.get(buildURL("/Lookup/BusinessType"));

  return response;
};
