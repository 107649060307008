import { useCallback, useEffect, useRef } from "react";

import ManagementTable from "../../components/ManagementTable";
import AddRowsModal from "../../components/AddRowsModal";
import { communityStaffApis, communityStaffFields, selectUserSearchFields } from "./DATA";
import { useToggle } from "../../helpers/hooks";
import { addCommunityStaffMembers, communityStaffPrimaryKey } from "../../services/communities/communityStaffServices";
import { userApis, userFields, userInitialSearchForm } from "../Users/DATA";
import { showResponseMessage } from "../../helpers";

export default function CommunityStaff({ communityId, communityDetails, staff, setRefresh, setStaff }) {
    const [show, toggleShow, setShow] = useToggle(false);
    const processing = useRef(false);

    const runAfterDelete = useCallback((row) => setStaff((prev) => prev.filter((data) => data[communityStaffPrimaryKey] !== row[communityStaffPrimaryKey])), [setStaff]);
    const runAfterUpdate = useCallback(
        ({ startDate, endDate, communityStaffId }) => setStaff((prev) => prev.map((data) => (data[communityStaffPrimaryKey] !== communityStaffId ? data : { ...data, startDate, endDate }))),
        [setStaff]
    );

    const onAddUsers = useCallback(
        async (selectedUsers) => {
            if (processing.current) return;
            processing.current = true;

            const response = await addCommunityStaffMembers({
                communityId,
                userIds: selectedUsers.map((user) => user.userGuid),
            });

            setTimeout(() => {
                processing.current = false;
            }, 2000);
            showResponseMessage(response, { success: "User(s) have been successfully added to the community." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            setShow(false);
        },
        [communityId, setRefresh, setShow]
    );

    useEffect(() => {
        if (!Array.isArray(staff)) setRefresh((prev) => prev + 1);
    }, [staff, setRefresh]);

    return (
        <>
            <ManagementTable
                title="Director"
                tableTitle="Directors"
                primaryKey={communityStaffPrimaryKey}
                data={staff}
                apis={communityStaffApis}
                fields={communityStaffFields}
                onClickAdd={toggleShow}
                runAfterDelete={runAfterDelete}
                runAfterSubmit={runAfterUpdate}
                customAddButtonTitle={"Add Directors"}
                customDeleteConfirmationMessage={"Are you sure want to delete?"}
                customDeleteSuccessMessage={"Staff has been removed successfully from this community."}
                customUpdateConfirmationMessage={"Are you sure want to update?"}
                customUpdateSuccessMessage={"Record has been updated successfully."}
                hideAddButton={!communityDetails.canAddStaff}
                hideEditButton={!communityDetails.canEditStaff}
                hideDeleteButton={!communityDetails.canDeleteStaff}
                hideActionsColumn={!communityDetails.canEditStaff && !communityDetails.canDeleteStaff}
                confirmOnSubmit
            />
            <AddRowsModal
                show={show}
                apis={userApis}
                fields={userFields}
                searchFields={selectUserSearchFields}
                initialSearchForm={userInitialSearchForm}
                onAddRows={onAddUsers}
                onHide={toggleShow}
                confirmationMessage={`Are you sure you want to add the director(s) to the community?`}
            />
        </>
    );
}
