import moment from "moment";

import { createGetOptionsApi } from "..";
import { formToFormData, renameObjectKey } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const userNotePrimaryKey = "userNoteId";

export const getUserNotes = async (filters) => {
    renameObjectKey(filters, "noteDate", "noteSelectedDate");
    renameObjectKey(filters, "documentExists", "isDocumentExists");
    renameObjectKey(filters, "noteDescription", "noteKeywords");
    renameObjectKey(filters, "userId", "noteForUserId");

    const response = await callApi.get(buildURL("/Search/Note"), {
        headers: filters,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((row) => ({
                  ...row,
                  [userNotePrimaryKey]: row.noteId,
                  noteDescription: row.notesDescription,
                  timeZoneName: row.noteDateTimeZone,
                  tags: row.tags?.split(", ") || [],
                  userId: filters.noteForUserId,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const getUserNoteTypeOptions = createGetOptionsApi("/Lookup/NoteType", "noteTypeOptions", 5);

export const createUserNote = async ({ noteDate: noteSelectedDateOrEventDate, noteType, documentName, documentFile: noteDocumentFile, tags, noteDescription, userIds }) => {
    const formData = formToFormData({
        noteSelectedDateOrEventDate,
        noteType,
        documentName,
        noteDocumentFile,
        tags: tags.join(", "),
        noteDescription,
        createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
    });

    formData.append("userGuids", userIds.join(", "));

    const response = await callApi.post(buildURL("/User/AddNote"), formData);

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.noteId } } : response;
};

export const deleteUserNote = async ({ noteById: userId, userNoteId: noteId }) => {
    const response = await callApi.post(buildURL("/User/DeleteNote"), {
        userId,
        noteId,
    });

    return response;
};

export const addMemberToPeerGroup = async (formData) => {
    const data = {
        peerGroupCode: formData.peerGroupId,
        userGuids: formData.userIds,
    };

    const response = await callApi.post(buildURL(Urls.PeerGroupMemberAdd), data);

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId } } : response;
};
