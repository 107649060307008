import { getCountryOptions } from "../../services/countries/countryServices";
import { getStateOptions } from "../../services/states/stateServices";

export const initialAddressForm = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  countryCode: "",
  isPrimaryAddress: "true",
  postal: "",
  state: "",
  stateCode: "",
  userContactInfoId: "",
  userContactType: "",
  userGuid: "",
};

export const initialPhoneForm = {
  isPrimaryPhone: "true",
  phoneNumber: "",
  phoneType: "Mobile",
};

export const initialEmailForm = {
  isPrimaryEmail: "true",
  email: "",
};
export const initialPreferredForm = {
  preferredContactMethod: "true",
};

export const contactAddressFields = [
  {
    name: "address1",
    title: "Address 1",
    inputType: "text",
    required: true,
    maxLength: 100,
  },
  {
    name: "address2",
    title: "Address 2",
    inputType: "text",
    maxLength: 100,
  },
  {
    name: "country",
    nameForm: "countryCode",
    title: "Country",
    inputType: "select",
    getOptions: getCountryOptions,
    required: true,
  },
  {
    name: "state",
    nameForm: "stateCode",
    title: "State",
    inputType: "select",
    required: true,
    getOptions: getStateOptions,
  },
  {
    name: "city",
    title: "City",
    inputType: "text",
    required: true,
    maxLength: 50,
  },
  {
    name: "postal",
    title: "Zip Code",
    inputType: "number",
    required: true,
    maxLength: 12,
  },
  {
    name: "isPrimaryAddress",
    title: "Set as primary",
    inputType: "radio",
    required: true,
    options: [
      {
        label: "Yes",
        value: "true",
      },
      {
        label: "No",
        value: "false",
      },
    ],
  },
];

export const contactBusinessPhoneFields = [
  {
    name: "phoneNumber",
    title: "Phone Number",
    inputType: "number",
    required: true,
    maxLength: 15,
    minLength: 10,
  },
  {
    name: "phoneType",
    disableEmptyOption: true,
    required: true,
    title: "Phone Type",
    inputType: "select",
    options: [
      {
        label: "Mobile",
        value: "Mobile",
      },

      {
        label: "Landline",
        value: "Landline",
      },
    ],
  },
  {
    name: "isPrimaryPhone",
    title: "Set as primary",
    inputType: "radio",
    required: true,
    options: [
      {
        label: "Yes",
        value: "true",
      },
      {
        label: "No",
        value: "false",
      },
    ],
  },
];
export const contactPersonalPhoneFields = [
  {
    name: "phoneNumber",
    title: "Phone Number",
    inputType: "number",
    required: true,
    maxLength: 15,
  },
  {
    name: "phoneType",
    disableEmptyOption: true,
    required: true,
    title: "Phone Type",
    inputType: "select",
    options: [
      {
        label: "Mobile",
        value: "Mobile",
      },
      {
        label: "Landline",
        value: "Landline",
      },
    ],
  },
  {
    name: "isPrimaryPhone",
    title: "Set as primary",
    inputType: "radio",
    required: true,
    options: [
      {
        label: "Yes",
        value: "true",
      },
      {
        label: "No",
        value: "false",
      },
    ],
  },
];
export const emailFields = [
  {
    name: "email",
    title: "Email",
    inputType: "email",
    required: true,
    maxLength: 200,
  },

  {
    name: "isPrimaryEmail",
    title: "Set as primary",
    inputType: "radio",
    required: true,
    options: [
      {
        label: "Yes",
        value: "true",
      },
      {
        label: "No",
        value: "false",
      },
    ],
  },
];
export const preFields = [
  {
    name: "preferredContactMethod",
    title: "Preferred Contact Method",
    inputType: "radio",
    required: true,
    options: [
      {
        label: "Email",
        value: "Email",
      },
      {
        label: "Phone",
        value: "Phone",
      },
      {
        label: "Text",
        value: "Text",
      },
    ],
  },
];
