import { useEffect } from "react";
import ReactGA from "react-ga";
import GreenTick from "../../assets/images/green-tick.svg";
import Footer from "../../components/BodyContainer/Footer.component";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

const NotScheduledRole = ({ isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/waitlist" + window.location.search);
    }, [gaEventTracker]);
    return (
        <>
            <div className="signUpRightFormSec">
                <div className="rightSecForm">
                    <div className="thankYouPage">
                        <div className="thanks-header">
                            <img className="greentick" src={GreenTick} alt="Tick" />
                            <h3>Thank you for your interest in our peer groups!</h3>
                        </div>
                        <p className="thanks-info">
                            Thank you for your interest in our peer groups! We know you’re dedicated to building your business, and we’re dedicated to helping you out. At the moment, we don’t have an
                            open spot for you in a peer group. But that’s only temporary.
                        </p>
                        <p className="thanks-info">
                            We’ve added you to our wait list and will be in touch the minute there’s an opening in your area. And with new groups forming all the time, it shouldn’t take too long.
                        </p>
                        <p className="not-schedule-txt">
                            So, hang tight and if you have any questions, please contact us at
                            <a style={{ paddingLeft: 5 }} href="mailto:info@e4ever.org">
                                info@e4ever.org
                            </a>{" "}
                        </p>
                    </div>
                </div>
            </div>
            {!isFunderLogo && (
                <div className="signupFooter">
                    <Footer />
                </div>
            )}
        </>
    );
};

export default NotScheduledRole;
