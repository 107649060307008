import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";

import FormModal from "../../components/FormModal";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { selectPermissionsFunderEdit } from "../../redux/permissionReducer/permissionSelectors";

import { useParams } from "react-router-dom";
import EditIcon from "../../components/EditIcon";
import ManagementTable from "../../components/ManagementTable";
import { selectUserFirstName, selectUserIsAdmin, selectUserLastName, selectUserMiddleName } from "../../redux/userReducer/userSelectors";
import { communityFunderPrimaryKey, createUpdateFunders, funderPrimaryKey } from "../../services/funder/funderService";
import { funderFormFields } from "../Funder/DATA";
import { communityApis, communityFields } from "./DATA";
import "./FunderDetails.css";

export default function Details({ funderDetails, setFunderDetails }) {
    const { funderId } = useParams();
    const { isAdmin } = useSelector(createStructuredSelector({ isAdmin: selectUserIsAdmin }));
    const [fieldsFinal, setFieldsFinal] = useState(funderFormFields);
    const [form, onChangeForm, setForm] = useForm({}, funderFormFields, setFieldsFinal);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showOne, toggleShowOne, setShowOne] = useToggle(false);
    const { firstName, middleName, lastName, funderEditPermission } = useSelector(
        createStructuredSelector({
            firstName: selectUserFirstName,
            middleName: selectUserMiddleName,
            lastName: selectUserLastName,
            funderEditPermission: selectPermissionsFunderEdit,
        })
    );
    useEffect(() => {
        setForm({ ...funderDetails });
    }, [funderDetails, setForm]);
    const onSubmit = useThrottle(
        async (formData) => {
            setShowProcessing(true);
            const response = await createUpdateFunders(formData);
            setShowProcessing(false);
            if (response.responseCode !== 200) return toast.error(response.responseMessage || "Something went wrong.");
            toast.success("Funder details have been updated successfully.");
            setShowOne(false);
            setFunderDetails((prev) => ({ ...prev, ...formData, logo1: isValidWebUrl(formData.logo1) ? formData.logo1 : URL.createObjectURL(formData.logo1), logo2: isValidWebUrl(formData.logo2) ? formData.logo2 : URL.createObjectURL(formData.logo2), modifiedBy: `${firstName} ${middleName} ${lastName}`, modifiedDate: moment().format("YYYY-MM-DD") }));
        },
        [setFunderDetails, setShowOne]
    );
    const isValidWebUrl = (url) => {
        let regEx = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return regEx.test(url);
    }
    const onCancelOne = useCallback(() => {
        setShowOne(false);
        setTimeout(() => setForm({ ...funderDetails }), 500);
    }, [setShowOne, setForm, funderDetails]);
    const createdByDateTimeInfo = `Created ${funderDetails.createdDate ? moment(funderDetails.createdDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${funderDetails.createdBy?.trim() || "-"}`;
    const updatedByDateTimeInfo = !funderDetails.modifiedBy?.trim()
        ? ""
        : `Last Updated ${funderDetails.modifiedDate ? moment(funderDetails.modifiedDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${funderDetails.modifiedBy?.trim()}`;
    const apis = useMemo(() => ({ ...communityApis, update: { ...communityApis.create }, create: { ...communityApis.create, body: { funderId } }, getAll: { ...communityApis.getAll, body: { funderId } } }), [funderId]);
    return (
        <>
            <div className="eventDetails">
                <div className="eventHeader">
                    <div style={{ minWidth: 200 }}>
                        <h1>{`Funder Details`}</h1>
                    </div>
                    <div className="date-time-info">
                        <span title={createdByDateTimeInfo} className="cursor-default text-right overflow-text">
                            {createdByDateTimeInfo}
                        </span>
                        {updatedByDateTimeInfo && (
                            <>
                                <span className="mx-2">|</span>
                                <span title={updatedByDateTimeInfo} className="cursor-default text-right overflow-text">
                                    {updatedByDateTimeInfo}
                                </span>
                            </>
                        )}
                    </div>
                    {funderDetails.canEdit && funderEditPermission && <EditIcon onClick={toggleShowOne} />}
                </div>
                <div className="descriptionContainer mt-4">
                    <div className="row">
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">Funder</h3>
                            <p className="content-txt mt-2">{funderDetails.name}</p>
                        </div>
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">Funder Code</h3>
                            <p className="content-txt mt-2">{funderDetails.code}</p>
                        </div>
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">Email</h3>
                            <p className="content-txt mt-2">{funderDetails.email}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="descriptionContet col-md-12">
                            <h3 className="contentHeading">Funder Notes</h3>
                            <p className="content-txt mt-3">{funderDetails.notes}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">Start Date</h3>
                            <p className="content-txt mt-3">{funderDetails.startDate ? moment(funderDetails.startDate).format("MM/DD/YYYY") : ""}</p>
                        </div>
                        <div className="descriptionContet col-md-4 ">
                            <h3 className="contentHeading ">End Date</h3>
                            <p className="content-txt mt-3">{funderDetails.endDate ? moment(funderDetails.endDate).format("MM/DD/YYYY") : ""}</p>
                        </div>

                    </div>
                    <div className="row">
                        <div className="descriptionContet col-md-4">
                            <h3 className="contentHeading">White Label</h3>
                            <p className="content-txt mt-3">{funderDetails.isWhiteLabel}</p>
                        </div>
                        {
                            funderDetails.isWhiteLabel === "ON" ? (<>
                                <div className="descriptionContet col-md-4">
                                    <h3 className="contentHeading">Sign In Logo</h3>
                                    <div className="personalImage content-txt mt-3">
                                        <img src={funderDetails.logo1} alt={funderDetails.name} />
                                    </div>
                                </div>
                                <div className="descriptionContet col-md-4">
                                    <h3 className="contentHeading">Navigation Logo</h3>
                                    <div className="content-txt personalImage mt-3">
                                        <img src={funderDetails.logo2} alt={funderDetails.name} />
                                    </div>
                                </div>
                            </>) : <></>
                        }

                    </div>
                    <div className="row">
                        <div className="descriptionContet col-md-6">
                            <h3 className="contentHeading ">Email Signature Format 1</h3>
                            <p className="content-txt mt-3">{funderDetails.emailSignature1}</p>
                        </div>
                        <div className="descriptionContet col-md-6">
                            <h3 className="contentHeading">Email Signature Format 2</h3>
                            <p className="content-txt mt-3">{funderDetails.emailSignature2}</p>
                        </div>
                    </div>
                </div>
                <div className="descriptionContainer mt-4">
                    <ManagementTable
                        title="Funder Community"
                        customAddButtonTitle="Add New Community"
                        fields={communityFields}
                        tableTitle={"Associated Communities"}
                        primaryKey={communityFunderPrimaryKey}
                        customFormModalTitle={"Associate Community"}
                        apis={apis}
                        refreshAfterSubmit
                        hideAddButton={!isAdmin}
                        /*     noSorting */
                        customDeleteConfirmationMessage={"Are you sure you want to delete the Community from the Funder?"}
                        hideActionsColumn={!isAdmin}
                    />
                </div>
            </div>


            <FormModal
                show={showOne}
                showProcessing={showProcessing}
                title={"Funder"}
                primaryKey={funderPrimaryKey}
                fields={fieldsFinal}
                form={form}
                onChange={onChangeForm}
                onSubmit={onSubmit}
                onCancel={onCancelOne}
                customUpdateConfirmationMessage={"Are you sure want to update the funder details?"}
                confirmOnSubmit
            />
        </>
    );
}
