import { useCallback, useMemo } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { FaUserAlt } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { HiOutlineMenuAlt1 } from "react-icons/hi";

import SmallLogo from "../../assets/images/logo-small.svg";
import { signOutUserAction } from "../../redux/userReducer/userActions";
import {
    selectUserFirstName,
    selectUserLastName,
    selectUserId,
    selectUserIsProspectQualified,
    selectUserIsProspectWaitlist,
    selectUserIsProspectNotScheduled,
} from "../../redux/userReducer/userSelectors";

const Header = ({ menuCollapse, toggleMenuCollapse }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { firstName, lastName, userId } = useSelector(createStructuredSelector({ firstName: selectUserFirstName, lastName: selectUserLastName, userId: selectUserId }));

    const signOut = useCallback(() => {
        dispatch(signOutUserAction());
        navigate("/sign-in");
    }, [dispatch, navigate]);
    const homeHeader = useMemo(() => (location.pathname === "/" ? "home-header" : ""), [location.pathname]);
    const { isProspectQualified, isProspectWaitlist, isProspectNotScheduled } = useSelector(
        createStructuredSelector({
            isProspectQualified: selectUserIsProspectQualified,
            isProspectWaitlist: selectUserIsProspectWaitlist,
            isProspectNotScheduled: selectUserIsProspectNotScheduled,
        })
    );
    return (
        <header className={`siteNav ${homeHeader}`}>
            <Container fluid>
                <Row>
                    <Col className="siteMenuInner">
                        <div className="closemenu showMob" onClick={toggleMenuCollapse}>
                            {!menuCollapse ? <MdClose className="close-menu-ic" /> : <HiOutlineMenuAlt1 className="close-menu-ic" />}
                        </div>
                        <div className="mobileLogo">
                            <img alt="Logo" src={SmallLogo} className="smallLogo" />
                        </div>
                        <Nav className="justify-content-end">
                            <Nav.Item>
                                <Nav.Link as={Link} className="px-0" to={isProspectQualified || isProspectWaitlist || isProspectNotScheduled ? `` : `/users/${userId}/user/details`}>
                                    <FaUserAlt />{" "}
                                    <span>
                                        {firstName} {lastName}
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as="span" className="px-0 cursor-default">
                                    <span>|</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as="span" className="px-0 pointer">
                                    <span onClick={signOut}>Sign out</span>
                                </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                <Nav.Link eventKey="link-1">
                  <FaCog />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2">
                  <FaBell />
                </Nav.Link>
              </Nav.Item> */}
                        </Nav>
                    </Col>
                </Row>
            </Container>
        </header>
    );
};

export default Header;
