import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { getMonthlyData } from "../../../services/monthlyDetails/monthlyService";
import WearyEmoticon from "../../../assets/images/WearyFace.png";
import GrinEmoticon from "../../../assets/images/GrinningFace.png";
import SmileEmoticon from "../../../assets/images/SmilingFace.png";
import NeutralEmoticon from "../../../assets/images/NeutralFace.png";
import FrowningEmoticon from "../../../assets/images/FrowningFace.png";
import Status from "../../../components/Status";

export const monthlyFormPrimaryKey = "monthlyFormId";

export const monthlyListFields = [
    {
        name: "date",
        title: "DATE",
        col: 6,
        required: true,
        cell: (value, data) =>
            (() => {
                const { userId } = useParams();
                return (
                    <Link target={"_blank"} to={`/month-view/${userId}/${data.eventId}/${data.monthlyFormId == null ? 0 : data.monthlyFormId}`}>
                        {moment(value).format("LLL")?.slice(0, -8)}
                    </Link>
                );
            })(),
    },
    {
        name: "emotions",
        title: "EMOTIONS",
        col: 6,
        required: true,
        cell: (value, data) => (
            <div className="emoji-container">
                {value === 1 ? (
                    <img src={WearyEmoticon} className="emoji" alt="unknown" />
                ) : value === 2 ? (
                    <img src={FrowningEmoticon} className="emoji" alt="unknown" />
                ) : value === 3 ? (
                    <img src={NeutralEmoticon} className="emoji" alt="unknown" />
                ) : value === 4 ? (
                    <img src={SmileEmoticon} className="emoji" alt="unknown" />
                ) : value === 5 ? (
                    <img src={GrinEmoticon} className="emoji" alt="unknown" />
                ) : (
                    ""
                )}
            </div>
        ),
    },
    {
        name: "focusAreas",
        title: "FOCUS AREAS",
        col: 12,
        required: true,
        options: [],
    },
    {
        name: "status",
        title: "STATUS",
        col: 12,
        required: true,
        cell: (value) => <Status text={value} />,
    },
];

export const monthlyFormInitialSort = {
    dir: "desc",
    field: "date",
};

export const monthlyFormInitialSearchForm = {
    sortOrder: monthlyFormInitialSort.dir,
    sortField: monthlyFormInitialSort.field,
};

export const monthlyFormApis = {
    getAll: {
        api: getMonthlyData,
    },
};
