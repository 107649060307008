import moment from "moment";
import { toast } from "react-toastify";

import messages from "./messages";

export function formattedPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "";
    const extraNumber = phoneNumber.length > 10 ? phoneNumber.slice(10, phoneNumber.length) : "";
    // convert the raw number to (xxx) xxx-xxx format
    const x = phoneNumber
        .slice(0, 10)
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ""}` + extraNumber;
}
export const acronymFunction = (str) => {
    let acronym = str.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");

    return acronym;
};
export const isEmailValid = (email) =>
    email &&
    String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export function showResponseMessage(response, customMessages) {
    if (response.responseCode !== 200)
        return toast.error(
            customMessages?.error || typeof response.responseData === "string" ? response.responseData : response.responseData?.errorMessege || response.responseMessage || "Something went wrong."
        );

    toast.success(
        customMessages?.success ||
            (typeof response.responseData === "string"
                ? response.responseData
                : typeof response.responseData?.message === "string"
                ? response.responseData.message || "Successfull."
                : response.responseMessage || "Successfull.")
    );
}
export function parseJwt(token) {
    if (!token) return {};
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split("")
            .map((c) => {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
}

export function objectToQueryString(obj = {}) {
    const str = [];

    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function queryStringAsObject() {
    const search = window.location.search.slice(1);

    try {
        const obj = !search ? {} : JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

        for (const key in obj)
            if (obj[key] === "null") obj[key] = null;
            else if (!isNaN(obj[key])) obj[key] = +obj[key];

        return obj;
    } catch (error) {
        return {};
    }
}

export const debounce = (func, delay = 100) => {
    let debounceTimer;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
};

export const throttle = (func, delay = 100) => {
    // Previously called time of the function
    let prev = 0;
    return (...args) => {
        // Current called time of the function
        let now = new Date().getTime();

        // Logging the difference between previously
        // called and current called timings

        // If difference is greater than delay call
        // the function again.
        if (now - prev > delay) {
            prev = now;

            // "..." is the spread operator here
            // returning the function with the
            // array of arguments
            return func(...args);
        }
    };
};
export const dateTimeFormat = "MM/DD/YYYY hh:mm A";
export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "hh:mm A";

export function normalizeDateOnly(dateTime) {
    if (!dateTime || dateTime === "0001-01-01T00:00:00") return "";
    return moment(dateTime.split(" ")[0]).format("YYYY-MM-DD");
}
export function formatDateTime(dateTime) {
    if (!dateTime || dateTime === "0001-01-01T00:00:00") return "";
    return moment(dateTime).format(dateTimeFormat);
}
export function formatTimeOnlyFromDate(dateTime) {
    if (!dateTime || dateTime === "0001-01-01T00:00:00") return "";
    return moment(dateTime).format("hh:mm A");
}
export function formatDateWithFullMonth(dateTime) {
    return moment(dateTime).format("MMMM DD, YYYY");
}
export function formatDateOnly(date) {
    if (!date || date === "0001-01-01T00:00:00") return "";
    return moment(date).format(dateFormat);
}
export function formatTimeOnly(time) {
    if (!time) return "";
    const [hours, minutes] = time.split(":");

    return `${hours <= 12 ? (+hours === 0 ? 12 : hours) : hours - 12 < 10 ? `0${hours - 12}` : hours - 12}:${minutes} ${hours < 12 ? "AM" : "PM"}`;
}
export function formatDateToString(date) {
    moment(date).format("LL");
}
export function formatAmountWithCommas(amount) {
    if (!amount) return "";
    return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function firstAlphabetCapitalize(name) {
    return name?.slice(0, 1)?.toUpperCase() + name?.slice(1, name.length);
}

export function getFormErrors(formFields = [], formData = {}) {
    const errors = {};

    if (Array.isArray(formFields))
        for (const { name, required, nameForm = name, fields, validate, inputType, maxLength, minLength } of formFields) {
            // SKIP required for Search Form.
            const isRequired = typeof required === "function" ? required(formData) : required;
            const value = formData[nameForm];

            if (fields?.length) Object.assign(errors, getFormErrors(fields, formData));

            if (isRequired && !value) {
                errors[nameForm] = messages.requiredField;
            } else {
                if (inputType === "email" && (isRequired ? !isEmailValid(value) : value && !isEmailValid(value))) errors[nameForm] = messages.invalidEmail;
                if (validate) {
                    const error = validate(formData);
                    if (error) errors[nameForm] = error;
                }
                if (typeof value === "string") {
                    if (maxLength && value.length > maxLength) errors[nameForm] = messages.maxLength(maxLength, inputType);
                    if (value && minLength && value.length < minLength) errors[nameForm] = messages.minLength(minLength, inputType);
                }
            }
        }

    return errors;
}

export function isOnMobileDevice() {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            // eslint-disable-next-line no-useless-escape
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
}

export function isStringUrl(string) {
    if (!string || typeof string !== "string" || (!string.toLowerCase().startsWith("http://") && !string.toLowerCase().startsWith("https://"))) return false;
    const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
        "i"
    ); // fragment locator

    return !!pattern.test(string);
}
export function commafy(num) {
    let str = num.toString().split(".");
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 4) {
        str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    return str.join(".");
}
export function generatePassword(length = 8) {
    let password = "";

    const charsetOne = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const charsetTwo = "abcdefghijklmnopqrstuvwxyz";
    const charsetThree = "0123456789";
    const charsetFour = "!@#$%";

    for (let i = 0, n = charsetOne.length; i < 3; ++i) password += charsetOne.charAt(Math.floor(Math.random() * n));
    for (let i = 0, n = charsetTwo.length; i < 3; ++i) password += charsetTwo.charAt(Math.floor(Math.random() * n));
    for (let i = 0, n = charsetThree.length; i < length - 6; ++i) password += charsetThree.charAt(Math.floor(Math.random() * n));
    for (let i = 0, n = charsetFour.length; i < length - 6; ++i) password += charsetFour.charAt(Math.floor(Math.random() * n));

    return password;
}
export function formToFormData(form) {
    const formData = new FormData();

    for (const key in form)
        if (!Array.isArray(form[key])) formData.append(key, form[key]);
        else for (const value of form[key]) formData.append(key, value);

    return formData;
}

export function renameObjectKey(obj, prev, next, setter) {
    if (!obj) return obj;
    obj[next] = setter ? setter(obj[prev]) : obj[prev];
    delete obj[prev];
    return obj;
}

export function downloadFile(url, fileName) {
    const element = document.createElement("a");
    if (fileName) element.setAttribute("download", fileName);
    element.setAttribute("href", url);
    element.setAttribute("style", "display: none;");

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}

export function isSpecialSymbolAllowed(val) {
    return !/^[a-zA-Z0-9]+$/.test(val);
}
