import { createSelector } from "reselect";

export const initialPermissionsState = {
    _fetched: false,
    // Sidebar Permissions:-
    sidebarDashboardMenu: true, // FOR NOW, LET's Keep It initially / Always TRUE.
    sidebarUsersMenu: false,
    sidebarMembersMenu: false,
    sidebarEventsMenu: false,
    sidebarCommunitiesMenu: false,
    sidebarPeerGroupsMenu: false,
    sidebarCurriculumsMenu: false,
    sidebarChecklistsMenu: false,
    sidebarMemberDirectoryMenu: false,
    sidebarReferralsMenu: false,
    sidebarReportsMenu: false,
    // Event Page Permissions:-
    eventOverviewMenu: false,
    eventDetailsMenu: false,
    eventAttendeesMenu: false,
    eventCurriculumMenu: false,
    eventNotesMenu: false,
    eventDocumentsMenu: false,
    eventStaffMenu: false,
    eventPermissionsMenu: false,
    eventEdit: false,
    // User Page Permissions:-
    userMenuPermission: false,
    userDashboardMenu: false,
    userFormsMenu: false,
    userEventsMenu: false,
    userNotesMenu: false,
    userDocumentsMenu: false,
    userDetailsMenu: false,
    userContactInfoMenu: false,
    userBusinessMenu: false,
    userDemographicsMenu: false,
    userPeerGroupsMenu: false,
    userCommunitiesMenu: false,
    userRolesMenu: false,
    userAnnualResultsMenu: false,
    userMilestonesMenu: false,
    userLOAMenu: false,
    userChecklistMenu: false,
    userMonthlyFormMenu: false,
    userAgreementFormMenu: false,
    userInitialFormMenu: false,
    userNoteAdd: false,
    userNoteEdit: false,
    userNoteDelete: false,
    // Peer Group Page Permissions:-
    peerGroupDashboardMenu: false,
    peerGroupDetailsMenu: false,
    peerGroupMembersMenu: false,
    peerGroupNotesMenu: false,
    peerGroupEventsMenu: false,
    peerGroupDocumentsMenu: false,
    peerGroupFacilitatorsMenu: false,
    peerGroupScorecardMenu: false,
    // Community Page Permissions:-
    communityDashboardMenu: false,
    communityDetailsMenu: false,
    communityMembersMenu: false,
    communityNotesMenu: false,
    communityPeerGroupsMenu: false,
    communityEventsMenu: false,
    communityDocumentsMenu: false,
    communityDirectorsMenu: false,
    communityAdd: false,
    communityEdit: false,
    communityDelete: false,
    // Curriculum Permissions

    curriculumAdd: false,
};

export const selectPermissions = (state) => state.permissions;

export const selectPermissionsFetched = createSelector(selectPermissions, (permissions) => permissions._fetched);
// Sidebar Permission Selectors:-
export const selectPermissionsSidebarDashboard = createSelector(selectPermissions, (permissions) => permissions.sidebarDashboardMenu);
export const selectPermissionsSidebarUsersMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarUsersMenu);
export const selectPermissionsSidebarMembersMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarMembersMenu);
export const selectPermissionsSidebarEventsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarEventsMenu);
export const selectPermissionsSidebarCommunitiesMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarCommunitiesMenu);
export const selectPermissionsSidebarPeerGroupsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarPeerGroupsMenu);
export const selectPermissionsSidebarCurriculumsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarCurriculumsMenu);
export const selectPermissionsSidebarChecklistsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarChecklistsMenu);
export const selectPermissionsSidebarMemberDirectoryMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarMemberDirectoryMenu);
export const selectPermissionsSidebarReferralsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarReferralsMenu);
export const selectPermissionsSidebarReportsMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarReportsMenu);
export const selectPermissionsSidebarFunderMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarFunderMenu);
export const selectPermissionsSidebarCountiesMenu = createSelector(selectPermissions, (permissions) => permissions.sidebarCountiesMenu);
// Event Permission Selectors:-
export const selectPermissionsEventOverviewMenu = createSelector(selectPermissions, (permissions) => permissions.eventOverviewMenu);
export const selectPermissionsEventDetailsMenu = createSelector(selectPermissions, (permissions) => permissions.eventDetailsMenu);
export const selectPermissionsEventAttendeesMenu = createSelector(selectPermissions, (permissions) => permissions.eventAttendeesMenu);
export const selectPermissionsEventCurriculumMenu = createSelector(selectPermissions, (permissions) => permissions.eventCurriculumMenu);
export const selectPermissionsEventNotesMenu = createSelector(selectPermissions, (permissions) => permissions.eventNotesMenu);
export const selectPermissionsEventDocumentsMenu = createSelector(selectPermissions, (permissions) => permissions.eventDocumentsMenu);
export const selectPermissionsEventStaffMenu = createSelector(selectPermissions, (permissions) => permissions.eventStaffMenu);
export const selectPermissionsEventPermissionsMenu = createSelector(selectPermissions, (permissions) => permissions.eventPermissionsMenu);
export const selectPermissionsEventEdit = createSelector(selectPermissions, (permissions) => permissions.eventEdit);
// User Permission Selectors:-
export const selectUserMenuPermission = createSelector(selectPermissions, (permissions) => permissions.userMenu);
export const selectPermissionsUserDashboardMenu = createSelector(selectPermissions, (permissions) => permissions.userDashboardMenu);
export const selectPermissionsUserFormsMenu = createSelector(selectPermissions, (permissions) => permissions.userFormsMenu);
export const selectPermissionsUserEventsMenu = createSelector(selectPermissions, (permissions) => permissions.userEventsMenu);
export const selectPermissionsUserNotesMenu = createSelector(selectPermissions, (permissions) => permissions.userNotesMenu);
export const selectPermissionsUserDocumentsMenu = createSelector(selectPermissions, (permissions) => permissions.userDocumentsMenu);
export const selectPermissionsUserDetailsMenu = createSelector(selectPermissions, (permissions) => permissions.userDetailsMenu);
export const selectPermissionsUserContactInfoMenu = createSelector(selectPermissions, (permissions) => permissions.userContactInfoMenu);
export const selectPermissionsUserBusinessMenu = createSelector(selectPermissions, (permissions) => permissions.userBusinessMenu);
export const selectPermissionsUserDemographicsMenu = createSelector(selectPermissions, (permissions) => permissions.userDemographicsMenu);
export const selectPermissionsUserPeerGroupsMenu = createSelector(selectPermissions, (permissions) => permissions.userPeerGroupsMenu);
export const selectPermissionsUserCommunitiesMenu = createSelector(selectPermissions, (permissions) => permissions.userCommunitiesMenu);
export const selectPermissionsUserRolesMenu = createSelector(selectPermissions, (permissions) => permissions.userRolesMenu);
export const selectPermissionsUserAnnualResultsMenu = createSelector(selectPermissions, (permissions) => permissions.userAnnualResultsMenu);
export const selectPermissionsUserMilestonesMenu = createSelector(selectPermissions, (permissions) => permissions.userMilestonesMenu);
export const selectPermissionsUserLOAMenu = createSelector(selectPermissions, (permissions) => permissions.userLOAMenu);
export const selectPermissionsUserChecklistMenu = createSelector(selectPermissions, (permissions) => permissions.userChecklistMenu);
export const selectPermissionsUserMonthlyFormMenu = createSelector(selectPermissions, (permissions) => permissions.userMonthlyFormMenu);
export const selectPermissionsUserAgreementFormMenu = createSelector(selectPermissions, (permissions) => permissions.userAgreementFormMenu);
export const selectPermissionsUserInitialFormMenu = createSelector(selectPermissions, (permissions) => permissions.userInitialFormMenu);
export const selectPermissionsUserNoteAdd = createSelector(selectPermissions, (permissions) => permissions.userNoteAdd);
export const selectPermissionsUserNoteEdit = createSelector(selectPermissions, (permissions) => permissions.userNoteEdit);
export const selectPermissionsUserNoteDelete = createSelector(selectPermissions, (permissions) => permissions.userNoteDelete);
// Peer Group Permission Selectors
export const selectPermissionsPeerGroupDashboardMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupDashboardMenu);
export const selectPermissionsPeerGroupDetailsMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupDetailsMenu);
export const selectPermissionsPeerGroupMembersMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupMembersMenu);
export const selectPermissionsPeerGroupEventsMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupEventsMenu);
export const selectPermissionsPeerGroupNotesMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupNotesMenu);
export const selectPermissionsPeerGroupDocumentsMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupDocumentsMenu);
export const selectPermissionsPeerGroupFacilitatorsMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupFacilitatorsMenu);
export const selectPermissionsPeerGroupScorecardMenu = createSelector(selectPermissions, (permissions) => permissions.peerGroupScorecardMenu);
// Community Permission Selectors
export const selectPermissionsCommunityDashboardMenu = createSelector(selectPermissions, (permissions) => permissions.communityDashboardMenu);
export const selectPermissionsCommunityDetailsMenu = createSelector(selectPermissions, (permissions) => permissions.communityDetailsMenu);
export const selectPermissionsCommunityMembersMenu = createSelector(selectPermissions, (permissions) => permissions.communityMembersMenu);
export const selectPermissionsCommunityPeerGroupsMenu = createSelector(selectPermissions, (permissions) => permissions.communityPeerGroupsMenu);
export const selectPermissionsCommunityEventsMenu = createSelector(selectPermissions, (permissions) => permissions.communityEventsMenu);
export const selectPermissionsCommunityNotesMenu = createSelector(selectPermissions, (permissions) => permissions.communityNotesMenu);
export const selectPermissionsCommunityDocumentsMenu = createSelector(selectPermissions, (permissions) => permissions.communityDocumentsMenu);
export const selectPermissionsCommunityDirectorsMenu = createSelector(selectPermissions, (permissions) => permissions.communityDirectorsMenu);
export const selectPermissionsCommunityAdd = createSelector(selectPermissions, (permissions) => permissions.communityAdd);
export const selectPermissionsCommunityEdit = createSelector(selectPermissions, (permissions) => permissions.communityEdit);
export const selectPermissionsCommunityDelete = createSelector(selectPermissions, (permissions) => permissions.communityDelete);
// Curriculum
export const selectPermissionCurriculum = createSelector(selectPermissions, (permissions) => permissions.curriculumAdd);

// Funder Permission
export const selectPermissionsFunderAdd = createSelector(selectPermissions, (permissions) => permissions.funderAdd);
export const selectPermissionsFunderEdit = createSelector(selectPermissions, (permissions) => permissions.funderEdit);
export const selectPermissionsFunderDelete = createSelector(selectPermissions, (permissions) => permissions.funderDelete);

// Counties Permission
export const selectPermissionsCountiesdd = createSelector(selectPermissions, (permissions) => permissions.countiesAdd);
export const selectPermissionsCountiesEdit = createSelector(selectPermissions, (permissions) => permissions.countiesEdit);
export const selectPermissionsCountiesDelete = createSelector(selectPermissions, (permissions) => permissions.countiesDelete);
