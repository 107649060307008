import { useCallback } from "react";
import { OverlayTrigger, Tooltip as DarkTooltip, Popover as LightTooltip } from "react-bootstrap";

export default function Tooltip({ children, text, theme = "light", placement = "auto" }) {
    const renderTooltip = useCallback(
        (props) =>
            theme === "light" ? (
                <LightTooltip className="p-2" {...props}>
                    {text}
                </LightTooltip>
            ) : (
                <DarkTooltip className="p-2" {...props}>
                    {text}
                </DarkTooltip>
            ),
        [text, theme]
    );

    return (
        <OverlayTrigger placement={placement} delay={{ show: 200, hide: 400 }} overlay={renderTooltip}>
            {children}
        </OverlayTrigger>
    );
}
