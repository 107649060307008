const messages = {
    requiredField: "Required Field.",
    endDateLater: "End Date must be after the Start Date.",
    invalidEmail: "Invalid Email.",
    invalidUrl: "Invalid URL. ( Hint: must begin with 'http://' or 'https://' )",
    endTimeBeforeStartTime: "Must be after Start Time.",
    createPeerGroup: "Peer Group has been added successfully.",
    updatePeerGroup: "Peer Group has been updated successfully.",
    createCommunity: "Community has been added successfully.",
    updateCommunity: "Community has been updated successfully.",
    createUserNote: "Note has been added successfully.",
    // Example: `Maximum 12 "characters" allowed.`
    maxLength: (length, inputType = "text") => `Maximum ${length} ${inputType === "number" ? "digits" : "characters"} allowed.`,
    // Example: `Minimum 10 "digits" required.`
    minLength: (length, inputType = "text") => `Minimum ${length} ${inputType === "number" ? "digits" : "characters"} required.`,
};

export default messages;
