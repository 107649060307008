import { useSelector } from "react-redux";

import ManagementTable from "../../components/ManagementTable";
import BreadCrumb from "../../components/BreadCrumb";
import messages from "../../helpers/messages";
import { communitiesInitialSort, communityApis, communityFields, communityFormFields, communityInitialForm, communityInitialSearchForm, communitySearchFields } from "./DATA";
import { communityPrimaryKey } from "../../services/communities/communityServices";
import { selectPermissionsCommunityAdd } from "../../redux/permissionReducer/permissionSelectors";

export default function Comminities() {
    const communityAddPermission = useSelector(selectPermissionsCommunityAdd);

    return (
        <>
            <BreadCrumb title="Communities" />
            <ManagementTable
                title="Community"
                primaryKey={communityPrimaryKey}
                initialForm={communityInitialForm}
                initialSearchForm={communityInitialSearchForm}
                apis={communityApis}
                fields={communityFields}
                formFields={communityFormFields}
                searchFields={communitySearchFields}
                initialSort={communitiesInitialSort}
                customCreateSuccessMessage={messages.createCommunity}
                searchCols={{ open: 6, close: 12 }}
                initialFilterCount={6}
                hideAddButton={!communityAddPermission}
                hideMoreFiltersButton
                hideActionsColumn
                withSearch
            />
        </>
    );
}
