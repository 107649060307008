import moment from "moment";
import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly } from "../../helpers";
import { deleteCommunityStaffMember, updateCommunityStaffMember } from "../../services/communities/communityStaffServices";
import { userSearchFields } from "../Users/DATA";

export const communityStaffFields = [
    {
        name: "lastName",
        title: "Last Name",
        col: 6,
        readOnly: true,
        cell: (value, data) => <Link to={`/users/${data.userId}`}>{value}</Link>,
    },
    {
        name: "firstName",
        title: "First Name",
        col: 6,
        readOnly: true,
        cell: (value, data) => <Link to={`/users/${data.userId}`}>{value}</Link>,
    },
    {
        name: "roleNames",
        title: "Roles",
        col: 12,
        readOnly: true,
        options: [],
    },
    {
        name: "startDate",
        title: "Start Date",
        col: 6,
        inputType: EFieldInputTypes.date,
        cell: (value) => formatDateOnly(value),
        required: true,
    },
    {
        name: "endDate",
        title: "End Date",
        col: 6,
        inputType: EFieldInputTypes.date,
        required: false,
        // cell: (value) => <div>{value && new Date(value).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime() ? formatDateOnly(value) : ""}</div>,
        cell: (value) => formatDateOnly(value),
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
];

export const communityStaffInitialForm = {
    lastName: "",
    firstName: "",
    roleCode: "",
};

export const communityStaffApis = {
    delete: {
        api: deleteCommunityStaffMember,
    },
    update: {
        api: updateCommunityStaffMember,
    },
};

export const selectUserSearchFields = userSearchFields.map((field) =>
    field.name === "status"
        ? {
              ...field,
              getInitialOption: (response) => response.data.find((opt) => opt.label === "Active"),
              readOnly: true,
              requiredInSearch: true,
          }
        : field.name === "role"
        ? {
              ...field,
              inputType: EFieldInputTypes.select,
              component: undefined,
              getInitialOption: (response) => response.data.find((opt) => opt.label === "Community Director"),
              readOnly: true,
              requiredInSearch: true,
          }
        : field
);
