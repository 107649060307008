import { RiDeleteBinLine, RiDeleteBin6Line } from "react-icons/ri";
import Tooltip from "../Tooltip";

export const EDeleteIconVariants = {
    default: "default",
};

const DeleteIcon = ({ onClick, size = 34, title = "Delete", withTooltip = false, variant = EDeleteIconVariants.default }) => {
    const iconProps = {
        style: { width: size, height: size },
        color: "#99CC66",
    };
    const icon = (
        <span onClick={onClick} className="mx-2 pointer" title={!withTooltip ? title : undefined}>
            {variant === EDeleteIconVariants.note ? <RiDeleteBin6Line {...iconProps} /> : <RiDeleteBinLine {...iconProps} />}
        </span>
    );

    return !withTooltip ? (
        icon
    ) : (
        <Tooltip theme="dark" placement="top" text={title}>
            {icon}
        </Tooltip>
    );
};

export default DeleteIcon;
