import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, throttle } from "../../helpers";
import { createUserCommunity, deleteUserCommunity, getCommunityOptions, getUserCommunities, updateUserCommunity } from "../../services/communities/communityServices";
import { getCountyCommunityCode } from "../../services/counties/countiesService";
import { getRoleOptionsForCommunity } from "../../services/roles/roleServices";
import { getFunderByCommunityCode } from "../../services/funder/funderService";

export const userCommunityFields = [
    {
        name: "communityName",
        nameForm: "communityCode",
        title: "Community Name",
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityOptions,
        dependencies: [
            {
                name: "county",
                nameForm: "countyId",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.communityCode ? await getCountyCommunityCode(form.communityCode) : await getCountyCommunityCode();

                    return response;
                }, 200),
            },
            {
                name: "funder",
                nameForm: "funderId",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.communityCode ? await getFunderByCommunityCode(form.communityCode) : await getCountyCommunityCode();

                    return response;
                }, 200),
            },
        ],
        required: true,
        columnWidth: 200,
        col: 6,
        readOnlyInEdit: true,
        cell: (name, data) => <Link to={`/communities/${data.communityCode}`}>{name}</Link>,
    },
    {
        name: "roleName",
        nameForm: "roleCode",
        title: "Responsibility",
        inputType: EFieldInputTypes.select,
        getOptions: getRoleOptionsForCommunity,
        required: true,
        readOnlyInEdit: true,
        col: 6,
    },
    {
        name: "startDate",
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        required: true,
        col: 6,
        cell: (value) => formatDateOnly(value),
        columnWidth: 80,
        // validate: (form) => (new Date(form.startDate).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime() ? "Start Date can not be in the past." : ""),
    },
    {
        name: "endDate",
        title: "End Date",
        inputType: EFieldInputTypes.date,
        required: false,
        col: 6,
        initialValue: null,
        cell: (value) => formatDateOnly(value),
        columnWidth: 80,
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
];

export const userCommunityInitialForm = {
    communityCode: "",
    roleCode: "",
    startDate: "",
    endDate: "",
    userId: "",
    countyId: "",
    funderId: "",
};

export const userCommunityApis = {
    create: {
        api: createUserCommunity,
    },
    update: {
        api: updateUserCommunity,
    },
    delete: {
        api: deleteUserCommunity,
    },
    getAll: {
        api: getUserCommunities,
        body: {
            userId: "",
        },
    },
};
