import { v4 as uuid } from "uuid";

import { formatDateOnly, normalizeDateOnly } from "../../helpers";
import { buildURL, callApi } from "../../helpers/api-urls";

export const peerGroupMemberPrimaryKey = "peerGroupMemberId";

const peerGroupMemberKeyMaps = {
    firstName: "memberFirstName",
    lastName: "memberLastName",
    email: "memberEmail",
    companyName: "memberCompanyName",
    startDate: "memberStartDate",
    endDate: "memberEndDate",
};

export const getPeerGroupMembers = async ({ peerGroupId, pageSize, pageNumber, sortOrder, sortField }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
        canAddNote: false,
        canSendEmail: false,
    };

    const { responseData } = await callApi.get(buildURL("/PeerGroup/Members"), {
        headers: {
            peerGroupCode: peerGroupId,
            pageSize,
            pageNumber,
            sortOrder,
            sortField: peerGroupMemberKeyMaps[sortField] || sortField,
        },
    });

    if (Array.isArray(responseData?.membersData)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.canAddNote = responseData.canAddNote;
        response.canSendEmail = responseData.canSendEmail;
        response.data = responseData.membersData.map((row) => ({
            firstName: row.memberFirstName,
            lastName: row.memberLastName,
            email: row.memberEmail,
            companyName: row.memberCompanyName,
            industryName: row.industryName,
            industryCode: row.industryCode,
            startDate: formatDateOnly(normalizeDateOnly(row.memberStartDate)),
            endDate: formatDateOnly(normalizeDateOnly(row.memberEndDate)),
            [peerGroupMemberPrimaryKey]: uuid(),
            isAssociate: row.isAssociate,
            memberDirectoryUid: row.memberDirectoryUid,
            userId: row.userGuid,
            photo: row.memberProfilePicture,

            peerGroupId,
        }));
    }

    return response;
};

export const sendEmailToPeerGroupMembers = async (formData) => {
    const response = await callApi.post(buildURL("/PeerGroup/Member/SendEmail"), formData);

    return response;
};
