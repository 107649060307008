import { useCallback, useEffect, useRef, useState } from "react";
import { FiUpload } from "react-icons/fi";
import ReactCrop from "react-image-crop";
import Resizer from "react-image-file-resizer";

import DummyUserImage from "../../assets/images/defaultUser.png";

import "react-image-crop/dist/ReactCrop.css";
import "./ImageInput.css";

const initialState = {
  image: null,
  src: null,
  croppedSrc: DummyUserImage,
  crop: {
    unit: "%",
    width: 60,
    height: 60,
    x: 30,
    y: 30,
    aspect: 1,
  },
};

const ImageInput = ({ onChange, name, viewName, value, viewValue, hideUploadButton, disabled, isRectangular }) => {
  if (isRectangular) {
    initialState.crop.aspect = undefined;
  }
  const imageRef = useRef(null);
  const inputRef = useRef(null);
  const previous = useRef({
    ...initialState,
    croppedSrc: viewValue || value || initialState.croppedSrc,
  });
  const [state, setState] = useState(() => ({
    ...initialState,
    croppedSrc: viewValue || value || initialState.croppedSrc,
  }));

  const onImageLoaded = (ref) => {
    imageRef.current = ref;
  };

  const onChangeCrop = (crop) => setState((prev) => ({ ...prev, crop }));

  const onCancel = (_crop) => setState((prev) => ({ ...initialState, image: previous.current.image, croppedSrc: previous.current.croppedSrc }));

  const onSelect = useCallback(({ target: { files } }) => {
    // if (files[0]?.type !== "image/jpeg" && files[0]?.type !== "image/png") return toastr.error(`Only JPG or PNG image is allowed.`, "Error");

    Resizer.imageFileResizer(
      files[0],
      1200,
      1200,
      "JPEG",
      100,
      0,
      (image) => {
        setState((prev) => ({ ...prev, src: image, image: files[0], croppedSrc: files[0] }));
      },
      "base64"
    );
  }, []);

  const onSubmit = useCallback(() => {
    if (!state.image?.name) return;
    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    const originWidth = state.crop.width * scaleX;
    const originHeight = state.crop.height * scaleY;
    // maximum width/height
    const maxWidth = 1200;
    const maxHeight = 1200 / (16 / 9);
    let targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(imageRef.current, state.crop.x * scaleX, state.crop.y * scaleY, state.crop.width * scaleX, state.crop.height * scaleY, 0, 0, targetWidth, targetHeight);
    const reader = new FileReader();
    canvas.toBlob((blob) => {
      if (!blob) return;
      reader.readAsDataURL(blob);
      blob.name = state.image?.name;
      blob.lastModifiedDate = new Date();

      const croppedSrc = URL.createObjectURL(blob);

      previous.current.croppedSrc = croppedSrc;
      previous.current.image = blob;

      setState((prev) => ({ ...prev, src: null, croppedSrc, image: blob }));
      onChange({ target: { name, value: blob } }, viewName && viewName !== name ? { [viewName]: croppedSrc } : undefined);
    }, state.image?.type);
  }, [name, onChange, state.crop.height, state.crop.width, state.crop.x, state.crop.y, state.image?.name, state.image?.type, viewName]);

  useEffect(() => {
    if (value && typeof value === "string") setState((prev) => ({ ...prev, image: value, croppedSrc: `${value}`, src: null }));
  }, [value]);
  return (
    <div className="my-1" >
      <div className={state.src ? "" : "editUserImg"}>
        {!state.src ? (
          <div className="userPicSec" style={disabled ? { pointerEvents: 'none', background: '#c4c4c4' } : {}} >
            <div className="image-container">
              <img src={state.croppedSrc} alt={name} />
            </div>
            {!hideUploadButton && (
              <div className="selectNewPic" onClick={() => inputRef.current?.click()}>
                <input name={name} accept="image/*" type="file" onChange={onSelect} ref={inputRef} />
                <span>
                  <FiUpload />
                </span>
              </div>
            )}
          </div>
        ) : (
          <div className="imgage-crop">
            <ReactCrop src={state.src} crop={state.crop} minHeight={80} minWidth={80} ruleOfThirds onImageLoaded={onImageLoaded} onChange={onChangeCrop} className="reactimagecrop" />
          </div>
        )}
      </div>
      {state.src && (
        <div className="d-flex justify-content-between my-2">
          <button className="btnPrimaryOutline btn btn-outline-primary p-2" style={{ minWidth: 80 }} type="button" onClick={onCancel}>
            CANCEL
          </button>
          <button className="customPrimaryBtn btn p-2" type="button" style={{ minWidth: 80 }} onClick={onSubmit}>
            CROP
          </button>
        </div>
      )}
    </div >
  );
};

export default ImageInput;
