import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi } from "../../helpers/api-urls";

export const getPeerGroupUpcomingEvents = async (filters) => {
  const { responseData } = await callApi.get(buildURL("/PeerGroup/UpcomingEvents"), {
    headers: { ...filters, pgCode: filters.peerGroupId },
  });

  return {
    canAdd: !!responseData?.canAdd,
    data: Array.isArray(responseData?.records)
      ? responseData?.records.map((u) => ({
          eventDate: u.eventDate?.slice(0, 10) || "",
          eventStartTime: u.startTime || "",
          eventEndTime: u.endTime || "",
          eventTimeZoneName: u.timezoneName || "",
          eventTypeName: u.eventTypeName || "",
          eventFormatName: u.formatTypeName || "",
          eventStatusName: u.eventStatusName || "",
          eventFocusName: u.focusName || "",
          eventRsvp: u.rsvpData || "",
          eventId: u.eventId || "",
        }))
      : [],
    totalRecords: responseData?.totalRecords || responseData?.records?.length || 0,
  };
};
export const getPeerGroupPastEvents = async (filters) => {
  const { responseData } = await callApi.get(buildURL("/PeerGroup/PastEvents"), {
    headers: { ...filters, pgCode: filters.peerGroupId },
  });

  return {
    data: Array.isArray(responseData?.records)
      ? responseData.records.map((u) => ({
          eventDate: u.eventDate?.slice(0, 10) || "",
          eventStartTime: formatTimeOnly(u.startTime),
          eventEndTime: formatTimeOnly(u.endTime),
          eventTimeZoneName: u.timezoneName || "",
          eventTypeName: u.eventTypeName || "",
          eventFormatName: u.formatTypeName || "",
          eventStatusName: u.eventStatusName || "",
          eventFocusName: u.focusName || "",
          eventRsvp: u.rsvpData || "",
          eventWentCount: u.wentCount || "",
          eventId: u.eventId || "",
        }))
      : [],
    totalRecords: responseData?.totalRecords || responseData?.records?.length || 0,
  };
};
