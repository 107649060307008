import React from "react";
import Requirement from "./Requirement";
import { FaCheckCircle } from "react-icons/fa";

const messages = {
  minCheck: " Must contain at least 8 characters, including at least:",
  numberCheck: " 1 Number ",
  uppercaseCheck: " 1 uppercase letter ",
  lowercaseCheck: " 1 lowercase letter",
  specialCheck: " 1 special letter (e.g. */$#@) ",
  minMixCheck: " Min mix of case",
  minTwoLetters: " Min 2 letters",
};

const Requirements = ({ long, number, uppercaseLetter, lowercaseLetter, specialLetter }) => (
  <section className="mb-3 px-3">
    <ul>
      <li>
        <Requirement
          htmlFor="password"
          isvalid={long}
          invalidMessage={messages.minCheck}
          validMessage={
            <>
              <FaCheckCircle />
              {messages.minCheck}
            </>
          }
        />
      </li>
      <li>
        <Requirement
          htmlFor="password"
          isvalid={number}
          invalidMessage={messages.numberCheck}
          validMessage={
            <>
              {" "}
              <FaCheckCircle /> {messages.numberCheck}
            </>
          }
        />
      </li>
      <li>
        <Requirement
          htmlFor="password"
          isvalid={uppercaseLetter}
          invalidMessage={messages.uppercaseCheck}
          validMessage={
            <>
              <FaCheckCircle />
              {messages.uppercaseCheck}
            </>
          }
        />
      </li>
      {/* <li>
        <Requirement
          htmlFor="password"
          isvalid={uppercaseLetter && lowercaseLetter}
          invalidMessage={messages.minMixCheck}
          validMessage={
            <>
              <FaCheckCircle />
              {messages.minMixCheck}
            </>
          }
        />
      </li>
      <li>
        <Requirement
          htmlFor="password"
          isvalid={
            (uppercaseLetter && lowercaseLetter) ||
            (uppercaseLetter && specialLetter) ||
            (lowercaseLetter && specialLetter) ||
            (number && specialLetter) ||
            (number && lowercaseLetter) ||
            (number && uppercaseLetter)
          }
          invalidMessage={messages.minTwoLetters}
          validMessage={
            <>
              <FaCheckCircle />
              {messages.minTwoLetters}
            </>
          }
        />
      </li> */}
      <li>
        <Requirement
          htmlFor="password"
          isvalid={lowercaseLetter}
          invalidMessage={messages.lowercaseCheck}
          validMessage={
            <>
              <FaCheckCircle /> {messages.lowercaseCheck}
            </>
          }
        />
      </li>
      <li>
        <Requirement
          htmlFor="password"
          isvalid={specialLetter}
          invalidMessage={messages.specialCheck}
          validMessage={
            <>
              <FaCheckCircle /> {messages.specialCheck}
            </>
          }
        />
      </li>
    </ul>
  </section>
);

export default Requirements;
