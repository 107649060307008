import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const getEventCurriculumDetailsPage = async ({ eventId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetEventCurriculumDetails + eventId));

    if (Array.isArray(responseData)) {
        // response.totalRecords = responseData.totalRecords;
        // response.canAdd = responseData.canAdd;
        // response.canEdit = responseData.canEdit;
        // response.canDelete = responseData.canDelete;
        response.data = responseData.map((u) => {
            return {
                title: u.title,
                for: u.documentFor,
                documentName: u.documentName,
                focus: u.monthlyFormFocusName,
                documentFilePath: u.documentFilePath,
                documentFileName: u.documentFileName,
            };
        });
    }

    return response;
};
export const getEventCurriculumDetailsOverviewPage = async ({ eventId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetEventCurriculumOverviewDetails + eventId));

    if (Array.isArray(responseData)) {
        // response.totalRecords = responseData.totalRecords;
        // response.canAdd = responseData.canAdd;
        // response.canEdit = responseData.canEdit;
        // response.canDelete = responseData.canDelete;
        response.data = responseData.map((u) => {
            return {
                title: u.title,
                for: u.documentFor,
                documentName: u.documentName,
                focus: u.monthlyFormFocusName,
                documentFilePath: u.documentFilePath,
                documentFileName: u.documentFileName,
            };
        });
    }

    return response;
};
