import { useCallback } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { MdOutlineAddCircleOutline } from "react-icons/md";

import { useToggle } from "../../helpers/hooks";

import Form from "../Form";

export default function SearchForm({
    title = "Title",
    showTable = true,
    fields,
    form,
    onChange,
    onSubmit,
    onReset,
    searchCols,
    initialFilterCount,
    onClickAdd,
    hideAddButton = false,
    customAddButtonTitle = `Add New ${title}`,
    ...props
}) {
    const [showFilters, toggleShowFilters, setShowFilters] = useToggle(true);

    const handleSubmit = useCallback(
        (formData) => {
            setShowFilters(false);
            onSubmit(formData);
        },
        [onSubmit, setShowFilters]
    );

    return (
        <div className="filterSec">
            <div className={showFilters ? "m-0" : "d-none"}>
                <Form fields={fields} form={form} onChange={onChange} onSubmit={handleSubmit} onReset={onReset} searchCols={searchCols} initialFilterCount={initialFilterCount} {...props} asSearchForm />
            </div>
            {showTable && (
                <div>
                    <div className="filterAdduserBtns pb-1">
                        <button className="filterBtn btn" onClick={toggleShowFilters}>
                            <BiFilterAlt /> Filters
                        </button>
                        {!hideAddButton && (
                            <button className="addNewUser btn" onClick={onClickAdd}>
                                <MdOutlineAddCircleOutline /> {customAddButtonTitle}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
