import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import GreenTick from "../../assets/images/green-tick.svg";
import PDFLogo from "../../assets/images/PDF-logo.png";
import Modal from "../../components/Modal";
import PageLoader from "../../components/PageLoader";
import { useThrottle, useToggle } from "../../helpers/hooks";
import { selectUserId } from "../../redux/userReducer/userSelectors";
import { getUserAggreementForm, submitUserAggreementForm } from "../../services/users/userFormServices";
import { formatDateOnly, formatTimeOnly, showResponseMessage } from "../../helpers";

import "./MemberAgreement.css";

const MemberAgreement = ({ userId, firstName, middleName, businessName, lastName }) => {
  const [refresh, setRefresh] = useState(0);
  const [show, toggleShow, setShow] = useToggle(false);
  const [state, setState] = useState({
    loading: true,
    userAgreementId: null,
    masterAgreementId: null,
    agreementSignupDate: undefined,
    agreementSignupTime: undefined,
    version: 0,
    ipAddress: "127.0.0.1",
    timeZoneName: "",
  });
  const disabled = useRef(true);
  const myIp = useRef("127.0.0.1");

  const myUserId = useSelector(selectUserId);

  const onSubmit = useThrottle(
    async () => {
      if (disabled.current) return toast.warn("Please accept terms & conditions.");
      const response = await submitUserAggreementForm({ userId, masterAgreementId: state.masterAgreementId, ipAddress: myIp.current });

      if (response.responseCode !== 200) return showResponseMessage(response);
      setRefresh((prev) => prev + 1);
      setShow(true);
      setTimeout(() => setShow(false), 5000);
      setState((prev) => ({
        ...prev,
        userAgreementId: true,
        ipAddress: myIp.current,
        // agreementSignupDate: moment().format("YYYY-MM-DD"), agreementSignupTime: moment().format("HH:mm"),
      }));
    },
    [userId, state.masterAgreementId],
    2000
  );

  useEffect(() => {
    getUserAggreementForm({ userId }).then((response) => {
      if (response.data?.masterAgreementId)
        setState((prev) => ({
          ...prev,
          loading: false,
          userAgreementId: response.data.userAgreementId,
          masterAgreementId: response.data.masterAgreementId,
          agreementSignupDate: response.data.agreementSignupDate?.slice(0, 10) || "",
          agreementSignupTime: response.data.agreementSignupTime?.slice(0, 5) || "",
          ipAddress: response.data.ipAddress,
          version: response.data.version,
          timeZoneName: response.data.timeZoneName,
        }));
      else setState((prev) => ({ ...prev, loading: false }));
    });
  }, [userId, refresh]);

  useEffect(() => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        if (response.data?.IPv4) myIp.current = response.data.IPv4;
      })
      .catch((e) => { });
  }, []);

  return state.loading ? (
    <PageLoader />
  ) : (
    <>
      {state.userAgreementId && (
        <div className="p-3">
          <h3>Member Agreement</h3>
          {state.agreementSignupDate && (
            <p className="my-3">{`Member Agreement version ${state.version}.0 electronically signed using checkbox by ${firstName || ""} ${middleName || ""} ${lastName || ""} on ${formatDateOnly(
              state.agreementSignupDate
            )} ${formatTimeOnly(state.agreementSignupTime)} ${state.timeZoneName} from IP: ${state.ipAddress}`}</p>
          )}
        </div>
      )}
      <div className="member-agreement-wrapper customCard shadow">
        {!state.userAgreementId && <h3 className="m-1 p-3">Member Agreement</h3>}
        <div className="agreement-form">
          <div className="agreementTextArea form-member-agreement px-4 mt-4">
            <div className="header-sec mb-5">
              <div className="left-col">
                <p>
                  <span>You've Got This.</span> Because We've Got You.
                </p>
              </div>
              <div className="right-col">
                <div className="inner-box">
                  <div className="top-logo">
                    <img src={PDFLogo} alt="E4Ever Logo" />
                  </div>
                  <ul>
                    <li>1435 Bedford Ave</li>
                    <li>Suite 132A </li>
                    <li>Pittsburgh,PA</li>
                    <li>15219</li>
                  </ul>
                  <a className="hp-link" href="https://entrepreneursforever.org/stories/">
                    entrepreneursforever.org
                  </a>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h1>Entrepreneurs Forever Participation Guidelines and Agreements</h1>
              <p>
                Entrepreneurs Forever is a program of the Mansmann Foundation, a Pennsylvania nonprofit corporation. I understand that my participation in Entrepreneurs Forever will involve meeting
                with other entrepreneurs, and I may receive confidential information regarding their businesses or other confidential matters. I may also disclose confidential information of my own. I
                agree not to share confidential information, directly or indirectly, now or in the future, about other entrepreneurs or their businesses outside of the group with whom such
                confidential information was originally shared, except that I may discuss otherwise confidential information with my eforever group facilitator or other eforever representative.
              </p>
              <p>
                At all times, I will treat the confidential information of my peers with at least as much care as I would treat my own confidential information. Likewise, my entrepreneurial peers owe
                me the same protection.
              </p>
              <h2>Agreement to Hold Harmless</h2>
              <p>
                I have requested advice regarding my business, and, in the course of my relationship with eforever, I will receive advice from my entrepreneurial peers, eforever staff, and other
                advisors. I understand and agree that these individuals are not officers, directors, or employees of my business and, therefore, have no duty of loyalty or fiduciary duty to me or my
                business. The advice I receive comes with no express or implied warranty or guarantee of success.
              </p>
              <p>
                I understand that I am never obligated or required to follow any advice. If I do take or follow any advice given to me, I do so at my own risk. I expressly and unconditionally release
                and forever discharge my entrepreneurial peers and all eforever representatives from any and all claims, damages, or causes of action arising out of or related to my following or
                disregarding their advice.
              </p>
              <p>Should I provide any advice to my entrepreneurial peers, I agree to do so in good faith and using my reasonable best efforts and judgment.</p>
              <p>
                <br />
              </p>
              <p>
                <span />
              </p>
              <p />
              {/* <hr className="agreement-hr" />
              <AgreementPage page={1} /> */}
            </div>
          </div>
          {/* <hr className="agreement-page-break" /> */}
          <div className="agreementTextArea form-member-agreement px-4">
            <h2 className="text-center">Photography and Media Release</h2>
            <ul id="l1" className="px-4">
              <li>
                <p>
                  I hereby authorize eforever and its representatives to take photographs of me while engaged in group meetings or other events. Such photos may be used in any and all media without
                  restriction.
                </p>
              </li>
              <li>
                <p>
                  In addition, I consent to group meetings and other events I may participate in being recorded (video and/or audio recording) for eforever staff training and other internal purposes.
                </p>
              </li>
              <li>
                <p>
                  I also grant permission to use my name, company name, logo, business contact information and social media handles in connection with any and all media, including member/participant
                  directories and other published lists.
                </p>
              </li>
              <li>
                <p>
                  Finally, if I should provide to eforever any photographs, videos, or promotional material of my own, such material may be used in any and all media without restriction, unless I
                  explicitly state otherwise at the time said materials are provided to eforever.
                </p>
              </li>
            </ul>
            <p>
              <br />
            </p>
            <h2 className="text-center">Committing to a Peer-to-Peer Group</h2>
            <p className="text-center">
              eforever's program includes entrepreneurial peer-to-peer groups that gather once a month for a two-hour meeting. It's a time for members to work on their business not in their business.
              In the event that you are selected for, and choose to enroll in, an eforever peer-to-peer group, please abide by the following guidelines:
            </p>
            <p>
              <br />
            </p>
            <ol id="l2" className="px-4">
              <li>
                <p>
                  Your presence is valuable to each business owner in your group, so regular attendance is a requirement of your membership. More than two consecutive absences, or three or more
                  absences in any twelve-month period, may result in removal from your group.
                </p>
              </li>
              <li>
                <p>If you will be absent from a group meeting, notify your facilitator.</p>
              </li>
              <li>
                <p>
                  If, at any point, you choose to withdraw from the program, written notice must be given to your group facilitator or to Program Director Katie Outon McMahon at{" "}
                  <a href="mailto:kmcmahon@e4ever.org">kmcmahon@e4ever.org</a>.
                </p>
              </li>
            </ol>
            <div className="footer-sec">
              <span>1435 Bedford Ave Suite 132A Pittsburgh,PA 15219</span>
              <a class="hp-link" href="https://entrepreneursforever.org/stories/">
                entrepreneursforever.org
              </a>
              <img className="footer-logo" src={PDFLogo} alt="E4Ever Logo" />
            </div>

            {/* <hr className="agreement-hr my-3" />
            <AgreementPage page={2} /> */}
          </div>
        </div>
      </div>
      {userId === myUserId && !state.userAgreementId && (
        <div className="agreement-submission-form">
          <div className="form-check mt-3">
            <input type="checkbox" className="form-check-input" name="remember-me" id="terms" onChange={() => (disabled.current = !disabled.current)} />
            <label className="custom-control-label" htmlFor="terms">
              I agree to the terms & conditions.
            </label>
          </div>
          <div className="bottomBtnSec pt-0 text-right">
            <button type="buttom" onClick={onSubmit} className="customPrimaryBtn">
              Submit
            </button>
          </div>
        </div>
      )}
      <Modal show={show} onHide={toggleShow} size="md">
        <div className="agreementsThanksPage customCard shadow">
          <div className="thankYouPage">
            <img className="greentick" src={GreenTick} alt="Tick" />
            <h3>Thank you!</h3>
            <p>We've received your member agreement successfully.</p>
            {/* <div className="bottomBtnSec mt-2 pt-4">
              <Link to={`/users/${userId}/dashboard`} className="customPrimaryBtn">
                Return to Dashboard
              </Link>
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

// function AgreementPage({ page = 1 }) {
//   return (
//     <div className="d-flex justify-content-between">
//       <div>{`©${new Date().getFullYear()} eforever. All rights reserved.`}</div>
//       {/* <div>{moment().format("MMMM DD, YYYY")}</div> */}
//       <div>{`June 3, 2020`}</div>
//       <div>{`Massachusetts Form`}</div>
//       <div>{`Page ${page}`}</div>
//     </div>
//   );
// }

export default MemberAgreement;
