import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";

import ManagementTable from "../../components/ManagementTable";
import { useThrottle } from "../../helpers/hooks";
import { peerGroupPrimaryKey } from "../../services/peer-groups/peerGroupServices";
import { communityPeerGroupApis, communityPeerGroupFields } from "./DATA";

// DO NOT forget to use 'setPeerGroups' after updating or deleting.
export default function CommunityPeerGroups({ communityId, communityDetails, peerGroups, setPeerGroups, setRefresh }) {
    const apis = useMemo(() => ({ ...communityPeerGroupApis, create: { ...communityPeerGroupApis.create, body: { communityId } } }), [communityId]);

    const onClickAdd = useThrottle(() => toast.info("Coming soon..."), [], 5000);

    useEffect(() => {
        if (!Array.isArray(peerGroups)) setRefresh((prev) => prev + 1);
    }, [peerGroups, setRefresh]);

    return (
        <ManagementTable
            title="Peer Group"
            tableTitle={"Peer Groups"}
            customAddButtonTitle="Add Peer Group"
            primaryKey={peerGroupPrimaryKey}
            data={peerGroups}
            fields={communityPeerGroupFields}
            apis={apis}
            formSize="md"
            onClickAdd={onClickAdd}
            hideAddButton
            //  ={!communityDetails.canAddPeerGroups}
            hideActionsColumn
        />
    );
}
