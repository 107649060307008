import { useCallback, useState } from "react";

import FormField from "../../components/FormField";

export default function EditNote({ name, title, initialValue, onChange, index }) {
    const [value, setValue] = useState(() => initialValue);
    const handleChange = useCallback(
        (event) => {
            onChange(event, index);
            setValue(event.target.value);
        },
        [index, onChange]
    );

    return <div className="form-group customInput my-0">
        <FormField name={name} value={value} title={title} placeholder=" " onChange={handleChange} maxLength={200} />
    </div>
}