import "./KPI.css";
import EditSquare from "../../assets/images/edit_square_icon.png";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "../Users/TextField";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import KPIModal from "./KPIModal";
import { useToggle } from "../../helpers/hooks";
import { getRevenueSource } from "../../services/revenue-sources";
import { getCommercialSpaceType } from "../../services/commercial-space-type";
import { commafy } from "../../helpers";
import Tooltip from "../../components/Tooltip";
import { BsInfoCircle } from "react-icons/bs";
import parse from "html-react-parser";

const validationSchemaEmployee = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    fullTime: Yup.string().max(5, "Cannot exceed more than 5 characters").required("Required Field").nullable(),
    partTime: Yup.string().max(5, "Cannot exceed more than 5 characters").required("Required Field").nullable(),
    independent: Yup.string().max(5, "Cannot exceed more than 5 characters").required("Required Field").nullable(),
    isAdditionEmployee: Yup.string().required("Required Field"),
});
const validationSchemaGrossRevenue = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    revenue: Yup.string().required("Required Field").nullable(),
    revenueSourceCode: Yup.string().required("Required Field").nullable(),
});

const validationSchemaOwnerCompensation = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    totalCompensation: Yup.string().required("Required Field").nullable(),
});

const validationSchemaCommercialSpace = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    commercialSpaceTypeCode: Yup.string().required("Required Field").nullable(),
});

const validationSchemaFinancialStatements = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    isMonthlyFinancialStatementProduce: Yup.string().required("Required Field").nullable(),
});

const validationSchemaHouseholdIncome = Yup.object().shape({
    noeYear: Yup.string().required("Required Field"),
    income: Yup.string().required("Required Field").nullable(),
});
const KPIForm = () => {
    const { userId } = useParams();

    const [showEmployeeDetails, toggleShowEmployeeDetails] = useToggle(false);
    const [showGrossRevenue, toggleShowGrossRevenue] = useToggle(false);
    const [showTotalCompensation, toggleShowTotalCompensation] = useToggle(false);
    const [showCommercialSpace, toggleShowCommercialSpace] = useToggle(false);
    const [showFinancialStatements, toggleShowFinancialStatements] = useToggle(false);
    const [showHouseholdIncome, toggleShowHouseholdIncome] = useToggle(false);
    const [formDataEmployee, setFormDataEmployee] = useState([]);
    const [formGrossRevenueData, setFormGrossRevenueData] = useState([]);
    const [formOwnerCompensation, setFormOwnerCompensation] = useState([]);
    const [formCommercialSpace, setFormCommercialSpace] = useState([]);
    const [formFinancialStatement, setFormFinancialStatement] = useState([]);
    const [formHouseholdIncome, setFormHouseholdIncome] = useState([]);
    const [revenueSource, setRevenueSource] = useState([]);
    const [commercialSpaceOptions, setCommercialSpaceOptions] = useState([]);
    const [annualMemberTooltips, setAnnualMemberTooltips] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [employeeInitialValues, setEmployeeInitialValues] = useState({
        noeYear: "",
        fullTime: "",
        partTime: "",
        independent: "",
        isAdditionEmployee: "",
    });
    const [grossRevenueInitialValues, setGrossRevenueInitialValues] = useState({
        userId: "",
        noeYear: "",
        revenue: 0,
        revenueSourceCode: "",
        revenueSourceName: "",
        otherRevenueSourceDetail: "",
        isAdded: true,
    });
    const [compensationInitialValues, setCompensationInitialValues] = useState({
        userId: "",
        noeYear: "",
        totalCompensation: 0,
        isAdded: true,
    });
    const [commercialSpaceInitialValues, setCommercialSpaceInitialValues] = useState({
        userId: "",
        noeYear: "",
        commercialSpaceTypeCode: "",
        commercialSpaceTypeName: "",
        isAdded: true,
    });
    const [financialStatementInitialValues, setFinancialStatementInitialValues] = useState({
        userId: "",
        noeYear: "",
        isMonthlyFinancialStatementProduce: "",
        isAdded: true,
    });
    const [householdIncomeInitialValues, setHouseholdIncomeInitialValues] = useState({
        userId: "",
        noeYear: "",
        income: 0,
        isAdded: true,
    });
    const handleSubmitEmployeeForm = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            fullTime: values.fullTime,
            partTime: values.partTime,
            independent: values.independent,
            isAdditionEmployee: values.isAdditionEmployee === "true",
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveEmployeeForm), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowEmployeeDetails();
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleSubmitAnnualGrossRevenue = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            revenue: values.revenue || 0,
            revenueSourceCode: values.revenueSourceCode || "",
            revenueSourceName: "",
            otherRevenueSourceDetail: "",
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveAnnualGrossRevenue), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowGrossRevenue();
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleSubmitCompensationForm = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            totalCompensation: values.totalCompensation || 0,
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveCompensation), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowTotalCompensation();
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleSubmitCommercialSpace = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            commercialSpaceTypeCode: values.commercialSpaceTypeCode,
            commercialSpaceTypeName: "",
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveOwnerCommercialSpace), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowCommercialSpace();
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleSubmitFinancialStatements = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            isMonthlyFinancialStatementProduce: values.isMonthlyFinancialStatementProduce === "Yes" ? true : values.isMonthlyFinancialStatementProduce === "No" ? false : "",
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveFinancialStatement), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowFinancialStatements();
        } else {
            toast.error("Something went wrong!");
        }
    };
    const handleSubmitHouseholdIncome = async (values) => {
        const formData = {
            userId,
            noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
            income: values.income,
            isAdded: true,
        };

        const response = await callApi.post(buildURL(Urls.SaveHouseholdIncome), formData);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            toggleShowHouseholdIncome();
        } else {
            toast.error("Something went wrong!");
        }
    };
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetKPIForm}${userId}`));

            if (!response.responseData) return;

            setFormDataEmployee(response.responseData?.employee);
            setFormGrossRevenueData(response.responseData?.revenue);
            setFormOwnerCompensation(response.responseData?.ownerCompensation);
            setFormCommercialSpace(response.responseData?.commercialSpace);
            setFormFinancialStatement(response.responseData?.financialStatement);
            setFormHouseholdIncome(response.responseData?.householdIncome);
        })();
    }, [userId, refresh]);
    // Master API
    useEffect(() => {
        (async () => {
            const response = await getRevenueSource();
            if (!response.responseData) return;

            setRevenueSource(response.responseData);
        })();
        (async () => {
            const response = await getCommercialSpaceType();
            if (!response.responseData) return;

            setCommercialSpaceOptions(response.responseData);
        })();
    }, []);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetAnnualMmeberTooltips}`));

            if (!response.responseData) return;

            setAnnualMemberTooltips(response.responseData);
        })();
    }, []);

    const empDetails = annualMemberTooltips.find(({ code }) => code === "annual_form_employee_details");
    const grossAnnualRevenue = annualMemberTooltips.find(({ code }) => code === "annual_form_gross_annual_revenue");
    const ownersCompensation = annualMemberTooltips.find(({ code }) => code === "annual_form_owners_compensation");
    const commercialSpace = annualMemberTooltips.find(({ code }) => code === "annual_form_commercial_space");
    const financialStatements = annualMemberTooltips.find(({ code }) => code === "annual_form_financial_statements");
    const householdIncome = annualMemberTooltips.find(({ code }) => code === "annual_form_household_income");

    return (
        <div className="kpi-form-page">
            <div className="kpiForm-wrapper">
                <div className="eventDetails">
                    <div className="eventHeader top-head-kpi">
                        <h1>Annual Member Results</h1>
                    </div>
                    <p>
                        After May 31st you will not be able to edit the data once entered. If any information below is incorrect please contact your community director to assist you in correcting for
                        the fields that you are not able to edit yourself. The previous years data will automatically be added on April 1st.{" "}
                    </p>
                    <p className="mt-2">
                        Entrepreneurs Forever keeps your personal and business information strictly confidential. The information you provide in this Annual Results form is never shared with third
                        parties in any personally identifiable format; we only use it by combining it with other members' results and calculating an average. Those averages allow us to show the impact
                        of our program to our funders and other stakeholders. And that keeps our program free of cost to business owners like you.{" "}
                    </p>
                    <div className="row">
                        <div className="col-md-12 form-group customInput">
                            <div className="employeeDetailTable">
                                <table>
                                    <caption>
                                        Employee Details{" "}
                                        {empDetails?.name && (
                                            <Tooltip text={<p className="custom-size">{parse(empDetails?.name)}</p>} placement="top">
                                                <span className="mx-2 pointer">
                                                    <BsInfoCircle />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </caption>
                                    <tr className="employeeTableHead">
                                        <th>
                                            <label>YEAR</label>
                                        </th>
                                        <th>
                                            <label>FULL-TIME</label>
                                        </th>
                                        <th>
                                            <label>PART-TIME</label>
                                        </th>
                                        <th>
                                            <label>INDEPENDENT CONTRACTS (1099)</label>
                                        </th>
                                        <th className="wideLabel">
                                            <label>Do you Expect any additional Employees in the next 12 months?</label>
                                        </th>
                                        <th>
                                            <label>ACTIONS</label>
                                        </th>
                                    </tr>
                                    {formDataEmployee?.map((e) => (
                                        <tr>
                                            <td>
                                                <p title={e.noeYear === "0" ? "Application Form" : e.noeYear}>{e.noeYear === "0" ? "Application Form" : e.noeYear}</p>
                                            </td>
                                            <td>
                                                <p title={e.fullTime}>{e.fullTime}</p>
                                            </td>
                                            <td>
                                                <p title={e.partTime}>{e.partTime}</p>
                                            </td>
                                            <td>
                                                <p title={e.independent}>{e.independent}</p>
                                            </td>
                                            <td className="wideLabel ">
                                                <p title={e.isAdditionEmployee === true ? "Yes" : e.isAdditionEmployee === false ? "No" : ""}>
                                                    {e.isAdditionEmployee === true ? "Yes" : e.isAdditionEmployee === false ? "No" : ""}
                                                </p>
                                            </td>
                                            {e.canEdit && (
                                                <td className="under-line">
                                                    <span
                                                        onClick={() => {
                                                            toggleShowEmployeeDetails();
                                                            setEmployeeInitialValues({
                                                                ...e,
                                                                isAdditionEmployee: e.isAdditionEmployee === true ? "true" : e.isAdditionEmployee === false ? "false" : "",
                                                                noeYear: e.noeYear === "0" ? "Application Form" : e.noeYear,
                                                            });
                                                        }}
                                                    >
                                                        <img className="pointer" src={EditSquare} alt="Edit" />
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group customInput">
                                <table className="employeeDetailTable four-col-table">
                                    <caption>
                                        Gross Annual Revenue{" "}
                                        {grossAnnualRevenue?.name && (
                                            <Tooltip text={<p className="custom-size">{parse(grossAnnualRevenue?.name)}</p>} placement="top">
                                                <span className="mx-2 pointer">
                                                    <BsInfoCircle />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </caption>
                                    <tr className="employeeTableHead">
                                        <th>
                                            <label>YEAR</label>
                                        </th>
                                        <th>
                                            <label>REVENUE</label>
                                        </th>
                                        <th>
                                            <label>SOURCE OF REVENUE DATA</label>
                                        </th>
                                        <th>
                                            <label>ACTIONS</label>
                                        </th>
                                    </tr>
                                    {formGrossRevenueData?.map((g) => (
                                        <tr>
                                            <td>
                                                <p title={g.noeYear === "0" ? "Application Form" : g.noeYear}>{g.noeYear === "0" ? "Application Form" : g.noeYear}</p>
                                            </td>
                                            <td>
                                                <p title={`${g.revenue && "$" + commafy(g.revenue)}`}>{g.revenue && "$" + commafy(g.revenue)}</p>
                                            </td>
                                            <td>
                                                <p title={g.revenueSourceName}>{g.revenueSourceName}</p>
                                            </td>
                                            {g.canEdit && (
                                                <td className="under-line">
                                                    <span>
                                                        <img
                                                            onClick={() => {
                                                                toggleShowGrossRevenue();
                                                                setGrossRevenueInitialValues({
                                                                    ...g,
                                                                    revenue: g.revenue,
                                                                    revenueSourceCode: g.revenueSourceCode,
                                                                    noeYear: g.noeYear === "0" ? "Application Form" : g.noeYear,
                                                                });
                                                            }}
                                                            className="pointer"
                                                            src={EditSquare}
                                                            alt="Edit"
                                                        />
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </table>
                            </div>
                        </div>
                        <div className="col-md-12 form-group customInput">
                            <table className="employeeDetailTable three-col-table">
                                <caption>
                                    Owner(s) Compensation{" "}
                                    {ownersCompensation?.name && (
                                        <Tooltip text={<p className="custom-size">{parse(ownersCompensation?.name)}</p>} placement="top">
                                            <span className="mx-2 pointer">
                                                <BsInfoCircle />
                                            </span>
                                        </Tooltip>
                                    )}
                                </caption>
                                <tr className="employeeTableHead">
                                    <th>
                                        <label>YEAR</label>
                                    </th>
                                    <th>
                                        <label>TOTAL COMPENSATION OF OWNER(s)</label>
                                    </th>
                                    <th>
                                        <label>ACTIONS</label>
                                    </th>
                                </tr>
                                {formOwnerCompensation?.map((o) => (
                                    <tr>
                                        <td>
                                            <p title={o.noeYear === "0" ? "Application Form" : o.noeYear}> {o.noeYear === "0" ? "Application Form" : o.noeYear}</p>
                                        </td>
                                        <td>
                                            <p title={`${o.totalCompensation && "$" + commafy(o.totalCompensation)}`}> {o.totalCompensation && "$" + commafy(o.totalCompensation)}</p>
                                        </td>

                                        {o.canEdit && (
                                            <td className="under-line">
                                                <span>
                                                    <img
                                                        onClick={() => {
                                                            toggleShowTotalCompensation();
                                                            setCompensationInitialValues({ ...o, noeYear: o.noeYear === "0" ? "Application Form" : o.noeYear });
                                                        }}
                                                        className="pointer"
                                                        src={EditSquare}
                                                        alt="Edit"
                                                    />
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group customInput">
                            <table className="employeeDetailTable three-col-table">
                                <caption>
                                    Commercial Space{" "}
                                    {commercialSpace?.name && (
                                        <Tooltip text={<p className="custom-size">{parse(commercialSpace?.name)}</p>} placement="top">
                                            <span className="mx-2 pointer">
                                                <BsInfoCircle />
                                            </span>
                                        </Tooltip>
                                    )}
                                </caption>
                                <tr className="employeeTableHead">
                                    <th>
                                        <label>YEAR</label>
                                    </th>
                                    <th>
                                        <label>DO YOU RENT OR OWN COMMERCIAL SPACE FOR YOUR BUSINESS?</label>
                                    </th>
                                    <th>
                                        <label>ACTIONS</label>
                                    </th>
                                </tr>
                                {formCommercialSpace?.map((c) => (
                                    <tr>
                                        <td>
                                            <p title={c.noeYear === "0" ? "Application Form" : c.noeYear}> {c.noeYear === "0" ? "Application Form" : c.noeYear}</p>
                                        </td>
                                        <td>
                                            <p title={c.commercialSpaceTypeName || ""}>{c.commercialSpaceTypeName || ""}</p>
                                        </td>
                                        {c.canEdit && (
                                            <td className="under-line">
                                                <span>
                                                    <img
                                                        onClick={() => {
                                                            toggleShowCommercialSpace();
                                                            setCommercialSpaceInitialValues({ ...c, noeYear: c.noeYear === "0" ? "Application Form" : c.noeYear });
                                                        }}
                                                        className="pointer"
                                                        src={EditSquare}
                                                        alt="Edit"
                                                    />
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </table>
                        </div>
                        <div className="col-md-12 form-group customInput">
                            <table className="employeeDetailTable three-col-table">
                                <caption>
                                    Financial Statements{" "}
                                    {financialStatements?.name && (
                                        <Tooltip text={<p className="custom-size">{parse(financialStatements?.name)}</p>} placement="top">
                                            <span className="mx-2 pointer">
                                                <BsInfoCircle />
                                            </span>
                                        </Tooltip>
                                    )}
                                </caption>
                                <tr className="employeeTableHead">
                                    <th>
                                        <label>YEAR</label>
                                    </th>
                                    <th>
                                        <label>DO YOU PRODUCE MONTHLY FINANCIAL STATEMENTS?</label>
                                    </th>
                                    <th>
                                        <label>ACTIONS</label>
                                    </th>
                                </tr>
                                {formFinancialStatement?.map((f) => (
                                    <tr>
                                        <td>
                                            <p title={f.noeYear === "0" ? "Application Form" : f.noeYear}> {f.noeYear === "0" ? "Application Form" : f.noeYear}</p>
                                        </td>
                                        <td>
                                            <p title={f.isMonthlyFinancialStatementProduce === true ? "Yes" : f.isMonthlyFinancialStatementProduce === false ? "No" : ""}>
                                                {f.isMonthlyFinancialStatementProduce === true ? "Yes" : f.isMonthlyFinancialStatementProduce === false ? "No" : ""}
                                            </p>
                                        </td>
                                        {f.canEdit && (
                                            <td className="under-line">
                                                <span>
                                                    <img
                                                        onClick={() => {
                                                            toggleShowFinancialStatements();
                                                            setFinancialStatementInitialValues({
                                                                ...f,
                                                                isMonthlyFinancialStatementProduce:
                                                                    f.isMonthlyFinancialStatementProduce === true ? "Yes" : f.isMonthlyFinancialStatementProduce === false ? "No" : "",
                                                                noeYear: f.noeYear === "0" ? "Application Form" : f.noeYear,
                                                            });
                                                        }}
                                                        className="pointer"
                                                        src={EditSquare}
                                                        alt="Edit"
                                                    />
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 form-group customInput">
                            <table className="employeeDetailTable three-col-table">
                                <caption>
                                    Household Income{" "}
                                    {householdIncome?.name && (
                                        <Tooltip text={<p className="custom-size">{parse(householdIncome?.name)}</p>} placement="top">
                                            <span className="mx-2 pointer">
                                                <BsInfoCircle />
                                            </span>
                                        </Tooltip>
                                    )}
                                </caption>
                                <tr className="employeeTableHead">
                                    <th>
                                        <label>YEAR</label>
                                    </th>
                                    <th>
                                        <label>TOTAL HOUSEHOLD INCOME</label>
                                    </th>
                                    <th>
                                        <label>ACTIONS</label>
                                    </th>
                                </tr>
                                {formHouseholdIncome?.map((h) => (
                                    <tr>
                                        <td>
                                            <p title={h.noeYear === "0" ? "Application Form" : h.noeYear}> {h.noeYear === "0" ? "Application Form" : h.noeYear}</p>
                                        </td>
                                        <td>
                                            <p title={`${h.income && "$" + commafy(h.income)}`}>{h.income && "$" + commafy(h.income)}</p>
                                        </td>
                                        {h.canEdit && (
                                            <td className="under-line">
                                                <span>
                                                    <img
                                                        onClick={() => {
                                                            toggleShowHouseholdIncome();
                                                            setHouseholdIncomeInitialValues({ ...h, noeYear: h.noeYear === "0" ? "Application Form" : h.noeYear });
                                                        }}
                                                        className="pointer"
                                                        src={EditSquare}
                                                        alt="Edit"
                                                    />
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </table>
                        </div>
                    </div>
                </div>
                <KPIModal
                    validationSchema={validationSchemaEmployee}
                    show={showEmployeeDetails}
                    onHide={toggleShowEmployeeDetails}
                    initialValues={employeeInitialValues}
                    modalTitle={"Number of Employees"}
                    onSubmit={handleSubmitEmployeeForm}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="NOE_Year" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Full Time</label>

                                    <TextField className="form-control" min="0" name="fullTime" type="number" placeholder="Full Time" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Part Time</label>
                                    <TextField className="form-control" min="0" name="partTime" type="number" placeholder="Part Time" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Independent Contracts(1099)</label>
                                    <TextField className="form-control" min="0" name="independent" type="number" placeholder="Independent Contracts(1099)" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Do you Expect any additional Employees in the next 12 months?</label>
                                    <Field className="form-select" as="select" name="isAdditionEmployee">
                                        <option value="">Please Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Field>{" "}
                                    <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"isAdditionEmployee"} />{" "}
                                </div>
                                {/* <div className="selectMethod">
                <div className="form-check form-check-inline">
                  <Field type="radio" name="isAdditionEmployee" value={"true"} id="yesRadio" className="form-check-input" />
                  <label title="" htmlFor="yesRadio" className="form-check-label">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <Field type="radio" name="isAdditionEmployee" value={"false"} id="noRadio" className="form-check-input" />
                  <label title="" htmlFor="noRadio" className="form-check-label">
                    No
                  </label>
                </div>
                {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"isAdditionEmployee"} className="error" />} */}

                                {/* {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"isBusinessFullTime"} className="error" />} */}
                                {/* </div> */}
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
                <KPIModal
                    validationSchema={validationSchemaGrossRevenue}
                    show={showGrossRevenue}
                    onHide={toggleShowGrossRevenue}
                    initialValues={grossRevenueInitialValues}
                    modalTitle={"Gross Annual Revenue"}
                    onSubmit={handleSubmitAnnualGrossRevenue}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="GAR_Year" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Revenue</label>

                                    <TextField className="form-control" name="revenue" type="number" placeholder="Revenue" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Source of Revenue</label>
                                    <Field className="form-select" as="select" name="revenueSourceCode">
                                        <option value="">Source of Revenue</option>
                                        {revenueSource?.map((r) => (
                                            <option value={r.code}>{r.name}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage className="error" style={{ color: "red" }} component={"div"} name={"revenueSourceCode"} />{" "}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
                <KPIModal
                    validationSchema={validationSchemaOwnerCompensation}
                    show={showTotalCompensation}
                    onHide={toggleShowTotalCompensation}
                    initialValues={compensationInitialValues}
                    onSubmit={handleSubmitCompensationForm}
                    modalTitle={`Total compensation of owner(s) in ${compensationInitialValues.noeYear} calendar year? (example: $44,000) *`}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="Compensation_Year" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Total Compensation of Owner(s)</label>

                                    <TextField className="form-control" name="totalCompensation" type="number" placeholder="Total Compensation of Owner(s)" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
                <KPIModal
                    validationSchema={validationSchemaCommercialSpace}
                    show={showCommercialSpace}
                    onHide={toggleShowCommercialSpace}
                    initialValues={commercialSpaceInitialValues}
                    modalTitle={"Do You Rent or Own Commercial Space for your Business?"}
                    onSubmit={handleSubmitCommercialSpace}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="Compensation_Year" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Rent or Own</label>
                                    <Field className="form-select" as="select" name="commercialSpaceTypeCode">
                                        <option selected disabled value="">
                                            Please Select
                                        </option>
                                        {commercialSpaceOptions?.map((c) => (
                                            <option value={c.code}>{c.name}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"commercialSpaceTypeCode"} />{" "}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
                <KPIModal
                    validationSchema={validationSchemaFinancialStatements}
                    show={showFinancialStatements}
                    onHide={toggleShowFinancialStatements}
                    initialValues={financialStatementInitialValues}
                    onSubmit={handleSubmitFinancialStatements}
                    modalTitle={"Do you currently produce monthly financial statements?"}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="12">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="Statements_Year" />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group customInput">
                                    <label>Do you currently produce monthly financial statements?</label>
                                    <Field className="form-select" as="select" name="isMonthlyFinancialStatementProduce">
                                        <option value="">Please Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </Field>
                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"isMonthlyFinancialStatementProduce"} />{" "}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
                <KPIModal
                    validationSchema={validationSchemaHouseholdIncome}
                    show={showHouseholdIncome}
                    onHide={toggleShowHouseholdIncome}
                    initialValues={householdIncomeInitialValues}
                    onSubmit={handleSubmitHouseholdIncome}
                    modalTitle={"Total Household Income for  calendar year (example: $60,000)"}
                >
                    <Col lg="12" md="12">
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>YEAR</label>
                                    <TextField className="form-control" disabled name="noeYear" type="text" placeholder="Household_Year" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Income</label>
                                    <TextField className="form-control" name="income" type="text" placeholder="Income" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </KPIModal>
            </div>
        </div>
    );
};

export default KPIForm;
