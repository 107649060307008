export const getCode = (text) => {
  switch (text) {
    // Green === 1
    case "Active":
      return 1;
    case "Completed":
      return 1;

    // Yellow === 0
    case "Pending Attendance":
      return 0;
    case "Pending":
      return 0;

    // Faded Blue === -1
    case "In-Progress":
      return -1;
    case "Inactive":
      return -1;

    // Red === -2
    case "Not Completed":
      return -2;
    default:
      return 1;
  }
};

export const getClassName = (code) => {
  switch (code) {
    case 1:
      return "active";
    case 0:
      return "pending";
    case -1:
      return "inactive";
    case -2:
      return "danger";
    default:
      return "active";
  }
};
