import { Suspense, useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import { createStructuredSelector } from "reselect";
import { useSelector } from "react-redux";
import { Navigate, NavLink, Route, Routes, useParams } from "react-router-dom";

import DetailsPageContainer from "../../components/DetailsPageContainer";
import ErrorBoundary from "../../components/ErrorBoundary";
import PageLoader from "../../components/PageLoader";
import NotFoundPage from "../NotFoundPage";
import Details from "./Details.component";
import UnAuthorisedPage from "../UnAuthorisedPage";
import { getPeerGroupById } from "../../services/peer-groups/peerGroupServices";
import { getPeerGroupStaff } from "../../services/peer-groups/peerGroupStaffServices";
import { formatDateTime } from "../../helpers";
import {
  selectPermissionsFetched,
  selectPermissionsPeerGroupDashboardMenu,
  selectPermissionsPeerGroupDetailsMenu,
  selectPermissionsPeerGroupDocumentsMenu,
  selectPermissionsPeerGroupEventsMenu,
  selectPermissionsPeerGroupFacilitatorsMenu,
  selectPermissionsPeerGroupMembersMenu,
  selectPermissionsPeerGroupNotesMenu,
  selectPermissionsSidebarPeerGroupsMenu,
  selectPermissionsPeerGroupScorecardMenu,
} from "../../redux/permissionReducer/permissionSelectors";
import "./PeerGroupDetails.css";

// NO LAZY IMPORTS ANYMORE...
import PeerGroupDashboard from "../PeerGroupDashboard";
import PeerGroupEvents from "../PeerGroupEvents";
import PeerGroupStaff from "../PeerGroupStaff";
import PeerGroupMembers from "../PeerGroupMembers";
import PeerGroupScorecard from "../PeerGroupScorecard";

const PeerGroupDetails = () => {
  const { peerGroupId } = useParams();
  const [staff, setStaff] = useState(undefined);
  const [refreshStaff, setRefreshStaff] = useState(0);
  const [peerGroupDetails, setPeerGroupDetails] = useState(() => ({
    peerGroupId,
    canAdd: false,
    canEdit: false,
    canDelete: false,
    canAddStaff: false,
    canEditStaff: false,
    canDeleteStaff: false,
    peerGroupName: "",
    peerGroupCode: "",
    communityCode: "",
    communityName: "",
    createdDate: "",
    createdBy: "",
    modifiedDate: "",
    modifiedBy: "",
    _loading: true,
  }));
  const {
    permissionsFetched,
    sidebarPeerGroupsMenuPermission,
    peerGroupDashboardMenuPermission,
    peerGroupMembersMenuPermission,
    peerGroupEventsMenuPermission,
    peerGroupDetailsMenuPermission,
    peerGroupNotesMenuPermission,
    peerGroupDocumentsMenuPermission,
    peerGroupFacilitatorsMenuPermission,
    peerGroupScorecardMenuPermission,
  } = useSelector(
    createStructuredSelector({
      permissionsFetched: selectPermissionsFetched,
      sidebarPeerGroupsMenuPermission: selectPermissionsSidebarPeerGroupsMenu,
      peerGroupDashboardMenuPermission: selectPermissionsPeerGroupDashboardMenu,
      peerGroupMembersMenuPermission: selectPermissionsPeerGroupMembersMenu,
      peerGroupEventsMenuPermission: selectPermissionsPeerGroupEventsMenu,
      peerGroupDetailsMenuPermission: selectPermissionsPeerGroupDetailsMenu,
      peerGroupNotesMenuPermission: selectPermissionsPeerGroupNotesMenu,
      peerGroupDocumentsMenuPermission: selectPermissionsPeerGroupDocumentsMenu,
      peerGroupFacilitatorsMenuPermission: selectPermissionsPeerGroupFacilitatorsMenu,
      peerGroupScorecardMenuPermission: selectPermissionsPeerGroupScorecardMenu,
    })
  );

  useEffect(() => {
    getPeerGroupById({ peerGroupId }).then((response) => {
      if (!response.data) toast.error("Invalid Peer Group Page.");

      setPeerGroupDetails((prev) => ({ ...prev, ...(response.data || {}), _loading: false, peerGroupId }));
    });
  }, [peerGroupId]);

  useEffect(() => {
    if (refreshStaff === 0) return;

    getPeerGroupStaff({ peerGroupId }).then(({ data, canAdd, canEdit, canDelete }) => {
      setStaff(data);
      setPeerGroupDetails((prev) => ({ ...prev, canAddStaff: canAdd, canEditStaff: canEdit, canDeleteStaff: canDelete }));
    });
  }, [peerGroupId, refreshStaff]);

  const expandedDetails = false;

  return (
    <DetailsPageContainer
      breadCrumbTitle={"PeerGroups"}
      breadCrumbLink={"/peer-groups"}
      expandedDetails={expandedDetails}
      image={peerGroupDetails.peerGroupPictureUrl}
      hideBreadcrumb={!sidebarPeerGroupsMenuPermission}
      nameLetters={peerGroupDetails.peerGroupName.slice(0, 2)}
      detailsChildren={
        <>
          {expandedDetails && <h6>Active since February 2021</h6>}
          <h4
            className="overflow-text"
            style={expandedDetails ? undefined : { width: 320 }}
            title={`${peerGroupDetails.peerGroupName} ${peerGroupDetails.communityName && "-"} ${peerGroupDetails.communityName}`}
          >
            {peerGroupDetails.peerGroupName === peerGroupDetails.peerGroupCustomName ? "" : peerGroupDetails.peerGroupName}{" "}
            {peerGroupDetails.peerGroupName !== peerGroupDetails.peerGroupCustomName && peerGroupDetails.communityName && "-"} {peerGroupDetails.communityName}
          </h4>
          {/*<p className="overflow-text">{formatDateTime(peerGroupDetails.createdDate)}</p> */}
          <p className="overflow-text">{peerGroupDetails.peerGroupCustomName}</p>
        </>
      }
      peerGroupCustomName={peerGroupDetails.peerGroupCustomName}
      navLinkChildren={
        permissionsFetched && (
          <>
            {/* {peerGroupDashboardMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`dashboard`}>
                                    Dashboard
                                </Nav.Link>
                            </Nav.Item>
                        )} */}
            {peerGroupDetailsMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`details`}>
                  Details
                </Nav.Link>
              </Nav.Item>
            )}
            {peerGroupMembersMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`members`}>
                  Members
                </Nav.Link>
              </Nav.Item>
            )}
            {peerGroupEventsMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`events`}>
                  Events
                </Nav.Link>
              </Nav.Item>
            )}
            {peerGroupNotesMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`schedule`}>
                  Notes
                </Nav.Link>
              </Nav.Item>
            )}
            {/* {peerGroupDocumentsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`documents`}>
                                    Documents
                                </Nav.Link>
                            </Nav.Item>
                        )} */}
            {peerGroupFacilitatorsMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`facilitators`}>
                  Facilitators
                </Nav.Link>
              </Nav.Item>
            )}
            {peerGroupScorecardMenuPermission && (
              <Nav.Item as="li">
                <Nav.Link as={NavLink} to={`scorecards`}>
                  Scorecard
                </Nav.Link>
              </Nav.Item>
            )}
          </>
        )
      }
    >
      <ErrorBoundary>
        {peerGroupDetails._loading || !permissionsFetched ? (
          <PageLoader />
        ) : (
          <Suspense fallback={<PageLoader />}>
            <Routes>
              <Route
                path="/dashboard"
                element={!peerGroupDashboardMenuPermission ? <UnAuthorisedPage /> : <PeerGroupDashboard peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} asDashboard />}
              />
              <Route
                path="/facilitators"
                element={
                  !peerGroupFacilitatorsMenuPermission ? (
                    <UnAuthorisedPage />
                  ) : (
                    <PeerGroupStaff peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} staff={staff} setRefresh={setRefreshStaff} setStaff={setStaff} />
                  )
                }
              />
              <Route
                path="/details"
                element={!peerGroupDetailsMenuPermission ? <UnAuthorisedPage /> : <Details peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} setPeerGroupDetails={setPeerGroupDetails} />}
              />
              <Route path="/events" element={!peerGroupEventsMenuPermission ? <UnAuthorisedPage /> : <PeerGroupEvents peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} />} />
              <Route path="/members" element={!peerGroupMembersMenuPermission ? <UnAuthorisedPage /> : <PeerGroupMembers peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} />} />
              <Route path="/scorecards" element={!peerGroupScorecardMenuPermission ? <UnAuthorisedPage /> : <PeerGroupScorecard peerGroupId={peerGroupId} peerGroupDetails={peerGroupDetails} />} />
              <Route path="/" element={<Navigate peerGroupId={peerGroupId} to="details" replace />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </Suspense>
        )}
      </ErrorBoundary>
    </DetailsPageContainer>
  );
};

export default PeerGroupDetails;
