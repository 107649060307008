import { useEffect, useState, useRef, useCallback } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

import FormGroup from "../../components/FormGroup";
import EditSquare from "../../assets/images/edit_square_icon.png";
import { useForm, useToggle } from "../../helpers/hooks";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import "./UserDemographics.css";
import "../../pages/Register/SignUp.css";

const inititalForm = {
    userId: "",
    gender: "",
    ethnicity: "",
    birthdayDate: "",
    isHispanicLatino: "",
    isVeteran: "",
    isImmigrant: "",
    language: "",
    canEdit: false,
};
export default function UserDemographics({ userId }) {
    const [show, toggleShow] = useToggle(false);
    const [userData, setUserData] = useState(() => ({ ...inititalForm, userId }));
    const [races, setRaces] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [refresh, setRefresh] = useState(0);
    const [form, onChange, setForm] = useForm(() => ({ ...inititalForm, userId }));

    const hasError = useRef(0);

    const updateErrors = useCallback(() => {
        const errors = {};

        if (!form.gender) errors.gender = "Required Field.";
        if (!form.ethnicity) errors.ethnicity = "Required Field.";
        if (!form.birthdayDate) errors.birthdayDate = "Required Field.";
        if (!form.isHispanicLatino) errors.isHispanicLatino = "Required Field.";
        if (!form.isVeteran) errors.isVeteran = "Required Field.";
        if (!form.isImmigrant) errors.isImmigrant = "Required Field.";
        if (!form.language) errors.language = "Required Field.";
        if (form.birthdayDate > moment().format("YYYY-MM-DD")) errors.birthdayDate = "Date of Birth cannot be a future date.";

        setFormErrors(errors);
        hasError.current = Object.keys(errors).length > 0;
    }, [form.birthdayDate, form.ethnicity, form.gender, form.isHispanicLatino, form.isImmigrant, form.isVeteran, form.language]);
    const handleClose = useCallback(() => {
        toggleShow();
        setFormErrors({});
        hasError.current = 0;
        setForm(userData);
    }, [toggleShow, setForm, userData]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (hasError.current === 0) updateErrors();
        if (hasError.current) return;

        const response = await callApi.post(buildURL(Urls.EditUserDemographics), {
            ...form,
            isHispanicLatino: form.isHispanicLatino === "true",
            isImmigrant: form.isImmigrant === "true",
            isVeteran: form.isVeteran === "true",
        });

        if (response.responseCode === 200) {
            setUserData((prev) => ({ ...prev, ...form }));
            toast.success(response.responseMessage);
            toggleShow(false);
            hasError.current = 0;
            setFormErrors({});
            setRefresh((prev) => prev + 1);
        } else toast.error(response.responseMessage);
    };
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetDemographicsUserInfo}${userId}`));
            if (!response.responseData) return;
            setUserData({
                ...response.responseData,
                birthdayDate: response.responseData.birthdayDate === "0001-01-01T00:00:00" ? "" : response.responseData.birthdayDate.slice(0, 10),
                isHispanicLatino: response.responseData.isHispanicLatino === null ? null : response.responseData.isHispanicLatino ? "true" : "false",
                isImmigrant: response.responseData.isImmigrant === null ? null : response.responseData.isImmigrant ? "true" : "false",
                isVeteran: response.responseData.isVeteran === null ? null : response.responseData.isVeteran ? "true" : "false",
            });
        })();
    }, [userId, refresh]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.Ethnicity}`));
            if (!response.responseData) return;
            setRaces(
                response.responseData.map((r) => ({
                    label: r.name,
                    value: r.code,
                }))
            );
        })();
    }, []);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.Languages}`));
            if (!response.responseData) return;
            setLanguages(
                response.responseData.map((r) => ({
                    label: r.name,
                    value: r.code,
                }))
            );
        })();
    }, []);
    useEffect(() => {
        if (hasError.current === 0) return;
        updateErrors();
    }, [form, updateErrors]);
    useEffect(() => {
        setForm(userData);
    }, [userData, setForm]);
    return (
        <div className="containerDemographics">
            <div className="eventHeader top-head-kpi">
                <h4>Demographics</h4>
                {userData.canEdit && (
                    <span style={{ cursor: "pointer" }} onClick={toggleShow}>
                        <img src={EditSquare} alt=""></img>
                    </span>
                )}
            </div>
            <p>
                In order to keep our programming free to our communities, we compile this information in bulk and use it to demonstrate to our funders the people with whom we engage. We promise to
                never share your individual demographic information without prior authorization.
            </p>
            <p>We do not discriminate based on any demographic info that is required.</p>
            <p>
                If you would like to speak with someone at Entrepreneurs Forever about any of the demographic questions asked, please feel free to contact us at{" "}
                <a href="mailto:info@e4ever.org">info@e4ever.org</a>.
            </p>
            <div className="contentDemo mt-4">
                <div className="row mt-4">
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">GENDER:</span>
                        <span className="demoInput">{userData.gender}</span>
                    </div>
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">VETERAN:</span>
                        <span className="demoInput">{userData.isVeteran === null ? "" : userData.isVeteran === "false" ? "No" : "Yes"}</span>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">RACE:</span>
                        <span className="demoInput">{userData.ethnicityDetail}</span>
                    </div>
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">IMMIGRANT:</span>
                        <span className="demoInput">{userData.isImmigrant === null ? "" : userData.isImmigrant === "false" ? "No" : "Yes"}</span>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">HISPANIC/LATINO:</span>
                        <span className="demoInput">{userData.isHispanicLatino === null ? "" : userData.isHispanicLatino === "false" ? "No" : "Yes"}</span>
                    </div>
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">NATIVE LANGUAGE:</span>
                        <span className="demoInput">{userData.languageDetail}</span>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6 demographics-items">
                        <span className="demoLabel">BIRTHDAY:</span>
                        <span title={moment(userData.birthdayDate).format("LL")} className="demoInput">{`${
                            userData.birthdayDate ? `${moment(userData.birthdayDate).format("LL").slice(0, -4)} ****` : ""
                        }`}</span>
                    </div>
                </div>
            </div>
            {show && (
                <Modal className="manageUserPopup" show={show} onHide={handleClose} backdrop="static" size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Demographics</Modal.Title>
                    </Modal.Header>

                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col lg="12" md="12">
                                    <Row>
                                        <Col md="6">
                                            <FormGroup
                                                form={form}
                                                formErrors={formErrors}
                                                field={{
                                                    title: "Gender",
                                                    inputType: "select",
                                                    options: [
                                                        { label: "Male", value: "Male" },
                                                        { label: "Female", value: "Female" },
                                                        { label: "Non Binary", value: "Non Binary" },
                                                        { label: "Prefer Not To Answer", value: "Prefer Not To Answer" },
                                                        { label: "Other", value: "Other" },
                                                    ],
                                                    name: "gender",
                                                }}
                                                onChange={onChange}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <FormGroup
                                                form={form}
                                                formErrors={formErrors}
                                                field={{
                                                    title: "Race",
                                                    inputType: "select",
                                                    options: races,
                                                    name: "ethnicity",
                                                }}
                                                onChange={onChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group customInput">
                                                <label>Date of Birth (mm/dd/yyyy)</label>
                                                <input
                                                    form={form}
                                                    // formErrors={formErrors}
                                                    // field={{
                                                    //     title: "Date of Birth",
                                                    //     inputType: "date",
                                                    name="birthdayDate"
                                                    className="form-control cursor-default bg-transparent custom-input-calendar"
                                                    // }}
                                                    value={form.birthdayDate || ""}
                                                    type="date"
                                                    placeholder="Date of Birth"
                                                    onChange={onChange}
                                                    max={moment().format("YYYY-MM-DD")}
                                                />
                                                {formErrors.birthdayDate && (
                                                    <div className="small text-danger p-1 my-1" title={formErrors.birthdayDate}>
                                                        {formErrors.birthdayDate}
                                                    </div>
                                                )}
                                            </div>
                                        </Col>
                                        {/* <Col md="6">
                                            <FormGroup
                                                form={form}
                                                formErrors={formErrors}
                                                field={{
                                                    title: "Date of Birth",
                                                    inputType: "date",
                                                    name: "birthdayDate",
                                                }}
                                                onChange={onChange}
                                            />
                                        </Col> */}
                                        <Col md="6">
                                            <FormGroup
                                                form={form}
                                                formErrors={formErrors}
                                                field={{
                                                    title: "Native Language",
                                                    inputType: "select",
                                                    options: languages,
                                                    name: "language",
                                                }}
                                                onChange={onChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <div className="contactMethod">
                                                <h6>Hispanic/Latino</h6>
                                                <div className="selectMethod">
                                                    <div class="customRadioButton form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="isHispanicLatino"
                                                            value={"true"}
                                                            id="isHispanicLatinoYes"
                                                            class="form-check-input"
                                                            onChange={onChange}
                                                            checked={form.isHispanicLatino === "true"}
                                                        />
                                                        <label for="isHispanicLatinoYes" class="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>

                                                    <div class="customRadioButton form-check-inline">
                                                        <input
                                                            type="radio"
                                                            name="isHispanicLatino"
                                                            value={"false"}
                                                            id="isHispanicLatinoNo"
                                                            class="form-check-input"
                                                            onChange={onChange}
                                                            checked={form.isHispanicLatino === "false"}
                                                        />
                                                        <label for="isHispanicLatinoNo" class="form-check-label">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small text-danger px-2">{formErrors.isHispanicLatino}</div>
                                        </Col>
                                        <Col md="6">
                                            <div className="contactMethod">
                                                <h6>Veteran</h6>

                                                <div className="selectMethod">
                                                    <div className="customRadioButton form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            onChange={onChange}
                                                            checked={form.isVeteran === "true"}
                                                            type="radio"
                                                            name="isVeteran"
                                                            id="VeteranYes"
                                                            value={"true"}
                                                        />
                                                        <label htmlFor="VeteranYes"> Yes</label>
                                                    </div>
                                                    <div className="customRadioButton form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            onChange={onChange}
                                                            type="radio"
                                                            checked={form.isVeteran === "false"}
                                                            name="isVeteran"
                                                            id="VeteranNo"
                                                            value={"false"}
                                                        />
                                                        <label htmlFor="VeteranNo">No</label>
                                                    </div>

                                                    {/* <Form.Check inline type="radio" aria-label="radio 1" name="radio" label="Yes" />
                              <Form.Check inline type="radio" aria-label="radio 2" name="radio" label="No" /> */}
                                                </div>
                                            </div>
                                            <div className="small text-danger px-2">{formErrors.isVeteran}</div>
                                        </Col>
                                        <Col md="6">
                                            <div className="contactMethod">
                                                <h6>Immigrant</h6>
                                                <div className="selectMethod">
                                                    <div className="customRadioButton form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            id="ImmigrantYes"
                                                            onChange={onChange}
                                                            checked={form.isImmigrant === "true"}
                                                            type="radio"
                                                            name="isImmigrant"
                                                            value={"true"}
                                                        />
                                                        <label htmlFor="ImmigrantYes">Yes</label>
                                                    </div>
                                                    <div className="customRadioButton form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            id="ImmigrantNo"
                                                            onChange={onChange}
                                                            checked={form.isImmigrant === "false"}
                                                            type="radio"
                                                            name="isImmigrant"
                                                            value={"false"}
                                                        />
                                                        <label htmlFor="ImmigrantNo">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="small text-danger px-2">{formErrors.isImmigrant}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12 mt-4" className="text-end">
                                    <button onClick={handleClose} className="btnPrimaryOutline btn btn-outline-primary mx-2" type="button">
                                        CANCEL
                                    </button>
                                    <button type="submit" className="customPrimaryBtn btn" variant="primary">
                                        SAVE
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Body>
                    </Form>
                </Modal>
            )}
        </div>
    );
}
