import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly } from "../../helpers";
import messages from "../../helpers/messages";
import { createUserRole, deleteUserRole, getUserRoles, updateUserRole } from "../../services/roles/roleServices";

export const userRoleFields = [
    {
        name: "roleName",
        nameForm: "roleCode",
        title: "Role",
        inputType: EFieldInputTypes.select,
        options: [],
        required: true,
        col: 12,
        colMd: 12,
        readOnlyInEdit: true,
        dependencies: [
            {
                name: "reason",
                nameForm: "reasonCode",
                initialValue: "",
                // getOptions: throttle(async (form) => {
                //     if (!form.roleCode) return { data: [] };
                //     // console.log(form, "GetDATA");
                //     const response = await getExitReasonOptionsByRole();
                //     response.data = response.data.filter((status) => status.roleCode === form.roleCode);
                //     console.log(response);
                //     return response;
                // }, 200),
            },
        ],
    },

    {
        name: "startDate",
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        required: true,
        col: 6,
        colSm: 6,
        cell: (value) => formatDateOnly(value),
        // validate: (form) => (new Date(form.startDate).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime() ? "Start Date can not be in the past." : ""),
    },
    {
        name: "endDate",
        title: "End Date",
        inputType: EFieldInputTypes.date,
        required: false,
        initialValue: null,
        col: 6,
        colSm: 6,
        cell: (value) => formatDateOnly(value),
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? messages.endDateLater : ""),
    },
    {
        name: "reason",
        nameForm: "reasonCode",
        title: "Reason",
        inputType: EFieldInputTypes.select,
        options: [],
        col: 12,
        colMd: 12,
        readOnlyInEdit: false,
        optionsFilterer: (options, form) => options.filter((o) => o.roleCode === form.roleCode),
        required: (form) => !!form.endDate,
        excludeInFormOnCondition: (form) => !form.endDate,
        // validate: (form) => (new Date(form.startDate).getTime() < new Date(moment().format("YYYY-MM-DD")).getTime() ? "Start Date can not be in the past." : ""),
    },
];

export const userRoleInitialForm = {
    roleCode: "",
    startDate: "",
    endDate: "",
    userId: "",
    reasonCode: "",
};

export const userRoleApis = {
    create: {
        api: createUserRole,
    },
    update: {
        api: updateUserRole,
    },
    delete: {
        api: deleteUserRole,
    },
    getAll: {
        api: getUserRoles,
        body: {
            userId: "",
        },
    },
};
