import { createSelector } from "reselect";

export const selectUser = (state) => state.user;

export const selectUserId = createSelector(selectUser, (user) => user.userId);
export const selectUserEmail = createSelector(selectUser, (user) => user.email);
export const selectUserFirstName = createSelector(selectUser, (user) => user.firstName);
export const selectUserMiddleName = createSelector(selectUser, (user) => user.middleName);
export const selectUserLastName = createSelector(selectUser, (user) => user.lastName);
export const selectUserIsAdmin = createSelector(selectUser, (user) => user.isAdmin);
export const selectUserIsCommunityDirector = createSelector(selectUser, (user) => user.isCommunityDirector);
export const selectUserIsFacilitator = createSelector(selectUser, (user) => user.isFacilitator);
export const selectUserIsMember = createSelector(selectUser, (user) => user.isMember);
export const selectUserIsProspectApproved = createSelector(selectUser, (user) => user.isProspectApproved);
export const selectUserIsProspectNotApproved = createSelector(selectUser, (user) => user.isProspectNotApproved);
export const selectUserIsProspectQualified = createSelector(selectUser, (user) => user.isProspectQualified);
export const selectUserIsProspectNotQualified = createSelector(selectUser, (user) => user.isProspectNotQualified);
export const selectUserIsProspectFull = createSelector(selectUser, (user) => user.isProspectFull);
export const selectUserIsProspectWaitlist = createSelector(selectUser, (user) => user.isProspectWaitlist);
export const selectUserIsProspectNotScheduled = createSelector(selectUser, (user) => user.isProspectNotScheduled);
export const selectfunderNavLogo = createSelector(selectUser, (user) => user.funderLogo2);
