import { Link } from "react-router-dom";
import { formatDateOnly, formatTimeOnly } from "../../helpers";
import { getUserPastEvents, getUserUpcomingEvents } from "../../services/users/userEventServices";

export const upcomingEventFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventEndTime",
    columnWidth: 110,
    title: "Time To",
    cell: (value) => formatTimeOnly(value),
  },
  {
    name: "eventTimeZoneName",
    columnWidth: 120,
    title: "Time Zone",
  },
  {
    name: "eventTypeName",
    columnWidth: 150,
    title: "Event Type",
  },
  {
    name: "eventFormatName",
    columnWidth: 110,
    title: "Format",
  },
  {
    name: "attendeeStatusName",
    title: "Status",
    columnWidth: 110,
  },
];

export const eventHistorFields = [
  {
    name: "eventDate",
    columnWidth: 110,
    title: "Date",
    filterable: true,
    cell: (value, data) => <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>,
  },
  {
    name: "eventStartTime",
    columnWidth: 120,
    title: "Time From",
    filterable: true,
  },
  {
    name: "eventEndTime",
    columnWidth: 110,
    title: "Time To",
    filterable: true,
  },
  {
    name: "eventTimeZoneName",
    columnWidth: 120,
    title: "Time Zone",
    filterable: true,
  },
  {
    name: "eventTypeName",
    columnWidth: 150,
    title: "Event Type",
    filterable: true,
  },
  {
    name: "attendeeStatusName",
    title: "Status",
    filterable: true,
    columnWidth: 110,
  },
];

export const upcomingEventApis = {
  getAll: {
    api: getUserUpcomingEvents,
    body: { userId: null },
  },
};

export const pastEventApis = {
  getAll: {
    api: getUserPastEvents,
    body: { userId: null },
  },
};
