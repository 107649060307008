import { Link } from "react-router-dom";

import Status from "../../components/Status";
import { deleteEventAttendee, EEventAttendeeFieldNames, searchEventAttendeeUsers } from "../../services/events/eventAttendeeServices";

export const eventAttendeesFields = [
    {
        name: EEventAttendeeFieldNames.lastName,
        title: "Last Name",
        cell: (value, data) => (data.isAssociate ? <Link to={`/users/${data.userId}`}>{value}</Link> : <span>{value}</span>),
    },
    {
        name: EEventAttendeeFieldNames.firstName,
        title: "First Name",
        cell: (value, data) => (data.isAssociate ? <Link to={`/users/${data.userId}`}>{value}</Link> : <span>{value}</span>),
    },
    {
        name: EEventAttendeeFieldNames.businessName,
        title: "Business",
    },
    {
        name: EEventAttendeeFieldNames.statusName,
        title: "Status",
        cell: (value) => <Status text={value} code={value === "Attended" || value === "Going" ? 1 : value === "Tentative" || value === "Pending" || value === "Awaiting Response" ? 0 : -2} />,
        columnWidth: 150,
    },
    {
        name: EEventAttendeeFieldNames.roleName,
        title: "Prospect",
    },
    {
        name: EEventAttendeeFieldNames.monthlyFormStatusName,
        title: "Monthly Results Form Status",
        columnWidth: 160,
        cell: (value, { userId, monthlyFormId, eventId }) =>
            !value ? (
                <div className="cursor-default">
                    <strong>{"NA"}</strong>
                </div>
            ) : (
                <a target={"_blank"} href={`/month-view/${userId}/${eventId}/${monthlyFormId}`} rel="noreferrer">
                    {value}
                </a>
            ),
    },
];

export const eventAttendeeApis = {
    delete: {
        api: deleteEventAttendee,
    },
};
export const searchEventAttendeeUserApis = {
    getAll: {
        api: searchEventAttendeeUsers,
    },
};
