import { parseJwt } from "../../helpers";
import { EUserActionTypes } from "./userActions";

const initialState = {
    userId: null,
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    status: 1,
    accountLocked: 0,
    isAdmin: false,
    isCommunityDirector: false,
    isFacilitator: false,
    isMember: false,
    isProspectApproved: false,
    isProspectNotApproved: false,
    isProspectQualified: false,
    isProspectNotQualified: false,
    isProspectFull: false,
    isProspectWaitlist: false,
    isProspectNotScheduled: false,
    token: "",
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case EUserActionTypes.logIn:
            localStorage.setItem("token", action.payload.token || "");
            localStorage.setItem("token_issued_at", Date.now());
            const token = parseJwt(action.payload.token);
            const rolesKey = Object.keys(token).find((k) => k.endsWith("role") || Array.isArray(token[k]));
            if (rolesKey) {
                action.payload.isAdmin = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RADM") : token[rolesKey] === "RADM";
                action.payload.isCommunityDirector = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RCODI") : token[rolesKey] === "RCODI";
                action.payload.isFacilitator = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RFACI") : token[rolesKey] === "RFACI";
                action.payload.isMember = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RMEM") : token[rolesKey] === "RMEM";
                action.payload.isProspectApproved = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRAPP") : token[rolesKey] === "RPRAPP";
                action.payload.isProspectNotApproved = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRNAP") : token[rolesKey] === "RPRNAP";
                action.payload.isProspectQualified = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRQUA") : token[rolesKey] === "RPRQUA";
                action.payload.isProspectNotQualified = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRNQU") : token[rolesKey] === "RPRNQU";
                action.payload.isProspectFull = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRFUL") : token[rolesKey] === "RPRFUL";
                action.payload.isProspectWaitlist = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRWAT") : token[rolesKey] === "RPRWAT";
                action.payload.isProspectNotScheduled = Array.isArray(token[rolesKey]) ? !!token[rolesKey].find((code) => code === "RPRNSH") : token[rolesKey] === "RPRNSH";
            }
            return {
                ...state,
                ...action.payload,
                firstName: action.payload.firstName || "",
                middleName: action.payload.middleName || "",
                lastName: action.payload.lastName || "",
            };
        case EUserActionTypes.update:
            return {
                ...state,
                ...action.payload,
            };
        case EUserActionTypes.signOut:
            localStorage.removeItem("token");
            localStorage.removeItem("token_issued_at");

            return { ...initialState };

        default:
            return state;
    }
};

export default userReducer;
