import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const communityStaffPrimaryKey = "communityStaffId";

export const getCommunityStaff = async ({ communityId }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };

  const { responseData } = await callApi.get(buildURL(Urls.GetCommunityStaff), {
    headers: {
      communityCode: communityId,
    },
  });

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((u) => ({
      firstName: u.userFirstName,
      lastName: u.userLastName,
      roleNames: u.roleName,
      communityStaffId: u.userCommunityId,
      userId: u.userGuid,
      communityId,
      startDate: normalizeDateOnly(u.startDate),
      endDate: normalizeDateOnly(u.endDate),
    }));
  }

  return response;
};

export const addCommunityStaffMembers = async ({ communityId, userIds }) => {
  const response = await callApi.post(buildURL(Urls.AddCommunityStaff), {
    communityCode: communityId,
    userGuids: userIds,
  });

  return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.communityStaffId } } : response;
};
export const updateCommunityStaffMember = async ({ communityId, communityStaffId, startDate, endDate }) => {
  const response = await callApi.post(buildURL(Urls.UpdateCommunityStaff), {
    communityCode: communityId,
    userCommunityId: communityStaffId,
    startDate,
    endDate: endDate || null,
  });

  return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.communityStaffId } } : response;
};

export const deleteCommunityStaffMember = async ({ communityStaffId, communityId }) => {
  const response = await callApi.post(buildURL(Urls.DeleteCommunityStaff), {
    userCommunityId: communityStaffId,
    communityCode: communityId,
  });

  return response;
};
