import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import { deleteEventStaffMember, searchEventStaffUsers } from "../../services/events/eventStaffServices";

export const eventStaffFields = [
  {
    name: "lastName",
    title: "Last Name",
    col: 6,
    required: true,
    cell: (value, data) => <Link to={`/users/${data.userId}`}>{value}</Link>,
  },
  {
    name: "firstName",
    title: "First Name",
    col: 6,
    required: true,
    cell: (value, data) => <Link to={`/users/${data.userId}`}>{value}</Link>,
  },
  {
    name: "roleNames",
    title: "Role",
    col: 12,
    required: true,
    inputType: EFieldInputTypes.select,
    options: [],
  },
];

export const eventStaffApis = {
  delete: {
    api: deleteEventStaffMember,
  },
};
export const searchEventStaffUserApis = {
  getAll: {
    api: searchEventStaffUsers,
  },
};
