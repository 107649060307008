import { createGetOptionsApi } from "..";
import { formToFormData, normalizeDateOnly } from "../../helpers";
import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const userPeerGroupPrimaryKey = "userPeerGroupId";
export const peerGroupPrimaryKey = "peerGroupId";

const peerGroupKeyMaps = {};

export const getPeerGroupOptions = createGetOptionsApi(Urls.GetPeerGroupOptions, "peerGroupOptions", 1);

export const getPeerGroupOptionsByCommunity = createGetOptionsApi(Urls.GetPeerGroupOptionsByCommunity, "communityPeerGroupOptions", 0);

export const getPeerGroups = async ({ ...filters }) => {
    const headers = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber,
        sortOrder: filters.sortOrder,
        sortField: peerGroupKeyMaps[filters.sortField] || filters.sortField,
        peerGroupCode: filters.peerGroupCode,
        communityCode: filters.communityCode,
        stateCode: filters.stateCode,
        peerGroupStatus: filters.peerGroupStatus,
        memberName: filters.memberName,
        facilitatorName: filters.facilitatorName,
    };
    if (!headers.peerGroupStatus) delete headers.peerGroupStatus;

    const response = await callApi.get(buildURL(Urls.SearchPeerGroups), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((row) => ({
                  [peerGroupPrimaryKey]: row.peerGroupCode,
                  peerGroupName: row.peerGroupName,
                  peerGroupCustomName: row.peerGroupCustomName,
                  communityName: row.communityName,
                  communityCode: row.communityCode,
                  startDate: row.startDate,
                  startTime: row.startTime,
                  endDate: row.endDate,
                  endTime: row.endTime,
                  membersCount: row.membersCount,
                  scheduleTime: !row.startTime
                      ? ""
                      : `${row.weekChoiceText} ${row.dayOfWeekName} of Month at ${formatTimeOnly(row.startTime)} - ${formatTimeOnly(row.endTime)} ${row.timeZoneName || "EST"}`,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const createPeerGroup = async ({ peerGroupName, peerGroupCustomName, communityCode, startDate, endDate, peerGroupPicture }) => {
    const response = await callApi.post(
        buildURL("/PeerGroup/Add"),
        formToFormData({ peerGroupName, peerGroupCustomName, communityCode, startDate, endDate, peerGroupPicture: peerGroupPicture || null })
    );

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.peerGroupCode || "" } } : response;
};

export const updatePeerGroup = async ({ peerGroupId, peerGroupName, peerGroupCustomName, communityCode, startDate, endDate, peerGroupPicture }) => {
    const response = await callApi.post(
        buildURL("/PeerGroup/Update"),
        formToFormData({ peerGroupCode: peerGroupId, peerGroupName, peerGroupCustomName, communityCode, startDate, endDate, peerGroupPicture })
    );

    return response;
};

export const updatePeerGroupMonthlyDetails = async ({
    peerGroupId,
    monthEventWeek,
    monthEventDayId,
    startTime,
    endTime,
    timeZoneCode,
    eventFormatCode,
    monthEventLink,
    formatNote: monthEventNote,
}) => {
    const response = await callApi.post(buildURL("/PeerGroup/UpdateDetail"), {
        peerGroupCode: peerGroupId,
        monthEventWeek,
        monthEventDayCode: monthEventDayId?.toString() || "",
        monthEventStartTime: startTime,
        monthEventEndTime: endTime,
        timeZoneCode,
        eventFormatCode,
        monthEventLink,
        monthEventNote,
    });

    return response;
};

export const getPeerGroupById = async ({ peerGroupId }) => {
    const response = await callApi.get(buildURL(Urls.GetPeerGroupDetailsPage), { headers: { peerGroupCode: peerGroupId } });

    const data = response.responseData;

    return {
        data: data
            ? {
                  ...data,
                  startTime: data.startTime?.slice(0, 5) || "",
                  endTime: data.endTime?.slice(0, 5) || "",
                  endDate: data.endDate || "",
                  monthEventDayId: (data.dayOfWeekCode || "").toString(), // Can be Zero, so must be Empty String.
                  monthEventWeek: (data.monthEventWeek || "").toString(), // Can be Zero, so must be Empty String.
                  monthEventLink: data.meetingLinkOrUrl,
                  peerGroupPictureUrl: data.peerGroupPicture,
              }
            : null,
    };
};
export const getUserPeerGroups = async ({ userId, ...filters }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetUserPeerGroups) + userId, { headers: filters });

    if (Array.isArray(responseData?.searchResult)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.searchResult.map((peerGroup, i) => ({
            ...peerGroup,
            roleCode: peerGroup.roleCode?.trim(),
            userId,
            communityCode: peerGroup.communityCode?.trim(),
            startDate: normalizeDateOnly(peerGroup.startDate),
            endDate: normalizeDateOnly(peerGroup.endDate),
            [userPeerGroupPrimaryKey]: peerGroup.userPeerGroupId,
        }));
    }

    return response;
};

export const createUserPeerGroup = async ({ userId, communityCode, roleCode, peerGroupCode, startDate, endDate }) => {
    const response = await callApi.post(buildURL(Urls.CreateUserPeerGroup), { userId, communityCode, roleCode, peerGroupCode, startDate: startDate || null, endDate: endDate || null });

    return response;
};

export const updateUserPeerGroup = async ({ userId, communityCode, roleCode, peerGroupCode, startDate, endDate, userPeerGroupId }) => {
    const response = await callApi.post(buildURL(Urls.UpdateUserPeerGroup), {
        userId,
        communityCode,
        roleCode,
        peerGroupCode,
        startDate: startDate || null,
        endDate: endDate || null,
        userPeerGroupId,
    });

    return response.responseCode === 200 && response.responseData === false ? { responseCode: 400, responseData: `User cannot have same responsibility with blank End Date.` } : response;
};

export const deleteUserPeerGroup = async ({ userPeerGroupId, userId }) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserPeerGroup), { userPeerGroupId, userId });

    return response;
};
