import { Link } from "react-router-dom";

import Status from "../../components/Status";
import Tooltip from "../../components/Tooltip";
import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, isStringUrl, throttle } from "../../helpers";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { getEventFormatOptions, getEvents, getEventStatusOptions, getEventTypeOptions, eventPrimaryKey, createUpdateEvent, getEventTypeMaxDefault } from "../../services/events/eventServices";
import { getPeerGroupOptions, getPeerGroupOptionsByCommunity } from "../../services/peer-groups/peerGroupServices";
import { getTimeZoneOptions } from "../../services/time-zones/timeZoneServices";
import { getUserCommunityOptions } from "../../services/users/userServices";
import messages from "../../helpers/messages";

const tooltipMap = (d, i) => <p key={i} className="p-1">{`${d.communityName ? `${d.communityName}, ` : ""}${d.name}`}</p>;

export const eventSearchFields = [
    {
        name: "eventTypeCode",
        col: 3,
        title: "Event Type",
        inputType: EFieldInputTypes.select,
        getOptions: getEventTypeOptions,
        maxLength: 50,
    },
    {
        name: "eventDateFrom",
        col: 3,
        title: "Event Date From",
        inputType: EFieldInputTypes.date,
        placeholder: "Start Date Range",
    },
    {
        name: "eventDateTo",
        col: 3,
        title: "Event Date To",
        inputType: EFieldInputTypes.date,
        placeholder: "End Date Range",
    },
    {
        name: "eventFormatCode",
        col: 3,
        title: "Format",
        inputType: EFieldInputTypes.select,
        getOptions: getEventFormatOptions,
        maxLength: 20,
    },
    {
        name: "communityCode",
        col: 3,
        title: "Community",
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityOptions,
        maxLength: 50,
        dependencies: [
            {
                name: "peerGroupCode",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.communityCode ? await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode }) : await getPeerGroupOptions();

                    return response;
                }, 200),
            },
        ],
    },
    {
        name: "peerGroupCode",
        col: 3,
        title: "Peer Group",
        inputType: EFieldInputTypes.select,
        getOptions: getPeerGroupOptions,
        maxLength: 50,
    },
    {
        name: "eventStatusCode",
        col: 3,
        title: "Event Status",
        inputType: EFieldInputTypes.select,
        getOptions: getEventStatusOptions,
        maxLength: 20,
        getInitialOption: (response) => response.data.find((opt) => opt.label === "Active"),
    },
];

export const eventFields = [
    {
        name: "eventDate",
        title: "Date",
        filterable: true,
        cell: (value, data) => (
            <Link to={`/events/${data[eventPrimaryKey]}`} state={data}>
                {formatDateOnly(value)}
            </Link>
        ),
        filter: {
            maxLength: 10,
        },
        inputType: EFieldInputTypes.date,
    },
    {
        name: "eventStartTime",
        title: "Time Start",
        filterable: true,
        filter: {
            maxLength: 15,
        },
        inputType: EFieldInputTypes.time,
        columnWidth: 140,
    },
    {
        name: "eventEndTime",
        title: "Time End",
        filterable: true,
        filter: {
            maxLength: 15,
        },
        inputType: EFieldInputTypes.time,
        columnWidth: 140,
    },
    {
        name: "eventTimeZoneName",
        title: "Time Zone",
        filterable: true,
        columnWidth: 140,
    },
    {
        name: "eventTypeName",
        title: "Event Type",
        filterable: true,
        filter: {
            maxLength: 50,
        },
    },
    {
        name: "communityNames",
        title: "Community",
        filterable: true,
        filter: {
            maxLength: 50,
        },
        columnWidth: 100,
        cell: (value, data) => (
            <div className="text-center font-weight-bold">
                {!data.communities?.length ? (
                    <span className="anchor-tag cursor-default">{value}</span>
                ) : (
                    <Tooltip placement="top" text={data.communities.map(tooltipMap)}>
                        <span className="anchor-tag">{value}</span>
                    </Tooltip>
                )}
            </div>
        ),
        notSortable: true,
    },
    {
        name: "peerGroupNames",
        title: "Peer Group",
        filterable: true,
        filter: {
            maxLength: 50,
        },
        notSortable: true,
        columnWidth: 90,
        cell: (value, data) => (
            <div className="text-center font-weight-bold">
                {!data.peerGroups?.length ? (
                    <span className="anchor-tag cursor-default">{value}</span>
                ) : (
                    <Tooltip placement="top" text={data.peerGroups.map(tooltipMap)}>
                        <span className="anchor-tag">{value}</span>
                    </Tooltip>
                )}
            </div>
        ),
    },
    {
        name: "eventFormatName",
        title: "Format",
        filterable: true,
        filter: {
            maxLength: 20,
        },
    },
    {
        name: "eventStatusName",
        title: "Event Status",
        filterable: true,
        cell: (value) => <Status text={value} />,
        filter: {
            maxLength: 20,
        },
        columnWidth: 180,
    },
];

const eventStatusField = {
    name: "eventStatusName",
    nameForm: "eventStatusCode",
    title: "Event Status",
    inputType: EFieldInputTypes.select,
    getOptions: getEventStatusOptions,
    required: true,
    col: 4,
};

export const eventFormFields = [
    {
        name: "eventTypeName",
        nameForm: "eventTypeCode",
        title: "Event Type",
        inputType: EFieldInputTypes.select,
        getOptions: async () => {
            const response = await getEventTypeOptions();

            const subResponse = await getEventTypeMaxDefault();

            response.data = response.data.map((o) => {
                const extraToChange = {};

                const found = subResponse.data.find((subO) => subO.value === o.value);

                if (found) extraToChange.maxAttendees = found.label;

                return { ...o, extraToChange };
            });

            return response;
        },
        required: true,
        dependencies: [
            {
                name: "communityNames",
                nameForm: "communityCode",
                initialValue: "",
                resetOnCondition: (form) => form.eventTypeName !== "Peer Group" && form.eventTypeName !== "One on One",
            },
            {
                name: "peerGroupNames",
                nameForm: "peerGroupCode",
                initialValue: "",
                resetOnCondition: (form) => form.eventTypeName !== "Peer Group" && form.eventTypeName !== "One on One",
            },
        ],
        readOnlyInEdit: true,
        showInfo: (form) =>
            form.eventTypeName && form.eventTypeName !== "Peer Group" && form.eventTypeName !== "One on One"
                ? "Please update the Permissions tab to add Communities and Peer Groups to the Event."
                : "",
    },
    {
        name: "eventName",
        title: "Event Name",
        maxLength: 50,
        inputType: EFieldInputTypes.text,
        col: 6,
        required: (form) => form.eventTypeName === "Other",
        excludeInFormOnCondition: (form) => form.eventTypeName !== "Other",
    },
    {
        name: "communityNames",
        nameForm: "communityCode",
        title: "Community Name",
        inputType: EFieldInputTypes.select,
        dependencies: [
            {
                name: "peerGroupNames",
                nameForm: "peerGroupCode",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    if (!form.communityCode) return { data: [] };

                    const response = await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode });

                    return response;
                }, 200),
            },
        ],
        getOptions: getUserCommunityOptions,
        col: 6,
        readOnlyInEdit: true,
        required: (form) => form.eventTypeName === "Peer Group" || form.eventTypeName === "One on One",
        excludeInFormOnCondition: (form) => !(form.eventTypeName === "Peer Group" || form.eventTypeName === "One on One"),
    },
    {
        name: "peerGroupNames",
        nameForm: "peerGroupCode",
        title: "Peer Group",
        inputType: EFieldInputTypes.select,
        options: [],
        col: 6,
        readOnlyInEdit: true,
        required: (form) => form.eventTypeName === "Peer Group" || form.eventTypeName === "One on One",
        excludeInFormOnCondition: (form) => !(form.eventTypeName === "Peer Group" || form.eventTypeName === "One on One"),
    },
    {
        name: "eventDate",
        title: "Date",
        inputType: EFieldInputTypes.date,
        required: true,
    },
    {
        name: "eventStartTime",
        title: "Time From",
        inputType: EFieldInputTypes.time,
        col: 3,
        colMd: 4,
        colSm: 6,
        required: true,
    },
    {
        name: "eventEndTime",
        title: "Time To",
        inputType: EFieldInputTypes.time,
        col: 3,
        colMd: 4,
        colSm: 6,
        required: true,
        validate: (form) => (form.eventEndTime <= form.eventStartTime ? messages.endTimeBeforeStartTime : ""),
    },
    {
        name: "eventTimeZoneName",
        nameForm: "eventTimeZoneCode",
        title: "Time Zone",
        inputType: EFieldInputTypes.select,
        required: true,
        colMd: 4,
        getOptions: getTimeZoneOptions,
        getInitialOption: (response) => response.data.find((opt) => opt.label === "EST"),
    },
    { ...eventStatusField },

    {
        name: "eventFormatName",
        nameForm: "eventFormatCode",
        title: "Format",
        inputType: EFieldInputTypes.select,
        getOptions: getEventFormatOptions,
        getInitialOption: (response) => response.data.find((opt) => opt.label === "Virtual"),
        required: true,
        dependencies: [
            {
                name: "eventLink",
            },
        ],
        col: 4,
        showInfo: (form) => (form.eventFormatName && form.eventFormatName === "In-Person" ? "Please enter the address and any directions in the Format Note below." : ""),
    },
    {
        name: "maxAttendees",
        viewName: "maxAttendees",
        col: 4,
        nameForm: "maxAttendees",
        title: "Max Attendees",
        inputType: EFieldInputTypes.number,
        validate: (form) => (form.maxAttendees < 0 ? "The value needs to be a positive number" : ""),
        required: true,
    },
    {
        name: "eventLink",
        title: "Format Link",
        inputType: EFieldInputTypes.text,
        colMd: 12,
        colSm: 12,
        col: 12,
        maxLength: 320,
        required: (form) => form.eventFormatName === "Virtual",
        validate: (form) => (form.eventFormatName === "Virtual" && !isStringUrl(form.eventLink) ? messages.invalidUrl : ""),
        excludeInFormOnCondition: (form) => form.eventFormatName !== "Virtual",
    },
    {
        name: "eventFormatNote",
        title: "Format Note",
        inputType: EFieldInputTypes.textarea,
        colMd: 12,
        colSm: 12,
        col: 12,
    },
    {
        name: "eventDescription",
        title: "Description",
        inputType: EFieldInputTypes.textarea,
        colMd: 12,
        colSm: 12,
        col: 12,
    },
    {
        name: "eventEmailMessage",
        title: "Email Message",
        inputType: EFieldInputTypes.textarea,
        colMd: 12,
        colSm: 12,
        col: 12,
    },
];

export const eventAddFormFields = eventFormFields.map((f) =>
    f.name !== eventStatusField.name
        ? f
        : {
              ...f,
              getOptions: async () => {
                  const response = await getEventStatusOptions();
                  response.data = response.data.filter((status) => status.label === "Active" || status.label === "Inactive");
                  return response;
              },
              getInitialOption: (response) => response.data.find((opt) => opt.label === "Active"),
          }
);

export const eventInitialSort = {
    dir: "asc",
    field: "eventDate",
};

export const eventInitialSearchForm = {
    sortOrder: eventInitialSort.dir,
    sortField: eventInitialSort.field,
    eventTypeCode: "",
    eventDateFrom: "",
    eventDateTo: "",
    eventFormatCode: "",
    communityCode: "",
    peerGroupCode: "",
    eventStatusCode: "",
};

export const eventInitialForm = {
    eventTypeCode: "",
    eventName: "",
    eventTypeName: "",
    communityCode: "",
    peerGroupCode: "",
    communityNames: "",
    peerGroupNames: "",
    eventDate: "",
    eventStartTime: "",
    eventEndTime: "",
    eventTimeZoneCode: "",
    eventTimeZoneName: "",
    eventFormatCode: "",
    eventFormatName: "",
    eventLink: "",
    eventStatusCode: "",
    eventStatusName: "",
    eventFormatNote: "",
    eventDescription: "",
    eventEmailMessage: "",
    maxAttendees: "0",
};

export const eventApis = {
    getAll: {
        api: getEvents,
    },
    create: {
        api: createUpdateEvent,
    },
};
