import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Grid, GridColumn as Column, GridNoRecords, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { toast } from "react-toastify";

import { updateFieldsOptions, useDebounce, useForm, useThrottle, useToggle } from "../../helpers/hooks";
import ConfirmationModal from "../ConfirmationModal";
import DeleteIcon from "../DeleteIcon";
import EditIcon, { EEditIconVariants } from "../EditIcon";
import FormModal from "../FormModal";
import LoadingSpinner from "../LoadingSpinner";
import SearchForm from "../SearchForm";
import FilterCell from "./FilterCell.component";
import HeaderCell from "./HeaderCell.component";
import InitialTableLayout from "./InitialTableLayout.component";

import "./ManagementTable.css";

const defaultApis = {
    create: null,
    update: null,
    delete: null,
    getAll: null,
};

const defaultPageSizes = [25, 50, 100, 200];

const CustomLockedCell = (props) => {
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            style={props.style} // this applies styles that lock the column at a specific position
            className={props.className} // this adds classes needed for locked columns
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
                [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...navigationAttributes}
        >
            {props.cell ? props.cell(props.dataItem[field], props.dataItem, props.dataIndex) : value === null ? "" : props.dataItem[field]?.toString()}
        </td>
    );
};

function ManagementTable({
    title = "Title",
    tableTitle,
    fields,
    formFields = fields,
    searchFields,
    data,
    selectedData,
    initialForm,
    isLoading,
    initialSearchForm,
    runOnSubmitSearch,
    hideActionsColumn = false,
    hideAddButton = false,
    hideViewButton = false,
    hideEditButton = false,
    hideDeleteButton = false,
    withCheckboxes = false,
    withSearch = false,
    withFilters = false,
    hideHeader = false,
    hideActionsLabel = false,
    hideMoreFiltersButton = false,
    confirmOnSubmit = false,
    localSort = Array.isArray(data),
    pageSizes = defaultPageSizes,
    initialPageSize = pageSizes[0],
    runOnPageChange,
    runAfterSubmit,
    runAfterDelete,
    runAfterGetAll,
    totalRecords,
    primaryKey = "id",
    onClickAdd,
    searchCols,
    initialFilterCount,
    initialActionsColumnWidth = 140,
    noPagination = false,
    noSorting = false,
    apis = defaultApis,
    noDataMessage = "No Record Found.",
    customOnSubmitResponseMessage,
    customCreateSuccessMessage,
    customUpdateSuccessMessage,
    customDeleteSuccessMessage,
    customDeleteConfirmationMessage,
    customCreateConfirmationMessage,
    customUpdateConfirmationMessage,
    customAddButtonTitle = `Add New ${title}`,
    className,
    childrenBeforeAddButton,
    refreshAfterSubmit = false,
    refreshTable = 0,
    onChangeSelection,
    formSize,
    extraActionButtons,
    clearSelection = 0,
    customFormModalTitle,
    initialSort,
    height = "calc(100vh - 25px)",
    hideDeleteButtonRow,
    fetchCount = 0,
}) {
    const tableRef = useRef(null);
    const mountRef = useRef(true);
    const fetched = useRef(fetchCount);
    const actionRowRef = useRef(null);
    const processingRef = useRef(false);

    const [formFieldsFinal, setFormFieldsFinal] = useState(() => formFields || []);
    const [searchFieldsFinal, setSearchFieldsFinal] = useState(() => searchFields || []);

    useEffect(() => setFormFieldsFinal(formFields || []), [formFields]);
    useEffect(() => setSearchFieldsFinal(searchFields || []), [searchFields]);

    const [filter, setFilter] = useState({
        logic: "and",
        filters: [],
    });
    const [refresh, setRefresh] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(() => ({ size: initialPageSize, number: 1 }));
    const [total, setTotal] = useState(0);
    const [sort, setSort] = useState(() => (initialSort ? [initialSort] : [])); // FOR LOCAL
    const [sortTable, setSortTable] = useState(() => (initialSort ? { sortOrder: initialSort.dir, sortField: initialSort.field } : {})); // FOR API
    const [dataRows, setDataRows] = useState([]);
    const [dataRowsFiltered, setDataRowsFiltered] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showTable, setShowTable] = useState(() => !withSearch || fetchCount > 0);
    const [show, toggleShow, setShow] = useToggle(false);
    const [showDelete, toggleShowDelete, setShowDelete] = useToggle(false);
    const [form, onChange, setForm] = useForm(initialForm, formFields, setFormFieldsFinal);
    const [searchFormData, setSearchFormData] = useState(() => initialSearchForm);
    const [searchForm, onChangeSearch, setSearchForm] = useForm(initialSearchForm, searchFields, setSearchFieldsFinal);

    const onSubmit = useThrottle(
        async (formData) => {
            const apiAction = formData[primaryKey] ? "update" : "create";

            if (!apis[apiAction] || processingRef.current) return;

            setShowProcessing(true);
            processingRef.current = true;

            const response = await apis[apiAction].api({ ...formData, ...(apis[apiAction].body || {}) });

            setShowProcessing(false);
            processingRef.current = false;

            if (response.responseCode !== 200) return toast.error(typeof response.responseData === "string" ? response.responseData : response.responseMessage || "Something went wrong.");

            toast.success(
                customOnSubmitResponseMessage?.(response, formData) ||
                    (formData[primaryKey] ? customUpdateSuccessMessage || `${title} has been updated successfully.` : customCreateSuccessMessage || `${title} has been added successfully.`),
                "Success."
            );

            if (!mountRef.current) return;

            fetched.current++;

            setShow(false);
            setForm({ ...(initialForm || {}) });
            setShowTable(true);

            if (runAfterSubmit) runAfterSubmit(formData, response);
            if (refreshAfterSubmit) return setRefresh((prev) => prev + 1);
            if (!formData[primaryKey]) setTotal((prev) => prev + 1);

            setDataRows((prev) =>
                formData[primaryKey]
                    ? prev.map((row) => (row[primaryKey] !== formData[primaryKey] ? row : { ...row, ...formData }))
                    : [{ ...formData, [primaryKey]: response.responseData?.id || prev.length + 1 }, ...prev]
            );
        },
        [apis, customCreateSuccessMessage, customUpdateSuccessMessage, initialForm, primaryKey, refreshAfterSubmit, setForm, setShow, title],
        1000
    );
    const onDelete = useThrottle(
        async () => {
            if (!apis.delete?.api) return;

            const response = await apis.delete.api({ ...actionRowRef.current, ...(apis.delete.body || {}) });

            if (response.responseCode !== 200) return toast.error(response.responseMessage || "Something went wrong.");

            toast.success(customDeleteSuccessMessage || `${title} has been removed successfully.`);

            if (!mountRef.current) return;

            setShowDelete(false);
            setSelectedRows((prev) => prev.filter((row) => row[primaryKey] !== actionRowRef.current[primaryKey]));
            setTotal((prev) => prev - 1);
            setDataRows((prev) => {
                if (!prev.length) setPage((prevPage) => (prevPage.number > 1 ? { ...prevPage, number: prevPage.number - 1 } : prevPage));
                return prev.filter((row) => row[primaryKey] !== actionRowRef.current[primaryKey]);
            });
            setTimeout(() => (actionRowRef.current = null), 100);
            if (runAfterDelete) runAfterDelete(actionRowRef.current);
        },
        [apis.delete, customDeleteSuccessMessage, primaryKey, setShowDelete, title],
        2000
    );
    const onCancel = () => {
        toggleShow();
        setTimeout(() => setForm({ ...(initialForm || {}) }), 500);
    };

    const onChangeSelectAll = useCallback(
        ({ target: { checked } }) => {
            setSelectedRows((prev) =>
                checked
                    ? [...prev.filter((row) => !dataRowsFiltered.find((r) => r[primaryKey] === row[primaryKey])), ...dataRowsFiltered]
                    : prev.filter((row) => !dataRowsFiltered.find((r) => r[primaryKey] === row[primaryKey]))
            );
            setSelectAll(checked);
            if (onChangeSelection) onChangeSelection(checked ? dataRowsFiltered : []);
        },
        [dataRowsFiltered, onChangeSelection, primaryKey]
    );
    const onChangeSelect = useCallback(
        ({ target: { checked } }, rowData) =>
            setSelectedRows((prev) => {
                const newSelectedRows = checked ? [...prev, rowData] : prev.filter((row) => row[primaryKey] !== rowData[primaryKey]);

                if (onChangeSelection) onChangeSelection(newSelectedRows);
                return newSelectedRows;
            }),
        [onChangeSelection, primaryKey]
    );

    const handleEdit = (rowData) => {
        setForm(rowData);
        toggleShow();

        for (const field of formFields) updateFieldsOptions({ ...rowData }, field, setFormFieldsFinal);
    };

    const handleDelete = (rowData) => {
        actionRowRef.current = rowData;
        toggleShowDelete();
    };

    const onSubmitSearch = useCallback(
        (formData) => {
            setPage((prev) => ({ ...prev, number: 1 }));
            setSearchFormData({ ...formData, pageNumber: 1 });
            if (fetched.current === 0) setShowTable(true);
            if (runOnSubmitSearch) runOnSubmitSearch({ ...formData });
            fetched.current++;
        },
        [runOnSubmitSearch]
    );
    const onChangePage = ({ page }) => {
        setPage((prev) => {
            const size = page.take;
            const number = (page.skip / page.take || 0) + 1;

            if (size === prev.size && number === prev.number) return prev;

            const newPage = { size, number };

            if (runOnPageChange) runOnPageChange(newPage);

            if (tableRef.current)
                tableRef.current.querySelector("tbody tr")?.scrollIntoView({
                    behavior: "smooth",
                });

            return newPage;
        });
    };
    const onSortChange = useThrottle(
        (event) => {
            const { sort } = event;

            setSort(sort);

            if (!sort[0]) return;
            if (!localSort)
                return setSortTable({
                    sortOrder: sort[0].dir.toUpperCase(),
                    sortField: sort[0].field,
                });

            setDataRows((prev) => [
                ...prev.sort((a, b) => {
                    const left = typeof a[sort[0].field] === "string" ? a[sort[0].field].toLowerCase() : a[sort[0].field] ?? "";
                    const right = typeof b[sort[0].field] === "string" ? b[sort[0].field].toLowerCase() : b[sort[0].field] ?? "";

                    if (left < right) return sort[0].dir === "asc" ? -1 : 1;
                    if (left > right) return sort[0].dir === "asc" ? 1 : -1;

                    return 0;
                }),
            ]);
        },
        [localSort],
        200
    );
    const onResetSearch = useCallback(() => {
        const newForm = { ...initialSearchForm };
        for (const field of searchFieldsFinal) if (field.readOnly) delete newForm[field.name];
        return setSearchForm((prev) => ({ ...prev, ...newForm }));
    }, [initialSearchForm, setSearchForm, searchFieldsFinal]);

    const updateRows = useCallback(
        (dataRows, page, filter) => {
            const pageRows = noPagination ? dataRows : dataRows.slice(page.size < dataRows.length ? (page.number - 1) * page.size : 0, page.number * page.size);
            const rows = !filter?.filters?.length
                ? pageRows
                : pageRows.filter((row) => {
                      if (filter) for (const f of filter.filters) if (!row[f.field]?.toLowerCase?.()?.includes?.(f.value.toLowerCase())) return false;

                      return true;
                  });
            setDataRowsFiltered(rows);
        },
        [noPagination]
    );
    const updateFilteredRows = useDebounce((dataRows, page, filter) => updateRows(dataRows, page, filter), [updateRows], 800);
    const onFilterChange = useCallback(
        ({ filter }) => {
            setFilter(filter);
            updateFilteredRows(dataRows, page, filter);
        },
        [dataRows, page, updateFilteredRows]
    );

    useEffect(() => {
        updateRows(dataRows, page);
    }, [dataRows, page, updateRows]);

    useEffect(() => {
        if (totalRecords !== undefined) return setTotal(totalRecords);

        if (Array.isArray(data)) setTotal(data.length);
    }, [data, totalRecords]);

    useEffect(() => {
        if (Array.isArray(data)) setDataRows(data);
    }, [data]);
    useEffect(() => {
        if (Array.isArray(selectedData)) setSelectedRows(selectedData);
    }, [selectedData]);

    useEffect(() => {
        if (isLoading !== undefined) setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        if (data || !apis.getAll?.api || (withSearch && fetched.current === 0)) return;

        (async () => {
            setLoading(true);

            const response = await apis.getAll.api({ ...searchFormData, ...sortTable, pageSize: page.size, pageNumber: page.number, ...(apis.getAll.body || {}) });

            if (!mountRef.current) return;

            setLoading(false);

            setDataRows(Array.isArray(response.data) ? response.data : []);
            setTotal(response.totalRecords);
            if (runAfterGetAll) runAfterGetAll(response);
        })();
    }, [data, withSearch, searchFormData, apis.getAll, page.size, page.number, sortTable, refresh, refreshTable, runAfterGetAll]);

    useEffect(
        () => () => {
            mountRef.current = false;
        },
        []
    );

    const isOpen = useRef(false);

    useEffect(() => {
        isOpen.current = false;
    }, [page.size]);

    useEffect(() => {
        const container = tableRef.current.querySelector(".k-dropdownlist");
        if (!showTable || !container) return;

        const handleClick = () => {
            isOpen.current = !isOpen.current;
            setTimeout(() => {
                container.focus();
            }, 100);
        };

        const handleBlur = () => {
            if (isOpen.current) container.click();
        };

        container.addEventListener("click", handleClick);
        container.addEventListener("blur", handleBlur);

        return () => {
            container.removeEventListener("click", handleClick);
            container.removeEventListener("blur", handleBlur);
        };
    }, [showTable]);

    const actionsColumnWidth = useMemo(() => {
        let width = initialActionsColumnWidth;
        if (hideViewButton) width -= 20;
        if (hideEditButton) width -= 20;
        if (hideDeleteButton) width -= 20;

        if (width < 90) width = 90; // Minimum Width must be DEFINED to avoid Ui issues.

        return width;
    }, [hideViewButton, hideDeleteButton, hideEditButton, initialActionsColumnWidth]);

    useEffect(() => {
        let selected = !!dataRowsFiltered.length;
        dataRowsFiltered.forEach((row) => {
            if (!selectedRows.find((r) => row[primaryKey] === r[primaryKey])) selected = false;
        });
        setSelectAll(selected);
    }, [page.size, page.number, dataRowsFiltered, selectedRows, primaryKey]);

    useEffect(() => {
        if (clearSelection > 0) {
            setSelectedRows([]);
            setSelectAll(false);
        }
    }, [clearSelection]);

    return (
        <>
            <div className={className || "card"}>
                {withSearch && (
                    <SearchForm
                        fields={searchFieldsFinal}
                        form={searchForm}
                        onChange={onChangeSearch}
                        onSubmit={onSubmitSearch}
                        onReset={onResetSearch}
                        searchCols={searchCols}
                        initialFilterCount={initialFilterCount}
                        hideAddButton={hideAddButton}
                        customAddButtonTitle={customAddButtonTitle}
                        title={title}
                        onClickAdd={onClickAdd || toggleShow}
                        showTable={showTable}
                        hideMoreFiltersButton={hideMoreFiltersButton}
                    />
                )}
                {!(hideHeader || withSearch) && showTable && (
                    <div className="inner-subHeader">
                        {typeof tableTitle === "string" ? <h1>{tableTitle}</h1> : tableTitle || <div />}
                        <div className="filterAdduserBtns align-items-center attendess-panel">
                            {childrenBeforeAddButton}
                            {!hideAddButton && (
                                <button className="addNewUser btn" onClick={onClickAdd || toggleShow}>
                                    <MdOutlineAddCircleOutline /> {customAddButtonTitle}
                                </button>
                            )}
                        </div>
                    </div>
                )}
                <div className="kendoGrid customGrid" ref={tableRef}>
                    {loading && (
                        <div className="w-100" style={{ height: 70, position: "absolute", zIndex: 9 }}>
                            <LoadingSpinner />
                        </div>
                    )}
                    {showTable ? (
                        <Grid
                            onFilterChange={onFilterChange}
                            sort={sort}
                            onSortChange={onSortChange}
                            pageSize={page.size}
                            onPageChange={onChangePage}
                            total={total}
                            skip={(page.number - 1) * page.size}
                            data={dataRowsFiltered}
                            sortable={{
                                allowUnsort: true,
                                mode: false ? "multiple" : "single",
                            }}
                            filterable={withFilters}
                            filter={filter}
                            style={{
                                width: "100%",
                                maxHeight: height,
                            }}
                            pageable={
                                noPagination
                                    ? undefined
                                    : {
                                          buttonCount: 5,
                                          info: true,
                                          type: "numeric",
                                          pageSizes: pageSizes,
                                          previousNext: true,
                                      }
                            }
                        >
                            <GridNoRecords>{noDataMessage}</GridNoRecords>
                            {withCheckboxes && (
                                <Column
                                    title={"Checkboxes"}
                                    width={60}
                                    field={"checkboxes"}
                                    className={"action"}
                                    filterable={false}
                                    headerCell={() => (
                                        <div className="text-center">
                                            <input className="form-check-input" type="checkbox" onChange={onChangeSelectAll} checked={selectAll} />
                                        </div>
                                    )}
                                    cell={({ dataItem }) => (
                                        <td className="text-center">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={!!selectedRows.find((r) => r[primaryKey] === dataItem[primaryKey])}
                                                onChange={(event) => onChangeSelect(event, dataItem)}
                                            />
                                        </td>
                                    )}
                                />
                            )}
                            {fields?.map((field, index) =>
                                field.hideTableColumn ? null : (
                                    <Column
                                        key={index}
                                        width={field.columnWidth || 120}
                                        field={field.name}
                                        title={field.title}
                                        sortable={noSorting ? false : !field.notSortable}
                                        filterable={!!field.filterable}
                                        headerClassName={field.headerClassName}
                                        filter={field.filter}
                                        filterTitle={field.title || field.name}
                                        filterCell={FilterCell}
                                        locked={field.freezeColumn}
                                        headerCell={() => <HeaderCell title={field.title} name={field.name} sort={sort} onSortChange={onSortChange} notSortable={noSorting || field.notSortable} />}
                                        cell={
                                            field.freezeColumn
                                                ? (props) => <CustomLockedCell {...props} cell={field.cell} />
                                                : ({ field: name, dataItem, dataIndex }) => <td>{field.cell ? field.cell(dataItem[name], dataItem, dataIndex) : dataItem[name]}</td>
                                        }
                                    />
                                )
                            )}
                            {!hideActionsColumn && (
                                <Column
                                    title={!hideActionsLabel ? "Action" : " "}
                                    width={actionsColumnWidth}
                                    field={"_actions"}
                                    filterable={false}
                                    headerCell={() => (
                                        <HeaderCell title={!hideActionsLabel ? "Action" : " "} name={"_actions"} sort={sort} onSortChange={onSortChange} className="text-center" notSortable />
                                    )}
                                    cell={({ dataItem }) => (
                                        <td>
                                            <div className="action-btn">
                                                {extraActionButtons?.({ data: dataItem })}
                                                {!hideEditButton && <EditIcon onClick={() => handleEdit(dataItem)} size={26} variant={EEditIconVariants.note} withTooltip />}
                                                {!hideDeleteButton && !hideDeleteButtonRow?.(dataItem) && <DeleteIcon onClick={() => handleDelete(dataItem)} size={26} withTooltip />}
                                            </div>
                                        </td>
                                    )}
                                />
                            )}
                        </Grid>
                    ) : (
                        <InitialTableLayout hideAddButton={hideAddButton} customAddButtonTitle={customAddButtonTitle} onClickAdd={onClickAdd || toggleShow} title={title} />
                    )}
                </div>
                <FormModal
                    show={show}
                    showProcessing={showProcessing}
                    title={title}
                    form={form}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    primaryKey={primaryKey}
                    fields={formFieldsFinal}
                    confirmOnSubmit={confirmOnSubmit}
                    customCreateConfirmationMessage={customCreateConfirmationMessage}
                    customUpdateConfirmationMessage={customUpdateConfirmationMessage}
                    size={formSize}
                    customModalTitle={customFormModalTitle}
                />
                <ConfirmationModal
                    show={showDelete}
                    modalTitle={`Delete ${title}`}
                    confirmationMessage={customDeleteConfirmationMessage || `Sure to delete this ${title} ?`}
                    onCancel={toggleShowDelete}
                    onConfirm={onDelete}
                />
            </div>
        </>
    );
}

Column.propTypes = {
    ...(Column.propTypes || {}),
    filter: Column.propTypes?.filter ? PropTypes.oneOfType([PropTypes.object, Column.propTypes.filter]) : PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

ManagementTable.propTypes = {
    title: PropTypes.string,
    tableTitle: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    formFields: PropTypes.arrayOf(PropTypes.object),
    searchFields: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.object),
    selectedData: PropTypes.arrayOf(PropTypes.object),
    initialForm: PropTypes.object,
    isLoading: PropTypes.bool,
    initialSearchForm: PropTypes.object,
    runOnSubmitSearch: PropTypes.func,
    hideActionsColumn: PropTypes.bool,
    hideAddButton: PropTypes.bool,
    hideViewButton: PropTypes.bool,
    hideEditButton: PropTypes.bool,
    hideDeleteButton: PropTypes.bool,
    withCheckboxes: PropTypes.bool,
    withSearch: PropTypes.bool,
    withFilters: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideActionsLabel: PropTypes.bool,
    confirmOnSubmit: PropTypes.bool,
    localSort: PropTypes.bool,
    pageSizes: PropTypes.arrayOf(PropTypes.number),
    initialPageSize: PropTypes.number,
    runOnPageChange: PropTypes.func,
    runAfterSubmit: PropTypes.func,
    runAfterDelete: PropTypes.func,
    runAfterGetAll: PropTypes.func,
    totalRecords: PropTypes.number,
    primaryKey: PropTypes.string.isRequired,
    onClickAdd: PropTypes.func,
    searchCols: PropTypes.object,
    initialFilterCount: PropTypes.number,
    initialActionsColumnWidth: PropTypes.number,
    noPagination: PropTypes.bool,
    noSorting: PropTypes.bool,
    apis: PropTypes.object,
    noDataMessage: PropTypes.string,
    customCreateSuccessMessage: PropTypes.string,
    customUpdateSuccessMessage: PropTypes.string,
    customDeleteSuccessMessage: PropTypes.string,
    customDeleteConfirmationMessage: PropTypes.string,
    customCreateConfirmationMessage: PropTypes.string,
    customUpdateConfirmationMessage: PropTypes.string,
    customAddButtonTitle: PropTypes.string,
    className: PropTypes.string,
    childrenBeforeAddButton: PropTypes.any,
    refreshAfterSubmit: PropTypes.bool,
    refreshTable: PropTypes.number,
    onChangeSelection: PropTypes.func,
    formSize: PropTypes.string,
    extraActionButtons: PropTypes.any,
    clearSelection: PropTypes.number,
    customFormModalTitle: PropTypes.string,
    initialSort: PropTypes.object,
};

export default ManagementTable;
