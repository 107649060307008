import { getClassName, getCode } from "./DATA";

import "./Status.css";

export default function Status({ text, code = getCode(text) }) {
  const className = getClassName(code);

  return (
    <div className="status-view-container" title={text}>
      {text && (
        <span className={`status-view shadow-sm ${className}-status`}>
          <div className={`badge-view ${className}-badge`} />
          <div className="status-text">{text}</div>
        </span>
      )}
    </div>
  );
}
