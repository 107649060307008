import { Link } from "react-router-dom";
import { deleteEventCurriculum } from "../../services/events/eventCurriculumServices";

export const eventCurriculumFields = [
    {
        name: "focus",
        title: "Focus",
        col: 6,
        required: true,
    },
    {
        name: "title",
        title: "Title",
        col: 6,
        required: true,
        cell: (value, data) => <Link to={`/curriculum/${data.curriculumId}`}>{value}</Link>,
    },
    {
        name: "status",
        title: "Status",
        col: 6,
        required: true,
    },
];

export const eventCurriculumApis = {
    // create: {
    //     api: createUpdateCurriculum,
    // },
    delete: {
        api: deleteEventCurriculum,
    },
};
