import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { formatDateOnly, formatDateTime, formatTimeOnly } from "../../helpers";
import { selectUserIsProspectNotScheduled, selectUserIsProspectQualified, selectUserIsProspectWaitlist } from "../../redux/userReducer/userSelectors";
import { getUserDashboardPendingAttendance } from "../../services/users/userDashboardFacilitatorAndStaffServices";
import { getUserDashboardForCommunityDirectorAndFacilitatorPeerGroups } from "../../services/users/userDashboardPeerGroupsServices";
import { getUserDashboardUpcomingEvents } from "../../services/users/userEventServices";
import { getUserDashboardMilestoneWidgets } from "../../services/users/userWidgetMilestoneServices";

const EventDateComponent = ({ value, data }) => {
    const { isProspectQualified, isProspectWaitlist, isProspectNotScheduled } = useSelector(
        createStructuredSelector({
            isProspectQualified: selectUserIsProspectQualified,
            isProspectWaitlist: selectUserIsProspectWaitlist,
            isProspectNotScheduled: selectUserIsProspectNotScheduled,
        })
    );
    return isProspectQualified || isProspectWaitlist || isProspectNotScheduled ? <span>{formatDateOnly(data.eventDate)}</span> : <Link to={`/events/${data.eventId}`}>{formatDateOnly(value)}</Link>;
};

export const userDashboardUpcomingEventFields = [
    {
        name: "eventDate",
        columnWidth: 110,
        title: "Date",
        cell: (value, data) => {
            return <EventDateComponent value={value} data={data} />;
        },
    },
    {
        name: "eventTypeName",
        columnWidth: 150,
        title: "Event Type",
    },
    {
        name: "eventStartTime",
        columnWidth: 120,
        title: "Time",
    },
    {
        name: "timeZoneName",
        columnWidth: 120,
        title: "Time Zone",
    },
    {
        name: "eventFormatName",
        columnWidth: 110,
        title: "Format",
    },
    {
        name: "userStatusName",
        title: "Status",
        columnWidth: 110,
    },
];

export const userDashboardUpcomingMilestoneFields = [
    {
        name: "date",
        columnWidth: 110,
        title: "Date",
        filterable: true,
        cell: (value, data) => <span>{formatDateOnly(value)}</span>,
    },
    {
        name: "peerGroupName",
        columnWidth: 120,
        title: "Peer Group",
        filterable: true,
        cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupCode}/details`}>{value}</Link>,
    },
    {
        name: "memberName",
        columnWidth: 110,
        title: "Member",
        filterable: true,
        cell: (value, data) => <Link to={`/users/${data.memberId}/user/details`}>{value}</Link>,
    },
    {
        name: "milestoneName",
        columnWidth: 120,
        title: "Milestone",
        filterable: true,
    },
];
export const userDashboardForCommunityDirectorAndFacilitatorPeerGroupsFields = [
    {
        name: "peerGroupName",
        columnWidth: 120,
        title: "Peer Group",
        filterable: true,
        cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupCode}/details`}>{value}</Link>,
    },
    {
        name: "totalMembers",
        columnWidth: 110,
        title: "Members",
        filterable: true,
    },
    {
        name: "communityName",
        columnWidth: 120,
        title: "Community",
        filterable: true,
    },
    {
        name: "communityName",
        columnWidth: 120,
        title: "Schedule",
        filterable: true,
        cell: (value, data) => (
            <span>{`${data.weekChoiceText} ${data.dayOfWeekName} ${formatTimeOnly(data.startTime)} ${data.endTime && "to"} ${formatTimeOnly(data.endTime)} ${
                data.startTime && data.timeZoneName
            }`}</span>
        ),
    },
];
export const userDashboardPendingAttendanceFields = [
    {
        name: "date",
        columnWidth: 110,
        title: "Date",
        filterable: true,
        cell: (value, data) => <Link to={`/events/${data.eventId}/details`}>{formatDateOnly(value)}</Link>,
    },
    {
        name: "eventType",
        columnWidth: 120,
        title: "Event Type",
        filterable: true,
        // cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupCode}/details`}>{value}</Link>,
    },
];
export const userDashboardUpcomingEventApis = {
    getAll: {
        api: getUserDashboardUpcomingEvents,
    },
};
export const userDashboardUpcomingMilestoneApis = {
    getAll: {
        api: getUserDashboardMilestoneWidgets,
    },
};
export const userDashboardForCommunityDirectorAndFacilitatorApis = {
    getAll: {
        api: getUserDashboardForCommunityDirectorAndFacilitatorPeerGroups,
    },
};
export const userDashboardPendingAttendanceApis = {
    getAll: {
        api: getUserDashboardPendingAttendance,
    },
};
