import { useMemo, useRef, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlineNoteAlt } from "react-icons/md";

import ButtonBorder from "../../components/ButtonBorder";
import ManagementTable from "../../components/ManagementTable";
import Modal from "../../components/Modal";
import Form from "../../components/Form";
import AddRowsModal from "../../components/AddRowsModal";
import FormModal from "../../components/FormModal";
import messages from "../../helpers/messages";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { peerGroupMemberPrimaryKey, sendEmailToPeerGroupMembers } from "../../services/peer-groups/peerGroupMemberServices";
import { memberEmailFields, memeberEmailInitialForm, peerGroupMemberApis, peerGroupMemberFields, selectUserSearchFields } from "./DATA";
import { showResponseMessage } from "../../helpers";
import { selectUserEmail, selectUserFirstName, selectUserLastName } from "../../redux/userReducer/userSelectors";
import { userApis, userFields, userInitialSearchForm } from "../Users/DATA";
import { addMemberToPeerGroup, createUserNote } from "../../services/users/userNoteServices";
import { userNoteInitialForm } from "../UserNotes/DATA";

export default function PeerGroupMembers({ peerGroupId }) {
    const [refresh, setRefresh] = useState(0);
    const [show, setShow] = useState(false);
    const [showMembers, toggleShowMembers, setShowMembers] = useToggle(false);
    const [showNote, toggleShowNote] = useToggle(false);
    const [clearSelection, setClearSelection] = useState(0);
    const [form, onChangeForm, setForm] = useForm(memeberEmailInitialForm);
    const [formNote, onChangeFormNote, setFormNote] = useForm(memeberEmailInitialForm);
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
        canAddNote: false,
        canSendEmail: false,
    });

    const processing = useRef(false);
    const selectedRows = useRef([]);
    const state = useRef({
        multiple: true,
        selected: [],
    });

    const { email, firstName, lastName } = useSelector(
        createStructuredSelector({
            email: selectUserEmail,
            firstName: selectUserFirstName,
            lastName: selectUserLastName,
        })
    );

    const onClickAdd = useThrottle(() => toast.info("Coming soon..."), [], 5000);
    const onAddEventAttendees = useCallback(
        async (selectedUsers) => {
            if (processing.current) return;
            processing.current = true;
            const response = await addMemberToPeerGroup({
                peerGroupId,
                userIds: selectedUsers.map((user) => user.userGuid),
            });
            setTimeout(() => {
                processing.current = false;
            }, 2000);
            showResponseMessage(response, { success: "User(s) have been successfully added to the peer group." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            setShowMembers(false);
        },
        [peerGroupId, setShowMembers]
    );

    const runAfterGetAll = useCallback(({ canAdd, canEdit, canDelete, canAddNote, canSendEmail }) => setPermissions({ canAdd, canEdit, canDelete, canAddNote, canSendEmail }), []);
    const onClose = useCallback(() => {
        setShow(false);
        setForm({ ...memeberEmailInitialForm });
        if (!state.current.multiple) {
            state.current.multiple = true;
            selectedRows.current = state.current.selected;
        }
    }, [setForm]);
    const onChangeSelection = useCallback((selected) => (selectedRows.current = selected), []);
    const onAddNote = useThrottle(() => toast.info("Coming soon..."), [], 5000);
    const handleSendEmail = useThrottle(
        () => {
            if (!selectedRows.current.length) return toast.warning("Please select atleast one member.");
            setShow(true);
        },
        [],
        1000
    );
    const onSubmitSendEmail = useThrottle(
        async (form) => {
            const formData = {
                peerGroupCode: peerGroupId,
                firstName: firstName,
                lastName: lastName,
                toEmailAddress: email,
                bccEmailAddress: selectedRows.current.map((row) => row.email).join(", "),
                subject: form.subject,
                message: form.message,
            };
            const response = await sendEmailToPeerGroupMembers(formData);

            showResponseMessage(response, { success: "Email has been sent successfully." });

            if (response.responseCode !== 200) return;

            setShow(false);
            setForm({ ...memeberEmailInitialForm });
            if (state.current.multiple) {
                selectedRows.current = [];
                setClearSelection((prev) => prev + 1);
            } else {
                selectedRows.current = state.current.selected;
                state.current.multiple = true;
            }
        },
        [email, firstName, lastName, peerGroupId],
        2000
    );
    const extraActionButtons = useCallback(
        ({ data }) => {
            const handleSend = () => {
                state.current.multiple = false;
                state.current.selected = selectedRows.current;
                selectedRows.current = [data];
                setShow(true);
            };

            return (
                <>
                    {permissions.canSendEmail && (
                        <span className="mx-2" onClick={handleSend} title={"Send Email"}>
                            <AiOutlineMail size={25} color={"#7ac332"} />
                        </span>
                    )}
                    {/* {permissions.canAddNote && (
                        <span className="mx-2" onClick={onClickAdd} title={"Add Note"}>
                            <MdOutlineNoteAlt size={25} color={"#7ac332"} />
                        </span>
                    )} */}
                </>
            );
        },
        [permissions.canSendEmail]
    );

    const apis = useMemo(() => ({ ...peerGroupMemberApis, getAll: { ...peerGroupMemberApis.getAll, body: { peerGroupId } } }), [peerGroupId]);
    const childrenBeforeAddButton = useMemo(
        () => (
            <>
                {/* {permissions.canAddNote && (
                    <ButtonBorder className={" mx-2"} onClick={onAddNote}>
                        Add Note
                    </ButtonBorder>
                )} */}
                {permissions.canSendEmail && (
                    <ButtonBorder className={" mx-2"} onClick={handleSendEmail}>
                        Email Selected Records
                    </ButtonBorder>
                )}
            </>
        ),
        [handleSendEmail, permissions.canSendEmail]
    );

    const onSubmitNote = useCallback(
        async (formData) => {
            const response = await createUserNote({ ...formData, entityTypeId: peerGroupId, noteType: "Member", userIds: selectedRows.current.map((u) => u.userId) });

            showResponseMessage(response, {
                success: messages.createUserNote,
            });

            if (response.responseCode !== 200) return;

            setFormNote({ ...userNoteInitialForm });
            toggleShowNote(false);
        },
        [peerGroupId, setFormNote, toggleShowNote]
    );
    const onCancelNote = useCallback(() => {
        toggleShowNote(false);
        setTimeout(() => setFormNote({ ...userNoteInitialForm }), 500);
    }, [setFormNote, toggleShowNote]);

    return (
        <>
            <ManagementTable
                title="Member"
                tableTitle="Members"
                customAddButtonTitle="Add Member"
                fields={peerGroupMemberFields}
                onClickAdd={toggleShowMembers}
                onChangeSelection={onChangeSelection}
                runAfterGetAll={runAfterGetAll}
                primaryKey={peerGroupMemberPrimaryKey}
                refreshTable={refresh}
                apis={apis}
                clearSelection={clearSelection}
                childrenBeforeAddButton={childrenBeforeAddButton}
                extraActionButtons={extraActionButtons}
                hideActionsColumn={!permissions.canAddNote && !permissions.canSendEmail}
                withCheckboxes={permissions.canAddNote || permissions.canSendEmail}
                hideAddButton={!permissions.canAdd}
                hideEditButton
                hideDeleteButton
            />
            <Modal show={show} onHide={onClose} title={"Send Email"}>
                <div className="form-group customInput">
                    <label>To</label>
                    <div className="border p-2">
                        <p>{email}</p>
                    </div>
                </div>
                <div className="form-group customInput">
                    <label>Bcc</label>
                    <div className="border p-2">
                        <p>{selectedRows.current.map((row) => row.email).join("; ")}</p>
                    </div>
                </div>
                <Form fields={memberEmailFields} onSubmit={onSubmitSendEmail} onCancel={onClose} form={form} onChange={onChangeForm} customSubmitTitle={"SEND"} />
            </Modal>
            <AddRowsModal
                show={showMembers}
                onHide={toggleShowMembers}
                fields={userFields}
                apis={userApis}
                initialSearchForm={userInitialSearchForm}
                searchFields={selectUserSearchFields}
                onAddRows={onAddEventAttendees}
                confirmationMessage={`Are you sure you want to add the user(s) to the peer group?`}
            />
            <FormModal show={showNote} form={formNote} onChange={onChangeFormNote} onSubmit={onSubmitNote} onCancel={onCancelNote} />
        </>
    );
}
