/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import "./SignUp.css";

import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import LeftBar from "./LeftBar";
import NotQualified from "./NotQualified";
import NotScheduledRole from "./NotScheduledRole";
import ProspectFull from "./ProspectFull";
import SignUpBusinessDetails from "./SignUpBusinessDetails";
import SignUpConfirmDiscovery from "./SignUpConfirmDiscovery";
import SignUpContactInfo from "./SignUpContactInfo";
import SignUpDiscoverySession from "./SignUpDiscoverySession";
import SignUpFinalizeApplication from "./SignUpFinalizeApplication";
import SignUpInitial from "./SignUpInitial";

const Register = () => {
    const [tab, setTab] = useState(1);
    const [userId, setUserId] = useState("");
    const [community, setCommunity] = useState("");
    const [eventData, setEventData] = useState({});
    const [prospectInitId, setProspectInitId] = useState("");
    const [activeClass, setActiveClass] = useState(0);
    const [communtiesURL, setCommunitiesURL] = useState([]);
    const [channelURL, setChannelURL] = useState([]);
    const [logo, setLogo] = useState(null);
    const [form, setForm] = useState({
        userId: "",
        prospectInitId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneType: "",
        phoneNumber: "",
        hearAboutUsCode: "",
        preferredContactMethod: "Email" || "",
        prospectUserFirstStepId: "",
        businessName: "",
        businessZipCode: "",
        businessDescription: "",
        businessOwnership: "",
        isBusinessFullTime: "",
        businessRevenue: "",
        businessStartMonth: "",
        businessStartYear: "",
        fullTimeEmployee: "",
        partTimeEmployee: "",
        independentContractor: "",
        businessProblemDetail: "",
        usersIpAddress: "",
        referrerURL: "",
        channel: "",
        funder: "",
        source: "",
        ownerCompensation: "",
    });
    useEffect(() => {
        const searchParams = new URLSearchParams(document.location.search);
        const funder = searchParams.get("funder");
        if (funder) {
            callApi.get(buildURL(`${Urls.GetFundersLogo}?funderCode=${funder}`)).then((response) => {
                if (response.responseCode === 200) {
                    setLogo(response?.responseData?.logo1);
                }
            });
        }
    }, []);
    const getEventDetails = (details) => {
    };
    function getAllUrlParams(url) {
        let queryString = url ? url.split("?")[1] : window.location.search.slice(1);
        let obj = {};

        if (queryString) {
            queryString = queryString.split("#")[0];

            let arr = queryString.split("&");

            for (let i = 0; i < arr.length; i++) {
                let a = arr[i].split("=");

                let paramName = a[0];
                let paramValue = typeof a[1] === "undefined" ? true : a[1];

                paramName = paramName.toLowerCase();
                if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

                if (paramName.match(/\[(\d+)?\]$/)) {
                    let key = paramName.replace(/\[(\d+)?\]/, "");
                    if (!obj[key]) obj[key] = [];

                    if (paramName.match(/\[\d+\]$/)) {
                        let index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                } else if (!obj[paramName]) {
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === "string") {
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    obj[paramName].push(paramValue);
                }
            }
        }

        return obj;
    }
    useEffect(() => {
        const URL = window.location.search;
        const decodedURL = decodeURIComponent(URL);
        setCommunitiesURL(getAllUrlParams(decodedURL).comm);
    }, []);
    useEffect(() => {
        const URL = window.location.search;
        const decodedURL = decodeURIComponent(URL);
        setChannelURL(getAllUrlParams(decodedURL).ch);
    }, []);
    const URL = window.location.search;
    const decodedURL = decodeURIComponent(URL);
    const splicedURL = decodedURL?.indexOf("?");
    const sourceURL = decodedURL?.slice(splicedURL + 1, decodedURL.length);

    return (
        <>
            <div className="signUpWrapper">
                <div className="signUpInnerSec prospect-page-wrapper">
                    <LeftBar activeClass={activeClass} tab={tab} setTab={setTab} logo={logo} isFunderLogo={!!logo} />
                    {tab === 0 ? <SignUpInitial prospectInitId={prospectInitId} setProspectInitId={setProspectInitId} form={form} tab={tab} setTab={setTab} setForm={setForm} isFunderLogo={!!logo} /> : ""}
                    {tab === 1 && (
                        <SignUpContactInfo setActiveClass={setActiveClass} setProspectInitId={setProspectInitId} prospectInitId={prospectInitId} form={form} setTab={setTab} sourceURL={sourceURL} setForm={setForm} isFunderLogo={!!logo} />
                    )}
                    {tab === 2 && (
                        <SignUpBusinessDetails setUserId={setUserId} setActiveClass={setActiveClass} setTab={setTab} form={form} setForm={setForm} channelURL={channelURL} sourceURL={sourceURL} isFunderLogo={!!logo} />
                    )}
                    {tab === 3 && (
                        <SignUpFinalizeApplication
                            setGlobalCommunity={setCommunity}
                            userId={userId}
                            setTab={setTab}
                            form={form}
                            setForm={setForm}
                            communtiesURL={communtiesURL}
                            decodedURL={decodedURL}
                            isFunderLogo={!!logo}
                            sourceURL={sourceURL}
                        />
                    )}
                    {tab === 4 && <NotQualified form={form} setForm={setForm} isFunderLogo={!!logo} />}
                    {tab === 5 && (
                        <SignUpDiscoverySession setEventData={setEventData} getEventDetails={getEventDetails} userId={userId} community={community} setTab={setTab} form={form} setForm={setForm} isFunderLogo={!!logo} />
                    )}
                    {tab === 6 && <SignUpConfirmDiscovery eventData={eventData} setTab={setTab} form={form} setForm={setForm} isFunderLogo={!!logo} />}
                    {tab === 7 && <ProspectFull eventData={eventData} setTab={setTab} form={form} setForm={setForm} isFunderLogo={!!logo} />}
                    {tab === 8 && <NotScheduledRole eventData={eventData} setTab={setTab} form={form} setForm={setForm} isFunderLogo={!!logo} />}
                </div>
            </div>
        </>
    );
};

export default Register;
