import moment from "moment";
import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import SelectInput from "../../components/SelectInput";
import Status from "../../components/Status";
import Tooltip from "../../components/Tooltip";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createUpdateCounites, getCounites } from "../../services/counties/countiesService";
import { getUserStatusOptions } from "../../services/users/userServices";

const communityTooltipMap = (d, i) => {
    return <p key={i} className="p-1">{`${d.name} (${moment(d?.startDate).format("MMM DD, YYYY")} - ${d?.endDate ? moment(d?.endDate).format("MMM DD, YYYY") : "Active"})`}</p>;
};
const memberTooltipMap = (d, i) => <p key={i} className="p-1">{`${d.name} (${moment(d?.startDate).format("MMM DD, YYYY")} - ${d?.endDate ? moment(d?.endDate).format("MMM DD, YYYY") : "Active"})`}</p>;

export const getCountiesStatusOptions = () => [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
];

export const countiesSearchFields = [
    {
        name: "county",
        col: 3,
        title: "County",
        maxLength: 50,
    },
    {
        name: "communityCode",
        col: 3,
        title: "Community",
        getOptions: getCommunityOptions,
        getInitialOption: undefined,
        readOnly: false,
        inputType: EFieldInputTypes.custom,
        disableActiveField: false,
        noEmptyOption: false,
        component({ form, onChange }) {
            return (
                <div className="my-2">
                    <SelectInput
                        name={this.name}
                        title={this.title}
                        getOptions={this.getOptions}
                        getInitialOption={this.getInitialOption}
                        onChange={onChange}
                        value={form[this.name]}
                        readOnly={this.readOnly}
                        noEmptyOption={this.noEmptyOption}
                        asSearchField
                    />
                </div>
            );
        },
    },
    {
        name: "member",
        col: 3,
        title: "Member",
        maxLength: 50,
    },
    {
        name: "accountStatus",
        col: 3,
        title: "Account Status",
        inputType: EFieldInputTypes.select,
        getOptions: getUserStatusOptions,
        /* noEmptyOption: true, */
    },
];

export const countiesFields = [
    {
        name: "county",
        title: "County",
        filterable: true,
        cell: (county, data) => (
            <Link style={{ color: "#00adba" }} to={`/county/${data.countyId}`}>
                {county}
            </Link>
        ),
        filter: {
            maxLength: 50,
        },
        columnWidth: 200,
    },
    {
        name: "communities",
        title: "Community",
        filterable: true,
        notSortable: true,
        cell: (communities, data) => (
            <div className="font-weight-bold">
                {!data.communities?.length ? (
                    <span className="anchor-tag cursor-default" style={{ marginLeft: "10%" }}>
                        {communities.length}
                    </span>
                ) : (
                    <Tooltip placement="top" text={data.communities.map(communityTooltipMap)}>
                        <span className="anchor-tag" style={{ marginLeft: "10%" }}>
                            {communities.length}
                        </span>
                    </Tooltip>
                )}
            </div>
        ),
        columnWidth: 80,
    },
    {
        name: "members",
        title: "Members",
        filterable: true,
        notSortable: true,
        cell: (members, data) => (
            <span className="anchor-tag" style={{ marginLeft: "10%", cursor: "default" }}>
                {members?.length}
            </span>
        ),
        columnWidth: 80,
    },
    {
        name: "accountStatus",
        title: "Account Status",
        filterable: true,
        cell: (value) => <Status text={value} />,
        filter: {
            maxLength: 20,
        },
        columnWidth: 150,
    },
];
export const countiesFormFields = [
    {
        name: "name",
        required: true,
        title: "County",
        placeholder: "County",
        col: 6,
        colMd: 6,
        maxLength: 50,
    },
    {
        name: "isActive",
        col: 6,
        title: "Account Status",
        inputType: EFieldInputTypes.select,
        options: getCountiesStatusOptions(),
        required: true,
        /*  noEmptyOption: true, */
    },
];

export const countiesInitialForm = {
    name: "",
    isActive: "true",
};

export const countiesInitialSort = {
    dir: "asc",
    field: "county",
};

export const countiesInitialSearchForm = {
    pageSize: 25,
    pageNumber: 1,
    sortOrder: "asc",
    sortField: "county",
    county: "",
    communityCode: "",
    member: "",
    accountStatus: "ASACT",
    // accountStatus: "true",
};

export const countiesApis = {
    getAll: {
        api: getCounites,
    },
    create: {
        api: createUpdateCounites,
    },
};
