import { ESidebarActionTypes } from "./sidebarActions";

const initialState = {
  show: true,
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ESidebarActionTypes.hideSidebar:
      return {
        ...state,
        show: false,
      };
    case ESidebarActionTypes.showSidebar:
      return {
        ...state,
        show: true,
      };
    case ESidebarActionTypes.toggleSidebar:
      return {
        ...state,
        show: !state.show,
      };
    default:
      return state;
  }
};

export default sidebarReducer;
