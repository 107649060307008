import { createGetOptionsApi } from "..";
import { formToFormData, normalizeDateOnly, renameObjectKey } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const userCommunityPrimaryKey = "userCommunityId";
export const communityPrimaryKey = "communityId";

const communityKeyMaps = {
    formStatusName: "applicationFormStatus",
};

export const getCommunityOptions = createGetOptionsApi(Urls.GetCommunityOptions, "communityOptions", 1);
export const getIndustryOptions = createGetOptionsApi("/Lookup/IndustryType", "industryOptions", 1);

export const getCommunityStatusOptions = createGetOptionsApi(Urls.GetCommunityStatusOptions, "communityStatusOptions", 1);
export const getCommunityFormStatusOptions = createGetOptionsApi("/Lookup/CommunityApplicationFormStatus", "communityFormStatusOptions", 1);

export const getCommunities = async ({ ...filters }) => {
    const headers = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber,
        sortOrder: filters.sortOrder,
        sortField: communityKeyMaps[filters.sortField] || filters.sortField,
        communityCode: filters.communityCode,
        stateCode: filters.stateCode,
        communityStatusCode: filters.communityStatusCode,
        directorName: filters.directorName,
        peerGroupCode: filters.peerGroupCode,
        applicationFormStatus: filters.formStatusCode,
    };
    if (!headers.communityStatus) delete headers.communityStatus;

    const response = await callApi.get(buildURL(Urls.SearchCommunities), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((row) => ({
                  [communityPrimaryKey]: row.communityCode,
                  communityName: row.communityName,
                  stateName: row.stateName,
                  startDate: row.startDate,
                  endDate: row.endDate,
                  peerGroups: row.peerGroups,
                  formStatusName: row.applicationFormStatus,
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const getCommunityById = async ({ communityId }) => {
    const response = await callApi.get(buildURL(Urls.GetCommunityById), {
        headers: {
            communityCode: communityId,
        },
    });

    const data = response.responseData;

    renameObjectKey(data, "communityFormStatusCode", "formStatusCode");
    renameObjectKey(data, "communityFormStatusName", "formStatusName");
    renameObjectKey(data, "communityStatusCode", "statusCode");
    renameObjectKey(data, "communityStatusName", "statusName");
    renameObjectKey(data, "communityPicture", "communityPictureUrl");

    return {
        data:
            typeof data === "object"
                ? {
                      ...data,
                      communityPictureInitials: data.communityPictureUrl?.includes("https") ? "" : data.communityPictureUrl || "",
                      communityPictureUrl: data.communityPictureUrl?.includes("https") ? data.communityPictureUrl : "",
                      endDate: data.endDate || "",
                      stateCode: data.stateCode?.trim() || "",
                  }
                : null,
    };
};

export const createCommunity = async ({ communityName, stateCode, startDate, endDate, formStatusCode: communityFormStatusCode, communityPicture }) => {
    const response = await callApi.post(buildURL("/Community/Add"), formToFormData({ communityName, stateCode, startDate, endDate, communityFormStatusCode, communityPicture }));

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.communityCode || "" } } : response;
};

export const updateCommunity = async ({ communityName, communityId: communityCode, stateCode, startDate, endDate, formStatusCode: communityFormStatusCode, communityPicture }) => {
    const response = await callApi.post(buildURL("/Community/Update"), formToFormData({ communityName, communityCode, stateCode, startDate, endDate, communityFormStatusCode, communityPicture }));

    return response;
};

export const getUserCommunities = async ({ userId, ...filters }) => {
    const response = {
        data: [],
        isStaff: false,
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetUserCommunities) + userId, { headers: filters });

    if (Array.isArray(responseData?.searchResult)) {
        response.totalRecords = responseData.totalRecords;
        response.isStaff = responseData.isStaff;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.searchResult.map((c) => ({
            ...c,
            roleCode: c.roleCode?.trim(),
            communityCode: c.communityCode?.trim(),
            startDate: normalizeDateOnly(c.startDate),
            endDate: normalizeDateOnly(c.endDate),
            userId,
            [userCommunityPrimaryKey]: c.userCommunityId,
        }));
    }

    return response;
};

export const createUserCommunity = async ({ communityCode, startDate, endDate, roleCode, userId, funderId, countyId }) => {
    const response = await callApi.post(buildURL(Urls.CreateUserCommunity), {
        communityCode,
        startDate: startDate || null,
        endDate: endDate || null,
        roleCode,
        userId,
        funderId,
        countyId,
    });

    return response;
};

export const updateUserCommunity = async ({ communityCode, startDate, endDate, roleCode, userId, userCommunityId, funderId, countyId }) => {
    const response = await callApi.post(buildURL(Urls.UpdateUserCommunity), {
        communityCode,
        startDate: startDate || null,
        endDate: endDate || null,
        roleCode,
        userId,
        userCommunityId,
        funderId,
        countyId,
    });

    return response.responseCode === 200 && response.responseData === false ? { responseCode: 400, responseData: `User cannot have same responsibility with blank End Date.` } : response;
};

export const deleteUserCommunity = async ({ userCommunityId, userId }) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserCommunity), { userCommunityId, userId });

    return response;
};
