import { EFieldInputTypes } from "../../components/FormField";
import { userSearchFields } from "../Users/DATA";
import { getUserStatusOptions } from "../../services/users/userServices";
import SelectInput from "../../components/SelectInput";

export const initialEventDetails = {
  canEdit: false,
  canAddAttendees: false,
  canDeleteAttendees: false,
  canEditAttendees: false,
  canAddStaff: false,
  canDeleteStaff: false,
  canEditStaff: false,
  canAddPermissions: false,
  canDeletePermissions: false,
  canEditPermissions: false,
  isAttendee: false,
  eventId: null,
  createdDate: "",
  createdBy: "",
  modifiedDate: "",
  modifiedBy: "",
  eventName: "",
  eventDate: "",
  eventStartTime: "",
  eventEndTime: "",
  eventFormatCode: "",
  eventFormatName: "",
  eventStatusCode: "",
  eventStatusName: "",
  eventAttendeeId: "",
  eventAttendeeStatusCode: "",
  eventAttendeeStatusName: "",
  eventTypeCode: "",
  eventTypeName: "",
  eventTimeZoneCode: "",
  eventTimeZoneName: "",
  eventDescription: "",
  eventEmailMessage: "",
  eventLink: "",
  eventFormatNote: "",
  communityCode: "",
  communityNames: "",
  peerGroupCode: "",
  peerGroupNames: "",
};

export const selectUserSearchFields = userSearchFields.map((field) =>
  field.name === "status"
    ? {
        ...field,
        inputType: EFieldInputTypes.custom,
        readOnly: true,
        requiredInSearch: true,
        getInitialOption: (response) => response.data.find((opt) => opt.label === "Active"),
        component({ onChange, form }) {
          return (
            <div className="d-flex my-2">
              <SelectInput
                {...field}
                getOptions={getUserStatusOptions}
                value={form[field.nameForm || field.name]}
                viewValue={form[field.name]}
                onChange={onChange}
                placeholder="Status"
                getInitialOption={this.getInitialOption}
                readOnly
              />
              <div style={{ minWidth: 114, paddingTop: 8 }}>
                <input type="checkbox" id={"user-status-options"} className="form-check-input" checked readOnly />
                <label htmlFor="user-status-options">Only Active</label>
              </div>
            </div>
          );
        },
      }
    : field.name === "role"
    ? { ...field, disableActiveRoles: true }
    : field
);
