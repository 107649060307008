import { BiMessageSquareEdit, BiEdit } from "react-icons/bi";
import Tooltip from "../Tooltip";

export const EEditIconVariants = {
    default: "default",
    note: "note",
};

const EditIcon = ({ onClick, size = 34, title = "Edit", withTooltip = false, variant = EEditIconVariants.default }) => {
    const iconProps = {
        style: { width: size, height: size },
        color: "#99CC66",
    };
    const icon = (
        <span onClick={onClick} className="mx-2 pointer" title={!withTooltip ? title : undefined}>
            {variant === EEditIconVariants.note ? <BiEdit {...iconProps} /> : <BiMessageSquareEdit {...iconProps} />}
        </span>
    );

    return !withTooltip ? (
        icon
    ) : (
        <Tooltip theme="dark" placement="top" text={title}>
            {icon}
        </Tooltip>
    );
};

export default EditIcon;
