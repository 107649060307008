import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
export const ReferralPrimaryKey = "userGuid";
export const getReferrals = async ({ userId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetUserReferral) + userId);

    if (Array.isArray(responseData?.searchResult)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.searchResult.map((c) => ({
            ...c,
            firstName: c.firstName?.trim(),
            lastName: c.lastName?.trim(),
            date: normalizeDateOnly(c.emailSentDate),
            userId: c.referrerUserGuid,
            email: c.email,
        }));
    }

    return response;
};
export const createReferral = async (formData) => {
    const response = await callApi.post(buildURL(Urls.CreateUserReferral), {
        userGuid: formData.userId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
    });

    return response;
};
