import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const curriculumAttachmentPrimaryKey = "curriculumAttachmentId";
export const curriculumFeedbackPrimaryKey = "curriculumFeedbackId";

export const curriculumAttachmentAdd = async (formData, curriculumId) => {
    const data = new FormData();
    data.append("CurriculumId", formData.curriculumId);
    data.append("DocumentName", formData.documentName);
    data.append("isDocForMember", formData.for !== "facilitator");
    data.append("DocumentFile", formData.documentFile);

    const response = await callApi.post(buildURL(Urls.CurriculumAttachmentAdd), data);

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId || formData[curriculumAttachmentPrimaryKey] } } : response;
};
export const getCurriculumAttachment = async ({ curriculumId, ...filters }) => {
    const headers = {
        curriculumId,
    };

    const response = await callApi.get(buildURL(Urls.GetCurriculumAttachments), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData.searchResult)
            ? response.responseData.searchResult.map((row) => {
                  return {
                      [curriculumAttachmentPrimaryKey]: row.curriculumAttachmentId,
                      for: row.documentFor,
                      documentName: row.documentName,
                      addedBy: row.createdBy,
                      addedDate: row.createdDate,
                      documentFilePath: row.documentFilePath,
                      documentFileName: row.documentFileName,
                  };
              })
            : [],
        canAdd: response.responseData?.canAdd,
        canDelete: response.responseData?.canDelete,
        totalRecords: response.responseData?.totalRecords || 0,
    };
};
export const deleteCurriculumAttachment = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteCurriculumAttachment), {
        curriculumAttachmentId: formData.curriculumAttachmentId,
    });

    return response;
};

export const addCurriculumFeedback = async (formData) => {
    const data = {
        curriculumId: formData.curriculumId,
        feedback: formData.feedback,
    };

    const response = await callApi.post(buildURL(Urls.AddCurriculumFeedback), data);

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId || formData[curriculumAttachmentPrimaryKey] } } : response;
};
export const getCurriculumFeedbacks = async ({ curriculumId, ...filters }) => {
    const headers = {
        curriculumId,
    };

    const response = await callApi.get(buildURL(Urls.GetCurriculumFeedbacks), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData.searchResult)
            ? response.responseData.searchResult.map((row) => {
                  return {
                      [curriculumFeedbackPrimaryKey]: row.curriculumFeedbackId,
                      feedback: row.feedback,
                      addedBy: row.createdBy,
                      addedDate: row.createdDate,
                  };
              })
            : [],
        canAdd: response.responseData?.canAdd,
        canDelete: response.responseData?.canDelete,
        totalRecords: response.responseData?.totalRecords || 0,
    };
};
export const deleteCurriculumFeedback = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteCurriculumFeedback), {
        curriculumFeedbackId: formData.curriculumFeedbackId,
    });

    return response;
};
