import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const curriculumPrimaryKey = "curriculumId";

export const createUpdateCurriculum = async (formData) => {
    const { title, status, type, focus, description, curriculumId, order } = formData;
    const response = await callApi.post(buildURL(formData[curriculumPrimaryKey] ? Urls.UpdateCurriculum : Urls.CreateCurriculum), {
        curriculumId,
        title,
        monthlyFormFocusCode: focus,
        isActive: status === "active",
        awareness: type.includes("awareness"),
        decision: type.includes("decision"),
        action: type.includes("action"),
        description,
        order,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.curriculumId || formData[curriculumPrimaryKey] } } : response;
};
export const getCurriculum = async ({ ...filters }) => {
    const headers = {
        pageSize: filters.pageSize,
        pageNumber: filters.pageNumber,
        sortOrder: filters.sortOrder,
        sortField: filters.sortField,
        title: filters.title,
        monthlyFormFocusCode: filters.focus,
        isActive: !filters.status ? "" : filters.status === "active",
        awareness: filters.type.includes("awareness"),
        decision: filters.type.includes("decision"),
        action: filters.type.includes("action"),
    };
    if (!headers.communityStatus) delete headers.communityStatus;

    const response = await callApi.get(buildURL(Urls.SearchCurriculum), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((row) => {
                  const type = [];
                  if (row.awareness) type.push("awareness");
                  if (row.decision) type.push("decision");
                  if (row.action) type.push("action");
                  return {
                      [curriculumPrimaryKey]: row.curriculumId,
                      focus: row.monthlyFormFocus,
                      status: row.isActive ? "Active" : "Inactive",
                      title: row.title,
                      awareness: row.awareness,
                      decision: row.decision,
                      action: row.action,
                      description: row.description,
                      type,
                      order: row.order,
                  };
              })
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};
