import { Link } from "react-router-dom";
import { formatDateOnly } from "../../helpers";
import { addSocialLink, deleteUserSocialLink, getSocialLinksApi, getSocialLinkTypes } from "../../services/userBusiness/userSocialLinksServices";

export const userSocialLinksFields = [
    {
        name: "socialPlatform",
        columnWidth: 120,
        title: "Social Platform",
        filterable: true,
        getOptions: getSocialLinkTypes,
        inputType: "select",
        required: true,
    },
    {
        name: "userName",
        required: true,
        columnWidth: 110,
        title: "Username",
        filterable: true,
        cell: (value, data) => (
            <a
                target={"_blank"}
                href={`https://www.${data.socialPlatform === "Meta (Facebook)" ? "facebook" : data.socialPlatform === "Pinterest" ? "pinterest" : data.socialPlatform}.com/${
                    data.socialPlatform === "LinkedIn" ? "in/" : ""
                }${data.userName}/`}
                rel="noreferrer"
            >
                {value}
            </a>
        ),
    },
];
export const userSocialLinksApis = {
    getAll: {
        api: getSocialLinksApi,
        body: {
            userId: "",
        },
    },
    create: {
        api: addSocialLink,
        body: {
            userId: "",
        },
    },
    delete: {
        api: deleteUserSocialLink,
        body: {
            userId: "",
        },
    },
};
