import moment from "moment";

import { createGetOptionsApi } from "..";
import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const eventPrimaryKey = "eventId";

export const EEventTypes = {
    peerGroup: "Peer Group",
    discoverySession: "Discovery Session",
    social: "Social",
    oneOnOne: "One on One",
    other: "Other",
};

const eventKeyMaps = {
    eventTimeZoneName: "eventTimeZone",
    eventStatusName: "eventStatus",
    eventFormatName: "eventFormat",
    eventTypeName: "eventType",
    eventDate: "EventDate",
};

export const getEvents = async (filters) => {
    const response = await callApi.get(buildURL(Urls.SearchEvents), {
        headers: {
            pageSize: filters.pageSize,
            pageNumber: filters.pageNumber,
            sortOrder: filters.sortOrder,
            sortField: eventKeyMaps[filters.sortField] || filters.sortField,
            eventType: filters.eventTypeCode,
            eventFormat: filters.eventFormatCode,
            community: filters.communityCode,
            peerGroup: filters.peerGroupCode,
            eventStatus: filters.eventStatusCode,
            eventDateFrom: filters.eventDateFrom || "",
            eventDateTo: filters.eventDateTo || "",
        },
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  communities: u.communities,
                  eventName: u.eventName,
                  eventDate: u.eventDate,
                  eventFormatCode: u.eventFormatCode,
                  eventFormatName: u.eventFormatName,
                  [eventPrimaryKey]: u.eventId,
                  eventStatusCode: u.eventStatusCode,
                  eventStatusName: u.eventStatusName,
                  eventTimeZoneName: u.eventTimeZone,
                  eventTypeCode: u.eventTypeCode,
                  eventTypeName: u.eventTypeName,
                  peerGroups: u.peerGroups,
                  eventStartTime: formatTimeOnly(u.eventStartTime),
                  eventEndTime: formatTimeOnly(u.eventEndTime),
                  communityNames: u.communities?.length ?? "0",
                  peerGroupNames: u.peerGroups?.length ?? "0",
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const getEventById = async (eventId) => {
    const response = await callApi.get(buildURL(Urls.GetEventById) + eventId);
    const data = response.responseData;

    return {
        data:
            response.responseCode !== 200 || !data
                ? null
                : {
                      canEdit: data.canEdit,
                      isAttendee: data.isEventAttendee,
                      createdDate: data.createdDate,
                      createdBy: data.createdBy,
                      modifiedDate: data.modifiedDate,
                      modifiedBy: data.modifiedBy,
                      eventName: data.eventName,
                      eventDate: !data.eventDate ? "" : moment(data.eventDate.slice(0, 10)).format("YYYY-MM-DD"),
                      eventStartTime: data.startTime?.slice(0, 5) || "",
                      eventEndTime: data.endTime?.slice(0, 5) || "",
                      eventFormatCode: data.formatTypeCode?.trim() || "",
                      eventFormatName: data.formatTypeName?.trim() || "",
                      eventStatusCode: data.eventStatusCode?.trim() || "",
                      eventAttendeeId: data.eventAttendeeId,
                      eventAttendeeStatusCode: data.eventAttendeeStatusCode,
                      eventAttendeeStatusName: data.eventAttendeeStatusName,
                      eventTypeCode: data.eventTypeCode?.trim() || "",
                      eventTimeZoneCode: data.timezoneCode?.trim() || "",
                      eventStatusName: data.eventStatusName,
                      eventTimeZoneName: data.timezoneName,
                      eventTypeName: data.eventTypeName,
                      eventDescription: data.description || "",
                      eventEmailMessage: data.emailMessage || "",
                      eventLink: data.eventLink || "",
                      eventFormatNote: data.formatNote || "",
                      communityCode: data.communityCode?.trim() || "",
                      communityNames: data.communityName || "",
                      peerGroupCode: data.peerGroupCode?.trim() || "",
                      peerGroupNames: data.peerGroupName || "",
                      maxAttendees: data.maxAttendees || "0",
                  },
    };
};

export const createUpdateEvent = async (formData) => {
    const response = await callApi.post(buildURL(formData[eventPrimaryKey] ? Urls.EditEvent : Urls.AddEvent), {
        eventType: formData.eventTypeCode?.length === 5 ? formData.eventTypeCode + " " : formData.eventTypeCode,
        eventStatus: formData.eventStatusCode?.length === 5 ? formData.eventStatusCode + " " : formData.eventStatusCode,
        formatType: formData.eventFormatCode?.length === 5 ? formData.eventFormatCode + " " : formData.eventFormatCode,
        timezone: formData.eventTimeZoneCode?.length === 5 ? formData.eventTimeZoneCode + " " : formData.eventTimeZoneCode,
        eventName: formData.eventName,
        startTime: formData.eventStartTime,
        endTime: formData.eventEndTime,
        community: formData.communityCode,
        peerGroup: formData.peerGroupCode,
        eventDate: formData.eventDate,
        eventLink: formData.eventLink,
        formatNote: formData.eventFormatNote,
        description: formData.eventDescription,
        emailMessage: formData.eventEmailMessage,
        eventId: formData[eventPrimaryKey],
        maxAttendees: formData.maxAttendees || "0",
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId || formData[eventPrimaryKey] } } : response;
};

export const updateEventRsvpStatus = async ({ eventId, eventAttendeeId, eventAttendeeStatusCode }) => {
    const response = await callApi.post(buildURL("/Event/UpdateAttendeeRsvpStatus"), { eventId, eventAttendeeId, eventAttendeeStatusCode });

    return response;
};

export const getEventRsvpStatusOptions = (formData) => createGetOptionsApi("/Event/RsvpStatus", "eventRsvpStatusOptions_" + formData.eventType, 2)(formData);

export const getEventTypeOptions = createGetOptionsApi(Urls.GetEventTypeOptions, "eventTypeOptions", 2);

export const getEventFormatOptions = createGetOptionsApi(Urls.GetEventFormatOptions, "eventFormatOptions", 2);
export const getEventTypeMaxDefault = createGetOptionsApi(Urls.GetEventTypeMaxDefault, "maxAttendees", 5);

export const getEventStatusOptions = createGetOptionsApi(Urls.GetEventStatusOptions, "eventStatusOptions", 5);
