import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BreadCrumb from "../../components/BreadCrumb";
import ManagementTable from "../../components/ManagementTable";
import { selectPermissionsFunderAdd } from "../../redux/permissionReducer/permissionSelectors";
import { countiesApis, countiesFields, countiesFormFields, countiesInitialForm, countiesInitialSearchForm, countiesInitialSort, countiesSearchFields } from "./DATA";

export default function Counties() {
    const navigate = useNavigate();
    const isCountiesHideAddPermission = useSelector(selectPermissionsFunderAdd);
    const runAfterSubmit = useCallback(
        (_formData, response) => {
            const countyId = response.responseData?.id;
            navigate(`/county/${countyId}`);
        },
        [navigate]
    );
    return (
        <>
            <BreadCrumb title="County" />
            <ManagementTable
                title="County"
                initialForm={countiesInitialForm}
                fields={countiesFields}
                tableTitle={"County"}
                primaryKey={"countyId"}
                customFormModalTitle={"County"}
                apis={countiesApis}
                formFields={countiesFormFields}
                runAfterSubmit={runAfterSubmit}
                initialSearchForm={countiesInitialSearchForm}
                searchFields={countiesSearchFields}
                hideAddButton={!isCountiesHideAddPermission}
                initialSort={countiesInitialSort}
                initialFilterCount={6}
                searchCols={{ open: 12, close: 6 }}
                withSearch
                hideActionsColumn
                hideMoreFiltersButton
            />
        </>
    );
}
