import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import ManagementTable from "../../components/ManagementTable";
import BreadCrumb from "../../components/BreadCrumb";
import { eventPrimaryKey } from "../../services/events/eventServices";
import { selectUserIsAdmin } from "../../redux/userReducer/userSelectors";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { eventApis, eventFields, eventAddFormFields, eventInitialSearchForm, eventSearchFields, eventInitialSort } from "./DATA";

export default function Events() {
    const navigate = useNavigate();
    const { isAdmin } = useSelector(createStructuredSelector({ isAdmin: selectUserIsAdmin }));

    const runAfterSubmit = useCallback(
        (_formData, response) => {
            const eventId = response.responseData?.id;

            navigate(`/events/${eventId}`);
        },
        [navigate]
    );

    const formFields = useMemo(() => (isAdmin ? eventAddFormFields.map((f) => (f.nameForm === "communityCode" ? { ...f, getOptions: getCommunityOptions } : f)) : eventAddFormFields), [isAdmin]);

    return (
        <>
            <BreadCrumb title="Events" />
            <ManagementTable
                title="Event"
                tableTitle={"Events"}
                primaryKey={eventPrimaryKey}
                apis={eventApis}
                fields={eventFields}
                formFields={formFields}
                searchFields={eventSearchFields}
                initialSearchForm={eventInitialSearchForm}
                searchCols={{ open: 12, close: 6 }}
                initialFilterCount={6}
                runAfterSubmit={runAfterSubmit}
                initialSort={eventInitialSort}
                confirmOnSubmit
                hideActionsColumn
                withSearch
            />
        </>
    );
}
