import React from "react";
import { Form } from "react-bootstrap";
import { MdOutlineAddCircleOutline } from "react-icons/md";

const UserDocuments = () => {
    return (
        <>
            <div className="viewUserTopAction">
                <div className="vutaLeftSec">
                    <h3>Documents</h3>
                    <Form.Control type="text" className="commonSearchBox" placeholder="Quick search documents..." />
                </div>
                <button className="addNewBtn btn"><MdOutlineAddCircleOutline /> ADD DOCUMENT</button>
            </div>
            <h3 className="text-center p-5 m-5">Documents coming soon...</h3>
        </>
    );
};

export default UserDocuments;
