import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";

export const EModalSizes = {
  small: "sm",
  medium: "md",
  large: "lg",
  extraLarge: "xl",
};

export default function Modal({ title, show, onHide, className, headerClassName, bodyClassName, hideHeader = false, centered = true, size = EModalSizes.large, backdrop = "static", children }) {
  return (
    <BootstrapModal centered={centered} className={className} show={show} onHide={onHide} size={size} backdrop={backdrop}>
      {!hideHeader && (
        <BootstrapModal.Header className={headerClassName} closeButton>
          {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body className={bodyClassName}>{children}</BootstrapModal.Body>
    </BootstrapModal>
  );
}
