import { initialPermissionsState } from "./permissionSelectors";

export const EPermissionActionTypes = {
    setPermissions: "SET_PERMISSIONS",
    clearPermissions: "CLEAR_PERMISSIONS",
};

export const setPermissionsAction = (permissionsData) => ({
    type: EPermissionActionTypes.setPermissions,
    payload: permissionsData,
});

export const clearPermissionsAction = () => ({
    type: EPermissionActionTypes.clearPermissions,
});

export const getPermissionsObj = (response) => {
    const permissions = { ...initialPermissionsState };

    if (!response.responseData?.leftMenus) return permissions;
    const { leftMenus, menus, pages } = response.responseData;
    const userNotesPage = pages.find((p) => p.code?.trim() === "USR_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "USR_NOT");
    const eventDetailsPage = pages.find((p) => p.code?.trim() === "EVN_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "EVN_DTL");
    const communityDetailsPage = pages.find((p) => p.code?.trim() === "COM_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "COM_DTL");
    const curriculumPage = pages.find((p) => p.code?.trim() === "CUR_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "CUR_DTL");
    const funderPage = pages.find((p) => p.code?.trim() === "FUN_DER_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "FUN_DER_DTL");
    const countiesPage = pages.find((p) => p.code?.trim() === "CON_NTY_MAN")?.subPageAndSection?.find((p) => p.code?.trim() === "CON_NTY_DTL");

    // Sidebar Permissions:-
    permissions.sidebarDashboardMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_DAS");
    permissions.sidebarUsersMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_USR_MGT");
    permissions.sidebarMembersMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_MEM_MGT");
    permissions.sidebarEventsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_EVN_MGT");
    permissions.sidebarCommunitiesMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_COM_MGT");
    permissions.sidebarChecklistsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_CHK_MGT");
    permissions.sidebarPeerGroupsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_PGR_MGT");
    permissions.sidebarCurriculumsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_CUR_MGT");
    permissions.sidebarMemberDirectoryMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_MEM_DIR");
    permissions.sidebarReportsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_RPT");
    permissions.sidebarReferralsMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_REF");
    permissions.sidebarFunderMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_FUN_DER");
    permissions.sidebarCountiesMenu = !!leftMenus.find((p) => p.code?.trim() === "LFT_MNU_CON_NTY");
    // Event Permissions:-
    permissions.eventOverviewMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_OVR");
    permissions.eventDetailsMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_DTL");
    permissions.eventAttendeesMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_ATT");
    permissions.eventCurriculumMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_CUR");
    permissions.eventNotesMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_NOT");
    permissions.eventDocumentsMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_DOC");
    permissions.eventStaffMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_STF");
    permissions.eventPermissionsMenu = !!menus.find((p) => p.code?.trim() === "MNU_EVN_PER");
    permissions.eventEdit = !!eventDetailsPage?.isEdit;
    // User Permissions:-
    permissions.userMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_MAN");
    permissions.userDashboardMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_DASH");
    permissions.userFormsMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_FOM");
    permissions.userEventsMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_EVN");
    permissions.userNotesMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_NOT");
    permissions.userDocumentsMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_DOC");
    permissions.userDetailsMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_DTL");
    permissions.userContactInfoMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_CON_INF");
    permissions.userBusinessMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_BUS");
    permissions.userDemographicsMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_DEM");
    permissions.userPeerGroupsMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_PER_GRP");
    permissions.userCommunitiesMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_COM");
    permissions.userRolesMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_ROL");
    permissions.userAnnualResultsMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_RSU");
    permissions.userMilestonesMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_MIL");
    permissions.userLOAMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_LOA");
    permissions.userChecklistMenu = !!menus.find((p) => p.code?.trim() === "MUN_USR_CHK_LST");
    permissions.userMonthlyFormMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_MON_FOM");
    permissions.userAgreementFormMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_AGR_FOM");
    permissions.userInitialFormMenu = !!menus.find((p) => p.code?.trim() === "MNU_USR_INT_FOM");
    permissions.userNoteAdd = !!userNotesPage?.isAdd;
    permissions.userNoteEdit = !!userNotesPage?.isEdit;
    permissions.userNoteDelete = !!userNotesPage?.isDelete;
    // Peer Group Permissions:-
    permissions.peerGroupDashboardMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_DASH");
    permissions.peerGroupDetailsMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_DTL");
    permissions.peerGroupMembersMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_MEM");
    permissions.peerGroupEventsMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_EVN");
    permissions.peerGroupNotesMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_NOT");
    permissions.peerGroupDocumentsMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_DOC");
    permissions.peerGroupFacilitatorsMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_FACI");
    permissions.peerGroupScorecardMenu = !!menus.find((p) => p.code?.trim() === "MNU_PER_GRP_SCRD");
    // Community Permissions:-
    permissions.communityDashboardMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_DASH");
    permissions.communityDetailsMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_DTL");
    permissions.communityMembersMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_MEM");
    permissions.communityPeerGroupsMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_PER_GRP");
    permissions.communityEventsMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_EVN");
    permissions.communityNotesMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_NOT");
    permissions.communityDocumentsMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_DOC");
    permissions.communityDirectorsMenu = !!menus.find((p) => p.code?.trim() === "MNU_COM_DIRE");
    permissions.communityAdd = !!communityDetailsPage?.isAdd;
    permissions.communityEdit = !!communityDetailsPage?.isEdit;
    permissions.communityDelete = !!communityDetailsPage?.isDelete;
    // Curriculum Page
    permissions.curriculumAdd = !!curriculumPage?.isAdd;

    // Funder Page Permission
    permissions.funderAdd = !!funderPage?.isAdd;
    permissions.funderEdit = !!funderPage?.isEdit;
    permissions.funderDelete = !!funderPage?.isDelete;

    // Counties Page Permission
    permissions.countiesAdd = !!countiesPage?.isAdd;
    permissions.countiesEdit = !!countiesPage?.isEdit;
    permissions.countiesDelete = !!countiesPage?.isDelete;
    return permissions;
};
