import { useState, useEffect } from "react";

import EditSquare from "../../assets/images/edit_square_icon.png";
import BlueHome from "../../assets/images/blue-home-alt.png";
import BluePhIc from "../../assets/images/blue-phone.png";
import BlueEmailIc from "../../assets/images/blue-email.png";
import FormModal from "../../components/FormModal";
import { useForm, useToggle } from "../../helpers/hooks";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { formattedPhoneNumber, showResponseMessage } from "../../helpers";
import {
    contactAddressFields,
    contactBusinessPhoneFields,
    contactPersonalPhoneFields,
    emailFields,
    initialAddressForm,
    initialEmailForm,
    initialPhoneForm,
    initialPreferredForm,
    preFields,
} from "./DATA";

import "./UserContactInfo.css";

export default function UserContactInfo({ userId }) {
    const [formBusinessAddress, onChangeBusinessAddress, setFormBusinessAddress] = useForm({ ...initialAddressForm });
    const [formBusinessPhone, onChangeBusinessPhone, setFormBusinessPhone] = useForm({ ...initialPhoneForm });
    const [formPersonalPhone, onChangePersonalPhone, setFormPersonalPhone] = useForm({
        ...initialPhoneForm,
    });
    const [formPersonalAddress, onChangePersonalAddress, setFormPersonalAddress] = useForm({ ...initialAddressForm });
    const [formEmail, onChangeEmail, setFormEmail] = useForm({ ...initialEmailForm });
    const [formEmailPersonal, onChangeEmailPersonal, setFormEmailPersonal] = useForm({
        ...initialEmailForm,
    });
    const [formPreMethod, onChangePreMethod, setFormPreMethod] = useForm({
        ...initialPreferredForm,
    });
    const [show, toggleShow] = useToggle(false);
    const [showPreMethod, toggleShowPreMethod] = useToggle(false);
    const [showTogglePhone, toggleShowPhone] = useToggle(false);
    const [showTogglePersonalPhone, toggleShowPersonalPhone] = useToggle(false);
    const [showPersonal, toggleShowPersonal] = useToggle(false);
    const [showEmail, toggleShowEmail] = useToggle(false);
    const [showEmailPersonal, toggleShowEmailPersonal] = useToggle(false);

    const [refresh, setRefresh] = useState(0);
    const [contactInfoDetails, setContactInfoDetails] = useState({
        canEdit: false,
        ...initialPreferredForm,
        userBusinessAddress: {
            ...initialAddressForm,
            isPrimaryAddress: false,
        },
        userBusinessPhone: {
            ...initialPhoneForm,
            isPrimaryPhone: false,
        },
        userPersonalPhone: {
            ...initialPhoneForm,
            isPrimaryPhone: false,
        },
        userBusinessEmail: { ...initialEmailForm, isPrimaryEmail: false },
        userPersonalEmail: { ...initialEmailForm, isPrimaryEmail: false },
        userPersonalAddress: { ...initialAddressForm, isPrimaryAddress: false },
    });

    const onCancelBusinessAddress = () => {
        setTimeout(
            () =>
                setFormBusinessAddress({
                    ...contactInfoDetails.userBusinessAddress,
                    isPrimaryAddress: !contactInfoDetails.userBusinessAddress.saved ? "true" : contactInfoDetails.userBusinessAddress.isPrimaryAddress ? "true" : "false",
                }),
            300
        );
        toggleShow();
    };
    const onCancelPersonalAddress = () => {
        setTimeout(
            () =>
                setFormPersonalAddress({
                    ...contactInfoDetails.userPersonalAddress,
                    isPrimaryAddress: !contactInfoDetails.userPersonalAddress.saved ? "true" : contactInfoDetails.userPersonalAddress.isPrimaryAddress ? "true" : "false",
                }),
            300
        );
        toggleShowPersonal();
    };
    const onSubmitBusinessAddress = async (formData) => {
        const response = await callApi.post(buildURL(`${Urls.UpdateContactAddress}`), {
            ...formData,
            userContactType: "BUSINESS",
            isPrimaryAddress: formData.isPrimaryAddress === "false" ? false : true,
            userGuid: userId,
        });

        showResponseMessage(response, { success: "Address has been updated successfully." });

        if (response.responseCode !== 200) return;
        setRefresh((prev) => prev + 1);
        toggleShow(false);
    };
    const onCancelBusinessPhone = () => {
        setTimeout(
            () =>
                setFormBusinessPhone({
                    ...contactInfoDetails.userBusinessPhone,
                    isPrimaryPhone: !contactInfoDetails.userBusinessPhone.saved ? "true" : contactInfoDetails.userBusinessPhone.isPrimaryPhone ? "true" : "false",
                }),
            300
        );
        toggleShowPhone();
    };
    const onCancelPersonalPhone = () => {
        setTimeout(
            () =>
                setFormPersonalPhone({
                    ...contactInfoDetails.userPersonalPhone,
                    isPrimaryPhone: !contactInfoDetails.userPersonalPhone.saved ? "true" : contactInfoDetails.userPersonalPhone.isPrimaryPhone ? "true" : "false",
                }),
            300
        );
        toggleShowPersonalPhone();
    };
    const onCancelEmail = () => {
        setTimeout(
            () =>
                setFormEmail({
                    ...contactInfoDetails.userBusinessEmail,
                    isPrimaryEmail: !contactInfoDetails.userBusinessEmail.saved ? "true" : contactInfoDetails.userBusinessEmail.isPrimaryEmail ? "true" : "false",
                }),
            300
        );
        toggleShowEmail();
    };
    const onCancelEmailPersonal = () => {
        setTimeout(
            () =>
                setFormEmailPersonal({
                    ...contactInfoDetails.userPersonalEmail,
                    isPrimaryEmail: !contactInfoDetails.userPersonalEmail.saved ? "true" : contactInfoDetails.userPersonalEmail.isPrimaryEmail ? "true" : "false",
                }),
            300
        );
        toggleShowEmailPersonal();
    };
    const onCancelPreMethod = () => {
        setTimeout(() => setFormPreMethod((prev) => ({ ...prev, preferredContactMethod: contactInfoDetails.preferredContactMethod })), 300);
        toggleShowPreMethod();
    };
    const onSubmitBusinessPhone = async (formData) => {
        const response = await callApi.post(buildURL(`${Urls.UpdatePhoneNumber}`), {
            ...formData,
            userContactType: "BUSINESS",
            isPrimaryPhone: formData.isPrimaryPhone === "false" ? false : true,
            userGuid: userId,
        });

        showResponseMessage(response, { success: "Phone Number has been updated successfully." });

        if (response.responseCode !== 200) return;

        setRefresh((prev) => prev + 1);
        toggleShowPhone(false);
    };
    const onSubmitPersonalPhone = async (formData) => {
        const response = await callApi.post(buildURL(`${Urls.UpdatePhoneNumber}`), {
            ...formData,
            userContactType: "PERSONAL",
            isPrimaryPhone: formData.isPrimaryPhone === "false" ? false : true,
            userGuid: userId,
        });

        showResponseMessage(response, { success: "Phone Number has been updated successfully." });
        if (response.responseCode !== 200) return;

        setRefresh((prev) => prev + 1);
        toggleShowPersonalPhone(false);
    };
    const onSubmitPersonalAddress = async (formData) => {
        (async () => {
            const response = await callApi.post(buildURL(`${Urls.UpdateContactAddress}`), {
                ...formData,
                userContactType: "PERSONAL",
                isPrimaryAddress: formData.isPrimaryAddress === "false" ? false : true,
                userGuid: userId,
            });

            showResponseMessage(response, { success: "Address has been updated successfully." });
            if (response.responseCode !== 200) return;
            setRefresh((prev) => prev + 1);
            toggleShowPersonal(false);
        })();
    };
    const onSubmitEmail = async (formData) => {
        (async () => {
            const response = await callApi.post(buildURL(`${Urls.UpdateEmail}`), {
                ...formData,
                userContactType: "BUSINESS",
                isPrimaryEmail: formData.isPrimaryEmail === "false" ? false : true,
                userGuid: userId,
            });

            showResponseMessage(response, { success: "Email address has been updated successfully." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            toggleShowEmail(false);
        })();
    };
    const onSubmitEmailPersonal = async (formData) => {
        (async () => {
            const response = await callApi.post(buildURL(`${Urls.UpdateEmail}`), {
                ...formData,
                userContactType: "PERSONAL",
                isPrimaryEmail: formData.isPrimaryEmail === "false" ? false : true,
                userGuid: userId,
            });

            showResponseMessage(response, { success: "Email address has been updated successfully." });

            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            toggleShowEmailPersonal(false);
        })();
    };
    const onSubmitPreMethod = async (formData) => {
        (async () => {
            const response = await callApi.post(buildURL(`${Urls.UpdatePreferredContact}`), { ...formData, userGuid: userId });

            showResponseMessage(response, { success: "Preferred Contact Method has been updated successfully." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            toggleShowPreMethod();
        })();
    };

    useEffect(() => {
        (async () => {
            const { responseData } = await callApi.get(buildURL(`${Urls.GetUserContactInfo}${userId}`));

            if (!responseData) return;

            setContactInfoDetails((prev) => {
                const newInfo = { ...prev, canEdit: responseData.canEdit };

                if (responseData.userBusinessAddress) {
                    newInfo.userBusinessAddress = {
                        ...responseData.userBusinessAddress,
                        countryCode: responseData.userBusinessAddress.countryCode?.trim() || "",
                        stateCode: responseData.userBusinessAddress.stateCode?.trim() || "",
                        saved: true,
                        userGuid: userId,
                    };
                    setFormBusinessAddress({
                        ...newInfo.userBusinessAddress,
                        isPrimaryAddress: responseData.userBusinessAddress.isPrimaryAddress ? "true" : "false",
                    });
                }
                if (responseData.userPersonalAddress) {
                    newInfo.userPersonalAddress = {
                        ...responseData.userPersonalAddress,
                        countryCode: responseData.userPersonalAddress.countryCode?.trim() || "",
                        stateCode: responseData.userPersonalAddress.stateCode?.trim() || "",
                        saved: true,
                        userGuid: userId,
                    };
                    setFormPersonalAddress({
                        ...newInfo.userPersonalAddress,
                        isPrimaryAddress: responseData.userPersonalAddress.isPrimaryAddress ? "true" : "false",
                    });
                }
                if (responseData.userBusinessPhone) {
                    newInfo.userBusinessPhone = {
                        ...responseData.userBusinessPhone,
                        userGuid: userId,
                        saved: true,
                    };
                    setFormBusinessPhone({ ...newInfo.userBusinessPhone, isPrimaryPhone: newInfo.userBusinessPhone.isPrimaryPhone ? "true" : "false" });
                }
                if (responseData.userPersonalPhone) {
                    newInfo.userPersonalPhone = {
                        ...responseData.userPersonalPhone,
                        isPrimaryPhone: responseData.userPersonalPhone.isPrimaryPhone && responseData.userPersonalPhone.phoneNumber,
                        phoneType: responseData.userPersonalPhone.phoneType || initialPhoneForm.phoneType,
                        userGuid: userId,
                        saved: !!responseData.userPersonalPhone.phoneNumber,
                    };
                    setFormPersonalPhone({
                        ...newInfo.userPersonalPhone,
                        isPrimaryPhone: !responseData.userPersonalPhone.phoneNumber ? "true" : newInfo.userPersonalPhone.isPrimaryPhone ? "true" : "false",
                    });
                }
                if (responseData.userBusinessEmail) {
                    newInfo.userBusinessEmail = {
                        ...responseData.userBusinessEmail,
                        userGuid: userId,
                        saved: true,
                    };
                    setFormEmail({ ...newInfo.userBusinessEmail, isPrimaryEmail: newInfo.userBusinessEmail.isPrimaryEmail ? "true" : "false" });
                }
                if (responseData.userPersonalEmail) {
                    newInfo.userPersonalEmail = {
                        ...responseData.userPersonalEmail,
                        userGuid: userId,
                        saved: true,
                    };
                    setFormEmailPersonal({ ...newInfo.userPersonalEmail, isPrimaryEmail: newInfo.userPersonalEmail.isPrimaryEmail ? "true" : "false" });
                }
                if (responseData.preferredContactMethod) {
                    newInfo.preferredContactMethod = responseData.preferredContactMethod;
                    setFormPreMethod({ preferredContactMethod: newInfo.preferredContactMethod });
                }
                return newInfo;
            });
        })();
    }, [userId, refresh, setFormBusinessAddress, setFormPreMethod, setFormEmailPersonal, setFormBusinessPhone, setFormPersonalAddress, setFormPersonalPhone, setFormEmail]);
    const {
        userBusinessAddress: { address1, address2, country, isPrimaryAddress, city, state, postal },
        preferredContactMethod,
        userBusinessPhone,
        userBusinessEmail,
        userPersonalAddress,
        userPersonalPhone,
        userPersonalEmail,
    } = contactInfoDetails;

    return (
        <div className="contactContainer">
            <h4>Contact Information</h4>
            <br />
            <div>
                <span>Preferred Contact Method : {preferredContactMethod}</span>
                {contactInfoDetails.canEdit && (
                    <span onClick={toggleShowPreMethod} className="editIc pointer mx-2">
                        <span>
                            <img src={EditSquare} alt="Edit" />
                        </span>
                    </span>
                )}
            </div>
            <div className="contactBox mt-5">
                <div className="businessContainer">
                    <div>
                        <h5>Business Contact</h5>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">ADDRESS</span>
                        <div className="rightSection">
                            {isPrimaryAddress && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span onClick={toggleShow} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent address-min mt-3">
                            <span>
                                <img src={BlueHome} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>{address1}</p>
                                <p> {address2}</p>
                                <p>
                                    {" "}
                                    {city} {postal}
                                </p>
                                <p> {state}</p>
                                <p>{country}</p>
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">PHONE</span>
                        <div className="rightSection">
                            {userBusinessPhone?.isPrimaryPhone && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span style={{ cursor: "pointer" }} onClick={toggleShowPhone} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent mt-3">
                            <span>
                                <img src={BluePhIc} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>
                                    {userBusinessPhone?.phoneType}: {formattedPhoneNumber(userBusinessPhone?.phoneNumber)}
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">EMAIL</span>
                        <div className="rightSection">
                            {userBusinessEmail?.isPrimaryEmail && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span onClick={toggleShowEmail} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent mt-3">
                            <span>
                                <img src={BlueEmailIc} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>{userBusinessEmail?.email}</p>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="personalContainer">
                    <div>
                        <h5>Personal Contact</h5>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">ADDRESS</span>
                        <div className="rightSection">
                            {userPersonalAddress?.isPrimaryAddress && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span onClick={toggleShowPersonal} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent address-min mt-3">
                            <span>
                                <img src={BlueHome} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>{userPersonalAddress?.address1}</p>
                                <p> {userPersonalAddress?.address2}</p>
                                <p>
                                    {" "}
                                    {userPersonalAddress?.city} {userPersonalAddress?.postal}
                                </p>
                                <p> {userPersonalAddress?.state}</p>
                                <p>{userPersonalAddress?.country}</p>
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">PHONE</span>
                        <div className="rightSection">
                            {userPersonalPhone?.isPrimaryPhone && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span onClick={toggleShowPersonalPhone} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent mt-3">
                            <span>
                                <img src={BluePhIc} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>
                                    {userPersonalPhone?.phoneType}: {formattedPhoneNumber(userPersonalPhone?.phoneNumber)}
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className="mt-3">
                        <span className="contactLabel">EMAIL</span>
                        <div className="rightSection">
                            {userPersonalEmail?.isPrimaryEmail && (
                                <button type="button" className="btnPrimary">
                                    Primary
                                </button>
                            )}
                            {contactInfoDetails.canEdit && (
                                <span onClick={toggleShowEmailPersonal} className="editIc pointer">
                                    <span>
                                        <img src={EditSquare} alt="Edit" />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="LabelContent mt-3">
                            <span>
                                <img src={BlueEmailIc} className="" alt="MessageIc" />
                            </span>
                            <span className="LabelContent-txt">
                                <p>{userPersonalEmail?.email}</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangeBusinessAddress}
                onSubmit={onSubmitBusinessAddress}
                form={formBusinessAddress}
                show={show}
                onCancel={onCancelBusinessAddress}
                fields={contactAddressFields}
                title={"Business User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangeBusinessPhone}
                onSubmit={onSubmitBusinessPhone}
                form={formBusinessPhone}
                show={showTogglePhone}
                onCancel={onCancelBusinessPhone}
                fields={contactBusinessPhoneFields}
                title={"Business User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangePersonalPhone}
                onSubmit={onSubmitPersonalPhone}
                form={formPersonalPhone}
                show={showTogglePersonalPhone}
                onCancel={onCancelPersonalPhone}
                fields={contactPersonalPhoneFields}
                title={"Personal User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangePersonalAddress}
                onSubmit={onSubmitPersonalAddress}
                form={formPersonalAddress}
                show={showPersonal}
                onCancel={onCancelPersonalAddress}
                fields={contactAddressFields}
                title={"Personal User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangeEmail}
                onSubmit={onSubmitEmail}
                form={formEmail}
                show={showEmail}
                onCancel={onCancelEmail}
                fields={emailFields}
                title={"Business User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangeEmailPersonal}
                onSubmit={onSubmitEmailPersonal}
                form={formEmailPersonal}
                show={showEmailPersonal}
                onCancel={onCancelEmailPersonal}
                fields={emailFields}
                title={"Personal User Details"}
            />
            <FormModal
                primaryKey={"userGuid"}
                onChange={onChangePreMethod}
                onSubmit={onSubmitPreMethod}
                form={formPreMethod}
                show={showPreMethod}
                onCancel={onCancelPreMethod}
                fields={preFields}
                title={"Preferred Contact Method"}
            />
        </div>
    );
}
