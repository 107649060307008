import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "../../components/Form";
import ManagementTable from "../../components/ManagementTable";
import { queryStringAsObject, showResponseMessage } from "../../helpers";
import { useThrottle, useToggle } from "../../helpers/hooks";
import { hideHeaderAction } from "../../redux/headerReducer/headerActions";
import { hideSidebarAction } from "../../redux/sidebarReducer/sidebarActions";
import { getPeerGroupScorecardDetails, getScorecardAnswerOptions, peerGroupScorecardPrimaryKey, updatePeerGroupScorecardDetails } from "../../services/peer-groups/peerGroupScorecardServices";
import { cellEditAnswer, cellEditNote, peerGroupScorecardDetailsFields, peerGroupScorecardFormFields, peerGroupScorecardInitialForm } from "./DATA";

import "./ScorecardDetails.css";

export default function PeerGroupScorecardDetails() {
    const { peerGroupId } = useParams();
    const [options, setOptions] = useState([]);
    const [rows, setRows] = useState([]);
    const [editMode, toggleEditMode] = useToggle(false);
    const [currentFields, setCurrentFields] = useState(peerGroupScorecardDetailsFields);
    const [permissions, setPermissions] = useState({ canEdit: false });
    const rowsForm = useRef([]);
    const dispatch = useDispatch();
    const initialDetails = useMemo(() => ({ ...peerGroupScorecardInitialForm, ...queryStringAsObject() }), []);
    const [details, setDetails] = useState(() => initialDetails);

    const getRows = useCallback(() => {
        getPeerGroupScorecardDetails({
            peerGroupId: peerGroupId,
            communityCode: initialDetails.communityCode,
            communityName: initialDetails.communityName || "",
            peerGroupName: initialDetails.peerGroupName || "",
            statusCode: initialDetails.statusCode,
            year: initialDetails.year,
            quarter: initialDetails.quarter,
        }).then((response) => {
            setRows(response.data);
            setPermissions((prev) => ({ ...prev, canEdit: response.canEdit }));
            setDetails((prev) => ({ ...prev, statusName: response.peerGroupScoreCardStatusName }));
        });
    }, [initialDetails.communityCode, initialDetails.communityName, initialDetails.peerGroupName, initialDetails.quarter, initialDetails.statusCode, initialDetails.year, peerGroupId]);

    const handleChange = useCallback(({ target: { name, value } }, index) => {
        rowsForm.current[index][name] = value;
    }, []);
    const onCancel = useCallback(() => {
        toggleEditMode(false);
        rowsForm.current = rows;
    }, [rows, toggleEditMode]);
    const onExport = useThrottle(() => toast.info("Coming Soon..."), [], 5000);
    const onSave = useCallback(async () => {
        const response = await updatePeerGroupScorecardDetails({ peerGroupId, rows: rowsForm.current });

        showResponseMessage(response);
        if (response.responseCode !== 200) return;

        toggleEditMode(false);
        getRows();
    }, [getRows, peerGroupId, toggleEditMode]);

    const editFields = useMemo(
        () =>
            peerGroupScorecardDetailsFields.map((f) =>
                f.name.startsWith("que") ? { ...f, onChange: handleChange, cell: cellEditAnswer, options } : f.name.startsWith("note") ? { ...f, onChange: handleChange, cell: cellEditNote } : f
            ),
        [handleChange, options]
    );

    useEffect(() => {
        rowsForm.current = rows;
    }, [rows]);

    useState(() => {
        dispatch(hideSidebarAction());
        dispatch(hideHeaderAction());
        getScorecardAnswerOptions().then((response) => setOptions(response.data));
    });

    useEffect(() => {
        setCurrentFields(editMode ? editFields : peerGroupScorecardDetailsFields);
    }, [editFields, editMode]);

    useEffect(() => {
        getRows();
    }, [getRows]);
    return (
        <div className="my-2 scorecard-details-page">
            <div className="px-3">
                <h1>Member Progress Scorecard</h1>
            </div>
            <div className="border p-3 my-2">
                <Form
                    fields={peerGroupScorecardFormFields}
                    form={details}
                    title="Scorecard"
                    customSubmitTitle={editMode ? "SAVE" : "Export"}
                    customCancelTitle={editMode ? "CANCEL" : "EDIT"}
                    onSubmit={editMode ? onSave : onExport}
                    hideSubmitButton={!editMode}
                    onCancel={!permissions.canEdit ? undefined : editMode ? onCancel : toggleEditMode}
                    viewOnly
                />
            </div>
            <ManagementTable
                title="Scorecard"
                data={rows}
                primaryKey={peerGroupScorecardPrimaryKey}
                fields={currentFields}
                noSorting={editMode}
                localSort
                hideHeader
                hideActionsColumn
                hideAddButton
                noPagination
            />
        </div>
    );
}
