import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const getMemberDirectories = async ({ userId, ...filters }) => {
    const { responseData, totalRecords } = await callApi.get(buildURL(Urls.GetMemberDirectories), { headers: filters });

    if (Array.isArray(responseData)) {
        return [responseData, totalRecords];
    }
};
