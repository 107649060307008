import { useCallback, useState } from "react";

import SelectInput from "../../components/SelectInput";
import { getColumnColor } from "./DATA";

const EditAnswer = ({ initialValue, name, title, options, onChange, index, data }) => {
    const [value, setValue] = useState(() => initialValue);
    const handleChange = useCallback(
        (event) => {
            onChange(event, index);
            setValue(event.target.value);
        },
        [index, onChange]
    );

    return (
        <div className="form-group customInput p-4 my-0" style={{ backgroundColor: getColumnColor(data[name + "Percentage"]) }}>
            <SelectInput name={name} title={title} placeholder="Please Select..." value={value} options={options} onChange={handleChange} disableEmptyOption />
        </div>
    );
};

export default EditAnswer;