import React, { useEffect, useState } from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditSquare from "../../assets/images/edit_square_icon.png";
import { TextFieldInput } from "./TextFieldInput";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { formatDateOnly, formatDateTime, formatTimeOnly } from "../../helpers";
import { hideSidebarAction, showSidebarAction } from "../../redux/sidebarReducer/sidebarActions";
import { hideHeaderAction, showHeaderAction } from "../../redux/headerReducer/headerActions";
import "./UserInitialForm.css";
import { createStructuredSelector } from "reselect";
import { selectUserId, selectUserIsAdmin } from "../../redux/userReducer/userSelectors";
import * as Yup from "yup";
import { toast } from "react-toastify";

const UserInitialForm = ({ hideErrorMessage }) => {
    const validationSchema = Yup.object({
        firstName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
        lastName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
        // email: Yup.string().trim().email("Email is invalid").max(320, "Must be 320 characters or less").required("Required Field."),
        // preferredContactMethod: Yup.string().required("Required Field."),
        hearAboutUsCode: Yup.string().required("Required Field."),
        phoneType: Yup.string().required("Required Field."),

        phoneNo: Yup.string()
            .matches(/^[\d \-()]*$/, "Not Valid Phone Number!")
            .min(6, "Not Valid Phone Number!")
            .max(15, "Not Valid Phone Number!")
            .required("Required Field."),
        businessName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
        businessZipCode: Yup.string().max(10, "Must be 10 characters or less").required("Required Field."),
        businessDescription: Yup.string().trim().max(250, "Must be 250 characters or less").required("Required Field."),
        businessOwnership: Yup.number().required("Required Field."),
        businessRevenue: Yup.string().required("Required Field."),
        fullTimeEmployee: Yup.string().max(5, "Cannot exceed more than 5 characters").required("Required Field."),
        partTimeEmployee: Yup.string().max(5, "Cannot exceed more than 5 characters").required("Required Field."),
        ownerCompensation: Yup.string().required("Required Field."),
        independentContractor: Yup.number().required("Required Field."),
        isBusinessFullTime: Yup.string().required("Required Field."),
        businessStartYear: Yup.string().required("Required Field."),
        businessStartMonth: Yup.string().required("Required Field."),
    });

    const { userId } = useParams();
    const dispatch = useDispatch();
    const { myUserId, isAdmin } = useSelector(createStructuredSelector({ myUserId: selectUserId, isAdmin: selectUserIsAdmin }));
    const [userInitialForm, setUserInitialForm] = useState({});
    const [message, setMessage] = useState("");
    const [hearAboutUsOptions, setHearAboutUsOptions] = useState([]);
    const [edit, setEdit] = useState(true);
    const [refresh, setRefresh] = useState(0);

    const handleSubmitIntitialForm = async (values) => {
        // const formData = {
        //     userId,
        //     noeYear: values.noeYear === "Application Form" ? "0" : values.noeYear,
        //     commercialSpaceTypeCode: values.commercialSpaceTypeCode,
        //     commercialSpaceTypeName: "",
        //     isAdded: true,
        // };

        const response = await callApi.post(buildURL(Urls.EditIntitialForm), values);

        if (response.responseCode === 200) {
            toast.success("Updated Successfully");
            setRefresh((prev) => prev + 1);
            setEdit(true);
        } else {
            toast.error("Something went wrong!");
        }
    };
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetUserInitialForm}${userId}`));

            if (!response.responseData) return;
            if (!response.responseData?.prospectUserId) {
                setMessage("Form has not been submitted");
            } else {
                setMessage("");
            }
            setUserInitialForm(response.responseData);
        })();
    }, [userId, refresh]);
    useEffect(() => {
        dispatch(hideSidebarAction());
        dispatch(hideHeaderAction());
        return () => {
            dispatch(showSidebarAction());
            dispatch(showHeaderAction());
        };
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetHearAboutUsOptions}`));

            if (!response.responseData) return;

            setHearAboutUsOptions(response.responseData);
        })();
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={{
                preferredContactMethod: userInitialForm?.preferredContactMethod || "Email",
                hearAboutUsCode: userInitialForm?.hearAboutUsCode || "",
                businessStartYear: userInitialForm?.businessStartYear || "",
                businessStartMonth: userInitialForm?.businessStartMonth || "",
                isBusinessFullTime: userInitialForm?.isBusinessFullTime === true ? "true" : userInitialForm?.isBusinessFullTime === false ? "false" : "",
                firstName: userInitialForm?.firstName || "",
                middleName: userInitialForm?.middleName || "",
                lastName: userInitialForm?.lastName || "",
                phoneNo: userInitialForm?.phoneNo,
                phoneType: String(userInitialForm?.phoneType),
                email: userInitialForm?.email || "",
                businessName: userInitialForm?.businessName || "",
                businessZipCode: userInitialForm?.businessZipCode || "",
                businessDescription: userInitialForm?.businessDescription || "",
                businessOwnership: userInitialForm?.businessOwnership || "",
                businessRevenue: userInitialForm?.businessRevenue || 0,
                ownerCompensation: userInitialForm?.ownerCompensation || 0,
                fullTimeEmployee: userInitialForm?.fullTimeEmployee || "",
                partTimeEmployee: userInitialForm?.partTimeEmployee || "",
                independentContractor: userInitialForm?.independentContractor || "",
                businessProblemDetail: userInitialForm?.businessProblemDetail || "",
                prospectUserId: userInitialForm?.prospectUserId,
                userGuid: userId,
            }}
            onSubmit={handleSubmitIntitialForm}
        >
            {({ errors, handleChange, isValid, isSubmitting, values, formik }) => (
                <Form>
                    <div className="rightSecForm userInfoForm">
                        <h3 className="mb-4" style={{ textAlign: "center", color: "#dc3545" }}>
                            {message}
                        </h3>
                        <div className="subheader">
                            <h3 style={{ display: "flex" }}>Contact Information </h3>
                            <span style={{ fontSize: 16 }}>
                                Document Submitted : {userInitialForm?.createdOn ? formatDateTime(userInitialForm?.createdOn) : "N/A"} <br />
                                <span className="Edit-txt mt-2">
                                    {userInitialForm?.updatedBy && "Last Updated By:"} {userInitialForm?.updatedBy} {userInitialForm?.updatedBy ? "on" : ""}{" "}
                                    {userInitialForm?.updatedBy && formatDateTime(userInitialForm?.updatedOn)}{" "}
                                    {isAdmin && edit && <img onClick={() => setEdit(false)} className="pointer" src={EditSquare} alt="Edit" />}
                                </span>
                            </span>
                        </div>
                        <Row>
                            <Col md="4">
                                <div className="form-group customInput">
                                    <label>First Name</label>
                                    <TextFieldInput name="firstName" type="text" placeholder="First Name" disabled={true} readOnly={true} />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="form-group customInput">
                                    <label>MIDDLE INITIAL</label>
                                    <TextFieldInput maxLength="1" name="middleName" type="text" placeholder="Middle Initial" disabled={true} readOnly={true} />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="form-group customInput">
                                    <label>last NAME</label>
                                    <TextFieldInput name="lastName" type="text" placeholder="Last Name" disabled={true} readOnly={true} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>PREFERRED PHONE number</label>
                                    <TextFieldInput maxLength="15" name="phoneNo" type="number" placeholder="Phone number" disabled={edit} readOnly={edit} />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>phone # Type</label>
                                    <Field disabled={edit} className="form-select" as="select" name="phoneType">
                                        <option value="">Please Select</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="Landline">Landline</option>
                                        <option value="Phone">Phone</option>
                                    </Field>
                                    <ErrorMessage style={{ color: "red" }} component="div" name={"phoneType"} className="error" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-group customInput">
                                    <label>PREFERRED EMAIL ADDRESS</label>
                                    <TextFieldInput name="email" type="email" placeholder="Enter Email Address" disabled={true} readOnly={true} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-group customInput contactMethod justify-content-start">
                                    <label>Preferred method of contact:</label>
                                    <div className="selectMethod">
                                        <div className="form-check form-check-inline">
                                            <Field disabled={true} type="radio" name="preferredContactMethod" value="Email" id="emailRadio" className="form-check-input" />
                                            <label for="emailRadio" className="form-check-label">
                                                Email
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field disabled={true} type="radio" name="preferredContactMethod" value="Phone" id="phoneRadio" className="form-check-input" />
                                            <label for="phoneRadio" className="form-check-label">
                                                Phone
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <Field disabled={true} type="radio" name="preferredContactMethod" value="Text" id="textRadio" className="form-check-input" />
                                            <label for="textRadio" className="form-check-label">
                                                Text
                                            </label>
                                        </div>
                                        {/* <ErrorMessage style={{ color: "red" }} component="div" name={"preferredContactMethod"} className="error" /> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>How did you hear about us?</label>
                                    <Field disabled={edit} name="hearAboutUsCode" className="form-select" as="select">
                                        <option value="">Please Select</option>
                                        {hearAboutUsOptions.map((option) => (
                                            <option value={option.code}>{option.name}</option>
                                        ))}
                                    </Field>
                                    <ErrorMessage style={{ color: "red" }} component="div" name={"hearAboutUsCode"} className="error" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="rightSecForm userInfoForm pb-3">
                        <h3>Business Details</h3>
                        <Row>
                            <Col md="8">
                                <div className="form-group customInput">
                                    <label>BUSINESS name</label>
                                    <TextFieldInput name="businessName" type="text" placeholder="Enter Business Name" disabled={edit} readOnly={false} />
                                </div>
                            </Col>
                            <Col md="4">
                                <div className="form-group customInput">
                                    <label>BUSINESS ZIP Code</label>
                                    <TextFieldInput name="businessZipCode" type="text" placeholder="Zip" disabled={edit} readOnly={false} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="form-group customInput">
                                    <label>BUSINESS DESCRIPTION</label>
                                    <TextFieldInput name="businessDescription" type="text" placeholder="Briefly describe your business in 10 words or fewer..." disabled={edit} readOnly={false} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>HOW MUCH OF THE BUSINESS DO YOU OWN?</label>
                                    <TextFieldInput name="businessOwnership" type="number" placeholder="Enter % of ownership (ie. 80%)" disabled={edit} readOnly={false} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <div className="quesAnsForm py-3">
                                    <h5 className="quesInfo">Please ensure the following questions are answered accurately in order to best determine qualification. </h5>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group customInput">
                                                <label>TOTAL SALES REVENUE LAST YEAR</label>
                                                <span className="dollar-icon">$</span>
                                                <TextFieldInput dollarClass={25} name="businessRevenue" type="number" placeholder="Enter Revenue (ie. 40,000)" disabled={edit} readOnly={false} />
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group customInput">
                                                <label>WHEN DID YOU START YOUR BUSINESS?</label>
                                                <Row>
                                                    <Col>
                                                        <Field disabled={edit} name="businessStartMonth" className="form-select" as="select">
                                                            <option selected>Month</option>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </Field>
                                                        {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"businessStartMonth"} className="error" />}
                                                    </Col>
                                                    <Col>
                                                        <Field disabled={edit} name="businessStartYear" className="form-select" as="select">
                                                            <option value={""}>Year</option>
                                                            {new Array(100).fill(new Date().getFullYear()).map((thisYear, index) => (
                                                                <option key={thisYear - index} value={thisYear - index}>
                                                                    {thisYear - index}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"businessStartYear"} className="error" />}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="form-group customInput contactMethod justify-content-start">
                                        <label>Do you work full time in your business?</label>
                                        <div className="selectMethod">
                                            <div className="form-check form-check-inline">
                                                <Field disabled={edit} type="radio" name="isBusinessFullTime" value={"true"} id="yesRadio" className="form-check-input" />
                                                <label htmlFor="yesRadio" className="form-check-label">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <Field disabled={edit} type="radio" name="isBusinessFullTime" value={"false"} id="noRadio" className="form-check-input" />
                                                <label htmlFor="noRadio" className="form-check-label">
                                                    No
                                                </label>
                                            </div>

                                            {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"isBusinessFullTime"} className="error" />}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div className="quesAnsForm py-3">
                            <Row>
                                <Col md="12">
                                    <h5 className="quesInfo">How many employees including YOU work in your business? Please be sure to include owner(s) in the totals below.</h5>
                                </Col>
                                <Col md="4">
                                    <div className="form-group customInput">
                                        <label>FULL-TIME Employees</label>
                                        <TextFieldInput name="fullTimeEmployee" type="number" placeholder="0+" disabled={edit} readOnly={false} />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group customInput">
                                        <label>pART-TIME Employees</label>
                                        <TextFieldInput name="partTimeEmployee" type="number" placeholder="0+" disabled={edit} readOnly={false} />
                                    </div>
                                </Col>
                                <Col md="4">
                                    <div className="form-group customInput">
                                        <label>INDEPENDENT CONTRACTS(1099)</label>
                                        <TextFieldInput name="independentContractor" type="number" placeholder="0+" disabled={edit} readOnly={false} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="quesAnsForm py-3">
                            <Row>
                                <Col md="12">
                                    <h5 className="quesInfo">
                                        {" "}
                                        How much did the owner(s) pay themselves in the last calendar year? (Please answer for Jan - Dec, even if your business was not open for some or all of that
                                        time.)
                                    </h5>
                                </Col>
                                <Col md="6">
                                    <div className="form-group customInput">
                                        <label>TOTAL OWNER COMPENSATION</label>
                                        <span className="dollar-icon">$</span>
                                        <TextFieldInput
                                            dollarClass={25}
                                            name="ownerCompensation"
                                            type="number"
                                            placeholder="Enter Total Owner Compensation (ie. 40,000)"
                                            disabled={edit}
                                            readOnly={false}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md="12">
                                <div className="form-group customInput">
                                    <label>IF YOU COULD SOLVE ONE PROBLEM YOU'RE CURRENTLY FACING IN YOUR BUSINESS, WHAT WOULD IT BE?</label>
                                    <TextFieldInput
                                        name="businessProblemDetail"
                                        type="textarea"
                                        placeholder="Enter a short description about a challenge you're facing in your business..."
                                        className="form-control "
                                        readOnly={false}
                                        disabled={edit}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Community</label>
                                    <select disabled={true} name="businessStartMonth" className="form-select" as="select">
                                        <option value={userInitialForm?.communityName}>{userInitialForm?.communityName}</option>
                                    </select>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <div className="form-group customInput">
                                    <label>Discovery Session</label>
                                    <TextFieldInput
                                        name="businessProblemDetail"
                                        type="textarea"
                                        placeholder="Enter a short description about a challenge you're facing in your business..."
                                        className="form-control "
                                        readOnly={true}
                                        disabled={true}
                                        value={`${userInitialForm?.eventDate ? moment(userInitialForm?.eventDate).format("LLLL").slice(0, -8) : ""} ${formatTimeOnly(userInitialForm?.eventStartTime)}`}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {isAdmin && !edit && (
                        <Row>
                            <Col lg="12" className="text-end pt-3">
                                <button className="btnPrimaryLink btn mx-3" type="button" onClick={() => setEdit(true)}>
                                    CANCEL
                                </button>
                                <button disabled={edit} type="submit" className="customPrimaryBtn btn" variant="primary">
                                    SAVE & UPDATE
                                </button>
                            </Col>
                        </Row>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default UserInitialForm;
