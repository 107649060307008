import { EFieldInputTypes } from "../../components/FormField";
import { downloadFile, formatDateOnly } from "../../helpers";
import {
    curriculumAttachmentAdd,
    deleteCurriculumAttachment,
    getCurriculumAttachment,
    getCurriculumFeedbacks,
    addCurriculumFeedback,
    deleteCurriculumFeedback,
} from "../../services/curriculum/curriculum.view.services";

export const curriculumAttachmentInitialForm = {
    documentName: "",
    documentFileName: "",
    for: "facilitator",
    addedDate: "",
};
export const curriculumAttachmentFields = [
    {
        name: "for",
        title: "For",
    },
    {
        name: "documentName",
        title: "Document",
        cell: (value, data) => {
            const names = (data.documentFilePath || "").split("?")[0].split(".");
            const fileName = (value || "") + "." + names[names.length - 1];

            return (
                <span className="anchor-tag" onClick={() => downloadFile(data.documentFilePath, fileName)}>
                    {value}
                </span>
            );
        },
    },
    {
        name: "addedBy",
        title: "Added By",
    },
    {
        name: "addedDate",
        title: "Added Date",
        cell: (value) => formatDateOnly(value),
    },
];
export const curriculumAttachmentFormFields = [
    {
        name: "documentName",
        title: "Document Name",
        col: 12,
        maxLength: 100,
        required: true,
    },
    {
        name: "documentFileName",
        nameForm: "documentFile",
        title: "Document File",
        inputType: EFieldInputTypes.file,
        accept: ".pdf, .doc, .docx, .xlsx, .png, .svg, .jpg, .jpeg, .gif, .txt",
        col: 12,
        colMd: 12,
        required: (form) => !!form.documentName,
    },
    {
        name: "for",
        title: "For",
        col: 12,
        required: true,

        inputType: EFieldInputTypes.select,
        options: [
            {
                label: "Facilitator",
                value: "facilitator",
            },
            {
                label: "Member",
                value: "member",
            },
        ],
    },
];

export const curriculumViewApis = {
    getAll: {
        api: getCurriculumAttachment,
        body: {
            curriculumId: "",
        },
    },
    create: {
        api: curriculumAttachmentAdd,
        body: {
            curriculumId: "",
        },
    },
    delete: {
        api: deleteCurriculumAttachment,
    },
};
// Curriculum Feedback
export const curriculumFeedbackFormFields = [
    {
        name: "feedback",
        title: "Feedback",
        col: 12,
        maxLength: 100,
        required: true,
    },
];
export const curriculumFeedbackFields = [
    {
        name: "addedDate",
        title: "Added Date",
        cell: (value) => formatDateOnly(value),
        inputType: EFieldInputTypes.date,
        required: true,
    },
    {
        name: "addedBy",
        title: "Added By",
        required: true,
    },
    {
        name: "feedback",
        title: "Feedback",
        inputType: EFieldInputTypes.textArea,
        maxLength: 500,
        required: true,
    },
];

export const curriculumViewFeedbackApis = {
    getAll: {
        api: getCurriculumFeedbacks,
        body: {
            curriculumId: "",
        },
    },
    create: {
        api: addCurriculumFeedback,
        body: {
            curriculumId: "",
        },
    },
    delete: {
        api: deleteCurriculumFeedback,
    },
};
