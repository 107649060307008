import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const eventStaffPrimaryKey = "eventStaffId";

export const getEventStaff = async ({ eventId }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };
  const { responseData } = await callApi.get(buildURL(Urls.GetEventStaff) + eventId);

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((u) => ({
      firstName: u.firstName,
      lastName: u.lastName,
      roleNames: u.roleName,
      eventStaffId: u.eventStaffId,
      userId: u.userGuid,
      eventId,
    }));
  }

  return response;
};

export const searchEventStaffUsers = async (filters) => {
  const response = await callApi.get(buildURL("/Search/EventStaff"), {
    headers: filters,
  });

  return {
    data: Array.isArray(response.responseData)
      ? response.responseData.map((u) => ({
          ...u,
          communityNames: `${u.communities?.length || 0}`,
          peerGroupNames: `${u.peerGroups?.length || 0}`,
          roleNames: u.roles?.map((d) => d.name).join(", "),
        }))
      : [],
    totalRecords: response.totalRecords || response.responseData?.length || 0,
  };
};

export const addEventStaffMembers = async (formData) => {
  const response = await callApi.post(buildURL(Urls.AddEventStaff), {
    eventId: formData.eventId,
    userGuids: formData.userIds,
  });

  return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventStaffId } } : response;
};

export const deleteEventStaffMember = async (formData) => {
  const response = await callApi.post(buildURL(Urls.DeleteEventStaff), {
    eventStaffId: formData.eventStaffId,
    eventId: formData.eventId,
  });

  return response;
};
