import ManagementTable from "../../components/ManagementTable";
import BreadCrumb from "../../components/BreadCrumb";
import { curriculumApis, curriculumFields, curriculumFormFields, curriculumInitialSearchForm, curriculumIntitialForm, curriculumSearchFields } from "./DATA";
import { curriculumPrimaryKey } from "../../services/curriculum/curriculum.services";
import { useSelector } from "react-redux";
import { selectPermissionCurriculum } from "../../redux/permissionReducer/permissionSelectors";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function Curriculum() {
    const navigate = useNavigate();

    const communityAddPermission = useSelector(selectPermissionCurriculum);
    const runAfterSubmit = useCallback(
        (_formData, response) => {
            const curriculumId = response.responseData?.id;
            navigate(`/curriculum/${curriculumId}`);
        },
        [navigate]
    );
    const curriculumIntitialFormFinal = useMemo(() => ({ ...curriculumIntitialForm, order: "0" }), []);
    return (
        <>
            <BreadCrumb title="Curriculum Management" />
            <ManagementTable
                title="Curriculum"
                tableTitle="Curriculum Management"
                searchFields={curriculumSearchFields}
                initialSearchForm={curriculumInitialSearchForm}
                fields={curriculumFields}
                apis={curriculumApis}
                initialForm={curriculumIntitialFormFinal}
                formFields={curriculumFormFields}
                runAfterSubmit={runAfterSubmit}
                withSearch
                hideMoreFiltersButton
                primaryKey={curriculumPrimaryKey}
                refreshAfterSubmit
                hideActionsColumn
                hideAddButton={!communityAddPermission}
            />
        </>
    );
}
