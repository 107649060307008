import { useCallback, useEffect, useRef } from "react";

import ManagementTable from "../../components/ManagementTable";
import AddRowsModal from "../../components/AddRowsModal";
import { eventStaffApis, eventStaffFields, searchEventStaffUserApis } from "./DATA";
import { showResponseMessage } from "../../helpers";
import { useToggle } from "../../helpers/hooks";
import { userFields } from "../Users/DATA";
import { addEventStaffMembers, eventStaffPrimaryKey } from "../../services/events/eventStaffServices";

export default function EventStaff({ eventId, dataRows, eventDetails, selectUserSearchFields, userInitialSearchForm, setDataRows, setRefresh }) {
    const [show, toggleShow, setShow] = useToggle(false);
    const processing = useRef(false);

    const runAfterDelete = useCallback(
        (data) => {
            setDataRows((prev) => prev.filter((user) => user.eventStaffId !== data.eventStaffId));
        },
        [setDataRows]
    );

    const onAddEventStaff = useCallback(
        async (selectedUsers) => {
            if (processing.current) return;
            processing.current = true;

            const response = await addEventStaffMembers({
                eventId,
                userIds: selectedUsers.map((user) => user.userGuid),
            });

            setTimeout(() => {
                processing.current = false;
            }, 2000);
            showResponseMessage(response, { success: "User(s) have been successfully added to the event." });
            if (response.responseCode !== 200) return;

            setRefresh((prev) => prev + 1);
            setShow(false);
        },
        [eventId, setRefresh, setShow]
    );

    useEffect(() => {
        if (!Array.isArray(dataRows)) setRefresh((prev) => prev + 1);
    }, [dataRows, setRefresh]);

    return (
        <>
            <ManagementTable
                title={"Staff Member"}
                tableTitle="Staff"
                primaryKey={eventStaffPrimaryKey}
                fields={eventStaffFields}
                onClickAdd={toggleShow}
                data={dataRows}
                runAfterDelete={runAfterDelete}
                customAddButtonTitle={"Add Staff Member"}
                hideAddButton={!eventDetails.canAddStaff}
                hideActionsColumn={!eventDetails.canDeleteStaff}
                apis={eventStaffApis}
                customDeleteSuccessMessage={"Staff has been removed successfully from this event."}
                customDeleteConfirmationMessage="Are you sure want to delete?"
                hideEditButton
            />
            <AddRowsModal
                show={show}
                onHide={toggleShow}
                fields={userFields}
                apis={searchEventStaffUserApis}
                initialSearchForm={userInitialSearchForm}
                searchFields={selectUserSearchFields}
                onAddRows={onAddEventStaff}
                confirmationMessage={`Are you sure you want to add the user(s) to the event?`}
            />
        </>
    );
}
