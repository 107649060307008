export default function CommunityIcon() {
    return (
        <span className="community-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 51 52" fill="none">
                <g clipPath="url(#clip0_3810_3279)">
                    <path
                        d="M18.7238 42.372H25.7404V28.2032L16.3849 20.0453L7.02952 28.2032V42.372H14.0461V33.0166H18.7238V42.372ZM46.7901 47.0497H4.69066C4.07036 47.0497 3.47546 46.8033 3.03684 46.3647C2.59822 45.9261 2.35181 45.3312 2.35181 44.7109V27.1391C2.35177 26.8049 2.42335 26.4745 2.56174 26.1703C2.70012 25.8661 2.90209 25.5951 3.15403 25.3756L11.7072 17.9169V7.28918C11.7072 6.66888 11.9536 6.07398 12.3923 5.63536C12.8309 5.19674 13.4258 4.95033 14.0461 4.95033H46.7901C47.4104 4.95033 48.0053 5.19674 48.4439 5.63536C48.8825 6.07398 49.1289 6.66888 49.1289 7.28918V44.7109C49.1289 45.3312 48.8825 45.9261 48.4439 46.3647C48.0053 46.8033 47.4104 47.0497 46.7901 47.0497ZM35.0958 23.6612V28.3389H39.7735V23.6612H35.0958ZM35.0958 33.0166V37.6943H39.7735V33.0166H35.0958ZM35.0958 14.3058V18.9835H39.7735V14.3058H35.0958ZM25.7404 14.3058V18.9835H30.4181V14.3058H25.7404Z"
                        fill="#65317E"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3810_3279">
                        <rect width="50.5193" height="50.5193" fill="white" transform="translate(0.480713 0.740387)" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
}
