import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { HiOutlineChevronRight } from "react-icons/hi";
import * as Yup from "yup";

import ReactGA from "react-ga";
import { toast } from "react-toastify";
import Footer from "../../components/BodyContainer/Footer.component";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import "./SignUp.css";
import { TextFieldInput } from "./TextFieldInput";

const HOW_MUCH_OF_THE_BUSINESS_DO_YOU_OWN = 100;

const validationSchema = Yup.object({
    businessName: Yup.string().trim().max(50, "Must be 50 characters or less").required("Required Field."),
    businessZipCode: Yup.string().max(10, "Must be 10 characters or less").required("Required Field."),
    businessDescription: Yup.string().trim().max(250, "Must be 250 characters or less").required("Required Field."),
    businessOwnership: Yup.string().required("Required Field."),
    businessRevenue: Yup.string().required("Required Field."),
    fullTimeEmployee: Yup.string() /* .max(5, "Cannot exceed more than 5 characters") */
        .required("Required Field."),
    partTimeEmployee: Yup.string() /* .max(5, "Cannot exceed more than 5 characters") */
        .required("Required Field."),
    ownerCompensation: Yup.string().required("Required Field."),
    independentContractor: Yup.string().required("Required Field."),
    isBusinessFullTime: Yup.string().required("Required Field."),
    businessStartYear: Yup.string().required("Required Field."),
    businessStartMonth: Yup.string().required("Required Field."),
    // password: Yup
    //   .string()
    //   .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character")
    //   .required("Required Field."),
});
const SignUpBusinessDetails = ({ label, hideErrorMessage, form, setForm, setTab, setUserId, setActiveClass, channelURL, sourceURL }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/business-details" + window.location.search);
    }, [gaEventTracker]);
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = useCallback(
        (values) => {
            setDisabled(true);

            setForm((prev) => {
                const newForm = { ...prev, ...values };
                callApi
                    .post(buildURL(`${Urls.SubmitProspectForm}`), {
                        ...newForm,
                        isBusinessFullTime: newForm.isBusinessFullTime === "true",
                        channel: channelURL,
                        source: sourceURL,
                    })
                    .then((response) => {
                        setTimeout(() => {
                            setDisabled(false);
                        }, 1000);
                        if (response.responseCode !== 200) return toast.error("Something Went Wrong!");

                        setActiveClass(2);

                        setUserId(response?.responseData?.prospectUserId);
                        if (response?.responseData?.businessProspectStatus === "RPRQUA" || response?.responseData?.businessProspectStatus === "RPRNSH") {
                            return setTab(3);
                        }
                        if (response?.responseData?.businessProspectStatus === "RPRNQU") return setTab(4);
                    });

                return newForm;
            });
        },
        [setForm, channelURL, sourceURL, setActiveClass, setUserId, setTab]
    );
    return (
        <>
            <div className="signUpRightFormSec align-items-start">
                <Formik validationSchema={validationSchema} initialValues={form} onSubmit={handleSubmit}>
                    {(formik) => (
                        <Form>
                            <div className="rightSecForm your-business-panel">
                                <h3>Your Business</h3>
                                <h6>Tell us what you’ve built!</h6>
                                <>
                                    <div className="business-sec">
                                        <Row>
                                            <Col md="8">
                                                <div className="form-group customInput">
                                                    <label>BUSINESS name*</label>
                                                    <TextFieldInput name="businessName" type="text" placeholder="Enter Business Name" />
                                                </div>
                                            </Col>
                                            <Col md="4" xs="7">
                                                <div className="form-group customInput">
                                                    <label>BUSINESS ZIP Code*</label>
                                                    <TextFieldInput name="businessZipCode" type="text" placeholder="Zip" inputmode="numeric" pattern="[0-9]*" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <div className="form-group customInput">
                                                    <label>BUSINESS DESCRIPTION*</label>
                                                    <Field
                                                        name="businessDescription"
                                                        rows={"3"}
                                                        type="text"
                                                        placeholder="Briefly describe your business in 10 words or fewer..."
                                                        className="form-select"
                                                        as="textarea"
                                                    ></Field>
                                                    {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"businessDescription"} className="error" />}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="form-group customInput">
                                                    <label>HOW MUCH OF THE BUSINESS DO YOU OWN?*</label>
                                                    <div className="select-box-wrapper">
                                                        <Field name="businessOwnership" className="form-select" as="select">
                                                            <option selected>Select Number</option>
                                                            {Array.from({ length: HOW_MUCH_OF_THE_BUSINESS_DO_YOU_OWN + 1 }, (_, index) => index).map((t, i) => {
                                                                return <option value={HOW_MUCH_OF_THE_BUSINESS_DO_YOU_OWN - i}>{HOW_MUCH_OF_THE_BUSINESS_DO_YOU_OWN - t}</option>;
                                                            })}
                                                        </Field>
                                                        <span className="select-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                <path
                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                    fill="#B4AFC6"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <ErrorMessage style={{ color: "red" }} component="div" name={"businessOwnership"} className="error" />
                                                    {/*  <TextFieldInput
                                                    name="businessOwnership"
                                                    onKeyDown={(evt) => {
                                                        evt.key === "e" && evt.preventDefault();
                                                        evt.key === "+" && evt.preventDefault();
                                                        evt.key === "-" && evt.preventDefault();
                                                        evt.key === "E" && evt.preventDefault();
                                                        evt.key === 'ArrowDown' && evt.target.value < 1 && evt.preventDefault();
                                                    }}
                                                    type="number"
                                                    placeholder="Enter % of ownership (ie. 80%)"
                                                    inputmode="numeric"
                                                    pattern="[0-9]*"
                                                /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <div className="quesAnsForm">
                                                <h5 className="quesInfo">Please ensure the following questions are answered accurately in order to best determine qualification. </h5>
                                                <Row>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>WHAT WAS YOUR APPROX. REVENUE LAST YEAR?*</label>
                                                            <div className="revenue-box">
                                                                <span className="dollar-icon">$</span>
                                                                <TextFieldInput
                                                                    dollarClass={25}
                                                                    name="businessRevenue"
                                                                    onKeyDown={(evt) => {
                                                                        evt.key === "e" && evt.preventDefault();
                                                                        evt.key === "+" && evt.preventDefault();
                                                                        evt.key === "-" && evt.preventDefault();
                                                                        evt.key === "E" && evt.preventDefault();
                                                                    }}
                                                                    min="0"
                                                                    type="number"
                                                                    placeholder="Enter Revenue (ie. 40000)"
                                                                    inputmode="numeric"
                                                                    pattern="[0-9]*"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md="6">
                                                        <div className="form-group customInput">
                                                            <label>WHEN DID YOU START YOUR BUSINESS?*</label>
                                                            <Row>
                                                                <Col>
                                                                    <div className="select-box-wrapper">
                                                                        <Field name="businessStartMonth" className="form-select" as="select">
                                                                            <option selected>Month</option>
                                                                            <option value="1">January</option>
                                                                            <option value="2">February</option>
                                                                            <option value="3">March</option>
                                                                            <option value="4">April</option>
                                                                            <option value="5">May</option>
                                                                            <option value="6">June</option>
                                                                            <option value="7">July</option>
                                                                            <option value="8">August</option>
                                                                            <option value="9">September</option>
                                                                            <option value="10">October</option>
                                                                            <option value="11">November</option>
                                                                            <option value="12">December</option>
                                                                        </Field>
                                                                        <span className="select-arrow">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                                <path
                                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                                    fill="#B4AFC6"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <ErrorMessage style={{ color: "red" }} component="div" name={"businessStartMonth"} className="error" />
                                                                </Col>
                                                                <Col>
                                                                    <div className="select-box-wrapper">
                                                                        <Field name="businessStartYear" className="form-select" as="select">
                                                                            <option value={""}>Year</option>
                                                                            {new Array(40).fill(new Date().getFullYear()).map((thisYear, index) => (
                                                                                <option key={thisYear - index} value={thisYear - index}>
                                                                                    {thisYear - index}
                                                                                </option>
                                                                            ))}
                                                                        </Field>
                                                                        <span className="select-arrow">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                                <path
                                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                                    fill="#B4AFC6"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"businessStartYear"} className="error" />}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="contactMethod justify-content-start">
                                                    <h6 className="mobile-font-12">Do you work full time in your business?*</h6>
                                                    <div className="selectMethod">
                                                        <div className="form-check form-check-inline">
                                                            <Field type="radio" name="isBusinessFullTime" value={"true"} id="yesRadio" className="form-check-input" />
                                                            <label title="" htmlFor="yesRadio" className="form-check-label">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field type="radio" name="isBusinessFullTime" value={"false"} id="noRadio" className="form-check-input" />
                                                            <label title="" htmlFor="noRadio" className="form-check-label">
                                                                No
                                                            </label>
                                                        </div>

                                                        {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={"isBusinessFullTime"} className="error" />}
                                                        {/* <Form.Check inline type="radio" aria-label="radio 1" name="radio" label="Yes" />
                              <Form.Check inline type="radio" aria-label="radio 2" name="radio" label="No" /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="quesAnsForm custom-bg-brd">
                                        <Row>
                                            <Col md="12">
                                                <h5 className="quesInfo">How many employees including YOU work in your business? Please be sure to include owner(s) in the totals below.</h5>
                                            </Col>

                                            <Col md="4" xs={"6"}>
                                                <div className="form-group customInput">
                                                    <label>FULL-TIME*</label>
                                                    <div className="select-box-wrapper">
                                                        <Field name="fullTimeEmployee" className="form-select" as="select">
                                                            <option selected>Select #</option>
                                                            {Array.from({ length: 99 + 1 }, (_, index) => index).map((t) => {
                                                                return <option value={t}>{t}</option>;
                                                            })}
                                                        </Field>

                                                        <span className="select-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                <path
                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                    fill="#B4AFC6"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <ErrorMessage style={{ color: "red" }} component="div" name={"fullTimeEmployee"} className="error" />
                                                    {/* <TextFieldInput
                                                        name="fullTimeEmployee"
                                                        onKeyDown={(evt) => {
                                                            evt.key === "e" && evt.preventDefault();
                                                            evt.key === "+" && evt.preventDefault();
                                                            evt.key === "-" && evt.preventDefault();
                                                            evt.key === "E" && evt.preventDefault();
                                                        }}
                                                        type="number"
                                                        placeholder="0+"
                                                    /> */}
                                                </div>
                                            </Col>
                                            <Col md="4" xs={"6"}>
                                                <div className="form-group customInput">
                                                    <label>PART-TIME*</label>
                                                    <div className="select-box-wrapper">
                                                        <Field name="partTimeEmployee" className="form-select" as="select">
                                                            <option selected>Select #</option>
                                                            {Array.from({ length: 99 + 1 }, (_, index) => index).map((t) => {
                                                                return <option value={t}>{t}</option>;
                                                            })}
                                                        </Field>

                                                        <span className="select-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                <path
                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                    fill="#B4AFC6"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <ErrorMessage style={{ color: "red" }} component="div" name={"partTimeEmployee"} className="error" />
                                                    {/*  <TextFieldInput
                                                        name="partTimeEmployee"
                                                        type="number"
                                                        onKeyDown={(evt) => {
                                                            evt.key === "e" && evt.preventDefault();
                                                            evt.key === "+" && evt.preventDefault();
                                                            evt.key === "-" && evt.preventDefault();
                                                            evt.key === "E" && evt.preventDefault();
                                                        }}
                                                        placeholder="0+"
                                                    /> */}
                                                </div>
                                            </Col>
                                            <Col md="4">
                                                <div className="form-group customInput">
                                                    <label>INDEPENDENT CONTRACTS(1099)*</label>
                                                    <div className="select-box-wrapper w-mobile-50">
                                                        <Field name="independentContractor" className="form-select" as="select">
                                                            <option selected>Select #</option>
                                                            {Array.from({ length: 99 + 1 }, (_, index) => index).map((t) => {
                                                                return <option value={t}>{t}</option>;
                                                            })}
                                                        </Field>

                                                        <span className="select-arrow">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                                                                <path
                                                                    d="M12.625 1.89877C12.6254 2.02949 12.5966 2.15865 12.5406 2.27676C12.4845 2.39487 12.4027 2.49892 12.3012 2.58127L7.05123 6.80752C6.89467 6.93622 6.69828 7.00658 6.49561 7.00658C6.29294 7.00658 6.09655 6.93622 5.93998 6.80752L0.689985 2.43252C0.511296 2.284 0.398925 2.07058 0.377592 1.83921C0.35626 1.60783 0.427714 1.37746 0.576235 1.19877C0.724757 1.02008 0.938179 0.907714 1.16955 0.886382C1.40092 0.865049 1.6313 0.936503 1.80998 1.08502L6.49998 4.99627L11.19 1.21627C11.3184 1.10928 11.4748 1.04131 11.6407 1.02041C11.8065 0.99952 11.9749 1.02657 12.1259 1.09837C12.2768 1.17017 12.4041 1.28371 12.4925 1.42556C12.581 1.5674 12.6269 1.73162 12.625 1.89877Z"
                                                                    fill="#B4AFC6"
                                                                />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <ErrorMessage style={{ color: "red" }} component="div" name={"independentContractor"} className="error" />
                                                    {/*  <TextFieldInput
                                                        name="independentContractor"
                                                        onKeyDown={(evt) => {
                                                            evt.key === "e" && evt.preventDefault();
                                                            evt.key === "+" && evt.preventDefault();
                                                            evt.key === "-" && evt.preventDefault();
                                                            evt.key === "E" && evt.preventDefault();
                                                        }}
                                                        type="number"
                                                        placeholder="0+"
                                                    /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col md="12">
                                            <div className="quesAnsForm custom-bg-brd">
                                                <h5 className="quesInfo">
                                                    {" "}
                                                    How much did the owner(s) pay themselves in the last calendar year? (Please answer for Jan - Dec, even if your business was not open for some or all
                                                    of that time.)
                                                </h5>
                                                <Row>
                                                    <Col md="10">
                                                        <div className="form-group customInput">
                                                            <label>TOTAL OWNER COMPENSATION*</label>
                                                            <div className="revenue-box">
                                                                <span className="dollar-icon">$</span>
                                                                <TextFieldInput
                                                                    dollarClass={25}
                                                                    name="ownerCompensation"
                                                                    onKeyDown={(evt) => {
                                                                        evt.key === "e" && evt.preventDefault();
                                                                        evt.key === "+" && evt.preventDefault();
                                                                        evt.key === "-" && evt.preventDefault();
                                                                        evt.key === "E" && evt.preventDefault();
                                                                    }}
                                                                    min="0"
                                                                    type="number"
                                                                    placeholder="Enter Total Owner Compensation"
                                                                    inputmode="numeric"
                                                                    pattern="[0-9]*"
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <div className="quesAnsForm custom-bg-brd">
                                                <div className="form-group customInput mt-0">
                                                    <label>IF YOU COULD SOLVE ONE CURRENT BUSINESS PROBLEM, WHAT WOULD IT BE?</label>
                                                    <Field
                                                        name="businessProblemDetail"
                                                        rows={"5"}
                                                        type="text"
                                                        placeholder="Enter a short description about a challenge you’re facing in your business..."
                                                        className="form-select"
                                                        as="textarea"
                                                    ></Field>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                                <div className="bottomBtnSec">
                                    <p className="nextScreenInfo">Thanks for the info! Now lets see if our program is the right fit for you!</p>
                                    <button disabled={disabled} type="submit" className="customPrimaryBtn mt-4">
                                        Last step <HiOutlineChevronRight />
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <div className="signupFooter">
                <Footer />
            </div>
        </>
    );
};

export default SignUpBusinessDetails;
