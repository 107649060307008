import { buildURL, callApi } from "../helpers/api-urls";

export const createGetOptionsApi = (url, key = url, cacheTimeout = 1) =>
    async function (formData) {
        const options = {
            data: [],
            _fetchedAt: Date.now(),
            _hasError: false,
        };

        try {
            const { _fetchedAt, data, _hasError } = JSON.parse(localStorage.getItem(key));

            options._fetchedAt = _fetchedAt;
            options.data = data;

            if (_hasError || !_fetchedAt || _fetchedAt < Date.now() - cacheTimeout * 60 * 1000) throw new Error("Options Expired");

            return options;
        } catch (e) {
            const response = await callApi.get(buildURL(url), { headers: formData });

            options._fetchedAt = Date.now();
            options._hasError = !Array.isArray(response.responseData);
            if (!options._hasError) options.data = response.responseData.map((c) => ({ label: c.name, value: c.code?.trim() || "", roleCode: c.roleCode?.trim() }));
            if (options.data.length && cacheTimeout) localStorage.setItem(key, JSON.stringify(options));
            else localStorage.removeItem(key);

            return options;
        }
    };
