import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import ManagementTable from "../../../components/ManagementTable";
import { monthlyFormApis, monthlyFormInitialSearchForm, monthlyFormInitialSort, monthlyListFields } from "./DATA";

import "./UserMonthlyList.css";

const UserMonthlyList = () => {
    const { userId } = useParams();

    const apis = useMemo(() => ({ ...monthlyFormApis, getAll: { ...monthlyFormApis.getAll, body: { userId } } }), [userId]);

    return (
        <div>
            <ManagementTable
                apis={apis}
                title={"Staff Member"}
                tableTitle="Monthly Results Forms"
                fields={monthlyListFields}
                initialSearchForm={monthlyFormInitialSearchForm}
                initialSort={monthlyFormInitialSort}
                hideActionsColumn
                hideAddButton
            />
        </div>
    );
};

export default UserMonthlyList;
