import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import { communityFormFields } from "../Communities/DATA";
import { formatDateOnly, formatDateTime, showResponseMessage } from "../../helpers";
import { useToggle, useForm } from "../../helpers/hooks";
import { communityPrimaryKey, updateCommunity } from "../../services/communities/communityServices";
import { selectUserFirstName, selectUserMiddleName, selectUserLastName } from "../../redux/userReducer/userSelectors";
import Tooltip from "../../components/Tooltip";
import EditIcon from "../../components/EditIcon";
import FormModal from "../../components/FormModal";
import Status from "../../components/Status";
import messages from "../../helpers/messages";
import CommunityIcon from "../../components/CommunityIcon";

export default function Details({ asDashboard = false, communityDetails, setCommunityDetails }) {
  const [show, toggleShow] = useToggle(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [form, onChange, setForm] = useForm({ ...communityDetails });

  const { firstName, middleName, lastName } = useSelector(createStructuredSelector({ firstName: selectUserFirstName, middleName: selectUserMiddleName, lastName: selectUserLastName }));

  const onSubmit = useCallback(
    async (form) => {
      setShowProcessing(true);
      const response = await updateCommunity(form);
      setShowProcessing(false);

      showResponseMessage(response, {
        success: messages.updateCommunity,
      });

      if (response.responseCode !== 200) return;

      setCommunityDetails((prev) => ({
        ...prev,
        ...form,
        modifiedBy: `${firstName} ${middleName} ${lastName}`,
        modifiedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
        statusName: new Date(form.startDate).getTime() < Date.now() && (form.endDate ? new Date(form.endDate).getTime() > Date.now() : true) ? "Active" : "Inactive",
      }));
      toggleShow(false);
    },
    [firstName, lastName, middleName, setCommunityDetails, toggleShow]
  );
  const onCancel = useCallback(async () => {
    toggleShow(false);
    setTimeout(() => setForm({ ...communityDetails }), 500);
  }, [communityDetails, setForm, toggleShow]);

  useEffect(() => setForm({ ...communityDetails }), [communityDetails, setForm]);

  return (
    <div className="viewUserMainData card">
      <div className="cardHeader">
        <h4>Community</h4>
        {!asDashboard && (
          <div className="right-sec">
            <p>
              {`Created: ${formatDateTime(communityDetails.createdDate)} by ${communityDetails.createdBy || " - "}${!communityDetails.modifiedBy?.trim() ? "" : ` | Last Updated: ${formatDateTime(communityDetails.modifiedDate)} by ${communityDetails.modifiedBy}`
                }`}
            </p>
            {communityDetails.canEdit && (
              <Tooltip text="Edit">
                <EditIcon onClick={toggleShow} />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div className="personalDetails">
        {!communityDetails.communityPictureUrl && !communityDetails.communityPictureInitials ? (
          <CommunityIcon />
        ) : !communityDetails.communityPictureUrl ? (
          <div className="user-initial-community">{communityDetails.communityPictureInitials}</div>
        ) : (
          <div>
            <div className="personalImage">
              <img src={communityDetails.communityPictureUrl} alt={communityDetails.communityName} />
            </div>
          </div>
        )}
        <div className="personalDetailsRightSec community-section">
          <div className="status-panel">
            <div className="community-sec block-1">
              <ul className="userContactInfo personal-info">
                <h4 className="overflow-text" title={`${communityDetails.communityName}`}>
                  {communityDetails.communityName}{" "}
                </h4>
                <h4 className="overflow-text" title={`${communityDetails.stateName}`}>
                  {communityDetails.stateName}{" "}
                </h4>
                <p className="overflow-text">{communityDetails.communityCustomName}</p>
              </ul>
            </div>
            <div className="community-sec block-2">
              <h3 className="contentHeading">Status</h3>
              <p className="overflow-text">{communityDetails.startDate && `${formatDateOnly(communityDetails.startDate)} - ${formatDateOnly(communityDetails.endDate) || "Active"}`}</p>
            </div>
            <div className="community-sec block-3">
              <h3 className="contentHeading">Application Form Status</h3>
              <p className="overflow-text">{communityDetails?.formStatusName}</p>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="p-2">
              <Status text={communityDetails.statusName} />
            </div>
          </div>
        </div>
      </div>
      <FormModal
        title="Community"
        show={show}
        showProcessing={showProcessing}
        form={form}
        primaryKey={communityPrimaryKey}
        fields={communityFormFields}
        onChange={onChange}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  );
}
