import { objectToQueryString } from "../../helpers";
import { getPeerGroupScorecard } from "../../services/peer-groups/peerGroupScorecardServices";

export const peerGroupScorecardFields = [
    {
        name: "year",
        title: "Year",
    },
    {
        name: "quarter",
        title: "Quarter",
    },
    {
        name: "membersCount",
        title: "Members",
    },
    {
        name: "statusName",
        title: "Status",
        cell: (value, { peerGroupName, peerGroupId, communityName, communityCode, year, quarter, statusName, statusCode }) => (
            <a
                href={`/peer-group-scorecard/${peerGroupId}?${objectToQueryString({ peerGroupName, communityCode, communityName, statusCode, year, quarter, statusName })}`}
                target="_blank"
                rel="noreferrer"
            >
                {value}
            </a>
        ),
    },
];

export const peerGroupScorecardApis = {
    getAll: {
        api: getPeerGroupScorecard,
    },
};
