import { EFieldInputTypes } from "../../components/FormField";
import { createUpdateCurriculum, getCurriculum } from "../../services/curriculum/curriculum.services";
import { getFormFocusAreaOptions } from "../../services/form-focus-area/focusArea";
import { BsCheckLg } from "react-icons/bs";
import { Link } from "react-router-dom";

export const curriculumIntitialForm = {
    status: "",
    type: [],
    focus: "",
    title: "",
    description: "",
    order: "",
};

export const curriculumInitialSearchForm = {
    sortOrder: "asc",
    sortField: "",
    title: "",
    focus: "",
    status: "active",
    type: [],
};

export const curriculumSearchFields = [
    {
        name: "focus",
        title: "Focus",
        inputType: EFieldInputTypes.select,
        col: 3,
        getOptions: getFormFocusAreaOptions,
    },
    {
        name: "title",
        title: "Title",
        col: 3,
    },
    {
        name: "status",
        title: "Status",
        col: 2,
        inputType: EFieldInputTypes.select,
        options: [
            {
                label: "Active",
                value: "active",
            },
            {
                label: "Inactive",
                value: "inactive",
            },
        ],
    },

    {
        name: "type",
        title: "Type",
        inputType: EFieldInputTypes.checkboxes,
        col: 12,
        options: [
            {
                label: "Awareness",
                value: "awareness",
            },
            {
                label: "Decision",
                value: "decision",
            },
            {
                label: "Action",
                value: "action",
            },
        ],
    },
];

export const curriculumFields = [
    {
        name: "focus",
        title: "Focus",
        cell: (value, data) => <Link to={`/curriculum/${data.curriculumId}`}>{value}</Link>,
    },
    {
        name: "status",
        title: "Status",
    },
    {
        name: "title",
        title: "Title",
        cell: (value, data) => <Link to={`/curriculum/${data.curriculumId}`}>{value}</Link>,
    },
    {
        name: "awareness",
        title: "Awareness",
        cell: (val) => <span className="px-3">{val && <BsCheckLg />} </span>,
    },
    {
        name: "decision",
        title: "Decision",
        cell: (val) => <span className="px-3">{val && <BsCheckLg />} </span>,
    },
    {
        name: "action",
        title: "Action",
        cell: (val) => <span className="px-3">{val && <BsCheckLg />} </span>,
    },
    {
        name: "order",
        title: "Order",
    },
];

export const curriculumFormFields = [
    {
        name: "status",
        title: "Status",
        inputType: EFieldInputTypes.select,
        options: [
            {
                label: "Active",
                value: "active",
            },
            {
                label: "Inactive",
                value: "inactive",
            },
        ],
        required: true,
    },
    {
        name: "focus",
        title: "Focus",
        inputType: EFieldInputTypes.select,
        getOptions: getFormFocusAreaOptions,
        required: true,
    },
    {
        name: "title",
        title: "Title",
        required: true,
        maxLength: 100,
        col: 9,
    },
    {
        name: "order",
        title: "Order",
        required: true,
        maxLength: 100,
        col: 3,
        inputType: EFieldInputTypes.number,
    },
    {
        name: "type",
        title: "Type",
        inputType: EFieldInputTypes.checkboxes,
        col: 12,
        options: [
            {
                label: "Awareness",
                value: "awareness",
            },
            {
                label: "Decision",
                value: "decision",
            },
            {
                label: "Action",
                value: "action",
            },
        ],
    },
    {
        name: "description",
        title: "Brief Description",
        inputType: EFieldInputTypes.textarea,
        col: 12,
        maxLength: 500,
    },
];

export const curriculumApis = {
    getAll: {
        api: getCurriculum,
    },
    create: {
        api: createUpdateCurriculum,
    },
    update: {
        api: createUpdateCurriculum,
    },
};
