import { EPermissionActionTypes } from "./permissionActions";
import { initialPermissionsState } from "./permissionSelectors";

const permissionReducer = (state = initialPermissionsState, action) => {
    switch (action.type) {
        case EPermissionActionTypes.setPermissions:
            return {
                ...state,
                ...action.payload,
                _fetched: true,
            };
        case EPermissionActionTypes.clearPermissions:
            return { ...initialPermissionsState };
        default:
            return state;
    }
};

export default permissionReducer;
