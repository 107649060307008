import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const UserMilestonePrimaryKey = "milestoneId";

export const getUserMilestones = async ({ userId, ...filters }) => {
    const headers = {
        userId,
    };

    const response = await callApi.get(buildURL(Urls.GetUserMilestones + userId), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData.searchResult)
            ? response.responseData.searchResult.map((row) => {
                  return {
                      [UserMilestonePrimaryKey]: row.milestoneId,
                      userId: row.userId,
                      date: row.milestoneDate,
                      milestone: row.milestone,
                  };
              })
            : [],
        canAdd: response.responseData?.canAdd,
        canDelete: response.responseData?.canDelete,
        totalRecords: response.responseData?.totalRecords || 0,
    };
};
export const addUserMilestone = async (formData) => {
    const data = {
        userGuid: formData.userId,
        milestoneDate: formData.date,
        milestone: formData.milestone,
    };

    const response = await callApi.post(buildURL(Urls.AddUserMilestone), data);

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId || formData[UserMilestonePrimaryKey] } } : response;
};
export const deleteUserMilestone = async ({ milestoneId, userId }) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserMilestone), { milestoneId, userId });

    return response;
};
