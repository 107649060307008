import moment from "moment";
import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import SelectInput from "../../components/SelectInput";
import Status from "../../components/Status";
import Tooltip from "../../components/Tooltip";
import { isSpecialSymbolAllowed } from "../../helpers";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createUpdateFunders, getFunders } from "../../services/funder/funderService";
import { getUserStatusOptions } from "../../services/users/userServices";

const communityTooltipMap = (d, i) => {
    return <p key={i} className="p-1">{`${d.name} (${moment(d?.startDate).format("MMM DD, YYYY")} - ${d?.endDate ? moment(d?.endDate).format("MMM DD, YYYY") : "Active"})`}</p>;
};
const memberTooltipMap = (d, i) => <p key={i} className="p-1">{`${d.name} (${moment(d?.startDate).format("MMM DD, YYYY")} - ${d?.endDate ? moment(d?.endDate).format("MMM DD, YYYY") : "Active"})`}</p>;

export const getWhiteLabelOptions = () => [
    {
        label: "ON",
        value: "ON",
    },
    {
        label: "OFF",
        value: "OFF",
    },
];

export const funderSearchFields = [
    {
        name: "funder",
        col: 3,
        title: "Funder",
        maxLength: 50,
    },
    {
        name: "communityCode",
        col: 3,
        title: "Community",
        getOptions: getCommunityOptions,
        getInitialOption: undefined,
        readOnly: false,
        inputType: EFieldInputTypes.custom,
        disableActiveField: false,
        noEmptyOption: false,
        component({ form, onChange }) {
            return (
                <div className="my-2">
                    <SelectInput
                        name={this.name}
                        title={this.title}
                        getOptions={this.getOptions}
                        getInitialOption={this.getInitialOption}
                        onChange={onChange}
                        value={form[this.name]}
                        readOnly={this.readOnly}
                        noEmptyOption={this.noEmptyOption}
                        asSearchField
                    />
                    <div className="my-2">
                        <input
                            type={"checkbox"}
                            className="form-check-input"
                            name="communityIsActive"
                            id="communityIsActive"
                            onChange={this.disableActiveField ? undefined : onChange}
                            checked={form.communityIsActive}
                            readOnly={this.disableActiveField}
                        />
                        <label htmlFor="communityIsActive" className="mx-1" style={{ fontSize: "16px" }}>
                            Active
                        </label>
                    </div>
                </div>
            );
        },
    },
    {
        name: "email",
        col: 3,
        title: "Email",
        maxLength: 50,
    },
    {
        name: "member",
        col: 3,
        title: "Member",
        inputType: EFieldInputTypes.custom,
        disableActiveField: false,
        noEmptyOption: false,
        maxLength: 50,
        component({ form, onChange }) {
            return (
                <div className="my-2">
                    <input
                        name={this.name}
                        type={EFieldInputTypes.text}
                        readOnly={this.readOnly}
                        maxLength={this.maxLength}
                        value={form[this.name]}
                        onChange={onChange}
                        placeholder={`Search by ${this.title}`}
                        className={`form-control searchBox`}
                    />
                    <div className="my-2">
                        <input
                            type={"checkbox"}
                            className="form-check-input"
                            name="memberIsActive"
                            id="memberIsActive"
                            onChange={this.disableActiveField ? undefined : onChange}
                            checked={form.memberIsActive}
                            readOnly={this.disableActiveField}
                        />
                        <label htmlFor="memberIsActive" className="mx-1" style={{ fontSize: "16px" }}>
                            Active
                        </label>
                    </div>
                </div>
            );
        },
    },
    {
        name: "status",
        col: 3,
        title: "Account Status",
        inputType: EFieldInputTypes.select,
        getOptions: getUserStatusOptions,
    },
    {
        name: "startDate",
        col: 3,
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        maxLength: 50,
    },
    {
        name: "endDate",
        col: 3,
        title: "End Date",
        inputType: EFieldInputTypes.date,
        maxLength: 50,
    },
];

export const funderFields = [
    {
        name: "funder",
        title: "Funder",
        filterable: true,
        cell: (funder, data) => (
            <Link style={{ color: "#00adba" }} to={`/funder/${data.funderId}`}>
                {funder}
            </Link>
        ),
        filter: {
            maxLength: 50,
        },
        columnWidth: 200,
    },
    {
        name: "communities",
        title: "Community",
        filterable: true,
        notSortable: true,
        cell: (communities, data) => (
            <div className="text-center font-weight-bold">
                {!data.communities?.length ? (
                    <span className="anchor-tag cursor-default">{communities.length}</span>
                ) : (
                    <Tooltip placement="top" text={data.communities.map(communityTooltipMap)}>
                        <span className="anchor-tag">{communities.length}</span>
                    </Tooltip>
                )}
            </div>
        ),
        columnWidth: 80,
    },
    {
        name: "members",
        title: "Members",
        filterable: true,
        notSortable: true,
        cell: (members, data) => (
            <div className="text-center font-weight-bold">
                {!data.members?.length ? (
                    <span className="anchor-tag cursor-default">{members?.length}</span>
                ) : (
                    /*  <Tooltip placement="top" text={data.members.map(memberTooltipMap)}> */
                    <span className="anchor-tag">{members?.length}</span>
                    /*      </Tooltip> */
                )}
            </div>
        ),
        columnWidth: 80,
    },
    {
        name: "email",
        title: "Email",
        filterable: true,
        columnWidth: 200,
    },
    {
        name: "status",
        title: "Account Status",
        columnWidth: 100,
        filterable: true,
        cell: (value) => <Status text={value} />,
        filter: {
            maxLength: 20,
        },
    },
    {
        name: "startDate",
        title: "Start Date",
        columnWidth: 100,
        filterable: true,
        cell: (value) => <span>{moment(value).format("MM/DD/YYYY")}</span>,
    },
    {
        name: "endDate",
        title: "End Date",
        columnWidth: 100,
        filterable: true,
        cell: (value) => (value ? <span>{moment(value).format("MM/DD/YYYY")}</span> : ""),
    },
];
export const funderFormFields = [
    {
        name: "name",
        required: true,
        title: "Funder",
        placeholder: "Funder",
        col: 8,
        colMd: 8,
        maxLength: 50,
    },
    {
        name: "code",
        required: true,
        title: "Funder Code",
        placeholder: "Funder Code",
        col: 4,
        colMd: 4,
        maxLength: 7,
        validate: (form) => (isSpecialSymbolAllowed(form.code) ? "No special characters allowed" : ""),
    },
    {
        name: "email",
        required: true,
        title: "Email",
        placeholder: "Email Address",
        inputType: EFieldInputTypes.email,
        col: 12,
        colMd: 12,
        maxLength: 320,
    },
    {
        name: "notes",
        inputType: EFieldInputTypes.textarea,
        required: false,
        title: "Funder Notes",
        placeholder: "Funder Notes",
        col: 12,
        colMd: 12,
        maxLength: 5000,
    },

    {
        name: "startDate",
        required: true,
        title: "Start Date",
        inputType: EFieldInputTypes.date,
        placeholder: "Start Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
    },
    {
        name: "endDate",
        title: "End Date",
        inputType: EFieldInputTypes.date,
        placeholder: "End Date",
        col: 6,
        colMd: 6,
        maxLength: 50,
        validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
    },
    {
        name: "isWhiteLabel",
        title: "White Label",
        inputType: EFieldInputTypes.radio,
        options: getWhiteLabelOptions(),
        col: 4,
        colMd: 4,
    },
    {
        name: "multiple",
        title: "",
        col: 8,
        colMd: 8,
        inputType: EFieldInputTypes.multiple,
        fields: [
            {
                name: "logo1",
                title: "SIGN IN LOGO",
                inputType: EFieldInputTypes.image,
                col: 6,
                colMd: 6,
                getDisabled: (form) => form.isWhiteLabel === "OFF",
                required: (form) => form.isWhiteLabel === "ON",
                isRectangular: true,
            },
            {
                name: "logo2",
                title: "NAVIGATION LOGO",
                inputType: EFieldInputTypes.image,
                getDisabled: (form) => form.isWhiteLabel === "OFF",
                col: 6,
                required: (form) => form.isWhiteLabel === "ON",
                colMd: 6,
                isRectangular: true,
            },
        ],
    },
    {
        name: "emailSignature1",
        title: "Email SIGNATURE FORMAT 1",
        inputType: EFieldInputTypes.text,
        placeholder: "Email SIGNATURE FORMAT 1",
        col: 6,
        getDisabled: (form) => form.isWhiteLabel === "OFF",
        colMd: 6,
        required: (form) => form.isWhiteLabel === "ON",
        validate: (form) => form.isWhiteLabel === "ON" && (/^\s*$/.test(form.emailSignature1) ? "Input contains only spaces." : ""),
    },
    {
        name: "emailSignature2",
        title: "Email SIGNATURE FORMAT 2",
        inputType: EFieldInputTypes.text,
        placeholder: "Email SIGNATURE FORMAT 2",
        getDisabled: (form) => form.isWhiteLabel === "OFF",
        col: 6,
        colMd: 6,
        required: (form) => form.isWhiteLabel === "ON",
        validate: (form) => form.isWhiteLabel === "ON" && (/^\s*$/.test(form.emailSignature2) ? "Input contains only spaces." : ""),
    },
];

export const funderInitialForm = {
    name: "",
    code: "",
    notes: "",
    email: "",
    startDate: "",
    endDate: "",
    isWhiteLabel: "OFF",
    logo1: "",
    logo2: "",
    emailSignature1: "",
    emailSignature2: "",
};

export const funderInitialSort = {
    dir: "asc",
    field: "funder",
};

export const funderInitialSearchForm = {
    pageSize: 25,
    pageNumber: 1,
    sortOrder: "asc",
    sortField: "funder",
    communityCode: "",
    email: "",
    member: "",
    status: "",
    startDate: "",
    endDate: "",
    funder: "",
    memberIsActive: false,
    communityIsActive: false,
};

export const funderApis = {
    getAll: {
        api: getFunders,
    },
    create: {
        api: createUpdateFunders,
    },
};
