import { useCallback, useEffect, useRef, useState } from "react";

import "./SelectInput.css";

const SelectInput = ({
    name,
    nameForm = name,
    title,
    options,
    getOptions,
    getInitialOption,
    placeholder,
    value,
    viewValue,
    onChange,
    error,
    noEmptyOption = false,
    asSearchField = false,
    disableEmptyOption = false,
    readOnly,
}) => {
    const mountRef = useRef(true);
    const initialValue = useRef(value ?? "");

    const [optionsFinal, setOptionsFinal] = useState(() => options || []);

    const handleOnChange = useCallback(
        (event) => {
            const extraToChange = {};
            const extra = event.target.options[event.target.selectedIndex]?.getAttribute("aria-describedby");

            if (readOnly) return;
            if (nameForm !== name) extraToChange[name] = !event.target.value ? "" : event.target.options[event.target.selectedIndex]?.label || "";
            if (extra) Object.assign(extraToChange, JSON.parse(extra));

            onChange(event, extraToChange);
        },
        [name, nameForm, onChange, readOnly]
    );

    useEffect(
        () => () => {
            mountRef.current = false;
        },
        []
    );

    useEffect(() => {
        if (!getOptions || options) return;
        let tries = 3;

        async function tryFetch() {
            if (tries === 0) return;

            tries--;
            setOptionsFinal([]);

            const response = await getOptions();

            if (!mountRef.current) return;

            // eslint-disable-next-line no-underscore-dangle
            if (response._hasError) tryFetch();

            setOptionsFinal(response.data);

            if (!getInitialOption || initialValue.current) return;

            const initialOption = getInitialOption(response);

            if (!initialOption) return;

            onChange({ target: { name: nameForm, value: initialOption.value } }, nameForm === name ? undefined : { [name]: initialOption.label });
        }
        tryFetch();
    }, [getInitialOption, getOptions, name, nameForm, onChange, options]);

    useEffect(() => {
        if (Array.isArray(options)) setOptionsFinal(options);
    }, [options]);

    return (
        <select name={nameForm} value={value ?? ""} onChange={handleOnChange} disabled={readOnly} className={`selectBox form-select${error ? " is-invalid" : ""}`}>
            {(!noEmptyOption || !!!optionsFinal.length) && (
                <option value={""} disabled={disableEmptyOption}>
                    {placeholder || (asSearchField ? `${title} (ALL)` : `Select ${title}`)}
                </option>
            )}
            {value && !optionsFinal.find((option) => option.value === value) && <option value={value}>{viewValue || value}</option>}
            {optionsFinal.map((option, idx) => (
                <option key={idx} value={option.value ?? option.label} aria-describedby={!option.extraToChange ? undefined : JSON.stringify(option.extraToChange)}>
                    {option.label}
                </option>
            ))}
        </select>
    );
};

export default SelectInput;
