import ManagementTable from "../../components/ManagementTable";
import BreadCrumb from "../../components/BreadCrumb";
import messages from "../../helpers/messages";
import { peerGroupApis, peerGroupFields, peerGroupFormFields, peerGroupInitialForm, peerGroupInitialSearchForm, peerGroupInitialSort, peerGroupSearchFields } from "./DATA";
import { peerGroupPrimaryKey } from "../../services/peer-groups/peerGroupServices";

export default function PeerGroups() {
    return (
        <>
            <BreadCrumb title="Peer Groups" />
            <ManagementTable
                title="Peer Group"
                primaryKey={peerGroupPrimaryKey}
                initialForm={peerGroupInitialForm}
                apis={peerGroupApis}
                fields={peerGroupFields}
                formFields={peerGroupFormFields}
                searchFields={peerGroupSearchFields}
                initialSearchForm={peerGroupInitialSearchForm}
                initialSort={peerGroupInitialSort}
                customCreateSuccessMessage={messages.createPeerGroup}
                searchCols={{ open: 12, close: 9 }}
                initialFilterCount={5}
                hideActionsColumn
                withSearch
            />
        </>
    );
}
