import { ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "@progress/kendo-theme-default/dist/all.css";

import "./App.css";
import "./assets/css/index.css";
import "./assets/css/responsive.css";

import AppRoutes from "./app.routes";
import ReactGA from "react-ga";

const TRACKING_ID = process.env.GOOGLE_TRACKING_ID;

ReactGA.initialize(TRACKING_ID);
function App() {
    return (
        <>
            <ToastContainer />
            <AppRoutes />
        </>
    );
}

export default App;
