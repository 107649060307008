import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";

import loginIcon from "../../assets/images/login-logo.png";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

import Requirements from "./Requirements";

const ResetPassword = () => {
  const { key } = useParams();
  const navigate = useNavigate();
  const [formPassword, setFormPassword] = useState("");
  const [changed, setChanged] = useState(false);
  // const [changePasswordError, setChangePasswordError] = useState("");
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);
  const [uppercaseLetter, hasUppercaseLetter] = useState(false);
  const [lowercaseLetter, hasLowercaseLetter] = useState(false);
  const [specialLetter, hasSpecialLetter] = useState(false);

  useEffect(() => {
    (async () => {
      if (!formPassword) return;

      const postData = {
        key: key,
        password: btoa(formPassword),
      };
      const response = await callApi.post(buildURL(Urls.ResetPassword), postData);
      if (!response) return;

      if (response.responseCode === 200) {
        toast.success("Password has been changed successfully.");
        setChanged(true);
      } else toast.error(response.responseMessage || "Something went wrong.");
    })();
  }, [formPassword, key]);

  //   const checkIsLinkValid = useCallback(async () => {
  //     const response = await callApi.get(buildURL(`${Urls.IsLinkValidApi}${key}`));

  //     setLinkResponseCode(response.responseCode);
  //     return response;
  //   }, [key]);
  return (
    <>
      <Formik
        initialValues={{
          password: "",
          changePassword: "",
        }}
        validate={(values) => {
          values.password.length < 8 ? longEnough(false) : longEnough(true);
          !/\d/.test(values.password) ? hasNumber(false) : hasNumber(true);
          !/.*[A-Z].*/.test(values.password) ? hasUppercaseLetter(false) : hasUppercaseLetter(true);
          !/.*[a-z].*/.test(values.password) ? hasLowercaseLetter(false) : hasLowercaseLetter(true);
          // eslint-disable-next-line no-useless-escape
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(values.password) ? hasSpecialLetter(false) : hasSpecialLetter(true);
        }}
        onSubmit={(values, { resetForm, setErrors }) => {
          const errors = {};
          // If the errors object is empty then we've successfully met all the requirements
          if (!values.password) errors.password = "Required Field";
          else if (!long || !number || !uppercaseLetter || !lowercaseLetter || !specialLetter) errors.password = "Your password doesn't currently meet the requirements";

          if (!values.changePassword) errors.changePassword = "Required Field";
          else if (values.password !== values.changePassword) errors.changePassword = "Passwords do not match";

          setErrors(errors);

          if (Object.keys(errors).length) return;

          setFormPassword(values.password);
        }}
      >
        {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <div className="login-body-wrap">
                  <div className="login-inner-wrap">
                    <div className="parent-background">
                      {/* <img src={loginBG} className="background-child" alt="bg" /> */}
                      <img src={loginIcon} className="background-logo" alt="logo" />
                    </div>
                    <div className="login-form-wrapper">
                      <h1 className="SignInTxt">{changed ? "Password Changed" : "Set Your Password"}</h1>
                      <div className="p-2 text-center my-2">{changed ? "Please go back to sign in page." : "Enter and confirm your new password."}</div>
                      {!changed && (
                        <>
                          <Requirements long={long} number={number} uppercaseLetter={uppercaseLetter} lowercaseLetter={lowercaseLetter} specialLetter={specialLetter} />
                          <div className="form-group">
                            <div className="form-group mail">
                              <label htmlFor="exampleInputEmail1">Password</label>
                              <input
                                type="password"
                                name="password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password}
                                className="form-control form-control-sm"
                                maxlength={20}
                                placeholder="Enter password"
                                autoComplete="off"
                              />
                              <div className="col-md-12 col-sm-12 col-sm-12">
                                <span className="error" style={{ color: "#dc3545" }}>
                                  {errors.password}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-group mail">
                              <label htmlFor="exampleInputEmail1">Confirm Password</label>
                              <input
                                type="password"
                                name="changePassword"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.changePassword}
                                className="form-control form-control-sm"
                                maxlength={20}
                                placeholder="Enter confirm password"
                                autoComplete="off"
                              />
                              <div className="col-md-12 col-sm-12 col-sm-12">
                                <span className="error" style={{ color: "#dc3545" }}>
                                  {errors.changePassword}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mb-3 text-center">
                            <button type="submit" className="btn purpleBtn">
                              CONFIRM PASSWORD
                            </button>
                          </div>
                        </>
                      )}
                      {changed && (
                        <div className="col-md-12 my-5 py-5 text-center">
                          <button onClick={() => navigate("/sign-in")} type="submit" className="btn purpleBtn">
                            Back To Sign In
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="login-footer">
                    <span>© 2021 Eforever. All Rights Reserved.</span>
                    <span>entrepreneursforever.org</span>
                  </div>
                </div>
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ResetPassword;
