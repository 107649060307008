import { formatDateOnly } from "../../helpers";
import { createReferral, getReferrals } from "../../services/users/userReferralServices";

export const referralFields = [
    {
        name: "firstName",
        title: "First Name",
        maxLength: 50,
        columnWidth: 160,
        required: true,
    },
    {
        name: "lastName",
        title: "Last Name",
        maxLength: 50,
        columnWidth: 160,
        required: true,
    },
    {
        name: "email",
        title: "Email",
        maxLength: 50,
        columnWidth: 200,
        required: true,
        inputType: "email",
    },
    {
        name: "date",
        title: "Date Sent",
        filterable: true,
        maxLength: 50,
        columnWidth: 160,
        excludeInForm: true,
        cell: (value, data) => <span>{formatDateOnly(value)}</span>,
    },
];

export const referralApis = {
    getAll: {
        api: getReferrals,
        body: {
            userId: "",
        },
    },
    create: {
        api: createReferral,
        body: {
            userId: "",
        },
    },
};
