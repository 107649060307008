import { createGetOptionsApi } from "..";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
export const getSocialLinkTypes = createGetOptionsApi(Urls.GetSocialTypeLookup, "socialLinkOptions", 3);
export const SocialLinkPrimaryKey = "socialPlatformId";
export const getSocialLinksApi = async ({ userId, ...filters }) => {
    const headers = {
        userId,
    };

    const response = await callApi.get(buildURL(Urls.GetUserSocialLinks + userId), {
        headers,
    });

    return {
        data: Array.isArray(response.responseData.searchResult)
            ? response.responseData.searchResult.map((row) => {
                  return {
                      //   [UserMilestonePrimaryKey]: row.milestoneId,
                      socialPlatformId: row.socialPlatformId,
                      userId: row.userId,
                      userGuid: row.userGuid,
                      socialPlatform: row.socialPlatform,
                      userName: row.userName,
                  };
              })
            : [],
        canAdd: response.responseData?.canAdd,
        canDelete: response.responseData?.canDelete,
        totalRecords: response.responseData?.totalRecords || 0,
    };
};
export const addSocialLink = async (formData) => {
    const response = await callApi.post(buildURL(Urls.AddUserSocialLink), {
        userGuid: formData.userId,
        socialPlatformTypeCode: formData.socialPlatform,
        username: formData.userName,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventId } } : response;
};
export const deleteUserSocialLink = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteUserSocialLink), {
        socialLinkId: formData.socialPlatformId,
        userId: formData.userId,
    });

    return response;
};
