import React, { useRef, useState } from "react";
import loginIcon from "../../../assets/images/logo-green-white.svg";
import LogoIcon from "../../../assets/images/white-logo-icon.png";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { buildURL, callApi, Urls } from "../../../helpers/api-urls";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid email address.").required("Required Field"),
});
const RequestLink = ({ linkStatus }) => {
    const processingRef = useRef(false);
    const [isVerified, setIsVerfied] = useState(false);

    const captchaChange = (value) => {
        if (value) {
            setIsVerfied(true);
        }
    };
    const handleSubmit = async (values) => {
        if (processingRef.current) return;
        processingRef.current = true;
        const formData = {
            email: values.email,
        };

        const response = await callApi.post(buildURL(Urls.ForgotPasswordApi), formData);
        if (response.responseCode === 200) {
            toast.success(response.responseMessage);
            linkStatus(true);
        } else {
            toast.error(response.responseMessage);
        }
        processingRef.current = false;
    };
    return (
        <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={({ setSubmitting }) => {
                setSubmitting(true);
            }}
        >
            {({ touched, errors, isSubmitting, values }) => (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(values);
                    }}
                >
                    <div className="login-body-wrap">
                        <div className="login-inner-wrap">
                            <div className="parent-background">
                                {/* <img src={loginBG} className="background-child" alt="bg" /> */}
                                <img src={loginIcon} className="background-logo" alt="logo" />
                                <img src={LogoIcon} className="logoIconLrg" alt="LogoIcon" />
                            </div>

                            <div className="login-form-wrapper">
                                <h1 className="SignInTxt mt-3">Set Your Password</h1>
                                <div className="text-center p-2">Enter your email below and we’ll send you an email to reset your password.</div>
                                <div className="form-group">
                                    <label htmlFor="email">EMAIL ADDRESS</label>
                                    <Field type="email" name="email" placeholder="Enter Email Address" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                                    <ErrorMessage component="div" name="email" className="invalid-feedback" />
                                </div>
                                <div className="col-md-12 my-2 captcha-container">
                                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={captchaChange} />
                                </div>
                                <div className="col-md-12 mb-3 text-center Request-link">
                                    <button type="submit" disabled={!isVerified || !values.email} className="btn purpleBtn">
                                        REQUEST RESET LINK
                                    </button>
                                </div>
                                <div className="col-md-12 text-center account-txt">
                                    <p>
                                        <Link to="/sign-in">Back To Sign In</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="login-footer">
                            <span>© 2021 Eforever. All Rights Reserved.</span>
                            <span>entrepreneursforever.org</span>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default RequestLink;
