import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ManagementTable from "../../components/ManagementTable";
import { ReferralPrimaryKey } from "../../services/users/userReferralServices";
import { referralApis, referralFields } from "./DATA";

export default function Referrals() {
    const { userId } = useParams();
    const [permissions, setPermissions] = useState({
        canAdd: false,
        canEdit: false,
        canDelete: false,
    });
    const runAfterGetAll = useCallback(({ canAdd, canEdit, canDelete }) => setPermissions((prev) => ({ ...prev, canAdd, canEdit, canDelete })), []);

    const referralApisFinal = useMemo(() => ({ ...referralApis, getAll: { ...referralApis.getAll, body: { userId } }, create: { ...referralApis.create, body: { userId } } }), [userId]);
    return (
        <ManagementTable
            tableTitle="Referral History"
            customAddButtonTitle="Add New Referral"
            customFormModalTitle="Referral"
            apis={referralApisFinal}
            fields={referralFields}
            primaryKey={ReferralPrimaryKey}
            refreshAfterSubmit
            runAfterGetAll={runAfterGetAll}
            customOnSubmitResponseMessage={(response, formData) =>
                response.responseMessage === "Success" ? "Email Sent" : `Referral has already been sent to ${formData.email}. We will not send another record at this time. Thank you.`
            }
            hideMoreFiltersButton
            hideAddButton={!permissions.canAdd}
            hideActionsColumn
            noSorting
            noPagination
        />
    );
}
