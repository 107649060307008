import { buildURL, callApi } from "../../helpers/api-urls";

export const getCommunityPeerGroups = async ({ communityId }) => {
  const response = {
    data: [],
    totalRecords: 0,
    canAdd: false,
    canEdit: false,
    canDelete: false,
  };

  const { responseData } = await callApi.get(buildURL("/Community/PeerGroups"), {
    headers: { communityCode: communityId },
  });

  if (Array.isArray(responseData?.dataResults)) {
    response.totalRecords = responseData.totalRecords;
    response.canAdd = responseData.canAdd;
    response.canEdit = responseData.canEdit;
    response.canDelete = responseData.canDelete;
    response.data = responseData.dataResults.map((row) => ({ ...row, communityId, peerGroupId: row.peerGroupCode }));
  }

  return response;
};

export const createCommunityPeerGroup = async ({ communityId, peerGroupName, startDate, endDate }) => {
  const response = await callApi.post(buildURL("/Community/PeerGroups/Add"), { communityCode: communityId, peerGroupName, startDate, endDate });

  return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.id } } : response;
};
export const updateCommunityPeerGroup = async ({ communityId, peerGroupName, startDate, endDate }) => {
  const response = await callApi.post(buildURL("/Community/PeerGroups/Update"), { communityCode: communityId, peerGroupName, startDate, endDate });

  return response;
};
