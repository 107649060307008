import { createGetOptionsApi } from "..";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const eventAttendeePrimaryKey = "eventAttendeeId";

export const EEventAttendeeFieldNames = {
    firstName: "firstName",
    lastName: "lastName",
    businessName: "businessName",
    statusName: "statusName",
    roleName: "roleName",
    monthlyFormStatusName: "monthlyFormStatusName",
    userId: "userId",
    email: "email",
    monthlyFormId: "monthlyFormId",
    eventId: "eventId",
    [eventAttendeePrimaryKey]: eventAttendeePrimaryKey,
};

export const getEventAttendees = async ({ eventId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };
    const { responseData } = await callApi.get(buildURL(Urls.GetEventAttendees) + eventId);

    if (Array.isArray(responseData?.dataResults)) {
        response.data = responseData.dataResults.map((u) => ({
            [EEventAttendeeFieldNames.firstName]: u.firstName,
            [EEventAttendeeFieldNames.lastName]: u.lastName,
            [EEventAttendeeFieldNames.businessName]: u.businessName,
            [EEventAttendeeFieldNames.roleName]: u.roleName,
            [EEventAttendeeFieldNames.statusName]: u.statusName,
            [EEventAttendeeFieldNames.eventAttendeeId]: u.eventAttendeeId,
            [EEventAttendeeFieldNames.userId]: u.userGuid?.toLowerCase() || "",
            [EEventAttendeeFieldNames.email]: u.emailAddress,
            [EEventAttendeeFieldNames.monthlyFormId]: u.peerGroupMonthlyFormId,
            [EEventAttendeeFieldNames.monthlyFormStatusName]: u.monthlyFormStatus,
            isAssociate: u.isAssociate,
            eventId,
        }));
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
    }

    return response;
};

export const addEventAttendee = async (formData) => {
    const response = await callApi.post(buildURL(Urls.AddEventAttendee), {
        eventId: formData.eventId,
        userGuids: formData.userIds,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.eventAttendeeId } } : response;
};

export const searchEventAttendeeUsers = async (filters) => {
    const response = await callApi.get(buildURL("/Search/EventAttendee"), {
        headers: filters,
    });

    return {
        data: Array.isArray(response.responseData)
            ? response.responseData.map((u) => ({
                  ...u,
                  communityNames: `${u.communities?.length || 0}`,
                  peerGroupNames: `${u.peerGroups?.length || 0}`,
                  roleNames: u.roles?.map((d) => d.name).join(", "),
              }))
            : [],
        totalRecords: response.totalRecords || response.responseData?.length || 0,
    };
};

export const deleteEventAttendee = async (formData) => {
    const response = await callApi.post(buildURL(Urls.DeleteEventAttendee), {
        eventAttendeeId: formData.eventAttendeeId,
        eventId: formData.eventId,
    });

    return response;
};

export const getEventAttendeeStatusOptionsByType = createGetOptionsApi("/Event/AttendeesStatus", "eventAttendeeStatusOptions", 0);
export const getEventAttendeeStatusOptions = createGetOptionsApi("/Lookup/AttendeesStatus", "eventAttendeeStatusOptions", 1);
export const getEventAttendeeRoleOptions = createGetOptionsApi("/Lookup/AttendeesRole", "eventAttendeeRoleOptions", 1);

export const updateAttendeesStatus = async (formData) => {
    if (formData.statusCode.length < 6) formData.statusCode += " ";
    const response = await callApi.post(buildURL(`/Event/UpdateAttendeeStatus${formData.roleCode !== undefined ? "AndRole" : ""}`), formData);

    return response;
};

export const resendEventInviteToAttendee = async (formData) => {
    const response = await callApi.post(buildURL("/Event/ResendInvitationToAttendee"), {
        eventId: formData.eventId,
        eventAttendeeId: formData.eventAttendeeId,
    });

    return response;
};
