import { useCallback, useMemo } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import FormModal from "../../components/FormModal";
import NoteIcon from "../../components/NoteIcon";
import Tooltip from "../../components/Tooltip";
import messages from "../../helpers/messages";
import { showResponseMessage } from "../../helpers";
import { selectPermissionsUserNoteAdd } from "../../redux/permissionReducer/permissionSelectors";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { resendEventInviteToAttendee } from "../../services/events/eventAttendeeServices";
import { createUserNote } from "../../services/users/userNoteServices";
import { userNoteFormFields, userNoteInitialForm } from "../UserNotes/DATA";

export default function ExtraButtons({ data, eventId, eventTypeName, eventDate }) {
    const initialNoteForm = useMemo(() => ({ ...userNoteInitialForm, noteDate: eventDate }), [eventDate]);
    const [form, onChange, setForm] = useForm(initialNoteForm);
    const [show, toggleShow] = useToggle(false);
    const [showEmailModal, toggleShowEmailModal] = useToggle(false);

    const userNoteAddPermission = useSelector(selectPermissionsUserNoteAdd);

    const handleResetInvite = useThrottle(
        async () => {
            const response = await resendEventInviteToAttendee(data);

            showResponseMessage(response, { success: "Event invite has been sent successfully." });
            toggleShowEmailModal();
        },
        [],
        2000
    );

    const onSubmit = useCallback(
        async (formData) => {
            const response = await createUserNote({ ...formData, entityTypeId: eventId, noteType: `Event: ${eventTypeName}`, userIds: [data.userId] });

            showResponseMessage(response, {
                success: messages.createUserNote,
            });

            if (response.responseCode !== 200) return;

            setForm({ ...initialNoteForm });
            toggleShow(false);
        },
        [data.userId, eventId, eventTypeName, initialNoteForm, setForm, toggleShow]
    );
    const onCancel = useCallback(() => {
        toggleShow(false);
        setTimeout(() => setForm({ ...initialNoteForm }), 500);
    }, [initialNoteForm, setForm, toggleShow]);

    return (
        <>
            {userNoteAddPermission && <NoteIcon onClick={toggleShow} size={25} withTooltip />}
            <Tooltip theme="dark" placement="top" text={"Resend invite email"}>
                <span className="mx-2" onClick={() => toggleShowEmailModal(true)}>
                    {/* <span className="mx-2" onClick={handleResetInvite}> */}
                    <MdOutlineEmail color="#99cc66" size={25} />
                    {showEmailModal && (
                        <Modal className="manageUserPopup" show={showEmailModal} onHide={toggleShowEmailModal} size="lg" backdrop="static" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Resend Email</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                Would you like to Re-Send the Invitation Email?
                                <Row>
                                    <Col lg="12" className="text-end pt-3">
                                        <button className="btnPrimaryLink btn mx-3" type="button" onClick={toggleShowEmailModal}>
                                            CANCEL
                                        </button>
                                        <button onClick={handleResetInvite} className="customPrimaryBtn btn" variant="primary">
                                            Continue
                                        </button>
                                    </Col>
                                </Row>
                            </Modal.Body>
                        </Modal>
                    )}
                </span>
            </Tooltip>
            <FormModal title="Note" show={show} fields={userNoteFormFields} form={form} onChange={onChange} onCancel={onCancel} onSubmit={onSubmit} />
        </>
    );
}
