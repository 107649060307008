import LoadingSpinner from "../LoadingSpinner"

import "./PageLoader.css"

export default function PageLoader() {
    return (
        <div className="page-loader">
            <LoadingSpinner />
        </div>
    );
}
