import { Link } from "react-router-dom";

import messages from "../../helpers/messages";
import Tooltip from "../../components/Tooltip";
import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, throttle } from "../../helpers";
import { createCommunity, getCommunityOptions, getCommunityFormStatusOptions, getCommunities, getCommunityStatusOptions } from "../../services/communities/communityServices";
import { getPeerGroupOptions, getPeerGroupOptionsByCommunity } from "../../services/peer-groups/peerGroupServices";
import { getStateOptions } from "../../services/states/stateServices";

const tooltipMap = (d, i) => (
    <p key={i} className="p-1">{`${d.communityName ? `${d.communityName}, ` : ""}${d.name}: ${formatDateOnly(d.startDate) || "Active"} - ${formatDateOnly(d.endDate) || "Active"}`}</p>
);

export const communitiesInitialSort = {
    dir: "asc",
    field: "communityName",
};

export const communityInitialSearchForm = {
    sortOrder: communitiesInitialSort.dir,
    sortField: communitiesInitialSort.field,
    stateCode: "",
    communityCode: "",
    communityStatusCode: "",
    directorName: "",
    peerGroupCode: "",
    formStatusCode: "",
};
export const communityInitialForm = {
    communityName: "",
    stateCode: "",
    stateName: "",
    startDate: "",
    endDate: "",
    formStatusCode: "",
    formStatusName: "",
    communityPicture: "",
};

export const communitySearchFields = [
    {
        name: "stateCode",
        title: "State",
        inputType: EFieldInputTypes.select,
        getOptions: getStateOptions,
        col: 3,
    },
    {
        name: "communityStatusCode",
        title: "Community Status",
        inputType: EFieldInputTypes.select,
        col: 3,
        getOptions: getCommunityStatusOptions,
    },
    {
        name: "communityCode",
        title: "Community",
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityOptions,
        dependencies: [
            {
                name: "peerGroupCode",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.communityCode ? await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode }) : await getPeerGroupOptions();

                    return response;
                }, 200),
            },
        ],
        col: 3,
    },
    {
        name: "peerGroupCode",
        title: "Peer Group",
        inputType: EFieldInputTypes.select,
        getOptions: getPeerGroupOptions,
        col: 3,
    },
    {
        name: "directorName",
        title: "Director Name",
        inputType: EFieldInputTypes.text,
        col: 3,
    },
    {
        name: "formStatusCode",
        title: "Application Form",
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityFormStatusOptions,
        col: 3,
    },
];
export const communityFields = [
    {
        name: "communityName",
        title: "Community Name",
        filterable: true,
        filter: {
            maxLength: 25,
        },
        columnWidth: 160,
        cell: (value, data) => <Link to={`/communities/${data.communityId}`}>{value}</Link>,
    },
    {
        name: "stateName",
        title: "State",
        filterable: true,
        inputType: EFieldInputTypes.select,
        filter: {
            maxLength: 25,
        },
    },
    {
        name: "startDate",
        title: "Start Date",
        filterable: true,
        inputType: EFieldInputTypes.date,
        filter: {
            maxLength: 25,
        },
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "endDate",
        title: "End Date",
        filterable: true,
        inputType: EFieldInputTypes.date,
        filter: {
            maxLength: 25,
        },
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "peerGroups",
        title: "Peer Group",
        filterable: true,
        notSortable: true,
        filter: {
            maxLength: 25,
        },
        cell: (value) => (
            <div>
                {!value?.length ? (
                    <span className="anchor-tag cursor-default">0</span>
                ) : (
                    <Tooltip placement="top" text={value.map(tooltipMap)}>
                        <span className="anchor-tag">{value.length}</span>
                    </Tooltip>
                )}
            </div>
        ),
    },
    {
        name: "formStatusName",
        title: "Application Form Status",
        filterable: true,
        filter: {
            maxLength: 25,
        },
        columnWidth: 180,
    },
];
export const communityFormFields = [
    {
        name: "communityPictureUrl",
        nameForm: "communityPicture",
        title: "",
        inputType: EFieldInputTypes.image,
        col: 3,
        colMd: 3,
    },
    {
        name: "multiple",
        title: "",
        col: 9,
        colMd: 9,
        inputType: EFieldInputTypes.multiple,
        fields: [
            {
                name: "communityName",
                title: "Community Name",
                inputType: EFieldInputTypes.text,
                required: true,
                maxLength: 50,
            },
            {
                name: "stateName",
                nameForm: "stateCode",
                title: "State",
                inputType: EFieldInputTypes.select,
                required: true,
                getOptions: getStateOptions,
            },
            {
                name: "startDate",
                title: "Start Date",
                inputType: EFieldInputTypes.date,
                required: true,
                colSm: 6,
            },
            {
                name: "endDate",
                title: "End Date",
                inputType: EFieldInputTypes.date,
                colSm: 6,
                validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? messages.endDateLater : ""),
            },
            {
                name: "formStatusName",
                nameForm: "formStatusCode",
                title: "Application Form Status",
                inputType: EFieldInputTypes.select,
                placeholder: "Please Select",
                required: true,
                getOptions: getCommunityFormStatusOptions,
            },
        ],
    },
];

export const communityApis = {
    getAll: {
        api: getCommunities,
    },
    create: {
        api: createCommunity,
    },
};
