import { normalizeDateOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const peerGroupStaffPrimaryKey = "peerGroupStaffId";

export const getPeerGroupStaff = async ({ peerGroupId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };

    const { responseData } = await callApi.get(buildURL(Urls.GetPeerGroupStaff), {
        headers: {
            peerGroupCode: peerGroupId,
        },
    });

    if (Array.isArray(responseData?.records)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.records.map((u) => {
            return {
                firstName: u.userFirstName,
                lastName: u.userLastName,
                roleNames: u.roleName,
                peerGroupStaffId: u.userPeerGroupId,
                userId: u.userGuid,
                peerGroupId,
                startDate: normalizeDateOnly(u.startDate),
                endDate: normalizeDateOnly(u.endDate),
            };
        });
    }

    return response;
};

export const addPeerGroupStaffMembers = async ({ peerGroupId, userIds }) => {
    const response = await callApi.post(buildURL(Urls.AddPeerGroupStaff), {
        peerGroupCode: peerGroupId,
        userGuids: userIds,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.peerGroupStaffId } } : response;
};

export const updatePeerGroupStaffMember = async ({ peerGroupId, peerGroupStaffId, startDate, endDate }) => {
    const response = await callApi.post(buildURL(Urls.UpdatePeerGroupStaff), {
        peerGroupCode: peerGroupId,
        userPeerGroupId: peerGroupStaffId,
        startDate,
        endDate: endDate || null,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.peerGroupStaffId } } : response;
};

export const deletePeerGroupStaffMember = async ({ peerGroupStaffId, peerGroupId }) => {
    const response = await callApi.post(buildURL(Urls.DeletePeerGroupStaff), {
        userPeerGroupId: peerGroupStaffId,
        peerGroupCode: peerGroupId,
    });

    return response;
};
