export const initialCommunityDetails = {
  communityId: "",
  canEdit: true,
  canAddPeerGroups: false,
  canEditPeerGroups: false,
  canDeletePeerGroups: false,
  canAddStaff: false,
  canEditStaff: false,
  canDeleteStaff: false,
  communityFormStatusCode: "",
  communityFormStatusName: "",
  communityFullName: null,
  communityName: "",
  communityPicture: null,
  communityStatusCode: "",
  communityStatusName: "",
  createdBy: "",
  createdDate: "",
  endDate: "",
  modifiedBy: "",
  modifiedDate: null,
  startDate: "",
  stateCode: "",
  stateName: "",
};
