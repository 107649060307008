import { Link } from "react-router-dom";

import Image from "../../components/Image";
import SelectInput from "../../components/SelectInput";
import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly } from "../../helpers";
import { getPeerGroupMembers } from "../../services/peer-groups/peerGroupMemberServices";
import { getUserStatusOptions } from "../../services/users/userServices";
import { userSearchFields } from "../Users/DATA";
import { addMemberToPeerGroup } from "../../services/users/userNoteServices";
import { FaAlignCenter } from "react-icons/fa";

export const peerGroupMemberFields = [
    {
        name: "photo",
        title: "Photo",
        col: 6,
        required: true,
        excludeInForm: true,
        notSortable: true,
        // inputType: EFieldInputTypes.image,
        cell: (value, data) => <Image src={value} nameLetters={`${data.firstName?.[0] || ""}${data.lastName?.[0] || ""}`} width={60} height={60} fontSize={24} />,
    },
    {
        name: "lastName",
        filterable: true,
        title: "Member Last Name",
        col: 6,
        required: true,
        columnWidth: 160,
        cell: (value, data) => (data.isAssociate ? <Link to={`/users/${data.userId}`}>{value}</Link> : <span>{value}</span>),
    },
    {
        name: "firstName",
        filterable: true,
        title: "Member First Name",
        col: 6,
        required: true,
        columnWidth: 160,
        cell: (value, data) => (data.isAssociate ? <Link to={`/users/${data.userId}`}>{value}</Link> : <span>{value}</span>),
    },
    {
        name: "memberDirectory",
        filterable: true,
        title: "Member Directory",
        col: 6,
        required: true,
        columnWidth: 160,
        cell: (value, data) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a target={"_blank"} href={`/member-directory/member-directory-view/${data.memberDirectoryUid}`} rel="noreferrer">
                <FaAlignCenter />
            </a>
        ),
    },
    {
        name: "companyName",
        filterable: true,
        title: "Company",
        col: 12,
        required: true,

        cell: (value) => <span className="anchor-tag font-weight-bold">{value}</span>,
    },
    {
        name: "industryName",
        filterable: true,
        title: "Industry",
        col: 12,
        required: true,
    },
    {
        name: "startDate",
        filterable: true,
        title: "Start Date",
        col: 6,
        required: true,
        inputType: EFieldInputTypes.date,
        cell: (value) => formatDateOnly(value),
        columnWidth: 120,
    },
    {
        name: "endDate",
        filterable: true,
        title: "End Date",
        col: 6,
        required: false,
        inputType: EFieldInputTypes.date,
        cell: (value) => formatDateOnly(value),
        columnWidth: 120,
    },
];

export const peerGroupMemberInitialForm = {
    lastName: "",
    firstName: "",
    roleCode: "",
};

export const peerGroupMemberApis = {
    getAll: {
        api: getPeerGroupMembers,
        body: {
            peerGroupId: null,
        },
    },
    create: {
        api: addMemberToPeerGroup,
        body: {
            userId: "",
        },
    },
};

export const memeberEmailInitialForm = {
    subject: "",
    message: "",
};

export const memberEmailFields = [
    {
        name: "subject",
        title: "Email Subject",
        inputType: EFieldInputTypes.text,
        required: true,
        col: 12,
        maxLength: 150,
    },
    {
        name: "message",
        title: "Email Message",
        inputType: EFieldInputTypes.textarea,
        required: true,
        col: 12,
    },
];

export const selectUserSearchFields = userSearchFields.map((field) =>
    field.name === "status"
        ? {
              ...field,
              inputType: EFieldInputTypes.custom,
              readOnly: true,
              requiredInSearch: true,
              getInitialOption: (response) => response.data.find((opt) => opt.label === "Active"),
              component({ onChange, form }) {
                  return (
                      <div className="d-flex my-2">
                          <SelectInput
                              {...field}
                              getOptions={getUserStatusOptions}
                              value={form[field.nameForm || field.name]}
                              viewValue={form[field.name]}
                              onChange={onChange}
                              placeholder="Status"
                              getInitialOption={this.getInitialOption}
                              readOnly
                          />
                          <div style={{ minWidth: 114, paddingTop: 8 }}>
                              <input type="checkbox" id={"user-status-options"} className="form-check-input" checked readOnly />
                              <label htmlFor="user-status-options">Only Active</label>
                          </div>
                      </div>
                  );
              },
          }
        : field.name === "role"
        ? {
              ...field,
              getOptions: async () => {
                  const response = await field.getOptions();
                  return { ...response, data: response.data.filter((option) => option.label === "Member" || option.label === "Prospect - Approved") };
              },
              getInitialOption: (options) => options.data.find((op) => op.label === "Member"),
              disableActiveRoles: true,
              requiredInSearch: true,
              noEmptyOption: true,
          }
        : field
);
