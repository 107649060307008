import React from "react";
import { useSelector } from "react-redux";

import MenuBar from "./MenuBar.component";
import Header from "./Header.component";
import { useToggle } from "../../helpers/hooks";
import { selectSidebarShow } from "../../redux/sidebarReducer/sidebarSelectors";
import { selectHeaderShow } from "../../redux/headerReducer/headerSelectors";

export default function BodyContainer({ children }) {
  const [menuCollapse, toggleMenuCollapse, setMenuCollapse] = useToggle(window.innerWidth < 768);

  const sidebarShow = useSelector(selectSidebarShow);
  const headerShow = useSelector(selectHeaderShow);

  return (
    <>
      {headerShow && (
        <div className="topHeader">
          <Header menuCollapse={menuCollapse} toggleMenuCollapse={toggleMenuCollapse} />
        </div>
      )}
      <div className="wrapper">
        {sidebarShow && <MenuBar menuCollapse={menuCollapse} toggleMenuCollapse={toggleMenuCollapse} setMenuCollapse={setMenuCollapse} />}
        <div className={!sidebarShow ? "w-100 my-3" : "mainContent"}>{children}</div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
