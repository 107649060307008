import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import BreadCrumb from "../../components/BreadCrumb";
import ManagementTable from "../../components/ManagementTable";
import { selectPermissionsFunderAdd } from "../../redux/permissionReducer/permissionSelectors";
import { funderApis, funderFields, funderFormFields, funderInitialForm, funderInitialSearchForm, funderInitialSort, funderSearchFields } from "./DATA";

export default function Funder() {
    const navigate = useNavigate();
    const isFunderHideAddPermission = useSelector(selectPermissionsFunderAdd);
    const runAfterSubmit = useCallback(
        (_formData, response) => {
            const funderId = response.responseData?.id;
            navigate(`/funder/${funderId}`);
        },
        [navigate]
    );
    return (
        <>
            <BreadCrumb title="Funder" />
            <ManagementTable
                title="Funder"
                initialForm={funderInitialForm}
                fields={funderFields}
                tableTitle={"Funder"}
                primaryKey={"funderId"}
                customFormModalTitle={"Funder"}
                apis={funderApis}
                formFields={funderFormFields}
                runAfterSubmit={runAfterSubmit}
                initialSearchForm={funderInitialSearchForm}
                searchFields={funderSearchFields}
                hideAddButton={!isFunderHideAddPermission}
                initialSort={funderInitialSort}
                initialFilterCount={6}
                searchCols={{ open: 12, close: 6 }}
                withSearch
                hideActionsColumn
            />
        </>
    );
}
