import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import MessageIc from "../../assets/images/msg-ic.png";
import Status from "../../components/Status";
import FormModal from "../../components/FormModal";
import EditIcon from "../../components/EditIcon";
import { userFormFields, userInitialForm } from "../Users/DATA";
import { updateUser } from "../../services/users/userServices";
import { selectUserFirstName, selectUserId, selectUserIsAdmin, selectUserIsCommunityDirector, selectUserLastName } from "../../redux/userReducer/userSelectors";
import { useToggle, useForm } from "../../helpers/hooks";
import { formatDateOnly, formatDateTime, showResponseMessage } from "../../helpers";

export default function Details({
    userId,
    firstName,
    middleName,
    lastName,
    email,
    createdDate,
    createdBy,
    lastLoginDate,
    lastUpdatedBy,
    mobilePhoneNumber,
    lastUpdatedDate,
    image,
    accountStatusName,
    accountStatusId,
    setUserDetails,
    canEdit,
}) {
    const [show, toggleShow, setShow] = useToggle(false);
    const [showProcessing, setShowProcessing] = useState(false);
    const [form, onChange, setForm] = useForm({ ...userInitialForm, userId });
    const processing = useRef(false);

    const { myFirstName, myLastName, myUserId, isAdmin, isCommunityDirector } = useSelector(
        createStructuredSelector({
            myFirstName: selectUserFirstName,
            myLastName: selectUserLastName,
            myUserId: selectUserId,
            isAdmin: selectUserIsAdmin,
            isCommunityDirector: selectUserIsCommunityDirector,
        })
    );

    const onSubmit = async (form) => {
        if (processing.current) return;

        processing.current = true;
        setShowProcessing(true);

        const response = await updateUser(form);

        setShowProcessing(false);
        showResponseMessage(response);
        setTimeout(() => (processing.current = false), 500);

        if (response.responseCode !== 200) return;

        toggleShow(false);
        setUserDetails((prev) => ({
            ...prev,
            accountStatusId: form.accountStatus,
            accountStatusName: form.accountStatusName,
            firstName: form.firstName,
            lastName: form.lastName,
            middleName: form.middleName,
            image: form.imagePath || form.image,
            lastUpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            lastUpdatedBy: `${myFirstName} ${myLastName}`,
            email: form.email,
            // mobilePhoneNumber: form.mobilePhoneNumber,
        }));
    };
    const resetForm = useCallback(
        () => setForm((prev) => ({ ...prev, firstName, lastName, middleName, email, image, mobilePhoneNumber, accountStatusName, accountStatus: accountStatusId, password: "" })),
        [setForm, firstName, lastName, middleName, email, image, mobilePhoneNumber, accountStatusName, accountStatusId]
    );
    const onCancel = useCallback(() => {
        setShow(false);
        setTimeout(() => resetForm(), 200);
    }, [resetForm, setShow]);

    useEffect(() => {
        resetForm();
    }, [resetForm]);

    const formFields = useMemo(
        () =>
            userFormFields.map((field) =>
                field.name !== "formSection"
                    ? field
                    : {
                          ...field,
                          fields: field.fields.map((f) =>
                              f.name !== "accountStatusName"
                                  ? f
                                  : {
                                        ...f,
                                        readOnly: (() => {
                                            if (isAdmin) return false;
                                            if (userId === myUserId) return true;
                                            if (isCommunityDirector && canEdit) return false;
                                            return true;
                                        })(),
                                    }
                          ),
                      }
            ),
        [canEdit, isAdmin, isCommunityDirector, myUserId, userId]
    );

    return (
        <div className="viewUserMainData card">
            <div className="cardHeader">
                <h4>Personal Details</h4>
                <div className="right-sec">
                    <p>
                        Created: {formatDateOnly(createdDate)} by {createdBy} / Last Updated: {formatDateOnly(lastUpdatedDate)} by {lastUpdatedBy}
                    </p>
                    {canEdit && <EditIcon onClick={toggleShow} />}
                </div>
            </div>
            <div className="personalDetails">
                {!image ? (
                    <div className="user-initial">
                        {firstName?.slice(0, 1)} {lastName?.slice(0, 1)}
                    </div>
                ) : (
                    <div>
                        <div className="personalImage">
                            <img src={image} className="" alt="" />
                        </div>
                    </div>
                )}
                <div className="personalDetailsRightSec">
                    <div className="d-flex justify-content-end">
                        <Status text={accountStatusName} />
                    </div>
                    <h1 className="mb-2">{`${firstName || ""} ${middleName || ""} ${lastName || ""}`}</h1>
                    <ul className="userContactInfo personal-info">
                        <li>
                            <span>
                                <img src={MessageIc} className="" alt="MessageIc" />
                            </span>
                            <label>{email}</label>
                        </li>
                    </ul>
                    <div className="last-login-info"> Last Login: {formatDateTime(lastLoginDate)}</div>
                </div>
            </div>
            <div className="k-display-none">
                {canEdit && (
                    <button type="button" onClick={toggleShow}>
                        Edit
                    </button>
                )}
                <div>First Name : {firstName}</div>
                <div>Middle Initial : {middleName}</div>
                <div>Last Name : {lastName}</div>
                <div>Email : {email}</div>
                <div>Account Status : {accountStatusName}</div>
                <div>Last Login Date : {formatDateTime(lastLoginDate)}</div>
                <div>
                    <span>
                        {" "}
                        Last Updated By : {formatDateOnly(lastUpdatedBy)} by {formatDateOnly(lastUpdatedDate)}
                    </span>
                </div>
                <div>Created Date : {formatDateOnly(createdDate)}</div>
                <div>Last Created By : {createdBy}</div>
                <div>Last Login : {formatDateTime(lastLoginDate)}</div>
                <FormModal
                    show={show}
                    showProcessing={showProcessing}
                    onCancel={onCancel}
                    customModalTitle="Manage User"
                    fields={formFields}
                    form={form}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    primaryKey="userId"
                    title={"User"}
                />
            </div>
        </div>
    );
}
