import { buildURL, callApi, Urls } from "../../helpers/api-urls";

export const eventCurriculumPrimaryKey = "eventCurriculumId";

export const getEventCurriculum = async ({ eventId }) => {
    const response = {
        data: [],
        totalRecords: 0,
        canAdd: false,
        canEdit: false,
        canDelete: false,
    };
    const { responseData } = await callApi.get(buildURL(Urls.GetEventCurriculum) + eventId);

    if (Array.isArray(responseData?.dataResults)) {
        response.totalRecords = responseData.totalRecords;
        response.canAdd = responseData.canAdd;
        response.canEdit = responseData.canEdit;
        response.canDelete = responseData.canDelete;
        response.data = responseData.dataResults.map((r) => ({
            status: r.isActive ? "Active" : "Inactive",
            title: r.title,
            focus: r.monthlyFormFocusName,
            eventId,
            eventCurriculumId: r.eventCurriculumId,
            curriculumId: r.curriculumId,
        }));
    }

    return response;
};

// export const searchEventStaffUsers = async (filters) => {
//     const response = await callApi.get(buildURL("/Search/EventStaff"), {
//         headers: filters,
//     });

//     return {
//         data: Array.isArray(response.responseData)
//             ? response.responseData.map((u) => ({
//                   ...u,
//                   communityNames: `${u.communities?.length || 0}`,
//                   peerGroupNames: `${u.peerGroups?.length || 0}`,
//                   roleNames: u.roles?.map((d) => d.name).join(", "),
//               }))
//             : [],
//         totalRecords: response.totalRecords || response.responseData?.length || 0,
//     };
// };

export const addEventCurriculum = async (formData) => {
    const response = await callApi.post(buildURL(Urls.AddEventCurriculum), {
        eventId: formData.eventId,
        curriculumIds: formData.curriculumIds,
    });

    return response.responseCode === 200 ? { ...response, responseData: { id: response.responseData?.curriculumId } } : response;
};

export const deleteEventCurriculum = async (formData) => {
    console.log(formData);
    const response = await callApi.post(buildURL(Urls.DeleteEventCurriculum), {
        eventCurriculumId: formData.eventCurriculumId,
        eventId: formData.eventId,
    });

    return response;
};
