import { useEffect } from "react";
import GreenTick from "../../assets/images/green-tick.svg";
import Footer from "../../components/BodyContainer/Footer.component";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";

import ReactGA from "react-ga";
import "./SignUp.css";

const NotQualified = ({ isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/not-qualified" + window.location.search);
    }, [gaEventTracker]);
    return (
        <>
            <div className="signUpRightFormSec">
                <div className="rightSecForm">
                    <div className="thankYouPage">
                        <div className="thanks-header mb-4">
                            <img className="greentick" src={GreenTick} alt="Tick" />
                            <h3 className="thanks-info">Thank you for your interest in Entrepreneurs Forever.</h3>
                        </div>
                        <p className="font-18 mb-3">Our program works best for owners who:</p>
                        <p className="thanks-info">
                            <ul>
                                <li>
                                    <span style={{ color: "#99cc66" }}>•</span> Have been in business for a year or more.
                                </li>
                                <li>
                                    {" "}
                                    <span style={{ color: "#99cc66" }}>•</span> Have achieved revenue of at least $30,000 per year.
                                </li>
                                <li>
                                    {" "}
                                    <span style={{ color: "#99cc66" }}>•</span> Are working full-time in their business.
                                </li>
                            </ul>
                        </p>
                        <p className="thanks-info">
                            Based on your responses, you <strong>don’t qualify</strong> right now. But don’t worry! There are other business owners making their way too.
                        </p>
                        <p className="thanks-info">
                            <a href="https://www.entrepreneursforever.org/events" className="bold-link-text" target={"_blank"} rel="noreferrer">
                                Forever Forward
                            </a>
                            , powered by Entrepreneurs Forever, offers a way to connect with other business owners in a peer environment and learn new business skills.{" "}
                            <span className="bold-link-text">On the 2nd Friday of every month</span>, we gather online to support business owners like you who want to work ON their business versus IN
                            IT. Please consider reserving a spot in our next “Forever Forward” meeting.{" "}
                        </p>
                        <p className="thanks-info">
                            <a href="https://www.entrepreneursforever.org/events" className="bold-link-text" target={"_blank"} rel="noreferrer">
                                Click here
                            </a>{" "}
                            to Register and bring a friend, <span className="bold-link-text">it's FREE!</span>
                        </p>
                    </div>
                </div>
            </div>
            {!isFunderLogo && (
                <div className="signupFooter">
                    <Footer />
                </div>
            )}
        </>
    );
};

export default NotQualified;
