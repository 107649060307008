import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { toast } from "react-toastify";

import ManagementTable from "../../components/ManagementTable";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import { getExitReasonOptionsByRole, getRoleOptions, userRolePrimaryKey } from "../../services/roles/roleServices";
import { selectUserIsAdmin, selectUserIsCommunityDirector } from "../../redux/userReducer/userSelectors";
import { userRoleApis, userRoleFields, userRoleInitialForm } from "./DATA";

const UserRoles = ({ userId, email, setUserDetails, canAddRoles, canEditRoles, canDeleteRoles }) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [reasonOptions, setReasonOptions] = useState([]);

    const processingRef = useRef(false);
    const { isAdmin, isCommunityDirector } = useSelector(createStructuredSelector({ isAdmin: selectUserIsAdmin, isCommunityDirector: selectUserIsCommunityDirector }));

    const apis = useMemo(() => ({ ...userRoleApis, getAll: { ...userRoleApis.getAll, body: { userId } } }), [userId]);
    const initialForm = useMemo(() => ({ ...userRoleInitialForm, userId }), [userId]);
    const fields = useMemo(
        () =>
            !isAdmin && !isCommunityDirector
                ? userRoleFields
                : userRoleFields.map((field) => (field.nameForm === "roleCode" ? { ...field, options: roleOptions } : field.nameForm === "reasonCode" ? { ...field, options: reasonOptions } : field)),
        [isAdmin, isCommunityDirector, roleOptions, reasonOptions]
    );

    const runAfterGetAll = useCallback(
        ({ canAdd, canEdit, canDelete }) => setUserDetails((prev) => ({ ...prev, canAddRoles: canAdd, canEditRoles: canEdit, canDeleteRoles: canDelete })),
        [setUserDetails]
    );

    const onClickSendPassword = useCallback(async () => {
        if (processingRef.current) return;

        processingRef.current = true;

        const response = await callApi.post(buildURL(Urls.ForgotPasswordApi), { email: email });

        setTimeout(() => (processingRef.current = false), 2000);

        if (response.responseCode === 200) return toast.success("Reset Link Sent. Please check your email to verify the reset link and change your password.");

        toast.error(typeof response.responseMessage === "string" ? response.responseMessage : "Something went wrong.");
    }, [email]);

    useEffect(() => {
        getRoleOptions().then((options) => {
            setRoleOptions(isAdmin ? options.data : options.data.filter((option) => option.label !== "Admin"));
        });
        getExitReasonOptionsByRole().then((options) => {
            setReasonOptions(isAdmin ? options.data : options.data.filter((option) => option.label !== "Admin"));
        });
    }, [isAdmin]);

    return (
        <ManagementTable
            title="Role"
            tableTitle={"Roles"}
            primaryKey={userRolePrimaryKey}
            fields={fields}
            initialForm={initialForm}
            apis={apis}
            runAfterGetAll={runAfterGetAll}
            noDataMessage={"No Roles have been assigned."}
            customDeleteConfirmationMessage={"Please verify you would like to delete the Role."}
            customAddButtonTitle={"ADD ROLE"}
            formSize={"md"}
            hideAddButton={!canAddRoles}
            hideEditButton={!canEditRoles}
            hideDeleteButton={!canDeleteRoles}
            hideActionsColumn={!canEditRoles && !canDeleteRoles}
            childrenBeforeAddButton={
                canEditRoles && (
                    <button type="button" onClick={onClickSendPassword} className="btnPrimaryOutline btn btn-outline-primary m-2">
                        Send Password
                    </button>
                )
            }
            localSort
            confirmOnSubmit
            refreshAfterSubmit
        />
    );
};

export default UserRoles;
