import axios from "axios";
import { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { HiOutlineChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import LogoIcon from "../../assets/images/logo-icon-large.svg";
import Logo from "../../assets/images/logo-white-green.svg";
import Footer from "../../components/BodyContainer/Footer.component";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import "./SignUp.css";

const SignUpInitial = ({ setTab, setProspectInitId, prospectInitId, isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/prospect-landing" + window.location.search);
    }, [gaEventTracker]);
    const [ip, setIP] = useState("");
    const [referrerURL, setReferrerURL] = useState("");
    useEffect(() => {
        (async () => {
            const res = await axios.get("https://geolocation-db.com/json/");
            setIP(res.data.IPv4);
        })();
    }, []);
    useEffect(() => {
        (() => {
            const referrer = document.referrer;
            setReferrerURL(referrer);
        })();
    }, [ip, referrerURL]);
    useEffect(() => {
        (async () => {
            if (!ip) return;
            const response = await callApi.post(buildURL(`${Urls.GetIp}`), {
                ipAddress: ip,
                referrerAddress: referrerURL,
            });

            if (!response.responseData) return;
            setProspectInitId(response.responseData.prospectInitId);
        })();
    }, [ip, referrerURL, setProspectInitId]);

    return (
        <>
            <div className="signUpLeftMenuSec">
                <div className="logoSec">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="leftSecMidData">
                    <h1>
                        <span className="text-green">Entrepreneurship is hard.</span> Don't do it alone.
                    </h1>
                    <p>We're looking for small business owners with a strong will to succeed and an eagerness to help their peers.</p>
                    <p>Apply to become a member now!</p>
                </div>
                <div className="logoBtmSec">
                    <img src={LogoIcon} alt="LogoIcon" />
                </div>
            </div>
            <div className="signUpRightFormSec">
                <div className="rightSecInfo">
                    <h3>Start your journey to better business support.</h3>
                    <p>You're just a few steps away from the supportive peers you've been looking for.</p>
                    <div className="rsList mt-4">
                        <span>1</span>
                        <p>Complete a brief questionnaire to tell us more about your business.</p>
                    </div>
                    <div className="rsList">
                        <span>2</span>
                        <p>Select a Discovery Session that works with your schedule.</p>
                    </div>
                    <div className="rsList">
                        <span>3</span>
                        <p>Meet your peers and get to work! Together, you'll grow your businesses and strengthen your community.</p>
                    </div>
                    <div className="mt-5">
                        <button onClick={() => (prospectInitId ? setTab(1) : undefined)} className="btn btn-primary customPrimaryBtn">
                            START NOW <HiOutlineChevronRight />
                        </button>
                    </div>
                    <div className="mt-2">
                        <span className="px-2">
                            If you already belong to a peer group, please <Link to="/sign-in">click here</Link> to access our Member Portal.
                        </span>
                    </div>
                </div>
            </div>
            {!isFunderLogo &&
                <div div className="signupFooter">
                    <Footer />
                </div>
            }
        </>
    );
};

export default SignUpInitial;
