import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import { EFieldInputTypes } from "../../components/FormField";

import ManagementTable from "../../components/ManagementTable";
import { selectUserIsAdmin, selectUserIsCommunityDirector, selectUserIsFacilitator } from "../../redux/userReducer/userSelectors";
import { userCommunityPrimaryKey } from "../../services/communities/communityServices";
import { getFunderByCommunityCode } from "../../services/funder/funderService";
import { userCommunityApis, userCommunityFields, userCommunityInitialForm } from "./DATA";

const UserCommunities = ({ userId, canAddCommunities, canEditCommunities, canDeleteCommunities, setUserDetails }) => {
    const apis = useMemo(() => ({ ...userCommunityApis, getAll: { ...userCommunityApis.getAll, body: { userId } } }), [userId]);
    const initialForm = useMemo(() => ({ ...userCommunityInitialForm, userId }), [userId]);

    const { isAdmin, isCommunityDirector, isFacilitator } = useSelector(
        createStructuredSelector({ isAdmin: selectUserIsAdmin, isCommunityDirector: selectUserIsCommunityDirector, isFacilitator: selectUserIsFacilitator })
    );
    const [isStaff, setIsStaff] = useState();

    const runAfterGetAll = useCallback(
        ({ canAdd, canEdit, canDelete, isStaff }) => {
            setIsStaff(isStaff);
            setUserDetails((prev) => ({ ...prev, canAddCommunities: canAdd, canEditCommunities: canEdit, canDeleteCommunities: canDelete }));
        },
        [setUserDetails]
    );
    const formFields = useMemo(() => {
        if ((isAdmin || isCommunityDirector || isFacilitator) && !isStaff) {
            return [
                ...userCommunityFields,
                {
                    name: "county",
                    nameForm: "countyId",
                    title: "County",
                    inputType: EFieldInputTypes.select,
                    required: false,
                    col: 6,
                    columnWidth: 150,
                },
                {
                    name: "funder",
                    nameForm: "funderId",
                    title: "Funder",
                    inputType: EFieldInputTypes.select,
                    required: false,
                    col: 6,
                    columnWidth: 100,
                    getOptions: getFunderByCommunityCode,
                },
            ];
        }
        return userCommunityFields;
    }, [isAdmin, isCommunityDirector, isFacilitator, isStaff]);

    return (
        <ManagementTable
            title="Community"
            tableTitle={"Communities"}
            primaryKey={userCommunityPrimaryKey}
            fields={formFields}
            initialForm={initialForm}
            apis={apis}
            runAfterGetAll={runAfterGetAll}
            noDataMessage={"No Communities have been assigned."}
            customAddButtonTitle={"ADD COMMUNITY"}
            customDeleteConfirmationMessage={"Are you sure you want to remove the community against user or member?"}
            hideAddButton={!canAddCommunities}
            hideEditButton={!canEditCommunities}
            hideDeleteButton={!canDeleteCommunities}
            hideActionsColumn={!canEditCommunities && !canDeleteCommunities}
            localSort
            refreshAfterSubmit
        />
    );
};

export default UserCommunities;
