import moment from "moment";
import * as Yup from "yup";
import { useState, useEffect, useRef } from "react";
import { BiMessageSquareEdit } from "react-icons/bi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";

import Status from "../../../components/Status";
import GrinEmoticon from "../../../assets/images/GrinningFace.png";
import SmileEmoticon from "../../../assets/images/SmilingFace.png";
import NeutralEmoticon from "../../../assets/images/NeutralFace.png";
import FrowningEmoticon from "../../../assets/images/FrowningFace.png";
import WearyEmoticon from "../../../assets/images/WearyFace.png";
import IbookIcon from "../../../assets/images/fluent-book.svg";
import PieImage from "../../../assets/images/Monthly_results_form_img.png";
import { buildURL, callApi, Urls } from "../../../helpers/api-urls";
import { useToggle } from "../../../helpers/hooks";
import { formatDateOnly } from "../../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { getFormFocusAreaOptions } from "../../../services/form-focus-area/focusArea";
import { hideSidebarAction, showSidebarAction } from "../../../redux/sidebarReducer/sidebarActions";
import { hideHeaderAction, showHeaderAction } from "../../../redux/headerReducer/headerActions";
import "../../../pages/Register/SignUp.css";

import "../UserMonthlyView.css";

const UserMonthlyView = () => {
    const ref = useRef(null);
    const validationSchema = Yup.object().shape(
        {
            monthlyResults: Yup.string().trim().required("Required Field"),
            emotionReason: Yup.string().trim().required("Required Field"),
            realizationAHAMoment: Yup.string().trim().required("Required Field"),
            focusAreaDetailDays1: Yup.string().trim().required("Required Field"),
            monthlyFormFocusArea1Code: Yup.string().trim().required("Required Field"),
            focusAreaDetail1: Yup.string().trim().required("Required Field"),
            focusAreaAction1: Yup.string().trim().required("Required Field"),

            focusAreaDetailDays2: Yup.string().when(["monthlyFormFocusArea2Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),

            monthlyFormFocusArea2Code: Yup.string(),

            focusAreaDetail2: Yup.string().when(["monthlyFormFocusArea2Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),
            focusAreaAction2: Yup.string().when(["monthlyFormFocusArea2Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),

            focusAreaDetailDays3: Yup.string().when(["monthlyFormFocusArea3Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),

            monthlyFormFocusArea3Code: Yup.string(),

            focusAreaDetail3: Yup.string().when(["monthlyFormFocusArea3Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),
            focusAreaAction3: Yup.string().when(["monthlyFormFocusArea3Code"], {
                is: (valA) => valA !== undefined,
                then: Yup.string().required("Required Field"),
                otherwise: Yup.string(),
            }),
        }
        //Focus Area 1
        //   focusAreaDetailDays1: Yup.string().when(["focusAreaDetailDays2", "focusAreaDetailDays3"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   monthlyFormFocusArea1Code: Yup.string().when(["monthlyFormFocusArea2Code", "monthlyFormFocusArea3Code"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaDetail1: Yup.string().when(["focusAreaDetail2", "focusAreaDetail3"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaAction1: Yup.string().when(["focusAreaAction2", "focusAreaAction3"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),

        //   //Focus Area 2
        //   focusAreaDetailDays2: Yup.string().when(["focusAreaDetailDays3", "focusAreaDetailDays1"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   monthlyFormFocusArea2Code: Yup.string().when(["monthlyFormFocusArea3Code", "monthlyFormFocusArea1Code"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaDetail2: Yup.string().when(["focusAreaDetail3", "focusAreaDetail1"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaAction2: Yup.string().when(["focusAreaAction3", "focusAreaAction1"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),

        //   //Focus Area 3
        //   focusAreaDetailDays3: Yup.string().when(["focusAreaDetailDays1", "focusAreaDetailDays2"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   monthlyFormFocusArea3Code: Yup.string().when(["monthlyFormFocusArea1Code", "monthlyFormFocusArea2Code"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaDetail3: Yup.string().when(["focusAreaDetail1", "focusAreaDetail2"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        //   focusAreaAction3: Yup.string().when(["focusAreaAction1", "focusAreaAction2"], {
        //     is: (valA, valB) => !valA && !valB,
        //     then: Yup.string().required("Required Field"),
        //     otherwise: Yup.string(),
        //   }),
        // },
        // [
        //   ["focusAreaDetailDays1", "focusAreaDetailDays2"],
        //   ["focusAreaDetailDays1", "focusAreaDetailDays3"],
        //   ["focusAreaDetailDays2", "focusAreaDetailDays3"],
        //   ["monthlyFormFocusArea1Code", "monthlyFormFocusArea2Code"],
        //   ["monthlyFormFocusArea1Code", "monthlyFormFocusArea3Code"],
        //   ["monthlyFormFocusArea2Code", "monthlyFormFocusArea3Code"],
        //   ["focusAreaDetail1", "focusAreaDetail2"],
        //   ["focusAreaDetail1", "focusAreaDetail3"],
        //   ["focusAreaDetail2", "focusAreaDetail3"],
        //   ["focusAreaAction1", "focusAreaAction2"],
        //   ["focusAreaAction1", "focusAreaAction3"],
        //   ["focusAreaAction2", "focusAreaAction3"],
        // ]
    );
    const validationSchemaDraft = Yup.object().shape({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({});
    const { userId, eventId, monthlyFormId } = useParams();

    const [toggleForm, toggleFormEdit] = useToggle(false);
    const [emojiValue, setEmojiValue] = useState(0);
    const [formViewData, setFormViewData] = useState({});
    const [focusAreaOptions, setFocusAreaOptions] = useState([]);
    const [reportTitle, setReportTitle] = useState("");
    const [formStatusCode, setFormStatusCode] = useState("");
    const [refresh, setRefresh] = useState(0);
    const validationSchemaFinal = formStatusCode === "MSINPR" ? validationSchemaDraft : validationSchema;

    const handleSubmit = async (values, actions) => {
        console.log(values);
        const response = await callApi.post(buildURL(`${Urls.EditMonthlyForm}`), {
            ...values,
            userFormsId: monthlyFormId === "0" ? null : monthlyFormId,
            userId,
            eventId,
            emotionIndex: emojiValue,
            focusAreaAction1: values.focusAreaAction1 === "1" ? 1 : values.focusAreaAction1 === "2" ? 2 : null,
            focusAreaAction2: values.focusAreaAction2 === "1" ? 1 : values.focusAreaAction2 === "2" ? 2 : null,
            focusAreaAction3: values.focusAreaAction3 === "1" ? 1 : values.focusAreaAction3 === "2" ? 2 : null,
            focusAreaDetailDays1: +values.focusAreaDetailDays1,
            focusAreaDetailDays2: +values.focusAreaDetailDays2,
            focusAreaDetailDays3: +values.focusAreaDetailDays3,
            reportTitleDisplay: reportTitle,
            monthlyFormsStatusCode: formStatusCode,
        });

        if (!response.responseData) return;

        if (response.responseCode === 200) {
            if (monthlyFormId === "0" && response.responseData.userFormsId) navigate(`/month-view/${userId}/${eventId}/${response.responseData.userFormsId}`, { replace: true });

            // setUserFormsIdState(response.responseData?.userFormsId);
            if (formStatusCode === "MSINPR") toast.success("Success");
            if (formStatusCode === "MSCMPL") toast.success("Thank you submitting the monthly report");
            toggleFormEdit();
            setRefresh((prev) => prev + 1);
        } else toast.error("Error");
    };
    useEffect(() => {
        dispatch(hideSidebarAction());
        dispatch(hideHeaderAction());
        return () => {
            dispatch(showSidebarAction());
            dispatch(showHeaderAction());
        };
    }, [dispatch]);
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(Urls.GetMonthlyViewResultForm), {
                headers: {
                    userFormId: monthlyFormId,
                    eventId: eventId,
                    userId,
                },
            });
            if (!response.responseData) return;
            if (!response.responseData?.userFormsId && response.responseData.canEdit) toggleFormEdit(true);

            setFormViewData(response.responseData);
            setEmojiValue(response.responseData.emotionIndex);
            setReportTitle(`${moment(response.responseData?.eventDate).format("MMMM DD, YYYY")} Monthly Results Form`);
        })();
    }, [eventId, monthlyFormId, userId, refresh, toggleFormEdit]);
    useEffect(() => {
        (async () => {
            const response = await getFormFocusAreaOptions();
            setFocusAreaOptions(response.data);
        })();
    }, []);
    const noOfDays = (days) => {
        if (days === 0) return "";
        if (days < 30) return "Less than 30 days";
        if (days === 30) return "30 days";
        if (days > 30 && days < 60) return "More than 30 days";
        if (days === 60) return "60 days";
        if (days > 60 && days < 90) return "More than 60 days";
        if (days > 60 && days === 90) return "90 days";
        if (days > 90) return "More than 90 days";
    };
    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(Urls.UserView) + userId);

            if (!response.responseData) return;
            setData(response.responseData);
        })();
    }, [userId, refresh]);
    return (
        <>
            {toggleForm && (
                <Formik
                    innerRef={ref}
                    enableReinitialize={true}
                    validationSchema={validationSchemaFinal}
                    initialValues={{
                        monthlyResults: formViewData.monthlyResults || "",
                        realizationAHAMoment: formViewData.realizationAHAMoment || "",
                        emotionReason: formViewData.emotionReason || "",
                        monthlyFormFocusArea1Code: formViewData.monthlyFormFocusArea1Code || "",
                        focusAreaDetail1: formViewData.focusAreaDetail1 || "",
                        focusAreaDetailDays1: formViewData.focusAreaDetailDays1 || "",
                        focusAreaAction1: formViewData.focusAreaAction1 === 1 ? "1" : formViewData.focusAreaAction1 === 2 ? "2" : null || "",
                        monthlyFormFocusArea2Code: formViewData.monthlyFormFocusArea2Code || "",
                        focusAreaDetailDays2: formViewData.focusAreaDetailDays2 || "",
                        focusAreaDetail2: formViewData.focusAreaDetail2 || "",
                        focusAreaAction2: formViewData.focusAreaAction2 === 1 ? "1" : formViewData.focusAreaAction2 === 2 ? "2" : null || "",
                        monthlyFormFocusArea3Code: formViewData.monthlyFormFocusArea3Code || "",
                        focusAreaDetail3: formViewData.focusAreaDetail3 || "",

                        focusAreaDetailDays3: formViewData.focusAreaDetailDays3 || "",
                        focusAreaAction3: formViewData.focusAreaAction3 === 1 ? "1" : formViewData.focusAreaAction3 === 2 ? "2" : null || "",
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ errors, handleChange, isValid, isSubmitting, values, formik }) => (
                        <Form>
                            <div className="modal-body monthlyViewForm" action="#">
                                <div className="MonthlyFormOuter">
                                    <div className="FormHeader">
                                        <div className="ImageContainer">
                                            <div className="image-box">
                                                {!formViewData.userImage || !data?.image ? (
                                                    <div className="user-initial reportDetailsImg">
                                                        {data?.firstName?.slice(0, 1)} {data?.lastName?.slice(0, 1)}
                                                    </div>
                                                ) : (
                                                    <img src={formViewData.userImage || data?.image} className="" alt="" />
                                                )}
                                            </div>
                                            <div className="ProfileContent">
                                                <h3>
                                                    {formViewData.firstName || data?.firstName} {formViewData.lastName || data?.lastName}
                                                </h3>
                                                <p>{formViewData.businessName || data?.businessName}</p>
                                            </div>
                                        </div>
                                        <div className="lastUpdatedData">
                                            {formViewData.monthlyFormsStatusDisplay && <Status text={formViewData.monthlyFormsStatusDisplay} />}
                                            <p className="Edit-txt mt-2">
                                                {formViewData.updatedBy && "Last Updated By:"} {formViewData.updatedBy} {formViewData.updatedBy ? "on" : ""} {formatDateOnly(formViewData.updatedOn)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="MonthlyForm">
                                        <h2 className="text-center">{reportTitle}</h2>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group customInput">
                                                    <label className="form-label-txt">1. Which of these best represents how you feel about your business today & why?</label>
                                                    <div className="EmoticonBox text-center mt-3">
                                                        <img onClick={() => setEmojiValue(5)} src={GrinEmoticon} className={emojiValue !== 5 && "unselected-image"} alt="unknown" />
                                                        <img onClick={() => setEmojiValue(4)} src={SmileEmoticon} className={emojiValue !== 4 && "unselected-image"} alt="unknown" />
                                                        <img onClick={() => setEmojiValue(3)} src={NeutralEmoticon} className={emojiValue !== 3 && "unselected-image"} alt="unknown" />
                                                        <img onClick={() => setEmojiValue(2)} src={FrowningEmoticon} className={emojiValue !== 2 && "unselected-image"} alt="unknown" />
                                                        <img onClick={() => setEmojiValue(1)} src={WearyEmoticon} className={emojiValue !== 1 && "unselected-image"} alt="unknown" />
                                                    </div>
                                                    <Field
                                                        className="form-control input-middle false"
                                                        placeholder="Enter short description of why..."
                                                        maxLength={250}
                                                        as="textarea"
                                                        name="emotionReason"
                                                    />

                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"emotionReason"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group customInput">
                                                    <label className="form-label-txt">2. ENTER YOUR MONTHLY RESULTS</label>
                                                    <Field
                                                        className="form-control input-middle textareaInput"
                                                        placeholder="Enter a few sentences about your monthly results in the last month..."
                                                        maxLength={250}
                                                        as="textarea"
                                                        name="monthlyResults"
                                                    />
                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"monthlyResults"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group customInput">
                                                    <label className="form-label-txt">3. ENTER DETAILS ABOUT YOUR “A-HA” MOMENTS this month</label>
                                                    <Field
                                                        className="form-control input-middle textareaInput"
                                                        placeholder="Enter a few sentences about your “a-ha” moments in the last month..."
                                                        maxLength={250}
                                                        as="textarea"
                                                        name="realizationAHAMoment"
                                                    />

                                                    <ErrorMessage style={{ color: "red" }} component={"div"} name={"realizationAHAMoment"} />

                                                    {/* <input className="form-control false" 
                name="businessDescription" 
                type="text" 
                placeholder="Enter a few sentences about your “a-ha” moments in the last month..." 
                autocomplete="off" /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group customInput">
                                                    <label className="form-label-txt">4. what are your focus areas & actions/decisions? </label>
                                                    <div className="graywrapper">
                                                        <span>
                                                            <img src={IbookIcon} className="" alt="unknown" />
                                                        </span>
                                                        <p>
                                                            When filling out your focus areas, please use verbs to make details measurable and decide if you will complete within &lt; 30, 60, 90, or 90
                                                            &gt; days.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-middle">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group customInput">
                                                        <label className="form-label-txt2">FOCUS AREA 1 </label>
                                                        <div className="row">
                                                            <div className="col-md-5 col-6">
                                                                <Field className="form-select select-txt" as="select" name="monthlyFormFocusArea1Code">
                                                                    <option value="">Please Select Focus Area</option>
                                                                    {focusAreaOptions?.map((f) => (
                                                                        <option value={f.value}>{f.label}</option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"monthlyFormFocusArea1Code"} />
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <Field className="form-select select-txt" as="select" name="focusAreaDetailDays1">
                                                                    <option value="">Please Select </option>
                                                                    <option value="29">Less than 30 days</option>
                                                                    <option value="30">30 days</option>
                                                                    <option value="60">60 days</option>
                                                                    <option value="90">90 days</option>
                                                                    <option value="91">More than 90 days</option>
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetailDays1"} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="selectMethodRadio">
                                                                    <div className="col-md-7">
                                                                        <div className="selectMethodRadio">
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction1" value={"1"} id="actionRadio1" className="form-check-input" />
                                                                                <label htmlFor="actionRadio1" className="form-check-label">
                                                                                    Action
                                                                                </label>
                                                                            </div>
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction1" value={"2"} id="DecisionRadio1" className="form-check-input" />
                                                                                <label htmlFor="DecisionRadio1" className="form-check-label">
                                                                                    Decision
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaAction1"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            className="form-control place-txt false mt-3"
                                                            placeholder="Describe details about this metric..."
                                                            maxLength={200}
                                                            as="textarea"
                                                            name="focusAreaDetail1"
                                                        />

                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetail1"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group customInput">
                                                        <label className="form-label-txt2">FOCUS AREA 2 </label>
                                                        <div className="row">
                                                            <div className="col-md-5 col-6">
                                                                <Field className="form-select select-txt" as="select" name="monthlyFormFocusArea2Code">
                                                                    <option value="">Please Select Focus Area</option>
                                                                    {focusAreaOptions?.map((f) => (
                                                                        <option value={f.value}>{f.label}</option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"monthlyFormFocusArea2Code"} />
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <Field className="form-select select-txt" as="select" name="focusAreaDetailDays2">
                                                                    <option value="">Please Select </option>
                                                                    <option value="29">Less than 30 days</option>
                                                                    <option value="30">30 days</option>
                                                                    <option value="60">60 days</option>
                                                                    <option value="90">90 days</option>
                                                                    <option value="91">More than 90 days</option>
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetailDays2"} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="selectMethodRadio">
                                                                    <div className="col-md-7">
                                                                        <div className="selectMethodRadio">
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction2" value={"1"} id="actionRadio2" class="form-check-input" />
                                                                                <label for="actionRadio2" class="form-check-label">
                                                                                    Action
                                                                                </label>
                                                                            </div>
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction2" value={"2"} id="DecisionRadio2" class="form-check-input" />
                                                                                <label for="DecisionRadio2" class="form-check-label">
                                                                                    Decision
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaAction2"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            className="form-control place-txt false mt-3"
                                                            placeholder="Describe details about this metric..."
                                                            maxLength={200}
                                                            as="textarea"
                                                            name="focusAreaDetail2"
                                                        />
                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetail2"} />{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group customInput">
                                                        <label className="form-label-txt2">FOCUS AREA 3 </label>
                                                        <div className="row">
                                                            <div className="col-md-5 col-6">
                                                                <Field className="form-select select-txt" as="select" name="monthlyFormFocusArea3Code">
                                                                    <option value="">Please Select Focus Area</option>
                                                                    {focusAreaOptions?.map((f) => (
                                                                        <option value={f.value}>{f.label}</option>
                                                                    ))}
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"monthlyFormFocusArea3Code"} />
                                                            </div>
                                                            <div className="col-md-3 col-6">
                                                                <Field className="form-select select-txt" as="select" name="focusAreaDetailDays3">
                                                                    <option value="">Please Select </option>
                                                                    <option value="29">Less than 30 days</option>
                                                                    <option value="30">30 days</option>
                                                                    <option value="60">60 days</option>
                                                                    <option value="90">90 days</option>
                                                                    <option value="91">More than 90 days</option>
                                                                </Field>
                                                                <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetailDays3"} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="selectMethodRadio">
                                                                    <div className="col-md-7">
                                                                        <div className="selectMethodRadio">
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction3" value={"1"} id="actionRadio" class="form-check-input" />
                                                                                <label for="actionRadio" class="form-check-label">
                                                                                    Action
                                                                                </label>
                                                                            </div>
                                                                            <div class="customRadioButton form-check-inline">
                                                                                <Field type="radio" name="focusAreaAction3" value={"2"} id="DecisionRadio" class="form-check-input" />
                                                                                <label for="DecisionRadio" class="form-check-label">
                                                                                    Decision
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaAction3"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            className="form-control place-txt false mt-3"
                                                            placeholder="Describe details about this metric..."
                                                            maxLength={200}
                                                            as="textarea"
                                                            name="focusAreaDetail3"
                                                        />
                                                        <ErrorMessage style={{ color: "red" }} component={"div"} name={"focusAreaDetail3"} />{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pieChartImage">
                                            <img src={PieImage} className="" alt=""></img>
                                        </div>
                                    </div>
                                    {/* <div className="pieChart">
                    <div className="upperHalf">
                      <div className="circleTopLeft">
                        <p className="circle-txt-left-1">BUSINESS IDEA</p>
                        <p className="mt-3 circle-txt-left-2">PRODUCT PORTFOLIO</p>
                        <p className="mt-3 circle-txt-left-3">REVENUE MODEL</p>
                        <p className="mt-3 circle-txt-left-4">CUSTOMER PORTFOLIO</p>
                        <p className="mt-3 circle-txt-left-5">MARKET POSITION</p>
                        <div class="badge">
                          <h1>
                            <span class="char5">B</span>
                            <span class="char6">R</span>
                            <span class="char7">A</span>
                            <span class="char8">N</span>
                            <span class="char9">D</span>
                          </h1>
                        </div>
                      </div>
                      <div className="circleTopRight">
                        <p className="circle-txt-right-1">NETWORKING</p>
                        <p className="mt-3 circle-txt-right-2">MARKETING</p>
                        <p className="mt-3 circle-txt-right-3">SALES & SERVICE</p>
                        <p className="mt-3 circle-txt-right-4">COMMUNICATION & PR</p>
                        <p className="mt-3 circle-txt-right-5">BRANDING</p>
                        <div class="badge2">
                          <h1>
                            <span class="char10">G</span>
                            <span class="char11">R</span>
                            <span class="char12">O</span>
                            <span class="char13">W</span>
                            <span class="char14">T</span>
                            <span class="char15">H</span>
                          </h1>
                        </div>
                      </div>
                    </div>
                    <div className="lowerHalf">
                      <div className="circleBottomLeft">
                        <p className="mt-4 circle-txt-left-6">OWNERSHIP & BRAND</p>
                        <p className="mt-3 circle-txt-left-7">EMPLOYEES</p>
                        <p className="mt-3 circle-txt-left-8">PARTNERSHIP</p>
                        <p className="mt-3 circle-txt-left-9">BUSINESS PROCESS</p>
                        <p className="mt-3 circle-txt-left-10">LEGAL ISSUES</p>
                        <div class="badge3">
                          <h1>
                            <span class="char39">N</span>
                            <span class="char38">O</span>
                            <span class="char37">I</span>
                            <span class="char36">T</span>
                            <span class="char35">A</span>
                            <span class="char34">R</span>
                            <span class="char33">T</span>
                            <span class="char32">S</span>
                            <span class="char31">I</span>
                            <span class="char30">N</span>
                            <span class="char29">I</span>
                            <span class="char28">M</span>
                            <span class="char27">D</span>
                            <span class="char26">A</span>
                          </h1>
                        </div>
                      </div>
                      <div className="circleBottomRight">
                        <p className="mt-4 circle-txt-right-6">FINANCIALS</p>
                        <p className="mt-3 circle-txt-right-7">FUNDING</p>
                        <p className="mt-3 circle-txt-right-8">SALES & SERVICE</p>
                        <p className="mt-3 circle-txt-right-9">COMMUNICATION & PR</p>
                        <p className="mt-3 circle-txt-right-10">BRANDING</p>
                        <div class="badge4">
                          <h1>
                            <span class="char16">S</span>
                            <span class="char17">N</span>
                            <span class="char18">O</span>
                            <span class="char19">I</span>
                            <span class="char20">T</span>
                            <span class="char21">A</span>
                            <span class="char22">R</span>
                            <span class="char23">E</span>
                            <span class="char24">P</span>
                            <span class="char25">O</span>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div> */}
                                    <div className="text-end form-buttons col-lg-12">
                                        <button type="button" onClick={toggleFormEdit} className="btnCancelNew">
                                            CANCEL
                                        </button>
                                        <button type="submit" onClick={() => setFormStatusCode("MSINPR")} className="btnPrimaryOutline btn btn-outline-primary mx-4">
                                            SAVE AS DRAFT
                                        </button>
                                        <button type="submit" onClick={() => setFormStatusCode("MSCMPL")} className="customPrimaryBtn btn" variant="primary">
                                            SUBMIT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
            {!toggleForm && (
                <form className="modal-body monthlyViewForm" action="#">
                    <div className="MonthlyFormOuter">
                        <div className="FormHeader">
                            <div className="ImageContainer">
                                <div className="image-box">
                                    {!formViewData.userImage || !data?.image ? (
                                        <div className="user-initial reportDetailsImg">
                                            {data?.firstName?.slice(0, 1)} {data?.lastName?.slice(0, 1)}
                                        </div>
                                    ) : (
                                        <img src={formViewData.userImage || data?.image} className="" alt="" />
                                    )}
                                </div>
                                <div className="ProfileContent">
                                    <h3>
                                        {formViewData.firstName || data?.firstName} {formViewData.lastName || data?.lastName}
                                    </h3>
                                    <p>{formViewData.businessName || data?.businessName}</p>
                                </div>
                            </div>
                            <div className="lastUpdatedData">
                                {formViewData.monthlyFormsStatusDisplay && <Status text={formViewData.monthlyFormsStatusDisplay} />}
                                <p className="Edit-txt mt-2">
                                    {formViewData.updatedBy && "Last Updated By:"} {formViewData.updatedBy} {formViewData.updatedBy ? "on" : ""} {formatDateOnly(formViewData.updatedOn)}
                                </p>
                            </div>
                        </div>
                        <div className="MonthlyForm">
                            <div className="form-headerTitle">
                                <h2 className="text-center">{reportTitle}</h2>
                                <button onClick={toggleFormEdit} className="editbtnblue btnPrimaryOutline btn" type="button">
                                    <BiMessageSquareEdit />
                                    EDIT
                                </button>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group customInput">
                                        <label className="form-label-txt">EMOTIONAL SENTIMENT</label>
                                        {formViewData.emotionIndex && (
                                            <div className="EmoticonGyarBox mt-3">
                                                <span>
                                                    {formViewData.emotionIndex === 1 ? (
                                                        <img src={WearyEmoticon} className="" alt="unknown" />
                                                    ) : formViewData.emotionIndex === 2 ? (
                                                        <img src={FrowningEmoticon} className="" alt="unknown" />
                                                    ) : formViewData.emotionIndex === 3 ? (
                                                        <img src={NeutralEmoticon} className="" alt="unknown" />
                                                    ) : formViewData.emotionIndex === 4 ? (
                                                        <img src={SmileEmoticon} className="" alt="unknown" />
                                                    ) : formViewData.emotionIndex === 5 ? (
                                                        <img src={GrinEmoticon} className="" alt="unknown" />
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                                {formViewData.emotionReason && <p>"{formViewData.emotionReason}"</p>}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 topDividerBg">
                                    <div className="form-group customInput">
                                        <label className="form-label-txt">MONTHLY RESULTS</label>
                                        <ul className="monthlyResultList">{formViewData.monthlyResults && <li className="list-txt">{formViewData.monthlyResults}</li>}</ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 topDividerBg">
                                    <div className="form-group customInput">
                                        <label className="form-label-txt">A-HA MOMENTS</label>
                                        <ul className="monthlyResultList">{formViewData.realizationAHAMoment && <li className="list-txt">{formViewData.realizationAHAMoment}</li>}</ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 topDividerBg">
                                    <div className="form-group customInput">
                                        <label className="form-label-txt">FOCUS AREAS</label>
                                        {(formViewData.focusAreaAction1 || formViewData.focusAreaDetail1 || formViewData.focusAreaDetailDays1 > 0) && (
                                            <div className="FocusArea-item mt-4">
                                                <div className="ContentFocusArea">
                                                    <div className="circleBorder">
                                                        <span className="circle-item">{formViewData.focusAreaAction1 === 1 ? "A" : formViewData.focusAreaAction1 === 2 ? "D" : ""}</span>
                                                    </div>
                                                    <div>
                                                        <p className="circle-item-txt">{formViewData.focusAreaDetail1}</p>
                                                        <p className="circle-item-txt-italic">{noOfDays(formViewData.focusAreaDetailDays1)}</p>
                                                    </div>
                                                </div>
                                                {formViewData.monthlyFormFocusArea1Display && <div className="colorbox1">{formViewData.monthlyFormFocusArea1Display?.toUpperCase()}</div>}
                                            </div>
                                        )}
                                        {(formViewData.focusAreaAction2 || formViewData.focusAreaDetailDays2 || formViewData.monthlyFormFocusArea2Display > 0) && (
                                            <div className="FocusArea-item">
                                                <div className="ContentFocusArea">
                                                    <div className="circleBorder">
                                                        <span className="circle-item">{formViewData.focusAreaAction2 === 1 ? "A" : formViewData.focusAreaAction2 === 2 ? "D" : ""}</span>
                                                    </div>
                                                    <div>
                                                        <p className="circle-item-txt">{formViewData.focusAreaDetail2}</p>
                                                        <p className="circle-item-txt-italic">{noOfDays(formViewData.focusAreaDetailDays2)}</p>
                                                    </div>
                                                </div>
                                                {formViewData.monthlyFormFocusArea2Display && <div className="colorbox2">{formViewData.monthlyFormFocusArea2Display?.toUpperCase()}</div>}
                                            </div>
                                        )}
                                        {(formViewData.focusAreaAction3 || formViewData.focusAreaDetail3 || formViewData.monthlyFormFocusArea3Display > 0) && (
                                            <div className="FocusArea-item">
                                                <div className="ContentFocusArea">
                                                    <div className="circleBorder">
                                                        <span className="circle-item">{formViewData.focusAreaAction3 === 1 ? "A" : formViewData.focusAreaAction3 === 2 ? "D" : ""}</span>
                                                    </div>
                                                    <div>
                                                        <p className="circle-item-txt">{formViewData.focusAreaDetail3}</p>
                                                        <p className="circle-item-txt-italic">{noOfDays(formViewData.focusAreaDetailDays3)}</p>
                                                    </div>
                                                </div>
                                                {formViewData.monthlyFormFocusArea3Display && <div className="colorbox3">{formViewData.monthlyFormFocusArea3Display?.toUpperCase()}</div>}
                                            </div>
                                        )}
                                        <div className="pieChartImage">
                                            <img src={PieImage} className="" alt=""></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </>
    );
};

export default UserMonthlyView;
