import { useCallback, useEffect, useRef, useState } from "react";

import { ReactComponent as Arrows } from "../../assets/images/double-arrow-left.svg";
import { getFormErrors } from "../../helpers";
import { useDebounce, useThrottle, useToggle } from "../../helpers/hooks";
import FormField, { EFieldInputTypes } from "../FormField";
import FormGroup from "../FormGroup";

import "./Form.css";

const defaultForm = {};
const defaultFunction = () => { };

export default function Form({
    fields,
    primaryKey,
    onSubmit,
    onReset,
    onCancel,
    form = defaultForm,
    onChange = defaultFunction,
    searchCols,
    initialFilterCount = 6,
    viewOnly = false,
    asSearchForm = false,
    hideMoreFiltersButton = false,
    customSubmitTitle = "SAVE",
    customCancelTitle = "CANCEL",
    hideSubmitButton = false,
}) {
    const [formErrors, setFormErrors] = useState({});
    const [showAll, toggleShowAll] = useToggle(true);

    const submitted = useRef(0);
    const hasErrors = useRef(false);
    const submitForm = useThrottle(
        () => {
            if (hasErrors.current) return;

            submitted.current++;
            const formData = { ...form };

            for (const key in formData) if (typeof formData[key] === "string") formData[key] = formData[key].trim();

            // No need to verify if already submitted or not, because now the useEffect is handling the 'setFormErrors' and 'hasErrors'.
            if (asSearchForm) {
                for (const field of fields) if (field.requiredInSearch && !formData[field.name]) return;

                return onSubmit?.(formData);
            }

            if (submitted.current === 1) {
                // 1 because incremented in above line so that useEffect can work.
                const errors = getFormErrors(fields, formData);

                setFormErrors(errors);

                if (Object.keys(errors).length) return (hasErrors.current = true);
            }

            // Reset after successful submission.
            submitted.current = 0;

            if (onSubmit) onSubmit(formData);
        },
        [asSearchForm, fields, form, onSubmit],
        2000
    );

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();
            submitForm();
        },
        [submitForm]
    );

    const updateErrors = useDebounce(
        (fields, form) => {
            const errors = getFormErrors(fields, form);

            setFormErrors(errors);

            hasErrors.current = Object.keys(errors).length > 0;
        },
        [],
        800
    );

    useEffect(() => {
        if (!submitted.current || asSearchForm) return;

        hasErrors.current = true;
        updateErrors(fields, form);
    }, [form, fields, asSearchForm, updateErrors]);

    const searchCol = showAll ? searchCols?.open || 12 : searchCols?.close || 6;
    const searchColMd = showAll ? searchCols?.openMd || 12 : searchCols?.closeMd || 6;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                {fields?.map((field, index) => {
                    const show = (showAll || initialFilterCount > index) && !field.excludeInFormOnCondition?.(form) && !field.excludeInForm && !(form[primaryKey] && field.hideInEditForm);
                    const { col = 6, colSm = 12, colMd = 6, colXs = 12 } = field;
                    return (
                        <div key={index} className={`col-lg-${col} col-sm-${colSm} col-md-${colMd} col-${colXs}${show ? "" : " d-none"}`}>
                            {field.inputType === EFieldInputTypes.custom ? (
                                field.component?.({ onChange, form })
                            ) : asSearchForm ? (
                                <div className="my-2">
                                    <FormField
                                        {...field}
                                        options={field.optionsFilterer ? field.optionsFilterer(field.options, form) : field.options}
                                        readOnly={(primaryKey && field.readOnlyInEdit && form[primaryKey]) || field.readOnly}
                                        value={form[field.nameForm || field.name]}
                                        viewValue={form[field.name]}
                                        onChange={onChange}
                                        asSearchField
                                    />
                                </div>
                            ) : field.inputType === EFieldInputTypes.multiple ? (
                                <div className="row">
                                    {field.fields?.map((f, i) => {
                                        const show = !f.excludeInFormOnCondition?.(form) && !f.excludeInForm && !(form[primaryKey] && f.hideInEditForm);
                                        const { col = 6, colSm = 12, colMd = 6, colXs = 12 } = f;
                                        return (
                                            <div key={i} className={`col-lg-${col} col-sm-${colSm} col-md-${colMd} col-${colXs}${show ? "" : " d-none"}`}>
                                                <FormGroup primaryKey={primaryKey} field={f} form={form} formErrors={formErrors} onChange={onChange} viewOnly={viewOnly} />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : field.inputType === "table" ? (
                                <div className="roleTbl">
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr>
                                                {field.fields?.map((f, i) => (
                                                    <th key={i}>{f.title}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {field.fields?.map((f, i) => (
                                                    <td key={i}>
                                                        <FormGroup primaryKey={primaryKey} field={f} form={form} formErrors={formErrors} onChange={onChange} hideLabel viewOnly={viewOnly} />
                                                    </td>
                                                ))}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <FormGroup primaryKey={primaryKey} field={field} form={form} formErrors={formErrors} onChange={onChange} viewOnly={viewOnly} />
                            )}
                        </div>
                    );
                })}
                <div className={`${asSearchForm ? `col-lg-${searchCol} col-md-${searchColMd}` : `col-lg-12`} col-sm-12 col-12`}>
                    <div className={`form-buttons-container py-2${asSearchForm ? "" : " justify-content-end"}`}>
                        {asSearchForm ? (
                            <>
                                <div className="search-buttons-group">
                                    <button className="btnPrimaryOutline btn btn-outline-primary" type="submit">
                                        SEARCH
                                    </button>
                                    <button className="btnPlink btn btn-link" type="button" onClick={onReset}>
                                        RESET
                                    </button>
                                </div>
                                {!hideMoreFiltersButton && (
                                    <div className={`moreFilters`}>
                                        <button className="btn btn-link btnPlink" type="button" onClick={toggleShowAll}>
                                            <Arrows className={`mx-2${showAll ? " opposite" : ""}`} />
                                            {`${showAll ? "LESS" : "MORE"} FILTERS`}
                                        </button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {onCancel && (
                                    <div className="mx-2">
                                        <button className="btnPrimaryOutline btn btn-outline-primary" type="button" onClick={onCancel}>
                                            {customCancelTitle}
                                        </button>
                                    </div>
                                )}
                                {!hideSubmitButton && (
                                    <div className="mx-2">
                                        <button className="customPrimaryBtn btn" type="submit">
                                            {customSubmitTitle}
                                        </button>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
}
