import { Nav } from "react-bootstrap";

import "./DetailsPageContainer.css";

import BreadCrumb from "../BreadCrumb";

export default function DetailsPageContainer({ children, detailsChildren, expandedDetailsChildren, navLinkChildren, breadCrumbTitle, breadCrumbLink, nameLetters, image, expandedDetails = false, hideBreadcrumb = false, fontSize = 35 }) {
    return (
        <>
            {/* HIDDEN THE BREADCRUMB AS PER THE NEW REQUIREMENTS. */}
            <BreadCrumb title={breadCrumbTitle} link={breadCrumbLink} hidden={hideBreadcrumb || true} />
            <div className="userManagementWrapper">
                <div className={`card${expandedDetails ? " userInfoWrapper" : ""}`}>
                    <div className="viewUserTopLinks">
                        <div className={`viewUserDetailsInfo${expandedDetails ? " userDetailsInfoLong" : ""}`}>
                            {!image ? (
                                <div className="user-initial" style={expandedDetails ? { margin: "0 auto" } : { height: 70, width: 70, fontSize: fontSize, letterSpacing: -2, minHeight: 70, minWidth: 70 }}>
                                    {nameLetters}
                                </div>
                            ) : (
                                <div className="userPic">
                                    <img src={image} alt={""} />
                                </div>
                            )}
                            <div className="userDetails">
                                {detailsChildren}
                            </div>
                            {expandedDetails && (
                                <div className="userDetailsContactInfo">
                                    {expandedDetailsChildren}
                                </div>
                            )}
                        </div>
                        <div className="myaccountNav p-4">
                            <Nav as="ul">
                                {navLinkChildren}
                            </Nav>
                        </div>
                    </div>
                    <div className="details-page-container" style={expandedDetails ? { width: "calc(100% - 366px)", marginLeft: 360 } : undefined}>{children}</div>
                </div>
            </div>
        </>
    );
}
