/* eslint-disable no-underscore-dangle */
import { Suspense, useEffect, useState } from "react";
import { Navigate, NavLink, Route, Routes, useParams } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useSelector } from "react-redux";

import DetailsPageContainer from "../../components/DetailsPageContainer";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorBoundary from "../../components/ErrorBoundary";
import UnAuthorisedPage from "../UnAuthorisedPage";
import Details from "./Details.component";
import NotFoundPage from "../NotFoundPage";
import { initialEventDetails, selectUserSearchFields } from "./DATA";
import { formatDateOnly, formatTimeOnly } from "../../helpers";
import { eventPrimaryKey, getEventById } from "../../services/events/eventServices";
import { createStructuredSelector } from "reselect";
import { getEventAttendees } from "../../services/events/eventAttendeeServices";
import { userInitialSearchForm } from "../Users/DATA";
import { getEventStaff } from "../../services/events/eventStaffServices";
import { getEventPermissions } from "../../services/events/eventPermissionServices";
import { selectUserId } from "../../redux/userReducer/userSelectors";
import {
    selectPermissionsEventAttendeesMenu,
    selectPermissionsEventCurriculumMenu,
    selectPermissionsEventDetailsMenu,
    selectPermissionsEventDocumentsMenu,
    selectPermissionsEventNotesMenu,
    selectPermissionsEventPermissionsMenu,
    selectPermissionsEventStaffMenu,
    selectPermissionsFetched,
    selectPermissionsSidebarEventsMenu,
} from "../../redux/permissionReducer/permissionSelectors";

// NO LAZY IMPORTS ANYMORE...
import EventStaff from "../EventStaff";
import EventAttendees from "../EventAttendees";
import EventPermissions from "../EventPermissions";
import EventCurriculum from "../EventCurriculum";
import { getEventCurriculum } from "../../services/events/eventCurriculumServices";
import EventCurriculumDetails from "../EventCurriculumDetails";
import EventNotes from "../EventNotes";

const EventDetails = () => {
    const { eventId } = useParams();
    const [attendees, setAttendees] = useState(undefined);
    const [staff, setStaff] = useState(undefined);

    const [eventCurriculum, setEventCurriculum] = useState(undefined);
    const [permissions, setPermissions] = useState(undefined);
    const [refreshAttendees, setRefreshAttendees] = useState(0);
    const [refreshStaff, setRefreshStaff] = useState(0);
    const [refreshEventCurriculum, setRefreshEventCurriculum] = useState(0);
    const [refreshPermissions, setRefreshPermissions] = useState(0);
    const [eventDetails, setEventDetails] = useState(() => ({
        ...initialEventDetails,
        _loading: true,
        eventId,
    }));
    const {
        sidebarEventsMenuPermission,
        eventDetailsMenuPermission,
        eventAttendeesMenuPermission,
        eventCurriculumMenuPermission,
        eventNotesMenuPermission,
        eventDocumentsMenuPermission,
        eventStaffMenuPermission,
        eventPermissionsMenuPermission,
        permissionsFetched,
        myUserId,
    } = useSelector(
        createStructuredSelector({
            sidebarEventsMenuPermission: selectPermissionsSidebarEventsMenu,
            eventDetailsMenuPermission: selectPermissionsEventDetailsMenu,
            eventAttendeesMenuPermission: selectPermissionsEventAttendeesMenu,
            eventCurriculumMenuPermission: selectPermissionsEventCurriculumMenu,
            eventNotesMenuPermission: selectPermissionsEventNotesMenu,
            eventDocumentsMenuPermission: selectPermissionsEventDocumentsMenu,
            eventStaffMenuPermission: selectPermissionsEventStaffMenu,
            eventPermissionsMenuPermission: selectPermissionsEventPermissionsMenu,
            permissionsFetched: selectPermissionsFetched,
            myUserId: selectUserId,
        })
    );

    const expandedDetails = false;

    useEffect(() => {
        (async () => {
            const response = await getEventById(eventId);

            setEventDetails((prev) => ({
                ...prev,
                ...(response.data || {}),
                [eventPrimaryKey]: eventId,
                _loading: false,
            }));
        })();
    }, [eventId]);
    useEffect(() => {
        if (refreshAttendees === 0) return;

        (async () => {
            const { data, canAdd, canEdit, canDelete } = await getEventAttendees({ eventId });

            setAttendees(data);
            // const found = data.find((u) => u.userId === myUserId);
            setEventDetails((prev) => ({
                ...prev,
                // isAttendee: !!found,
                // eventAttendeeStatusName: !found?.statusName || prev.eventAttendeeStatusName,
                // eventAttendeeStatusCode: found?.statusCode || "",
                canAddAttendees: canAdd,
                canEditAttendees: canEdit,
                canDeleteAttendees: canDelete,
            }));
        })();
    }, [eventId, refreshAttendees, myUserId]);
    useEffect(() => {
        if (refreshStaff === 0) return;

        (async () => {
            const { data, canAdd, canEdit, canDelete } = await getEventStaff({ eventId });

            setStaff(data);
            setEventDetails((prev) => ({ ...prev, canAddStaff: canAdd, canEditStaff: canEdit, canDeleteStaff: canDelete }));
        })();
    }, [eventId, refreshStaff]);
    // Event Curriculum
    useEffect(() => {
        if (refreshEventCurriculum === 0) return;

        (async () => {
            const { data, canAdd, canEdit, canDelete } = await getEventCurriculum({ eventId });

            setEventCurriculum(data);
            setEventDetails((prev) => ({ ...prev, canAddEventCurriculum: canAdd, canEditEventCurriculum: canEdit, canDeleteEventCurriculum: canDelete }));
        })();
    }, [eventId, refreshEventCurriculum]);
    // Event Curriculum

    useEffect(() => {
        if (refreshPermissions === 0) return;

        (async () => {
            const { data, canAdd, canEdit, canDelete } = await getEventPermissions({ eventId });

            setPermissions(data);
            setEventDetails((prev) => ({ ...prev, canAddPermissions: canAdd, canEditPermissions: canEdit, canDeletePermissions: canDelete }));
        })();
    }, [eventId, refreshPermissions]);

    // const selectUserSearchFieldsFinal = useMemo(
    //     () =>
    //         eventDetails.eventTypeName === "Peer Group" || eventDetails.eventTypeName === "One on One"
    //             ? selectUserSearchFields.map((f) => (f.name === "community" || f.name === "peerGroup" ? { ...f, nameForm: f.name, name: f.name + "Name", getOptions: undefined, readOnly: true } : f))
    //             : selectUserSearchFields,
    //     [eventDetails.eventTypeName]
    // );
    // const userInitialSearchFormFinal = useMemo(() => {
    //     const form =
    //         eventDetails.eventTypeName === "Peer Group" || eventDetails.eventTypeName === "One on One"
    //             ? {
    //                 ...userInitialSearchForm,
    //                 community: eventDetails.communityCode,
    //                 communityName: eventDetails.communityNames,
    //                 peerGroup: eventDetails.peerGroupCode,
    //                 peerGroupName: eventDetails.peerGroupNames,
    //             }
    //             : userInitialSearchForm;

    //     getUserStatusOptions().then((response) => (form.status = response.data.find((o) => o.label === "Active")?.value || ""));

    //     return form;
    // }, [eventDetails.communityCode, eventDetails.communityNames, eventDetails.eventTypeName, eventDetails.peerGroupCode, eventDetails.peerGroupNames]);

    return (
        <DetailsPageContainer
            breadCrumbTitle={"Events"}
            breadCrumbLink={"/events"}
            expandedDetails={expandedDetails}
            image={null}
            hideBreadcrumb={!sidebarEventsMenuPermission}
            nameLetters={(eventDetails.eventName || eventDetails.eventTypeName)[0] || " "}
            detailsChildren={
                <div style={{ maxWidth: 320 }}>
                    <h4 className="overflow-text my-0 py-0" title={`${eventDetails.eventName ? `${eventDetails.eventName} | ` : ""}${eventDetails.eventTypeName}`}>
                        {eventDetails.eventName || eventDetails.eventTypeName}
                    </h4>
                    {eventDetails.communityNames && <p className="overflow-text my-0 py-0">{`${eventDetails.communityNames} - ${eventDetails.peerGroupNames}`}</p>}
                    <p className="overflow-text my-0 py-0">{formatDateOnly(eventDetails.eventDate)}</p>
                    <p className="overflow-text my-0 py-0">{`${formatTimeOnly(eventDetails.eventStartTime)} - ${formatTimeOnly(eventDetails.eventEndTime)} ${eventDetails.eventTimeZoneName}`}</p>
                </div>
            }
            navLinkChildren={
                eventDetails._loading ? null : (
                    <>
                        {eventDetails.isAttendee && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`overview`}>
                                    Overview
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {eventDetailsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`details`}>
                                    Details
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {eventAttendeesMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`attendees`}>
                                    Attendees
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {eventCurriculumMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`Curriculum`}>
                                    Curriculum
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {/* {eventNotesMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`notes`}>
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        )} */}
                        {/* {eventDocumentsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`documents`}>
                                    Documents
                                </Nav.Link>
                            </Nav.Item>
                        )} */}
                        {eventStaffMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`staff`}>
                                    Staff
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {eventPermissionsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`permissions`}>
                                    Permissions
                                </Nav.Link>
                            </Nav.Item>
                        )}
                        {eventDetailsMenuPermission && (
                            <Nav.Item as="li">
                                <Nav.Link as={NavLink} to={`notes`}>
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        )}
                    </>
                )
            }
        >
            <ErrorBoundary>
                {eventDetails._loading || !permissionsFetched ? (
                    <div className="h-100 pt-5">
                        <LoadingSpinner />
                    </div>
                ) : (
                    <Suspense
                        fallback={
                            <div className="h-100 pt-5">
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Routes>
                            <Route
                                path="/overview"
                                element={
                                    eventDetails.isAttendee ? (
                                        <Details eventDetails={eventDetails} setEventDetails={setEventDetails} eventId={eventId} setAttendees={setAttendees} asOverview />
                                    ) : (
                                        <UnAuthorisedPage />
                                    )
                                }
                            />
                            <Route
                                path="/details"
                                element={!eventDetailsMenuPermission ? <UnAuthorisedPage /> : <Details eventDetails={eventDetails} setEventDetails={setEventDetails} eventId={eventId} />}
                            />
                            <Route
                                path="/staff"
                                element={
                                    !eventStaffMenuPermission ? (
                                        <UnAuthorisedPage />
                                    ) : (
                                        <EventStaff
                                            eventDetails={eventDetails}
                                            eventId={eventId}
                                            dataRows={staff}
                                            setDataRows={setStaff}
                                            setRefresh={setRefreshStaff}
                                            selectUserSearchFields={selectUserSearchFields}
                                            userInitialSearchForm={userInitialSearchForm}
                                        />
                                    )
                                }
                            />
                            <Route
                                path="/curriculum"
                                element={
                                    !eventCurriculumMenuPermission ? (
                                        <UnAuthorisedPage />
                                    ) : (
                                        <EventCurriculum
                                            eventDetails={eventDetails}
                                            eventId={eventId}
                                            dataRows={eventCurriculum}
                                            setDataRows={setEventCurriculum}
                                            setRefresh={setRefreshEventCurriculum}
                                            selectUserSearchFields={selectUserSearchFields}
                                            userInitialSearchForm={userInitialSearchForm}
                                        />
                                    )
                                }
                            />
                            <Route path="/curriculum/:eventCurriculumId" element={!eventCurriculumMenuPermission ? <UnAuthorisedPage /> : <EventCurriculumDetails />} />
                            <Route
                                path="/attendees"
                                element={
                                    !eventAttendeesMenuPermission ? (
                                        <UnAuthorisedPage />
                                    ) : (
                                        <EventAttendees
                                            eventDetails={eventDetails}
                                            eventId={eventId}
                                            myUserId={myUserId}
                                            dataRows={attendees}
                                            refresh={refreshAttendees}
                                            setDataRows={setAttendees}
                                            setEventDetails={setEventDetails}
                                            setRefresh={setRefreshAttendees}
                                            selectUserSearchFields={selectUserSearchFields}
                                            userInitialSearchForm={userInitialSearchForm}
                                        />
                                    )
                                }
                            />
                            <Route
                                path="/permissions"
                                element={
                                    !eventPermissionsMenuPermission ? (
                                        <UnAuthorisedPage />
                                    ) : (
                                        <EventPermissions eventDetails={eventDetails} eventId={eventId} dataRows={permissions} setDataRows={setPermissions} setRefresh={setRefreshPermissions} />
                                    )
                                }
                            />
                            <Route
                                path="/notes"
                                element={
                                    // !eventPermissionsMenuPermission ? (
                                    //     <UnAuthorisedPage />
                                    // ) : (

                                    <EventNotes eventDetails={eventDetails} eventId={eventId} dataRows={permissions} setDataRows={setPermissions} setRefresh={setRefreshPermissions} />
                                    // )
                                }
                            />
                            <Route path="/" element={<Navigate to={eventDetails.isAttendee ? "overview" : eventDetailsMenuPermission ? "details" : "404"} replace />} />
                            <Route path="/*" element={<NotFoundPage />} />
                        </Routes>
                    </Suspense>
                )}
            </ErrorBoundary>
        </DetailsPageContainer>
    );
};

export default EventDetails;
