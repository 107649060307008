import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { toast } from "react-toastify";
import CheckmarkIcon from "../../assets/images/eva_checkmark.svg";
import Footer from "../../components/BodyContainer/Footer.component";
import { formatTimeOnly } from "../../helpers";
import { buildURL, callApi, Urls } from "../../helpers/api-urls";
import useAnalyticsEventTracker from "../../services/useAnalyticsEventTracker";
import "./SignUp.css";

const SignUpDiscoverySession = ({ setTab, community, userId, getEventDetails, setEventData, isFunderLogo }) => {
    const gaEventTracker = useAnalyticsEventTracker("Propsect form");
    useEffect(() => {
        gaEventTracker();
        ReactGA.pageview(window.location.pathname + "/discovery-session" + window.location.search);
    }, [gaEventTracker]);
    const [show, setShow] = useState(false);
    const [events, setEvents] = useState([]);
    const [checkedOption, setCheckedOption] = useState({ eventId: null });
    const [checkedOptionNone, setCheckedOptionNone] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleSubmit = async () => {
        const postData = {
            prospectUserId: userId,
            eventId: !checkedOptionNone ? checkedOption.eventId : "0",
            convenientCommunityNotFound: checkedOptionNone,
        };
        if (!postData.eventId) return toast.error(`Please select at least one Discovery Session.`);
        const response = await callApi.post(buildURL(`${Urls.AssignEvent}`), { ...postData });
        if (!response.responseData) return;
        if (response.responseCode === 200 && !checkedOptionNone) {
            getEventDetails(response.responseData);
            setEventData(checkedOption);
            setTab(6);
        }
        if (response.responseCode === 200 && checkedOptionNone) {
            setTab(8);
        }
    };

    useEffect(() => {
        (async () => {
            const response = await callApi.get(buildURL(`${Urls.GetCommunityEvents}${community}`));
            if (!response.responseData) return;
            setEvents(response.responseData);
            console.log(response.responseData, "response");
        })();
    }, [community]);
    return (
        <>
            <div className="signUpRightFormSec align-items-start">
                <div className="rightSecForm">
                    <h3 className="disTitle">
                        <img src={CheckmarkIcon} alt="CheckmarkIcon" /> <span>Great news! You qualify! Get started by scheduling your intro Discovery Session.</span>
                    </h3>
                    <h6>Please select one or more times below that you are available to attend.</h6>

                    <div className="quesAnsForm mb-2">
                        <h5 className="quesInfo">What's a Discovery Session?</h5>
                        <p className="disSess">
                            Discovery sessions are a quick introduction meeting to our facilitator and peer group processes, where we'll discuss your business goals and establish a baseline for your
                            businesses' growth to find you the best community fit.
                            <br />
                            Discovery Sessions are an introduction to the peer group experience. We’ll learn more about you and your business, and answer any questions you may have about our program.
                        </p>
                    </div>

                    <Form>
                        <Row>
                            <Col md="12">
                                {events?.slice(0, show ? events.length : 4).map((e) => (
                                    <div class="customCheckBox">
                                        <input
                                            disabled={checkedOptionNone}
                                            type="checkbox"
                                            value={e.eventId}
                                            id={e.eventId}
                                            onChange={() => setCheckedOption(e)}
                                            checked={checkedOption.eventId === e.eventId && !checkedOptionNone}
                                        />
                                        <label htmlFor={e.eventId}>
                                            <h6>{moment(e.eventDate).format("LLLL").slice(0, -8)}</h6>
                                            <div className="date_time">
                                                {formatTimeOnly(e.eventStartTime)} - {formatTimeOnly(e.eventEndTime)} {e.eventTimeZone}
                                            </div>
                                        </label>
                                    </div>
                                ))}

                                {events.length > 2 && !show && (
                                    <div className="viewMoreData pointer">
                                        <span onClick={() => setShow(true)}>VIEW MORE</span>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Form>

                    <div class="customCheckBox">
                        <input
                            type="checkbox"
                            id="contactAdmin"
                            onChange={() => {
                                setCheckedOptionNone(!checkedOptionNone);
                                console.log(checkedOptionNone);
                            }}
                        />
                        <label htmlFor={"contactAdmin"}>
                            <h6 className="font-14"> None of these times work. Please contact me for scheduling.</h6>
                        </label>
                    </div>

                    <Button onClick={() => setTab(3)} className="customPrimaryBtn">
                        BACK
                    </Button>
                    <Button className="ml-10 customPrimaryBtn" onClick={handleSubmit}>
                        CONFIRM & SUBMIT
                    </Button>
                </div>
            </div>
            {!isFunderLogo && <div className="signupFooter">
                <Footer />
            </div>}
        </>
    );
};

export default SignUpDiscoverySession;
