import EditAnswer from "./EditAnswer.component";
import EditNote from "./EditNote.component";

export function cellEditAnswer(_value, data, index) {
    return <EditAnswer initialValue={data[this.nameForm]} name={this.nameForm} data={data} onChange={this.onChange} title={this.title} options={this.options} index={index} />;
}
export function cellEditNote(value, _data, index) {
    return <EditNote initialValue={value} name={this.name} onChange={this.onChange} title={this.title} index={index} />;
}

function cellQue(value, data) {
    return (
        <div className="px-3 py-2 que-ans-cell" style={{ backgroundColor: getColumnColor(data[this.nameForm + "Percentage"]) }}>
            {value}
        </div>
    );
}

export const getColumnColor = (percentage) => {
    if (percentage >= 60) return "#ff0000";
    if (percentage >= 40) return "#f07ac9";

    return "#ffffff";
};

export const peerGroupScorecardDetailsFields = [
    {
        name: "lastName",
        title: "Last Name",
        freezeColumn: true,
        cell: (value, data) => (
            <div className="px-3 py-2" style={(data.noCount * 100) / data.totalOptions < 50 ? undefined : { backgroundColor: "red" }}>
                {value}
            </div>
        ),
    },
    {
        name: "firstName",
        title: "First Name",
        freezeColumn: true,
        cell: (value, data) => (
            <div className="px-3 py-2" style={(data.noCount * 100) / data.totalOptions < 50 ? undefined : { backgroundColor: "red" }}>
                {value}
            </div>
        ),
    },
    {
        name: "year",
        title: "Year",
    },
    {
        name: "quarter",
        title: "Quarter",
    },
    {
        name: "communityName",
        title: "Community",
    },
    {
        name: "peerGroupName",
        title: "Peer Group",
    },
    {
        name: "queOneView",
        nameForm: "queOne",
        columnWidth: 240,
        title: "Bonding: I feel connected to one or more member(s) of my peer group",
        cell: cellQue,
    },
    {
        name: "noteOne",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queTwoView",
        nameForm: "queTwo",
        columnWidth: 240,
        title: "Bonding: I belong in my peer group, and my presence there is important",
        cell: cellQue,
    },
    {
        name: "noteTwo",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queThreeView",
        nameForm: "queThree",
        columnWidth: 240,
        title: "Money: I consistently bring in enough revenue to cover all expenses and show a profit",
        cell: cellQue,
    },
    {
        name: "noteThree",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queFourView",
        nameForm: "queFour",
        columnWidth: 300,
        title: "Money: I carefully review my business updated financial statements each month if not more frequently",
        cell: cellQue,
    },
    {
        name: "noteFour",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queFiveView",
        nameForm: "queFive",
        columnWidth: 300,
        title: "Sales: The steps I follow to make a sale, from generating leads to closing the deal, are generally the same each time",
        cell: cellQue,
    },
    {
        name: "noteFive",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queSixView",
        nameForm: "queSix",
        columnWidth: 240,
        title: "Talent: I pay myself at market rate or better for my own work in my business",
        cell: cellQue,
    },
    {
        name: "noteSix",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queSevenView",
        nameForm: "queSeven",
        columnWidth: 240,
        title: "Talent: I expect that my business will add one or more employees in the coming year",
        cell: cellQue,
    },
    {
        name: "noteSeven",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queEightView",
        nameForm: "queEight",
        columnWidth: 350,
        title: "Process: If I were suddenly incapacitated, someone else could step in and run my business using the systems I have set up and documented",
        cell: cellQue,
    },
    {
        name: "noteEight",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "queNineView",
        nameForm: "queNine",
        columnWidth: 300,
        title: "Grow: I know where I want my business to be three years from now, and I know the next steps I must take to get there",
        cell: cellQue,
    },
    {
        name: "noteNine",
        columnWidth: 280,
        title: "Note",
    },
    {
        name: "facilitators",
        columnWidth: 350,
        title: "Facilitators",
        cell: (value) => <div>{value?.map((obj) => `${obj.firstName || ""} ${obj.lastName || ""}`).join(", ")}</div>,
    },
];

export const peerGroupScorecardInitialForm = {
    year: "",
    quarter: "",
    communityName: "",
    peerGroupName: "",
    statusName: "",
};

export const peerGroupScorecardFormFields = [
    {
        name: "year",
        col: 4,
        title: "Year",
    },
    {
        name: "quarter",
        col: 4,
        title: "Quarter",
    },
    {
        name: "communityName",
        col: 4,
        title: "Community",
    },
    {
        name: "peerGroupName",
        col: 4,
        title: "Peer Group",
    },
    {
        name: "statusName",
        col: 4,
        nameForm: "statusCode",
        title: "Status",
    },
];
