import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import ManagementTable from "../../components/ManagementTable";
import { selectPermissionsUserNoteAdd } from "../../redux/permissionReducer/permissionSelectors";
import { selectUserId, selectUserIsAdmin } from "../../redux/userReducer/userSelectors";
import { userNotePrimaryKey } from "../../services/users/userNoteServices";
import { userNoteApis, userNoteFields, userNoteFormFields, userNoteInitialForm, userNoteInitialSearchForm, userNoteSearchFields } from "./DATA";

export default function UserNotes({ userId }) {
    const { userNoteAddPermission, myUserId, isAdmin } = useSelector(
        createStructuredSelector({ userNoteAddPermission: selectPermissionsUserNoteAdd, myUserId: selectUserId, isAdmin: selectUserIsAdmin })
    );

    const apis = useMemo(
        () => ({ ...userNoteApis, create: { ...userNoteApis.create, body: { noteType: "Member", entityTypeId: userId, userIds: [userId] } }, getAll: { ...userNoteApis.getAll, body: { userId } } }),
        [userId]
    );

    return (
        <ManagementTable
            title="Note"
            tableTitle="Notes"
            primaryKey={userNotePrimaryKey}
            fields={userNoteFields}
            searchFields={userNoteSearchFields}
            formFields={userNoteFormFields}
            apis={apis}
            fetchCount={1}
            initialSearchForm={userNoteInitialSearchForm}
            initialForm={userNoteInitialForm}
            hideAddButton={!userNoteAddPermission}
            hideDeleteButtonRow={(row) => !isAdmin && row.noteById !== myUserId}
            hideActionsColumn={!isAdmin && !userNoteAddPermission}
            customDeleteConfirmationMessage={"Are you sure you would like to delete the note?"}
            searchCols={{
                open: 3,
                openMd: 8,
            }}
            hideMoreFiltersButton
            hideEditButton
            withSearch
            localSort
            refreshAfterSubmit
        />
    );
}
