import { useCallback, useMemo } from "react";

import ManagementTable from "../../components/ManagementTable";
import { UserMilestonePrimaryKey } from "../../services/userMilestones";
import { userMilestoneApis, userMilestoneFields, userMilestoneInitialForm } from "./DATA";

const UserMilestonePage = ({ userId, setUserDetails, canAddUserMilestone, canDeleteUserMilestone }) => {
    const runAfterGetAll = useCallback(({ canAdd, canEdit, canDelete }) => setUserDetails((prev) => ({ ...prev, canAddUserMilestone: canAdd, canDeleteUserMilestone: canDelete })), [setUserDetails]);

    const apis = useMemo(() => ({ ...userMilestoneApis, getAll: { ...userMilestoneApis.getAll, body: { userId } }, create: { ...userMilestoneApis.create, body: { userId } } }), [userId]);
    const initialForm = useMemo(() => ({ ...userMilestoneInitialForm, userId }), [userId]);

    return (
        <ManagementTable
            title="Milestone"
            tableTitle={"Milestones"}
            primaryKey={UserMilestonePrimaryKey}
            fields={userMilestoneFields}
            initialForm={initialForm}
            apis={apis}
            runAfterGetAll={runAfterGetAll}
            customAddButtonTitle={"ADD MILESTONE"}
            customDeleteConfirmationMessage={"Are you sure you would like to delete the record?"}
            hideAddButton={!canAddUserMilestone}
            hideEditButton
            hideViewButton
            hideActionsColumn={!canDeleteUserMilestone}
            refreshAfterSubmit
            noPagination
            noSorting
        />
    );
};

export default UserMilestonePage;
