import React from "react";
import { ErrorMessage, useField } from "formik";

export const TextField = ({ label, hideErrorMessage, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-group customInput">
      {/* <label htmlFor={field.name}>{label}</label> */}
      <input className={`form-control  ${meta.touched && meta.error && "is-invalid"}`} {...field} {...props} autoComplete="off" />
      {!hideErrorMessage && <ErrorMessage style={{ color: "red" }} component="div" name={field.name} className="error" />}
    </div>
  );
};
