import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";

import CommunityHome from "../../assets/images/people_community_home.svg";
import Status from "../../components/Status";
import FormModal from "../../components/FormModal";
import EditIcon from "../../components/EditIcon";
import AttendIcon from "../../assets/images/attending.svg";
import NotAttendIcon from "../../assets/images/not_attending.svg";
import TentativeIcon from "../../assets/images/tentative.svg";
import { useForm, useThrottle, useToggle } from "../../helpers/hooks";
import { eventFormFields } from "../Events/DATA";
import { selectUserFirstName, selectUserId, selectUserLastName, selectUserMiddleName } from "../../redux/userReducer/userSelectors";
import { eventPrimaryKey, createUpdateEvent, getEventRsvpStatusOptions, updateEventRsvpStatus } from "../../services/events/eventServices";
import { formatDateOnly, formatTimeOnly, showResponseMessage } from "../../helpers";
import { selectPermissionsEventEdit } from "../../redux/permissionReducer/permissionSelectors";

import "./EventDetails.css";
import ManagementTable from "../../components/ManagementTable";
import { curriculumEventAttachmentFields, curriculumEventOverviewFields, eventDetailsCurriculumApis, eventDetailsCurriculumOverviewApis } from "../EventCurriculumDetails/DATA";

// const eventFormFieldsOne = eventFormFields.slice(0, eventFormFields.length - 2);
// const eventFormFieldsTwo = eventFormFields.slice(eventFormFields.length - 5, eventFormFields.length);

const getButtonIcon = (status) =>
    status === "Attended" || status?.includes("will attend") || status?.toLowerCase().includes("going")
        ? AttendIcon
        : status?.includes("Tentative") || status?.includes("Await")
        ? TentativeIcon
        : NotAttendIcon;

export default function Details({ eventDetails, setEventDetails, eventId, setAttendees, asOverview = false }) {
    const [fieldsFinal, setFieldsFinal] = useState(eventFormFields);
    const [form, onChangeForm, setForm] = useForm({}, eventFormFields, setFieldsFinal);
    const [showProcessing, setShowProcessing] = useState(false);
    const [showOne, toggleShowOne, setShowOne] = useToggle(false);
    const [editMode, toggleEditMode, setEditMode] = useToggle(false);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(() => ({ label: eventDetails.eventAttendeeStatusName, value: eventDetails.eventAttendeeStatusCode }));
    const processing = useRef(false);
    // const [showTwo, toggleShowTwo, setShowTwo] = useToggle(false);
    const eventDetailsCurriculumFinalApis = useMemo(() => ({ ...eventDetailsCurriculumApis, getAll: { ...eventDetailsCurriculumApis.getAll, body: { eventId } } }), [eventId]);
    const eventDetailsCurriculumOverviewFinalApis = useMemo(() => ({ ...eventDetailsCurriculumOverviewApis, getAll: { ...eventDetailsCurriculumOverviewApis.getAll, body: { eventId } } }), [eventId]);

    const { userId, firstName, middleName, lastName, eventEditPermission } = useSelector(
        createStructuredSelector({
            userId: selectUserId,
            firstName: selectUserFirstName,
            middleName: selectUserMiddleName,
            lastName: selectUserLastName,
            eventEditPermission: selectPermissionsEventEdit,
        })
    );

    const onSaveStatus = useCallback(async () => {
        if (processing.current) return;
        processing.current = true;
        const { label, value } = selectedOption;

        const response = await updateEventRsvpStatus({ eventAttendeeId: eventDetails.eventAttendeeId, eventId, eventAttendeeStatusCode: value });

        processing.current = false;

        showResponseMessage(response, {
            success: "Status updated successfully.",
        });
        if (response.responseCode !== 200) return;

        setEventDetails((prev) => ({ ...prev, eventAttendeeStatusCode: value, eventAttendeeStatusName: label }));
        setEditMode(false);
        setAttendees((prev) =>
            !prev
                ? prev
                : prev.map((row) =>
                      row.userId !== userId.toUpperCase()
                          ? row
                          : { ...row, statusCode: value, statusName: label?.includes("will attend") ? "Going" : label?.includes("Tentative") ? "Awaiting Response" : "Declined" }
                  )
        );
    }, [selectedOption, eventDetails.eventAttendeeId, eventId, setEventDetails, setEditMode, setAttendees, userId]);

    const onSubmit = useThrottle(
        async (formData) => {
            setShowProcessing(true);

            const response = await createUpdateEvent(formData);

            setShowProcessing(false);

            if (response.responseCode !== 200) return toast.error(response.responseMessage || "Something went wrong.");

            toast.success("Event details have been updated successfully.");
            setShowOne(false);
            // setShowTwo(false);
            setEventDetails((prev) => ({ ...prev, ...formData, modifiedBy: `${firstName} ${middleName} ${lastName}`, modifiedDate: moment().format("YYYY-MM-DD") }));
        },
        [setEventDetails, setShowOne, firstName, lastName, middleName],
        4000
    );

    const onCancelOne = useCallback(() => {
        setShowOne(false);
        setTimeout(() => setForm(eventDetails), 500);
    }, [setShowOne, setForm, eventDetails]);
    // const onCancelTwo = useCallback(() => {
    //     setShowTwo(false);
    //     setTimeout(() => setForm(eventDetails), 500);
    // }, [setShowTwo, setForm, eventDetails]);

    useEffect(() => {
        setForm(eventDetails);
    }, [eventDetails, setForm]);

    useEffect(() => {
        if (asOverview && eventDetails.eventTypeCode && (eventDetails.eventTypeName === "Social" || eventDetails.eventTypeName === "Other"))
            getEventRsvpStatusOptions({ eventType: eventDetails.eventTypeCode }).then((response) => {
                setOptions(response.data);
                // setSelectedOption((prev) => {
                //     const found = response.data.find((o) => o.value === prev.value);
                //     return found || response.data.find((o) => o.label?.includes("will attend")) || prev;
                // });
            });
    }, [asOverview, eventDetails.eventTypeCode, eventDetails.eventTypeName]);

    const eventPassed = useMemo(() => new Date(`${eventDetails.eventDate} ${eventDetails.eventStartTime}`).getTime() < Date.now(), [eventDetails.eventDate, eventDetails.eventStartTime]);
    const currentAttendeeStatus = useMemo(
        () =>
            eventPassed
                ? eventDetails.eventAttendeeStatusName
                : options.find((o) => (eventDetails.eventAttendeeStatusCode ? o.value === eventDetails.eventAttendeeStatusCode : o.label === eventDetails.eventAttendeeStatusName))?.label ||
                  eventDetails.eventAttendeeStatusName,
        [eventDetails.eventAttendeeStatusCode, eventDetails.eventAttendeeStatusName, eventPassed, options]
    );
    const createdByDateTimeInfo = `Created ${eventDetails.createdDate ? moment(eventDetails.createdDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${eventDetails.createdBy?.trim() || "-"}`;
    const updatedByDateTimeInfo = !eventDetails.modifiedBy?.trim()
        ? ""
        : `Last Updated ${eventDetails.modifiedDate ? moment(eventDetails.modifiedDate.slice(0, 10)).format("MMM DD, YYYY") : "-"} by ${eventDetails.modifiedBy?.trim()}`;

    return (
        <>
            <div className="eventDetails">
                <div className="eventHeader">
                    <div style={{ minWidth: 200 }}>
                        <h1>{`Event ${asOverview ? "Overview" : "Details"}`}</h1>
                    </div>
                    {!asOverview && (
                        <div className="date-time-info">
                            <span title={createdByDateTimeInfo} className="cursor-default text-right overflow-text">
                                {createdByDateTimeInfo}
                            </span>
                            {updatedByDateTimeInfo && (
                                <>
                                    <span className="mx-2">|</span>
                                    <span title={updatedByDateTimeInfo} className="cursor-default text-right overflow-text">
                                        {updatedByDateTimeInfo}
                                    </span>
                                </>
                            )}
                        </div>
                    )}
                    {eventDetails.canEdit && eventEditPermission && !asOverview && <EditIcon onClick={toggleShowOne} />}
                </div>
                <div className="eventContainer justify-content-between">
                    <div className="inner-event-box">
                        <div className="event-initials">
                            <div className="user-initial">{(eventDetails.eventName || eventDetails.eventTypeName)[0]}</div>
                        </div>
                        <div className="eventContent">
                            <div className="eventContentHeading mb-15">
                                <h3>{`${eventDetails.eventName ? `${eventDetails.eventName} | ` : ""}${eventDetails.eventTypeName}`}</h3>
                            </div>
                            <span>
                                <ul>
                                    {eventDetails.peerGroupNames && (
                                        <li className="event-items mb-15">
                                            <span>
                                                <img height={26} width={26} src={CommunityHome} alt="Icon" />
                                            </span>
                                            <span className="event-txt">{`${eventDetails.communityNames} - ${eventDetails.peerGroupNames}`}</span>
                                        </li>
                                    )}
                                    <li className="event-items mb-15">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
                                                <path
                                                    d="M18.8472 4.16666H4.1528C2.99344 4.16666 2.05359 5.0994 2.05359 6.24999V20.8333C2.05359 21.9839 2.99344 22.9167 4.1528 22.9167H18.8472C20.0066 22.9167 20.9464 21.9839 20.9464 20.8333V6.24999C20.9464 5.0994 20.0066 4.16666 18.8472 4.16666Z"
                                                    stroke="#99CC66"
                                                    strokeWidth="2.4"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path d="M15.6985 2.08333V6.24999" stroke="#99CC66" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M7.30164 2.08333V6.24999" stroke="#99CC66" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.05359 10.4167H20.9464" stroke="#99CC66" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                        {`${formatDateOnly(eventDetails.eventDate)} ${formatTimeOnly(eventDetails.eventStartTime)} - ${formatTimeOnly(eventDetails.eventEndTime)} ${
                                            eventDetails.eventTimeZoneName
                                        }`}
                                    </li>
                                    <li className="event-items mb-15">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path
                                                    d="M12.5 23.375C18.5061 23.375 23.375 18.5061 23.375 12.5C23.375 6.4939 18.5061 1.625 12.5 1.625C6.4939 1.625 1.625 6.4939 1.625 12.5C1.625 18.5061 6.4939 23.375 12.5 23.375Z"
                                                    stroke="#99CC66"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path d="M2.34998 8.875H22.65" stroke="#99CC66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M2.34998 16.125H22.65" stroke="#99CC66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path
                                                    d="M11.8958 1.625C9.86021 4.88703 8.78101 8.65492 8.78101 12.5C8.78101 16.3451 9.86021 20.113 11.8958 23.375"
                                                    stroke="#99CC66"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M13.1041 1.625C15.1398 4.88703 16.219 8.65492 16.219 12.5C16.219 16.3451 15.1398 20.113 13.1041 23.375"
                                                    stroke="#99CC66"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        {eventDetails.eventFormatName}
                                    </li>
                                    {/* <li className="event-items mt-2">
                          <span>
                              <img height={26} width={26} src={ZondIcon} alt="Icon" />
                          </span>
                          Focusing on<span className="bold-txt"></span>
                          <strong>Sales, Marketing</strong>
                      </li> */}
                                </ul>
                            </span>
                        </div>
                    </div>
                    {asOverview && (
                        <div className="rsvpBox">
                            <div className="rsvpHeader">
                                <h6>Attendance</h6>
                                {(eventDetails.eventTypeName === "Social" || eventDetails.eventTypeName === "Other") && !eventPassed && (
                                    <button type="button" className="customPrimaryBtn btn rsvpSaveBtn" onClick={editMode ? onSaveStatus : toggleEditMode}>
                                        {editMode ? "SAVE" : "EDIT"}
                                    </button>
                                )}
                            </div>
                            <div className="rsvpContainer">
                                {editMode ? (
                                    options.map((option) => (
                                        <button
                                            key={option.value}
                                            type="button"
                                            className={`rsvpStatus${selectedOption.value === option.value ? " selected" : ""}`}
                                            onClick={selectedOption.value === option.value ? undefined : () => setSelectedOption(option)}
                                        >
                                            <span>
                                                <img src={getButtonIcon(option.label)} alt="Icon" />
                                            </span>
                                            {option.label}
                                        </button>
                                    ))
                                ) : (
                                    <button type="button" className="rsvpStatus selected">
                                        <span>
                                            <img src={getButtonIcon(currentAttendeeStatus)} alt="Icon" />
                                        </span>
                                        {currentAttendeeStatus}
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {!asOverview && (
                    <div className="d-flex justify-content-end">
                        {eventDetails.eventStatusName && (
                            <div>
                                <Status text={eventDetails.eventStatusName} />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="descriptionContainer mt-4">
                <div className="row">
                    {/* {eventDetails.canEdit && eventEditPermission && !asOverview && (
              <div className="col-md-12">
                  <span className="edit-ic pointer" onClick={toggleShowTwo}>
                      <img src={EditSquare} alt="Icon" />
                  </span>
              </div>
          )} */}
                    <div className="descriptionContet col-md-6">
                        <h3 className="contentHeading">Description</h3>
                        <p className="content-txt mt-2">{eventDetails.eventDescription}</p>
                    </div>
                    <div className="FormatDetails col-md-6">
                        <h3 className="contentHeading">Format</h3>
                        <div className="overflow-text mt-2">
                            <p className="bold-txt">
                                {eventDetails.eventFormatName}{" "}
                                {eventDetails.eventLink && (
                                    <>
                                        :{" "}
                                        <a href={eventDetails.eventLink} target={"_blank"} rel="noreferrer">
                                            {eventDetails.eventLink}
                                        </a>
                                    </>
                                )}
                            </p>
                        </div>
                        {eventDetails.eventFormatNote && (
                            <>
                                <h3 className="contentHeading my-3">Format Note</h3>
                                <p className="content-txt mt-3">{eventDetails.eventFormatNote}</p>
                            </>
                        )}
                    </div>
                </div>
                {!asOverview && (
                    <div className="row">
                        <div className="emailMessage mt-4">
                            <h3 className="contentHeading">Email Message</h3>
                            <p className="content-txt mt-3">{eventDetails.eventEmailMessage}</p>
                        </div>
                    </div>
                )}
            </div>
            {!asOverview && (
                <div style={{ marginTop: 20 }}>
                    <ManagementTable
                        title="Curriculum"
                        tableTitle="Curriculum Attachments"
                        // searchFields={curriculumSearchFields}
                        // initialSearchForm={curriculumInitialSearchForm}
                        fields={curriculumEventAttachmentFields}
                        apis={eventDetailsCurriculumFinalApis}
                        // initialForm={curriculumIntitialForm}
                        // formFields={curriculumFormFields}
                        // withSearch
                        // hideMoreFiltersButton
                        // primaryKey={curriculumPrimaryKey}
                        // refreshAfterSubmit
                        localSort={true}
                        hideActionsColumn
                        hideAddButton
                    />
                </div>
            )}
            {asOverview && (
                <div style={{ marginTop: 20 }}>
                    <ManagementTable
                        title="Curriculum"
                        tableTitle="Curriculum Overview"
                        // searchFields={curriculumSearchFields}
                        // initialSearchForm={curriculumInitialSearchForm}
                        fields={curriculumEventOverviewFields}
                        apis={eventDetailsCurriculumOverviewFinalApis}
                        // initialForm={curriculumIntitialForm}
                        // formFields={curriculumFormFields}
                        // withSearch
                        // hideMoreFiltersButton
                        // primaryKey={curriculumPrimaryKey}
                        // refreshAfterSubmit
                        localSort={true}
                        hideActionsColumn
                        hideAddButton
                    />
                </div>
            )}

            <FormModal
                show={showOne}
                showProcessing={showProcessing}
                title={"Event"}
                primaryKey={eventPrimaryKey}
                fields={fieldsFinal}
                form={form}
                onChange={onChangeForm}
                onSubmit={onSubmit}
                onCancel={onCancelOne}
                customUpdateConfirmationMessage={"Are you sure want to update the event details?"}
                confirmOnSubmit
            />
            {/* <FormModal
            show={showTwo}
            showProcessing={showProcessing}
            title={"Event"}
            primaryKey={eventPrimaryKey}
            fields={eventFormFieldsTwo}
            form={form}
            onChange={onChangeForm}
            onSubmit={onSubmit}
            onCancel={onCancelTwo}
            customUpdateConfirmationMessage={"Are you sure want to update the event details?"}
            confirmOnSubmit
        /> */}
        </>
    );
}
