export const EHeaderActionTypes = {
  hideHeader: "HIDE_HEADER",
  showHeader: "SHOW_HEADER",
  toggleHeader: "TOGGLE_HEADER",
};

export const hideHeaderAction = () => ({
  type: EHeaderActionTypes.hideHeader,
});
export const showHeaderAction = () => ({
  type: EHeaderActionTypes.showHeader,
});
export const toggleHeaderAction = () => ({
  type: EHeaderActionTypes.toggleHeader,
});
