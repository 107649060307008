import React from "react";
import { Link } from "react-router-dom";

import BodyBackground from "../../../components/BodyBackground";

const SentLink = () => {
    return (
        <BodyBackground>
            <div className="login-form-wrapper">
                <h1 className="SignInTxt reset-link-txt">Reset Link Sent</h1>
                <div className="text-center">We have sent a link to the email you provided to reset your password. Please be sure to check your spam folder if you don't see it in your inbox.</div>
                <div className="col-md-12 mb-3 text-center back-to-login">
                    <Link to="/sign-in">
                        <button className="btn purpleBtn">BACK TO SIGN IN</button>
                    </Link>
                </div>
            </div>
        </BodyBackground>
    );
};

export default SentLink;
