import { useCallback, useMemo } from "react";

import ManagementTable from "../../components/ManagementTable";
import { userPeerGroupPrimaryKey } from "../../services/peer-groups/peerGroupServices";
import { userPeerGroupApis, userPeerGroupFields, userPeerGroupInitialForm } from "./DATA";

const UserPeerGroups = ({ userId, setUserDetails, canAddPeerGroups, canEditPeerGroups, canDeletePeerGroups }) => {
    const runAfterGetAll = useCallback(
        ({ canAdd, canEdit, canDelete }) => setUserDetails((prev) => ({ ...prev, canAddPeerGroups: canAdd, canEditPeerGroups: canEdit, canDeletePeerGroups: canDelete })),
        [setUserDetails]
    );

    const apis = useMemo(() => ({ ...userPeerGroupApis, getAll: { ...userPeerGroupApis.getAll, body: { userId } } }), [userId]);
    const initialForm = useMemo(() => ({ ...userPeerGroupInitialForm, userId }), [userId]);

    return (
        <ManagementTable
            title="Peer Group"
            tableTitle={"Peer Groups"}
            primaryKey={userPeerGroupPrimaryKey}
            fields={userPeerGroupFields}
            initialForm={initialForm}
            apis={apis}
            runAfterGetAll={runAfterGetAll}
            customAddButtonTitle={"ADD PEER GROUP"}
            noDataMessage={"No Peer Groups have been assigned."}
            customDeleteConfirmationMessage={"Are you sure you want to remove the peer group against user or member?"}
            hideAddButton={!canAddPeerGroups}
            hideEditButton={!canEditPeerGroups}
            hideDeleteButton={!canDeletePeerGroups}
            hideActionsColumn={!canEditPeerGroups && !canDeletePeerGroups}
            localSort
            refreshAfterSubmit
        />
    );
};

export default UserPeerGroups;
