import React from "react";
import DummyUserImage from "../../assets/images/user.jpg";

import "./Image.css";

export default function Image({ src = "", dummy = DummyUserImage, alt = "", width = 50, height = 50, nameLetters, fontSize = 24 }) {
  return (
    <div className="image-initials" style={{ width, height }}>
      {nameLetters && !src ? (
        <div className="image-letters" style={{ fontSize }}>
          {nameLetters}
        </div>
      ) : (
        <img src={src || dummy} alt={alt || nameLetters || "user"} width={width} height={height} />
      )}
    </div>
  );
}
