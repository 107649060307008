import { Link } from "react-router-dom";

import { EFieldInputTypes } from "../../components/FormField";
import { formatDateOnly, throttle } from "../../helpers";
import { getCommunityOptions } from "../../services/communities/communityServices";
import { createPeerGroup, getPeerGroupOptions, getPeerGroupOptionsByCommunity, getPeerGroups } from "../../services/peer-groups/peerGroupServices";
import { getStateOptions } from "../../services/states/stateServices";

export const peerGroupStatusOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
];

export const peerGroupInitialForm = {
    peerGroupName: "",
    peerGroupCustomName: "",
    communityCode: "",
    startDate: "",
    endDate: "",
    peerGroupPicture: "",
    membersCount: 0,
};

export const peerGroupInitialSort = {
    field: "peerGroupName",
    dir: "asc",
};

export const peerGroupInitialSearchForm = {
    sortOrder: peerGroupInitialSort.dir,
    sortField: peerGroupInitialSort.field,
    peerGroupCode: "",
    communityCode: "",
    stateCode: "",
    peerGroupStatus: peerGroupStatusOptions.find((o) => o.label === "Active")?.value || "",
    memberName: "",
    facilitatorName: "",
};

export const peerGroupSearchFields = [
    {
        name: "stateCode",
        title: "State",
        col: 3,
        inputType: EFieldInputTypes.select,
        getOptions: getStateOptions,
    },
    {
        name: "communityCode",
        title: "Community",
        col: 3,
        inputType: EFieldInputTypes.select,
        getOptions: getCommunityOptions,
        dependencies: [
            {
                name: "peerGroupCode",
                initialValue: "",
                getOptions: throttle(async (form) => {
                    const response = form.communityCode ? await getPeerGroupOptionsByCommunity({ communityCode: form.communityCode }) : await getPeerGroupOptions();

                    return response;
                }, 200),
            },
        ],
    },
    {
        name: "peerGroupCode",
        title: "Peer Group",
        inputType: EFieldInputTypes.select,
        getOptions: getPeerGroupOptions,
        col: 3,
    },
    {
        name: "peerGroupStatus",
        title: "Peer Group Status",
        col: 3,
        inputType: EFieldInputTypes.select,
        getOptions: async () => ({
            data: peerGroupStatusOptions,
        }),
        getInitialOption: ({ data }) => data.find((o) => o.label === "Active"),
    },
    {
        name: "memberName",
        title: "Member",
        col: 3,
    },
    {
        name: "facilitatorName",
        title: "Staff Name",
        col: 3,
    },
];

export const peerGroupFields = [
    {
        name: "peerGroupName",
        title: "Peer Group Name",
        filterable: true,
        filter: {
            maxLength: 10,
        },
        columnWidth: 140,
        cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupId}`}>{value}</Link>,
    },
    {
        name: "peerGroupCustomName",
        title: "Peer Group Custom Name",
        filterable: true,
        filter: {
            maxLength: 10,
        },
        columnWidth: 140,
        cell: (value, data) => <Link to={`/peer-groups/${data.peerGroupId}`}>{value}</Link>,
    },
    {
        name: "communityName",
        title: "Community Name",
        filterable: true,
        columnWidth: 140,
        cell: (value, data) => <Link to={`/communities/${data.communityCode}`}>{value}</Link>,
    },
    {
        name: "startDate",
        title: "Start Date",
        filterable: true,
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "endDate",
        title: "End Date",
        filterable: true,
        cell: (value) => formatDateOnly(value),
    },
    {
        name: "membersCount",
        title: "Members",
        filterable: true,
        columnWidth: 90,
    },
    {
        name: "scheduleTime",
        title: "Schedule Time",
        filterable: true,
        notSortable: true,
        columnWidth: 200,
    },
];

export const peerGroupFormFields = [
    {
        name: "peerGroupPictureUrl",
        nameForm: "peerGroupPicture",
        title: "",
        inputType: EFieldInputTypes.image,
        col: 3,
        colMd: 3,
    },
    {
        name: "multiple",
        inputType: EFieldInputTypes.multiple,
        col: 9,
        colMd: 9,
        fields: [
            {
                name: "peerGroupName",
                title: "Peer Group Name",
                placeholder: "Enter Name",
                maxLength: 50,
                required: true,
            },
            {
                name: "peerGroupCustomName",
                title: "Peer Group Custom Name",
                placeholder: "Enter Custom Name",
                maxLength: 50,
                required: true,
            },
            {
                name: "communityName",
                nameForm: "communityCode",
                title: "Community",
                inputType: EFieldInputTypes.select,
                getOptions: getCommunityOptions,
                required: true,
            },
            {
                name: "startDate",
                title: "Start Date",
                inputType: EFieldInputTypes.date,
                required: true,
            },
            {
                name: "endDate",
                title: "End Date",
                inputType: EFieldInputTypes.date,
                required: false,
                validate: (form) => (!form.endDate ? "" : form.startDate && new Date(form.startDate).getTime() > new Date(form.endDate).getTime() ? "End Date must be after the Start Date." : ""),
            },
        ],
    },
];

export const peerGroupApis = {
    getAll: {
        api: getPeerGroups,
    },
    create: {
        api: createPeerGroup,
    },
};
